export const SECTION1_SEATS = [
  //////////// 1列目 ////////////
  /*1 */{dataseat: 1, data_seatno: "s1-y-1-10", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席1", class: "type_1"},
  /*2 */{dataseat: 2, data_seatno: "s1-y-1-11", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席1", class: "type_1"},
  /*3 */{dataseat: 3, data_seatno: "s1-y-1-12", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席1", class: "type_1"},
  /*4 */{dataseat: 4, data_seatno: "s1-y-1-13", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席1", class: "type_1"},
  /*5 */{dataseat: 5, data_seatno: "s1-y-1-14", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席1", class: "type_1"},
  /*6 */{dataseat: 6, data_seatno: "s1-y-1-15", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席1", class: "type_1"},
  /*7 */{dataseat: 7, data_seatno: "s1-y-1-16", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席1", class: "type_1"},
  /*8 */{dataseat: 8, data_seatno: "s1-y-1-17", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席1", class: "type_1"},
  /*9 */{dataseat: 9, data_seatno: "s1-y-1-19", customer_id: "", name: "", mail: "", phone: "", studio: "カメラ", class: "type_98"},
  /*10 */{dataseat: 10, data_seatno: "s1-y-1-20", customer_id: "", name: "", mail: "", phone: "", studio: "カメラ", class: "type_98"},
  /*11 */{dataseat: 11, data_seatno: "s1-y-1-21", customer_id: "", name: "", mail: "", phone: "", studio: "カメラ", class: "type_98"},
  /*12 */{dataseat: 12, data_seatno: "s1-y-1-22", customer_id: "", name: "", mail: "", phone: "", studio: "カメラ", class: "type_98"},
  /*13 */{dataseat: 13, data_seatno: "s1-y-1-23", customer_id: "", name: "", mail: "", phone: "", studio: "カメラ", class: "type_98"},
  /*14 */{dataseat: 14, data_seatno: "s1-y-1-24", customer_id: "", name: "", mail: "", phone: "", studio: "カメラ", class: "type_98"},
  /*15 */{dataseat: 15, data_seatno: "s1-y-1-25", customer_id: "", name: "", mail: "", phone: "", studio: "カメラ", class: "type_98"},
  /*16 */{dataseat: 16, data_seatno: "s1-y-1-26", customer_id: "", name: "", mail: "", phone: "", studio: "カメラ", class: "type_98"},
  /*17 */{dataseat: 17, data_seatno: "s1-y-1-27", customer_id: "", name: "", mail: "", phone: "", studio: "カメラ", class: "type_98"},
  /*18 */{dataseat: 18, data_seatno: "s1-y-1-28", customer_id: "", name: "", mail: "", phone: "", studio: "カメラ", class: "type_98"},
  /*19 */{dataseat: 19, data_seatno: "s1-y-1-29", customer_id: "", name: "", mail: "", phone: "", studio: "カメラ", class: "type_98"},
  /*20 */{dataseat: 20, data_seatno: "s1-y-1-30", customer_id: "", name: "", mail: "", phone: "", studio: "カメラ", class: "type_98"},
  /*21 */{dataseat: 21, data_seatno: "s1-y-1-32", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席1", class: "type_1"},
  /*22 */{dataseat: 22, data_seatno: "s1-y-1-33", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席1", class: "type_1"},
  /*23 */{dataseat: 23, data_seatno: "s1-y-1-34", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席1", class: "type_1"},
  /*24 */{dataseat: 24, data_seatno: "s1-y-1-35", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席1", class: "type_1"},
  /*25 */{dataseat: 25, data_seatno: "s1-y-1-36", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席1", class: "type_1"},
  /*26 */{dataseat: 26, data_seatno: "s1-y-1-37", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席1", class: "type_1"},
  /*27 */{dataseat: 27, data_seatno: "s1-y-1-38", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席1", class: "type_1"},
  /*28 */{dataseat: 28, data_seatno: "s1-y-1-39", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席1", class: "type_1"},
  //////////// 2列目 ////////////
  /*29 */{dataseat: 29, data_seatno: "s1-y-2-9", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席1", class: "type_1"},
  /*30 */{dataseat: 30, data_seatno: "s1-y-2-10", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席1", class: "type_1"},
  /*31 */{dataseat: 31, data_seatno: "s1-y-2-11", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席1", class: "type_1"},
  /*32 */{dataseat: 32, data_seatno: "s1-y-2-12", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席1", class: "type_1"},
  /*33 */{dataseat: 33, data_seatno: "s1-y-2-13", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席1", class: "type_1"},
  /*34 */{dataseat: 34, data_seatno: "s1-y-2-14", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席1", class: "type_1"},
  /*35 */{dataseat: 35, data_seatno: "s1-y-2-15", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席1", class: "type_1"},
  /*36 */{dataseat: 36, data_seatno: "s1-y-2-16", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席1", class: "type_1"},
  /*37 */{dataseat: 37, data_seatno: "s1-y-2-17", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席1", class: "type_1"},
  /*38 */{dataseat: 38, data_seatno: "s1-y-2-19", customer_id: "", name: "", mail: "", phone: "", studio: "カメラ", class: "type_98"},
  /*39 */{dataseat: 39, data_seatno: "s1-y-2-20", customer_id: "", name: "", mail: "", phone: "", studio: "カメラ", class: "type_98"},
  /*40 */{dataseat: 40, data_seatno: "s1-y-2-21", customer_id: "", name: "", mail: "", phone: "", studio: "カメラ", class: "type_98"},
  /*41 */{dataseat: 41, data_seatno: "s1-y-2-22", customer_id: "", name: "", mail: "", phone: "", studio: "カメラ", class: "type_98"},
  /*42 */{dataseat: 42, data_seatno: "s1-y-2-23", customer_id: "", name: "", mail: "", phone: "", studio: "カメラ", class: "type_98"},
  /*43 */{dataseat: 43, data_seatno: "s1-y-2-24", customer_id: "", name: "", mail: "", phone: "", studio: "カメラ", class: "type_98"},
  /*44 */{dataseat: 44, data_seatno: "s1-y-2-25", customer_id: "", name: "", mail: "", phone: "", studio: "カメラ", class: "type_98"},
  /*45 */{dataseat: 45, data_seatno: "s1-y-2-26", customer_id: "", name: "", mail: "", phone: "", studio: "カメラ", class: "type_98"},
  /*46 */{dataseat: 46, data_seatno: "s1-y-2-27", customer_id: "", name: "", mail: "", phone: "", studio: "カメラ", class: "type_98"},
  /*47 */{dataseat: 47, data_seatno: "s1-y-2-28", customer_id: "", name: "", mail: "", phone: "", studio: "カメラ", class: "type_98"},
  /*48 */{dataseat: 48, data_seatno: "s1-y-2-29", customer_id: "", name: "", mail: "", phone: "", studio: "カメラ", class: "type_98"},
  /*49 */{dataseat: 49, data_seatno: "s1-y-2-30", customer_id: "", name: "", mail: "", phone: "", studio: "カメラ", class: "type_98"},
  /*50 */{dataseat: 50, data_seatno: "s1-y-2-32", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席1", class: "type_1"},
  /*51 */{dataseat: 51, data_seatno: "s1-y-2-33", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席1", class: "type_1"},
  /*52 */{dataseat: 52, data_seatno: "s1-y-2-34", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席1", class: "type_1"},
  /*53 */{dataseat: 53, data_seatno: "s1-y-2-35", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席1", class: "type_1"},
  /*54 */{dataseat: 54, data_seatno: "s1-y-2-36", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席1", class: "type_1"},
  /*55 */{dataseat: 55, data_seatno: "s1-y-2-37", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席1", class: "type_1"},
  /*56 */{dataseat: 56, data_seatno: "s1-y-2-38", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席1", class: "type_1"},
  /*57 */{dataseat: 57, data_seatno: "s1-y-2-39", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席1", class: "type_1"},
  /*58 */{dataseat: 58, data_seatno: "s1-y-2-40", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席1", class: "type_1"},
  //////////// 3列目 ////////////
  /*59 */{dataseat: 59, data_seatno: "s1-y-3-8", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席1", class: "type_1"},
  /*60 */{dataseat: 60, data_seatno: "s1-y-3-9", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席1", class: "type_1"},
  /*61 */{dataseat: 61, data_seatno: "s1-y-3-10", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席1", class: "type_1"},
  /*62 */{dataseat: 62, data_seatno: "s1-y-3-11", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席1", class: "type_1"},
  /*63 */{dataseat: 63, data_seatno: "s1-y-3-12", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席1", class: "type_1"},
  /*64 */{dataseat: 64, data_seatno: "s1-y-3-13", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席1", class: "type_1"},
  /*65 */{dataseat: 65, data_seatno: "s1-y-3-14", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席1", class: "type_1"},
  /*66 */{dataseat: 66, data_seatno: "s1-y-3-15", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席1", class: "type_1"},
  /*67 */{dataseat: 67, data_seatno: "s1-y-3-16", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席1", class: "type_1"},
  /*68 */{dataseat: 68, data_seatno: "s1-y-3-17", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席1", class: "type_1"},
  /*69 */{dataseat: 69, data_seatno: "s1-y-3-19", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席1", class: "type_1"},
  /*70 */{dataseat: 70, data_seatno: "s1-y-3-20", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席1", class: "type_1"},
  /*71 */{dataseat: 71, data_seatno: "s1-y-3-21", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席1", class: "type_1"},
  /*72 */{dataseat: 72, data_seatno: "s1-y-3-22", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席1", class: "type_1"},
  /*73 */{dataseat: 73, data_seatno: "s1-y-3-23", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席1", class: "type_1"},
  /*74 */{dataseat: 74, data_seatno: "s1-y-3-24", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席1", class: "type_1"},
  /*75 */{dataseat: 75, data_seatno: "s1-y-3-25", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席1", class: "type_1"},
  /*76 */{dataseat: 76, data_seatno: "s1-y-3-26", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席1", class: "type_1"},
  /*77 */{dataseat: 77, data_seatno: "s1-y-3-27", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席1", class: "type_1"},
  /*78 */{dataseat: 78, data_seatno: "s1-y-3-28", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席1", class: "type_1"},
  /*79 */{dataseat: 79, data_seatno: "s1-y-3-29", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席1", class: "type_1"},
  /*80 */{dataseat: 80, data_seatno: "s1-y-3-30", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席1", class: "type_1"},
  /*81 */{dataseat: 81, data_seatno: "s1-y-3-32", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席1", class: "type_1"},
  /*82 */{dataseat: 82, data_seatno: "s1-y-3-33", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席1", class: "type_1"},
  /*83 */{dataseat: 83, data_seatno: "s1-y-3-34", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席1", class: "type_1"},
  /*84 */{dataseat: 84, data_seatno: "s1-y-3-35", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席1", class: "type_1"},
  /*85 */{dataseat: 85, data_seatno: "s1-y-3-36", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席1", class: "type_1"},
  /*86 */{dataseat: 86, data_seatno: "s1-y-3-37", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席1", class: "type_1"},
  /*87 */{dataseat: 87, data_seatno: "s1-y-3-38", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席1", class: "type_1"},
  /*88 */{dataseat: 88, data_seatno: "s1-y-3-39", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席1", class: "type_1"},
  /*89 */{dataseat: 89, data_seatno: "s1-y-3-40", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席1", class: "type_1"},
  /*90 */{dataseat: 90, data_seatno: "s1-y-3-41", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席1", class: "type_1"},
  //////////// 4列目 ////////////
  /*91 */{dataseat: 91, data_seatno: "s1-y-4-8", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席1", class: "type_1"},
  /*92 */{dataseat: 92, data_seatno: "s1-y-4-9", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席1", class: "type_1"},
  /*93 */{dataseat: 93, data_seatno: "s1-y-4-10", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席1", class: "type_1"},
  /*94 */{dataseat: 94, data_seatno: "s1-y-4-11", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席1", class: "type_1"},
  /*95 */{dataseat: 95, data_seatno: "s1-y-4-12", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席1", class: "type_1"},
  /*96 */{dataseat: 96, data_seatno: "s1-y-4-13", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席1", class: "type_1"},
  /*97 */{dataseat: 97, data_seatno: "s1-y-4-14", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席1", class: "type_1"},
  /*98 */{dataseat: 98, data_seatno: "s1-y-4-15", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席1", class: "type_1"},
  /*99 */{dataseat: 99, data_seatno: "s1-y-4-16", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席1", class: "type_1"},
  /*100 */{dataseat: 100, data_seatno: "s1-y-4-17", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席1", class: "type_1"},
  /*101 */{dataseat: 101, data_seatno: "s1-y-4-19", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席1", class: "type_1"},
  /*102 */{dataseat: 102, data_seatno: "s1-y-4-20", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席1", class: "type_1"},
  /*103 */{dataseat: 103, data_seatno: "s1-y-4-21", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席1", class: "type_1"},
  /*104 */{dataseat: 104, data_seatno: "s1-y-4-22", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席1", class: "type_1"},
  /*105 */{dataseat: 105, data_seatno: "s1-y-4-23", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席1", class: "type_1"},
  /*106 */{dataseat: 106, data_seatno: "s1-y-4-24", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席1", class: "type_1"},
  /*107 */{dataseat: 107, data_seatno: "s1-y-4-25", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席1", class: "type_1"},
  /*108 */{dataseat: 108, data_seatno: "s1-y-4-26", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席1", class: "type_1"},
  /*109 */{dataseat: 109, data_seatno: "s1-y-4-27", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席1", class: "type_1"},
  /*110 */{dataseat: 110, data_seatno: "s1-y-4-28", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席1", class: "type_1"},
  /*111 */{dataseat: 111, data_seatno: "s1-y-4-29", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席1", class: "type_1"},
  /*112 */{dataseat: 112, data_seatno: "s1-y-4-30", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席1", class: "type_1"},
  /*113 */{dataseat: 113, data_seatno: "s1-y-4-32", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席1", class: "type_1"},
  /*114 */{dataseat: 114, data_seatno: "s1-y-4-33", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席1", class: "type_1"},
  /*115 */{dataseat: 115, data_seatno: "s1-y-4-34", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席1", class: "type_1"},
  /*116 */{dataseat: 116, data_seatno: "s1-y-4-35", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席1", class: "type_1"},
  /*117 */{dataseat: 117, data_seatno: "s1-y-4-36", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席1", class: "type_1"},
  /*118 */{dataseat: 118, data_seatno: "s1-y-4-37", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席1", class: "type_1"},
  /*119 */{dataseat: 119, data_seatno: "s1-y-4-38", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席1", class: "type_1"},
  /*120 */{dataseat: 120, data_seatno: "s1-y-4-39", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席1", class: "type_1"},
  /*121 */{dataseat: 121, data_seatno: "s1-y-4-40", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席1", class: "type_1"},
  /*122 */{dataseat: 122, data_seatno: "s1-y-4-41", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席1", class: "type_1"},
  //////////// 5列目 ////////////
  /*123 */{dataseat: 123, data_seatno: "s1-y-5-7", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席1", class: "type_1"},
  /*124 */{dataseat: 124, data_seatno: "s1-y-5-8", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席1", class: "type_1"},
  /*125 */{dataseat: 125, data_seatno: "s1-y-5-9", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席1", class: "type_1"},
  /*126 */{dataseat: 126, data_seatno: "s1-y-5-10", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席1", class: "type_1"},
  /*127 */{dataseat: 127, data_seatno: "s1-y-5-11", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席1", class: "type_1"},
  /*128 */{dataseat: 128, data_seatno: "s1-y-5-12", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席1", class: "type_1"},
  /*129 */{dataseat: 129, data_seatno: "s1-y-5-13", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席1", class: "type_1"},
  /*130 */{dataseat: 130, data_seatno: "s1-y-5-14", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席1", class: "type_1"},
  /*131 */{dataseat: 131, data_seatno: "s1-y-5-15", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席1", class: "type_1"},
  /*132 */{dataseat: 132, data_seatno: "s1-y-5-16", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席1", class: "type_1"},
  /*133 */{dataseat: 133, data_seatno: "s1-y-5-17", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席1", class: "type_1"},
  /*134 */{dataseat: 134, data_seatno: "s1-y-5-19", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席1", class: "type_1"},
  /*135 */{dataseat: 135, data_seatno: "s1-y-5-20", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席1", class: "type_1"},
  /*136 */{dataseat: 136, data_seatno: "s1-y-5-21", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席1", class: "type_1"},
  /*137 */{dataseat: 137, data_seatno: "s1-y-5-22", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席1", class: "type_1"},
  /*138 */{dataseat: 138, data_seatno: "s1-y-5-23", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席1", class: "type_1"},
  /*139 */{dataseat: 139, data_seatno: "s1-y-5-24", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席1", class: "type_1"},
  /*140 */{dataseat: 140, data_seatno: "s1-y-5-25", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席1", class: "type_1"},
  /*141 */{dataseat: 141, data_seatno: "s1-y-5-26", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席1", class: "type_1"},
  /*142 */{dataseat: 142, data_seatno: "s1-y-5-27", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席1", class: "type_1"},
  /*143 */{dataseat: 143, data_seatno: "s1-y-5-28", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席1", class: "type_1"},
  /*144 */{dataseat: 144, data_seatno: "s1-y-5-29", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席1", class: "type_1"},
  /*145 */{dataseat: 145, data_seatno: "s1-y-5-30", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席1", class: "type_1"},
  /*146 */{dataseat: 146, data_seatno: "s1-y-5-32", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席1", class: "type_1"},
  /*147 */{dataseat: 147, data_seatno: "s1-y-5-33", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席1", class: "type_1"},
  /*148 */{dataseat: 148, data_seatno: "s1-y-5-34", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席1", class: "type_1"},
  /*149 */{dataseat: 149, data_seatno: "s1-y-5-35", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席1", class: "type_1"},
  /*150 */{dataseat: 150, data_seatno: "s1-y-5-36", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席1", class: "type_1"},
  /*151 */{dataseat: 151, data_seatno: "s1-y-5-37", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席1", class: "type_1"},
  /*152 */{dataseat: 152, data_seatno: "s1-y-5-38", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席1", class: "type_1"},
  /*153 */{dataseat: 153, data_seatno: "s1-y-5-39", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席1", class: "type_1"},
  /*154 */{dataseat: 154, data_seatno: "s1-y-5-40", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席1", class: "type_1"},
  /*155 */{dataseat: 155, data_seatno: "s1-y-5-41", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席1", class: "type_1"},
  /*156 */{dataseat: 156, data_seatno: "s1-y-5-42", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席1", class: "type_1"},
  //////////// 6列目 ////////////
  /*157 */{dataseat: 157, data_seatno: "s1-y-6-6", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席1", class: "type_1"},
  /*158 */{dataseat: 158, data_seatno: "s1-y-6-7", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席1", class: "type_1"},
  /*159 */{dataseat: 159, data_seatno: "s1-y-6-8", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席1", class: "type_1"},
  /*160 */{dataseat: 160, data_seatno: "s1-y-6-9", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席1", class: "type_1"},
  /*161 */{dataseat: 161, data_seatno: "s1-y-6-10", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席1", class: "type_1"},
  /*162 */{dataseat: 162, data_seatno: "s1-y-6-11", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席1", class: "type_1"},
  /*163 */{dataseat: 163, data_seatno: "s1-y-6-12", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席1", class: "type_1"},
  /*164 */{dataseat: 164, data_seatno: "s1-y-6-13", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席1", class: "type_1"},
  /*165 */{dataseat: 165, data_seatno: "s1-y-6-14", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席1", class: "type_1"},
  /*166 */{dataseat: 166, data_seatno: "s1-y-6-15", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席1", class: "type_1"},
  /*167 */{dataseat: 167, data_seatno: "s1-y-6-16", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席1", class: "type_1"},
  /*168 */{dataseat: 168, data_seatno: "s1-y-6-17", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席1", class: "type_1"},
  /*169 */{dataseat: 169, data_seatno: "s1-y-6-19", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席1", class: "type_1"},
  /*170 */{dataseat: 170, data_seatno: "s1-y-6-20", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席1", class: "type_1"},
  /*171 */{dataseat: 171, data_seatno: "s1-y-6-21", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席1", class: "type_1"},
  /*172 */{dataseat: 172, data_seatno: "s1-y-6-22", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席1", class: "type_1"},
  /*173 */{dataseat: 173, data_seatno: "s1-y-6-23", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席1", class: "type_1"},
  /*174 */{dataseat: 174, data_seatno: "s1-y-6-24", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席1", class: "type_1"},
  /*175 */{dataseat: 175, data_seatno: "s1-y-6-25", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席1", class: "type_1"},
  /*176 */{dataseat: 176, data_seatno: "s1-y-6-26", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席1", class: "type_1"},
  /*177 */{dataseat: 177, data_seatno: "s1-y-6-27", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席1", class: "type_1"},
  /*178 */{dataseat: 178, data_seatno: "s1-y-6-28", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席1", class: "type_1"},
  /*179 */{dataseat: 179, data_seatno: "s1-y-6-29", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席1", class: "type_1"},
  /*180 */{dataseat: 180, data_seatno: "s1-y-6-30", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席1", class: "type_1"},
  /*181 */{dataseat: 181, data_seatno: "s1-y-6-32", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席1", class: "type_1"},
  /*182 */{dataseat: 182, data_seatno: "s1-y-6-33", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席1", class: "type_1"},
  /*183 */{dataseat: 183, data_seatno: "s1-y-6-34", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席1", class: "type_1"},
  /*184 */{dataseat: 184, data_seatno: "s1-y-6-35", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席1", class: "type_1"},
  /*185 */{dataseat: 185, data_seatno: "s1-y-6-36", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席1", class: "type_1"},
  /*186 */{dataseat: 186, data_seatno: "s1-y-6-37", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席1", class: "type_1"},
  /*187 */{dataseat: 187, data_seatno: "s1-y-6-38", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席1", class: "type_1"},
  /*188 */{dataseat: 188, data_seatno: "s1-y-6-39", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席1", class: "type_1"},
  /*189 */{dataseat: 189, data_seatno: "s1-y-6-40", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席1", class: "type_1"},
  /*190 */{dataseat: 190, data_seatno: "s1-y-6-41", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席1", class: "type_1"},
  /*191 */{dataseat: 191, data_seatno: "s1-y-6-42", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席1", class: "type_1"},
  /*192 */{dataseat: 192, data_seatno: "s1-y-6-43", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席1", class: "type_1"},
  //////////// 7列目 ////////////
  /*193 */{dataseat: 193, data_seatno: "s1-y-7-6", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席1", class: "type_1"},
  /*194 */{dataseat: 194, data_seatno: "s1-y-7-7", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席1", class: "type_1"},
  /*195 */{dataseat: 195, data_seatno: "s1-y-7-8", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席1", class: "type_1"},
  /*196 */{dataseat: 196, data_seatno: "s1-y-7-9", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席1", class: "type_1"},
  /*197 */{dataseat: 197, data_seatno: "s1-y-7-10", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席1", class: "type_1"},
  /*198 */{dataseat: 198, data_seatno: "s1-y-7-11", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席1", class: "type_1"},
  /*199 */{dataseat: 199, data_seatno: "s1-y-7-12", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席1", class: "type_1"},
  /*200 */{dataseat: 200, data_seatno: "s1-y-7-13", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席1", class: "type_1"},
  /*201 */{dataseat: 201, data_seatno: "s1-y-7-14", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席1", class: "type_1"},
  /*202 */{dataseat: 202, data_seatno: "s1-y-7-15", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席1", class: "type_1"},
  /*203 */{dataseat: 203, data_seatno: "s1-y-7-16", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席1", class: "type_1"},
  /*204 */{dataseat: 204, data_seatno: "s1-y-7-17", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席1", class: "type_1"},
  /*205 */{dataseat: 205, data_seatno: "s1-y-7-19", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席1", class: "type_1"},
  /*206 */{dataseat: 206, data_seatno: "s1-y-7-20", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席1", class: "type_1"},
  /*207 */{dataseat: 207, data_seatno: "s1-y-7-21", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席1", class: "type_1"},
  /*208 */{dataseat: 208, data_seatno: "s1-y-7-22", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席1", class: "type_1"},
  /*209 */{dataseat: 209, data_seatno: "s1-y-7-23", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席1", class: "type_1"},
  /*210 */{dataseat: 210, data_seatno: "s1-y-7-24", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席1", class: "type_1"},
  /*211 */{dataseat: 211, data_seatno: "s1-y-7-25", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席1", class: "type_1"},
  /*212 */{dataseat: 212, data_seatno: "s1-y-7-26", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席1", class: "type_1"},
  /*213 */{dataseat: 213, data_seatno: "s1-y-7-27", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席1", class: "type_1"},
  /*214 */{dataseat: 214, data_seatno: "s1-y-7-28", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席1", class: "type_1"},
  /*215 */{dataseat: 215, data_seatno: "s1-y-7-29", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席1", class: "type_1"},
  /*216 */{dataseat: 216, data_seatno: "s1-y-7-30", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席1", class: "type_1"},
  /*217 */{dataseat: 217, data_seatno: "s1-y-7-32", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席1", class: "type_1"},
  /*218 */{dataseat: 218, data_seatno: "s1-y-7-33", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席1", class: "type_1"},
  /*219 */{dataseat: 219, data_seatno: "s1-y-7-34", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席1", class: "type_1"},
  /*220 */{dataseat: 220, data_seatno: "s1-y-7-35", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席1", class: "type_1"},
  /*221 */{dataseat: 221, data_seatno: "s1-y-7-36", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席1", class: "type_1"},
  /*222 */{dataseat: 222, data_seatno: "s1-y-7-37", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席1", class: "type_1"},
  /*223 */{dataseat: 223, data_seatno: "s1-y-7-38", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席1", class: "type_1"},
  /*224 */{dataseat: 224, data_seatno: "s1-y-7-39", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席1", class: "type_1"},
  /*225 */{dataseat: 225, data_seatno: "s1-y-7-40", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席1", class: "type_1"},
  /*226 */{dataseat: 226, data_seatno: "s1-y-7-41", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席1", class: "type_1"},
  /*227 */{dataseat: 227, data_seatno: "s1-y-7-42", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席1", class: "type_1"},
  /*228 */{dataseat: 228, data_seatno: "s1-y-7-43", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席1", class: "type_1"},
  //////////// 8列目 ////////////
  /*229 */{dataseat: 229, data_seatno: "s1-y-8-6" , customer_id: "103", name: "古市 夕芽", mail: "", phone: "", studio: "木津川", class: "type_2"},
  /*230 */{dataseat: 230, data_seatno: "s1-y-8-7" , customer_id: "103", name: "古市 夕芽", mail: "", phone: "", studio: "木津川", class: "type_2"},
  /*231 */{dataseat: 231, data_seatno: "s1-y-8-8" , customer_id: "969", name: "金高 菜々子", mail: "", phone: "", studio: "木津川", class: "type_2"},
  /*232 */{dataseat: 232, data_seatno: "s1-y-8-9" , customer_id: "969", name: "金高 菜々子", mail: "", phone: "", studio: "木津川", class: "type_2"},
  /*233 */{dataseat: 233, data_seatno: "s1-y-8-10", customer_id: "1064", name: "川廣 心優紗", mail: "", phone: "", studio: "木津川", class: "type_2"},
  /*234 */{dataseat: 234, data_seatno: "s1-y-8-11", customer_id: "1064", name: "川廣 心優紗", mail: "", phone: "", studio: "木津川", class: "type_2"},
  /*235 */{dataseat: 235, data_seatno: "s1-y-8-12", customer_id: "1088", name: "山口 あかり", mail: "", phone: "", studio: "木津川", class: "type_2"},
  /*236 */{dataseat: 236, data_seatno: "s1-y-8-13", customer_id: "1088", name: "山口 あかり", mail: "", phone: "", studio: "木津川", class: "type_2"},
  /*237 */{dataseat: 237, data_seatno: "s1-y-8-14", customer_id: "1202", name: "中川 佐奈", mail: "", phone: "", studio: "木津川", class: "type_2"},
  /*238 */{dataseat: 238, data_seatno: "s1-y-8-15", customer_id: "1202", name: "中川 佐奈", mail: "", phone: "", studio: "木津川", class: "type_2"},
  /*239 */{dataseat: 239, data_seatno: "s1-y-8-16", customer_id: "", name: "中川優美", mail: "yummyoct04@gmail.com", phone: "09059029596", studio: "木津川", class: "type_2"},
  /*240 */{dataseat: 240, data_seatno: "s1-y-8-17", customer_id: "", name: "中川優美", mail: "yummyoct04@gmail.com", phone: "09059029596", studio: "木津川", class: "type_2"},
  /*241 */{dataseat: 241, data_seatno: "s1-y-8-19", customer_id: "1209", name: "柴山 涼楓", mail: "", phone: "", studio: "木津川", class: "type_2"},
  /*242 */{dataseat: 242, data_seatno: "s1-y-8-20", customer_id: "1209", name: "柴山 涼楓", mail: "", phone: "", studio: "木津川", class: "type_2"},
  /*243 */{dataseat: 243, data_seatno: "s1-y-8-21", customer_id: "1225", name: "和塚 恵依", mail: "", phone: "", studio: "木津川", class: "type_2"},
  /*244 */{dataseat: 244, data_seatno: "s1-y-8-22", customer_id: "1225", name: "和塚 恵依", mail: "", phone: "", studio: "木津川", class: "type_2"},
  /*245 */{dataseat: 245, data_seatno: "s1-y-8-23", customer_id: "1279", name: "秋田 日茉莉", mail: "", phone: "", studio: "木津川", class: "type_2"},
  /*246 */{dataseat: 246, data_seatno: "s1-y-8-24", customer_id: "1279", name: "秋田 日茉莉", mail: "", phone: "", studio: "木津川", class: "type_2"},
  /*247 */{dataseat: 247, data_seatno: "s1-y-8-25", customer_id: "1305", name: "伊達 花夏", mail: "", phone: "", studio: "木津川", class: "type_2"},
  /*248 */{dataseat: 248, data_seatno: "s1-y-8-26", customer_id: "1305", name: "伊達 花夏", mail: "", phone: "", studio: "木津川", class: "type_2"},
  /*249 */{dataseat: 249, data_seatno: "s1-y-8-27", customer_id: "1305", name: "伊達 花夏", mail: "", phone: "", studio: "木津川", class: "type_2"},
  /*250 */{dataseat: 250, data_seatno: "s1-y-8-28", customer_id: "1466", name: "大塩 花", mail: "", phone: "", studio: "木津川", class: "type_2"},
  /*251 */{dataseat: 251, data_seatno: "s1-y-8-29", customer_id: "1466", name: "大塩 花", mail: "", phone: "", studio: "木津川", class: "type_2"},
  /*252 */{dataseat: 252, data_seatno: "s1-y-8-30", customer_id: "1466", name: "大塩 花", mail: "", phone: "", studio: "木津川", class: "type_2"},
  /*253 */{dataseat: 253, data_seatno: "s1-y-8-32", customer_id: "1470", name: "冨川 優衣", mail: "", phone: "", studio: "木津川", class: "type_2"},
  /*254 */{dataseat: 254, data_seatno: "s1-y-8-33", customer_id: "1470", name: "冨川 優衣", mail: "", phone: "", studio: "木津川", class: "type_2"},
  /*255 */{dataseat: 255, data_seatno: "s1-y-8-34", customer_id: "1470", name: "冨川 優衣", mail: "", phone: "", studio: "木津川", class: "type_2"},
  /*256 */{dataseat: 256, data_seatno: "s1-y-8-35", customer_id: "1470", name: "冨川 優衣", mail: "", phone: "", studio: "木津川", class: "type_2"},
  /*257 */{dataseat: 257, data_seatno: "s1-y-8-36", customer_id: "1470", name: "冨川 優衣", mail: "", phone: "", studio: "木津川", class: "type_2"},
  /*258 */{dataseat: 258, data_seatno: "s1-y-8-37", customer_id: "1470", name: "冨川 優衣", mail: "", phone: "", studio: "木津川", class: "type_2"},
  /*259 */{dataseat: 259, data_seatno: "s1-y-8-38", customer_id: "1471", name: "細谷 紗那", mail: "", phone: "", studio: "木津川", class: "type_2"},
  /*260 */{dataseat: 260, data_seatno: "s1-y-8-39", customer_id: "1471", name: "細谷 紗那", mail: "", phone: "", studio: "木津川", class: "type_2"},
  /*261 */{dataseat: 261, data_seatno: "s1-y-8-40", customer_id: "1471", name: "細谷 紗那", mail: "", phone: "", studio: "木津川", class: "type_2"},
  /*262 */{dataseat: 262, data_seatno: "s1-y-8-41", customer_id: "1471", name: "細谷 紗那", mail: "", phone: "", studio: "木津川", class: "type_2"},
  /*263 */{dataseat: 263, data_seatno: "s1-y-8-42", customer_id: "1700", name: "和塚 紗希", mail: "", phone: "", studio: "木津川", class: "type_2"},
  /*264 */{dataseat: 264, data_seatno: "s1-y-8-43", customer_id: "1700", name: "和塚 紗希", mail: "", phone: "", studio: "木津川", class: "type_2"},
  //////////// 9列目 ////////////
  /*265 */{dataseat: 265, data_seatno: "s1-y-9-6", customer_id: "1475", name: "山田 瑛愛", mail: "", phone: "", studio: "木津川", class: "type_2"},
  /*266 */{dataseat: 266, data_seatno: "s1-y-9-7", customer_id: "1475", name: "山田 瑛愛", mail: "", phone: "", studio: "木津川", class: "type_2"},
  /*267 */{dataseat: 267, data_seatno: "s1-y-9-8", customer_id: "1475", name: "山田 瑛愛", mail: "", phone: "", studio: "木津川", class: "type_2"},
  /*268 */{dataseat: 268, data_seatno: "s1-y-9-9", customer_id: "1475", name: "山田 瑛愛", mail: "", phone: "", studio: "木津川", class: "type_2"},
  /*269 */{dataseat: 269, data_seatno: "s1-y-9-10", customer_id: "1475", name: "山田 瑛愛", mail: "", phone: "", studio: "木津川", class: "type_2"},
  /*270 */{dataseat: 270, data_seatno: "s1-y-9-11", customer_id: "1475", name: "山田 瑛愛", mail: "", phone: "", studio: "木津川", class: "type_2"},
  /*271 */{dataseat: 271, data_seatno: "s1-y-9-12", customer_id: "1606", name: "北風 伽羅", mail: "", phone: "", studio: "木津川", class: "type_2"},
  /*272 */{dataseat: 272, data_seatno: "s1-y-9-13", customer_id: "1606", name: "北風 伽羅", mail: "", phone: "", studio: "木津川", class: "type_2"},
  /*273 */{dataseat: 273, data_seatno: "s1-y-9-14", customer_id: "1606", name: "北風 伽羅", mail: "", phone: "", studio: "木津川", class: "type_2"},
  /*274 */{dataseat: 274, data_seatno: "s1-y-9-15", customer_id: "1612", name: "奥田 凛心", mail: "", phone: "", studio: "木津川", class: "type_2"},
  /*275 */{dataseat: 275, data_seatno: "s1-y-9-16", customer_id: "1612", name: "奥田 凛心", mail: "", phone: "", studio: "木津川", class: "type_2"},
  /*276 */{dataseat: 276, data_seatno: "s1-y-9-17", customer_id: "1612", name: "奥田 凛心", mail: "", phone: "", studio: "木津川", class: "type_2"},
  /*277 */{dataseat: 277, data_seatno: "s1-y-9-19", customer_id: "1767", name: "有山 愛桜", mail: "", phone: "", studio: "木津川", class: "type_2"},
  /*278 */{dataseat: 278, data_seatno: "s1-y-9-20", customer_id: "1767", name: "有山 愛桜", mail: "", phone: "", studio: "木津川", class: "type_2"},
  /*279 */{dataseat: 279, data_seatno: "s1-y-9-21", customer_id: "1767", name: "有山 愛桜", mail: "", phone: "", studio: "木津川", class: "type_2"},
  /*280 */{dataseat: 280, data_seatno: "s1-y-9-22", customer_id: "1767", name: "有山 愛桜", mail: "", phone: "", studio: "木津川", class: "type_2"},
  /*281 */{dataseat: 281, data_seatno: "s1-y-9-23", customer_id: "1926", name: "中平 愛瑠", mail: "", phone: "", studio: "木津川", class: "type_2"},
  /*282 */{dataseat: 282, data_seatno: "s1-y-9-24", customer_id: "1926", name: "中平 愛瑠", mail: "", phone: "", studio: "木津川", class: "type_2"},
  /*283 */{dataseat: 283, data_seatno: "s1-y-9-25", customer_id: "1939", name: "太田 恵実", mail: "", phone: "", studio: "木津川", class: "type_2"},
  /*284 */{dataseat: 284, data_seatno: "s1-y-9-26", customer_id: "1939", name: "太田 恵実", mail: "", phone: "", studio: "木津川", class: "type_2"},
  /*285 */{dataseat: 285, data_seatno: "s1-y-9-27", customer_id: "1982", name: "石川 瑞歩", mail: "", phone: "", studio: "木津川", class: "type_2"},
  /*286 */{dataseat: 286, data_seatno: "s1-y-9-28", customer_id: "1982", name: "石川 瑞歩", mail: "", phone: "", studio: "木津川", class: "type_2"},
  /*287 */{dataseat: 287, data_seatno: "s1-y-9-29", customer_id: "639", name: "福田 花", mail: "", phone: "", studio: "木津川", class: "type_2"},
  /*288 */{dataseat: 288, data_seatno: "s1-y-9-30", customer_id: "639", name: "福田 花", mail: "", phone: "", studio: "木津川", class: "type_2"},
  /*289 */{dataseat: 289, data_seatno: "s1-y-9-32", customer_id: "94", name: "吉岡 梨乃", mail: "", phone: "", studio: "木津川", class: "type_2"},
  /*290 */{dataseat: 290, data_seatno: "s1-y-9-33", customer_id: "94", name: "吉岡 梨乃", mail: "", phone: "", studio: "木津川", class: "type_2"},
  /*291 */{dataseat: 291, data_seatno: "s1-y-9-34", customer_id: "537", name: "坂田 千奈", mail: "", phone: "", studio: "木津川", class: "type_2"},
  /*292 */{dataseat: 292, data_seatno: "s1-y-9-35", customer_id: "537", name: "坂田 千奈", mail: "", phone: "", studio: "木津川", class: "type_2"},
  /*293 */{dataseat: 293, data_seatno: "s1-y-9-36", customer_id: "542", name: "深坂 恋菜", mail: "", phone: "", studio: "木津川", class: "type_2"},
  /*294 */{dataseat: 294, data_seatno: "s1-y-9-37", customer_id: "542", name: "深坂 恋菜", mail: "", phone: "", studio: "木津川", class: "type_2"},
  /*295 */{dataseat: 295, data_seatno: "s1-y-9-38", customer_id: "553", name: "野田 愛加", mail: "", phone: "", studio: "木津川", class: "type_2"},
  /*296 */{dataseat: 296, data_seatno: "s1-y-9-39", customer_id: "553", name: "野田 愛加", mail: "", phone: "", studio: "木津川", class: "type_2"},
  /*297 */{dataseat: 297, data_seatno: "s1-y-9-40", customer_id: "561", name: "永石 碧椛", mail: "", phone: "", studio: "木津川", class: "type_2"},
  /*298 */{dataseat: 298, data_seatno: "s1-y-9-41", customer_id: "561", name: "永石 碧椛", mail: "", phone: "", studio: "木津川", class: "type_2"},
  /*299 */{dataseat: 299, data_seatno: "s1-y-9-42", customer_id: "589", name: "橋脇 莉依菜", mail: "", phone: "", studio: "木津川", class: "type_2"},
  /*300 */{dataseat: 300, data_seatno: "s1-y-9-43", customer_id: "589", name: "橋脇 莉依菜", mail: "", phone: "", studio: "木津川", class: "type_2"},
  //////////// 10列目 ////////////
  /*301 */{dataseat: 301, data_seatno: "s1-y-10-6", customer_id: "626", name: "松尾 唯花", mail: "", phone: "", studio: "木津川", class: "type_2"},
  /*302 */{dataseat: 302, data_seatno: "s1-y-10-7", customer_id: "626", name: "松尾 唯花", mail: "", phone: "", studio: "木津川", class: "type_2"},
  /*303 */{dataseat: 303, data_seatno: "s1-y-10-8", customer_id: "626", name: "松尾 唯花", mail: "", phone: "", studio: "木津川", class: "type_2"},
  /*304 */{dataseat: 304, data_seatno: "s1-y-10-9", customer_id: "626", name: "松尾 唯花", mail: "", phone: "", studio: "木津川", class: "type_2"},
  /*305 */{dataseat: 305, data_seatno: "s1-y-10-10", customer_id: "936", name: "今中 虹心", mail: "", phone: "", studio: "木津川", class: "type_2"},
  /*306 */{dataseat: 306, data_seatno: "s1-y-10-11", customer_id: "936", name: "今中 虹心", mail: "", phone: "", studio: "木津川", class: "type_2"},
  /*307 */{dataseat: 307, data_seatno: "s1-y-10-12", customer_id: "943", name: "石橋 結愛", mail: "", phone: "", studio: "木津川", class: "type_2"},
  /*308 */{dataseat: 308, data_seatno: "s1-y-10-13", customer_id: "943", name: "石橋 結愛", mail: "", phone: "", studio: "木津川", class: "type_2"},
  /*309 */{dataseat: 309, data_seatno: "s1-y-10-14", customer_id: "", name: "石橋浩美(結愛)", mail: "tacky-62.hirolin@docomo.ne.jp", phone: "09077606889", studio: "木津川", class: "type_2"},
  /*310 */{dataseat: 310, data_seatno: "s1-y-10-15", customer_id: "", name: "石橋浩美(結愛)", mail: "tacky-62.hirolin@docomo.ne.jp", phone: "09077606889", studio: "木津川", class: "type_2"},
  /*311 */{dataseat: 311, data_seatno: "s1-y-10-16", customer_id: "1425", name: "井畑 里望", mail: "", phone: "", studio: "木津川", class: "type_2"},
  /*312 */{dataseat: 312, data_seatno: "s1-y-10-17", customer_id: "1425", name: "井畑 里望", mail: "", phone: "", studio: "木津川", class: "type_2"},
  /*313 */{dataseat: 313, data_seatno: "s1-y-10-19", customer_id: "1434", name: "北尾 空", mail: "", phone: "", studio: "木津川", class: "type_2"},
  /*314 */{dataseat: 314, data_seatno: "s1-y-10-20", customer_id: "1434", name: "北尾 空", mail: "", phone: "", studio: "木津川", class: "type_2"},
  /*315 */{dataseat: 315, data_seatno: "s1-y-10-21", customer_id: "1436", name: "磯崎 美緒愛", mail: "", phone: "", studio: "木津川", class: "type_2"},
  /*316 */{dataseat: 316, data_seatno: "s1-y-10-22", customer_id: "1436", name: "磯崎 美緒愛", mail: "", phone: "", studio: "木津川", class: "type_2"},
  /*317 */{dataseat: 317, data_seatno: "s1-y-10-23", customer_id: "1679", name: "梅本 千優", mail: "", phone: "", studio: "木津川", class: "type_2"},
  /*318 */{dataseat: 318, data_seatno: "s1-y-10-24", customer_id: "1679", name: "梅本 千優", mail: "", phone: "", studio: "木津川", class: "type_2"},
  /*319 */{dataseat: 319, data_seatno: "s1-y-10-25", customer_id: "568", name: "島永 寧音", mail: "", phone: "", studio: "木津川", class: "type_2"},
  /*320 */{dataseat: 320, data_seatno: "s1-y-10-26", customer_id: "568", name: "島永 寧音", mail: "", phone: "", studio: "木津川", class: "type_2"},
  /*321 */{dataseat: 321, data_seatno: "s1-y-10-27", customer_id: "568", name: "島永 寧音", mail: "", phone: "", studio: "木津川", class: "type_2"},
  /*322 */{dataseat: 322, data_seatno: "s1-y-10-28", customer_id: "1134", name: "中尾 友菜", mail: "", phone: "", studio: "新田辺", class: "type_3"},
  /*323 */{dataseat: 323, data_seatno: "s1-y-10-29", customer_id: "1134", name: "中尾 友菜", mail: "", phone: "", studio: "新田辺", class: "type_3"},
  /*324 */{dataseat: 324, data_seatno: "s1-y-10-30", customer_id: "1134", name: "中尾 友菜", mail: "", phone: "", studio: "新田辺", class: "type_3"},
  /*325 */{dataseat: 325, data_seatno: "s1-y-10-32", customer_id: "910", name: "里井 優芽", mail: "", phone: "", studio: "新田辺", class: "type_3"},
  /*326 */{dataseat: 326, data_seatno: "s1-y-10-33", customer_id: "910", name: "里井 優芽", mail: "", phone: "", studio: "新田辺", class: "type_3"},
  /*327 */{dataseat: 327, data_seatno: "s1-y-10-34", customer_id: "1059", name: "西島 由莉", mail: "", phone: "", studio: "新田辺", class: "type_3"},
  /*328 */{dataseat: 328, data_seatno: "s1-y-10-35", customer_id: "1059", name: "西島 由莉", mail: "", phone: "", studio: "新田辺", class: "type_3"},
  /*329 */{dataseat: 329, data_seatno: "s1-y-10-36", customer_id: "1122", name: "山野 真央", mail: "", phone: "", studio: "新田辺", class: "type_3"},
  /*330 */{dataseat: 330, data_seatno: "s1-y-10-37", customer_id: "1122", name: "山野 真央", mail: "", phone: "", studio: "新田辺", class: "type_3"},
  /*331 */{dataseat: 331, data_seatno: "s1-y-10-38", customer_id: "1139", name: "廣畑 愛琉", mail: "", phone: "", studio: "新田辺", class: "type_3"},
  /*332 */{dataseat: 332, data_seatno: "s1-y-10-39", customer_id: "1139", name: "廣畑 愛琉", mail: "", phone: "", studio: "新田辺", class: "type_3"},
  /*333 */{dataseat: 333, data_seatno: "s1-y-10-40", customer_id: "", name: "", mail: "", phone: "", studio: "", class: "type_99"},
  /*334 */{dataseat: 334, data_seatno: "s1-y-10-41", customer_id: "", name: "", mail: "", phone: "", studio: "", class: "type_99"},
  /*335 */{dataseat: 335, data_seatno: "s1-y-10-42", customer_id: "1881", name: "中野 紗良", mail: "", phone: "", studio: "新田辺", class: "type_3"},
  /*336 */{dataseat: 336, data_seatno: "s1-y-10-43", customer_id: "1881", name: "中野 紗良", mail: "", phone: "", studio: "新田辺", class: "type_3"},
  //////////// 11列目 ////////////
  /*337 */{dataseat: 337, data_seatno: "s1-y-11-6", customer_id: "794", name: "前田 結心音", mail: "", phone: "", studio: "新田辺", class: "type_3"},
  /*338 */{dataseat: 338, data_seatno: "s1-y-11-7", customer_id: "794", name: "前田 結心音", mail: "", phone: "", studio: "新田辺", class: "type_3"},
  /*339 */{dataseat: 339, data_seatno: "s1-y-11-8", customer_id: "1303", name: "中西 咲華", mail: "", phone: "", studio: "新田辺", class: "type_3"},
  /*340 */{dataseat: 340, data_seatno: "s1-y-11-9", customer_id: "1303", name: "中西 咲華", mail: "", phone: "", studio: "新田辺", class: "type_3"},
  /*341 */{dataseat: 341, data_seatno: "s1-y-11-10", customer_id: "1312", name: "青木 春来", mail: "", phone: "", studio: "新田辺", class: "type_3"},
  /*342 */{dataseat: 342, data_seatno: "s1-y-11-11", customer_id: "1312", name: "青木 春来", mail: "", phone: "", studio: "新田辺", class: "type_3"},
  /*343 */{dataseat: 343, data_seatno: "s1-y-11-12", customer_id: "759", name: "仲村 彩花", mail: "", phone: "", studio: "新田辺", class: "type_3"},
  /*344 */{dataseat: 344, data_seatno: "s1-y-11-13", customer_id: "759", name: "仲村 彩花", mail: "", phone: "", studio: "新田辺", class: "type_3"},
  /*345 */{dataseat: 345, data_seatno: "s1-y-11-14", customer_id: "759", name: "仲村 彩花", mail: "", phone: "", studio: "新田辺", class: "type_3"},
  /*346 */{dataseat: 346, data_seatno: "s1-y-11-15", customer_id: "759", name: "仲村 彩花", mail: "", phone: "", studio: "新田辺", class: "type_3"},
  /*347 */{dataseat: 347, data_seatno: "s1-y-11-16", customer_id: "784", name: "塚本 愛琉", mail: "", phone: "", studio: "新田辺", class: "type_3"},
  /*348 */{dataseat: 348, data_seatno: "s1-y-11-17", customer_id: "784", name: "塚本 愛琉", mail: "", phone: "", studio: "新田辺", class: "type_3"},
  /*349 */{dataseat: 349, data_seatno: "s1-y-11-19", customer_id: "790", name: "若木 花奈", mail: "", phone: "", studio: "新田辺", class: "type_3"},
  /*350 */{dataseat: 350, data_seatno: "s1-y-11-20", customer_id: "790", name: "若木 花奈", mail: "", phone: "", studio: "新田辺", class: "type_3"},
  /*351 */{dataseat: 351, data_seatno: "s1-y-11-21", customer_id: "795", name: "福田 佳央里", mail: "", phone: "", studio: "新田辺", class: "type_3"},
  /*352 */{dataseat: 352, data_seatno: "s1-y-11-22", customer_id: "795", name: "福田 佳央里", mail: "", phone: "", studio: "新田辺", class: "type_3"},
  /*353 */{dataseat: 353, data_seatno: "s1-y-11-23", customer_id: "1219", name: "柳 楽々", mail: "", phone: "", studio: "新田辺", class: "type_3"},
  /*354 */{dataseat: 354, data_seatno: "s1-y-11-24", customer_id: "1219", name: "柳 楽々", mail: "", phone: "", studio: "新田辺", class: "type_3"},
  /*355 */{dataseat: 355, data_seatno: "s1-y-11-25", customer_id: "1343", name: "松川 望依", mail: "", phone: "", studio: "新田辺", class: "type_3"},
  /*356 */{dataseat: 356, data_seatno: "s1-y-11-26", customer_id: "1343", name: "松川 望依", mail: "", phone: "", studio: "新田辺", class: "type_3"},
  /*357 */{dataseat: 357, data_seatno: "s1-y-11-27", customer_id: "1935", name: "辰己 颯梨", mail: "", phone: "", studio: "新田辺", class: "type_3"},
  /*358 */{dataseat: 358, data_seatno: "s1-y-11-28", customer_id: "1935", name: "辰己 颯梨", mail: "", phone: "", studio: "新田辺", class: "type_3"},
  /*359 */{dataseat: 359, data_seatno: "s1-y-11-29", customer_id: "1973", name: "村上 日那", mail: "", phone: "", studio: "新田辺", class: "type_3"},
  /*360 */{dataseat: 360, data_seatno: "s1-y-11-30", customer_id: "1973", name: "村上 日那", mail: "", phone: "", studio: "新田辺", class: "type_3"},
  /*361 */{dataseat: 361, data_seatno: "s1-y-11-32", customer_id: "779", name: "松本 希月", mail: "", phone: "", studio: "新田辺", class: "type_3"},
  /*362 */{dataseat: 362, data_seatno: "s1-y-11-33", customer_id: "779", name: "松本 希月", mail: "", phone: "", studio: "新田辺", class: "type_3"},
  /*363 */{dataseat: 363, data_seatno: "s1-y-11-34", customer_id: "971", name: "藤井 杏", mail: "", phone: "", studio: "新田辺", class: "type_3"},
  /*364 */{dataseat: 364, data_seatno: "s1-y-11-35", customer_id: "971", name: "藤井 杏", mail: "", phone: "", studio: "新田辺", class: "type_3"},
  /*365 */{dataseat: 365, data_seatno: "s1-y-11-36", customer_id: "1075", name: "安福 碧海", mail: "", phone: "", studio: "新田辺", class: "type_3"},
  /*366 */{dataseat: 366, data_seatno: "s1-y-11-37", customer_id: "1075", name: "安福 碧海", mail: "", phone: "", studio: "新田辺", class: "type_3"},
  /*367 */{dataseat: 367, data_seatno: "s1-y-11-38", customer_id: "1285", name: "浅居 七海", mail: "", phone: "", studio: "新田辺", class: "type_3"},
  /*368 */{dataseat: 368, data_seatno: "s1-y-11-39", customer_id: "1285", name: "浅居 七海", mail: "", phone: "", studio: "新田辺", class: "type_3"},
  /*369 */{dataseat: 369, data_seatno: "s1-y-11-40", customer_id: "1285", name: "浅居 七海", mail: "", phone: "", studio: "新田辺", class: "type_3"},
  /*370 */{dataseat: 370, data_seatno: "s1-y-11-41", customer_id: "1285", name: "浅居 七海", mail: "", phone: "", studio: "新田辺", class: "type_3"},
  /*371 */{dataseat: 371, data_seatno: "s1-y-11-42", customer_id: "1285", name: "浅居 七海", mail: "", phone: "", studio: "新田辺", class: "type_3"},
  /*372 */{dataseat: 372, data_seatno: "s1-y-11-43", customer_id: "1285", name: "浅居 七海", mail: "", phone: "", studio: "新田辺", class: "type_3"},
  //////////// 12列目 ////////////
  /*373 */{dataseat: 373, data_seatno: "s1-y-12-8", customer_id: "1290", name: "山田 愛笑", mail: "", phone: "", studio: "新田辺", class: "type_3"},
  /*374 */{dataseat: 374, data_seatno: "s1-y-12-9", customer_id: "1290", name: "山田 愛笑", mail: "", phone: "", studio: "新田辺", class: "type_3"},
  /*375 */{dataseat: 375, data_seatno: "s1-y-12-10", customer_id: "1613", name: "安田 陽茉莉", mail: "", phone: "", studio: "新田辺", class: "type_3"},
  /*376 */{dataseat: 376, data_seatno: "s1-y-12-11", customer_id: "1613", name: "安田 陽茉莉", mail: "", phone: "", studio: "新田辺", class: "type_3"},
  /*377 */{dataseat: 377, data_seatno: "s1-y-12-12", customer_id: "842", name: "神谷 花", mail: "", phone: "", studio: "新田辺", class: "type_3"},
  /*378 */{dataseat: 378, data_seatno: "s1-y-12-13", customer_id: "842", name: "神谷 花", mail: "", phone: "", studio: "新田辺", class: "type_3"},
  /*379 */{dataseat: 379, data_seatno: "s1-y-12-14", customer_id: "1642", name: "前田 乙葉", mail: "", phone: "", studio: "新田辺", class: "type_3"},
  /*380 */{dataseat: 380, data_seatno: "s1-y-12-15", customer_id: "1642", name: "前田 乙葉", mail: "", phone: "", studio: "新田辺", class: "type_3"},
  /*381 */{dataseat: 381, data_seatno: "s1-y-12-16", customer_id: "1837", name: "月成 望結", mail: "", phone: "", studio: "新田辺", class: "type_3"},
  /*382 */{dataseat: 382, data_seatno: "s1-y-12-17", customer_id: "1837", name: "月成 望結", mail: "", phone: "", studio: "新田辺", class: "type_3"},
  /*383 */{dataseat: 383, data_seatno: "s1-y-12-19", customer_id: "1850", name: "上田 優花", mail: "", phone: "", studio: "新田辺", class: "type_3"},
  /*384 */{dataseat: 384, data_seatno: "s1-y-12-20", customer_id: "1850", name: "上田 優花", mail: "", phone: "", studio: "新田辺", class: "type_3"},
  /*385 */{dataseat: 385, data_seatno: "s1-y-12-21", customer_id: "1851", name: "森田 幸花", mail: "", phone: "", studio: "新田辺", class: "type_3"},
  /*386 */{dataseat: 386, data_seatno: "s1-y-12-22", customer_id: "1851", name: "森田 幸花", mail: "", phone: "", studio: "新田辺", class: "type_3"},
  /*387 */{dataseat: 387, data_seatno: "s1-y-12-23", customer_id: "1084", name: "田中 杏里紗", mail: "", phone: "", studio: "新田辺", class: "type_3"},
  /*388 */{dataseat: 388, data_seatno: "s1-y-12-24", customer_id: "1084", name: "田中 杏里紗", mail: "", phone: "", studio: "新田辺", class: "type_3"},
  /*389 */{dataseat: 389, data_seatno: "s1-y-12-25", customer_id: "1854", name: "土肥 美月", mail: "", phone: "", studio: "新田辺", class: "type_3"},
  /*390 */{dataseat: 390, data_seatno: "s1-y-12-26", customer_id: "1854", name: "土肥 美月", mail: "", phone: "", studio: "新田辺", class: "type_3"},
  /*391 */{dataseat: 391, data_seatno: "s1-y-12-27", customer_id: "", name: "土肥真生子", mail: "maikyo.1011.xxxxxx@docomo.ne.jp", phone: "09095429788", studio: "新田辺", class: "type_3"},
  /*392 */{dataseat: 392, data_seatno: "s1-y-12-28", customer_id: "1168", name: "小山 楓乃音", mail: "", phone: "", studio: "新田辺", class: "type_3"},
  /*393 */{dataseat: 393, data_seatno: "s1-y-12-29", customer_id: "1168", name: "小山 楓乃音", mail: "", phone: "", studio: "新田辺", class: "type_3"},
  /*394 */{dataseat: 394, data_seatno: "s1-y-12-30", customer_id: "", name: "小山晃", mail: "k.hikarun@yahoo.ne.jp", phone: "09068206678", studio: "新田辺", class: "type_3"},
  /*395 */{dataseat: 395, data_seatno: "s1-y-12-32", customer_id: "1857", name: "谷 帆琉", mail: "", phone: "", studio: "新田辺", class: "type_3"},
  /*396 */{dataseat: 396, data_seatno: "s1-y-12-33", customer_id: "1857", name: "谷 帆琉", mail: "", phone: "", studio: "新田辺", class: "type_3"},
  /*397 */{dataseat: 397, data_seatno: "s1-y-12-34", customer_id: "1380", name: "谷口 澪", mail: "", phone: "", studio: "新田辺", class: "type_3"},
  /*398 */{dataseat: 398, data_seatno: "s1-y-12-35", customer_id: "1380", name: "谷口 澪", mail: "", phone: "", studio: "新田辺", class: "type_3"},
  /*399 */{dataseat: 399, data_seatno: "s1-y-12-36", customer_id: "741", name: "塩貝 美織", mail: "", phone: "", studio: "新田辺", class: "type_3"},
  /*400 */{dataseat: 400, data_seatno: "s1-y-12-37", customer_id: "741", name: "塩貝 美織", mail: "", phone: "", studio: "新田辺", class: "type_3"},
  /*401 */{dataseat: 401, data_seatno: "s1-y-12-38", customer_id: "761", name: "古野 奈乃芭", mail: "", phone: "", studio: "新田辺", class: "type_3"},
  /*402 */{dataseat: 402, data_seatno: "s1-y-12-39", customer_id: "761", name: "古野 奈乃芭", mail: "", phone: "", studio: "新田辺", class: "type_3"},
  /*403 */{dataseat: 403, data_seatno: "s1-y-12-40", customer_id: "802", name: "西山 茉向", mail: "", phone: "", studio: "新田辺", class: "type_3"},
  /*404 */{dataseat: 404, data_seatno: "s1-y-12-41", customer_id: "802", name: "西山 茉向", mail: "", phone: "", studio: "新田辺", class: "type_3"},
  //////////// 13列目左 ////////////
  /*405 */{dataseat: 405, data_seatno: "s1-y-13-1", customer_id: "845", name: "山下 那采", mail: "", phone: "", studio: "丹波橋", class: "type_6"},
  /*406 */{dataseat: 406, data_seatno: "s1-y-13-2", customer_id: "845", name: "山下 那采", mail: "", phone: "", studio: "丹波橋", class: "type_6"},
  /*407 */{dataseat: 407, data_seatno: "s1-y-13-3", customer_id: "", name: "山下由美子", mail: "samanaaya.0224@mineo.jp", phone: "09038427474", studio: "丹波橋", class: "type_6"},
  /*408 */{dataseat: 408, data_seatno: "s1-y-13-4", customer_id: "", name: "山下由美子", mail: "samanaaya.0224@mineo.jp", phone: "09038427474", studio: "丹波橋", class: "type_6"},
  //////////// 13列目右 ////////////
  /*409 */{dataseat: 409, data_seatno: "s1-y-13-45", customer_id: "1862", name: "佐原 朱亜莉", mail: "", phone: "", studio: "宇治", class: "type_5"},
  /*410 */{dataseat: 410, data_seatno: "s1-y-13-46", customer_id: "1862", name: "佐原 朱亜莉", mail: "", phone: "", studio: "宇治", class: "type_5"},
  /*411 */{dataseat: 411, data_seatno: "s1-y-13-47", customer_id: "1863", name: "藤田 りこ", mail: "", phone: "", studio: "宇治", class: "type_5"},
  /*412 */{dataseat: 412, data_seatno: "s1-y-13-48", customer_id: "1863", name: "藤田 りこ", mail: "", phone: "", studio: "宇治", class: "type_5"},
  //////////// 13列目中央 ////////////
  /*413 */{dataseat: 413, data_seatno: "s1-y-13-8", customer_id: "1706", name: "岩下 楓", mail: "", phone: "", studio: "新田辺", class: "type_3"},
  /*414 */{dataseat: 414, data_seatno: "s1-y-13-9", customer_id: "1706", name: "岩下 楓", mail: "", phone: "", studio: "新田辺", class: "type_3"},
  /*415 */{dataseat: 415, data_seatno: "s1-y-13-10", customer_id: "1787", name: "中村 沙耶", mail: "", phone: "", studio: "新田辺", class: "type_3"},
  /*416 */{dataseat: 416, data_seatno: "s1-y-13-11", customer_id: "1787", name: "中村 沙耶", mail: "", phone: "", studio: "新田辺", class: "type_3"},
  /*417 */{dataseat: 417, data_seatno: "s1-y-13-12", customer_id: "", name: "中村 沙耶", mail: "akari47710512@gmail.com", phone: "09097175085", studio: "新田辺", class: "type_3"},
  /*418 */{dataseat: 418, data_seatno: "s1-y-13-13", customer_id: "1911", name: "西島 朱那", mail: "", phone: "", studio: "新田辺", class: "type_3"},
  /*419 */{dataseat: 419, data_seatno: "s1-y-13-14", customer_id: "1911", name: "西島 朱那", mail: "", phone: "", studio: "新田辺", class: "type_3"},
  /*420 */{dataseat: 420, data_seatno: "s1-y-13-15", customer_id: "1911", name: "西島 朱那", mail: "", phone: "", studio: "新田辺", class: "type_3"},
  /*421 */{dataseat: 421, data_seatno: "s1-y-13-16", customer_id: "1911", name: "西島 朱那", mail: "", phone: "", studio: "新田辺", class: "type_3"},
  /*422 */{dataseat: 422, data_seatno: "s1-y-13-17", customer_id: "1911", name: "西島 朱那", mail: "", phone: "", studio: "新田辺", class: "type_3"},
  /*423 */{dataseat: 423, data_seatno: "s1-y-13-19", customer_id: "717", name: "小島 咲", mail: "", phone: "", studio: "新田辺", class: "type_3"},
  /*424 */{dataseat: 424, data_seatno: "s1-y-13-20", customer_id: "717", name: "小島 咲", mail: "", phone: "", studio: "新田辺", class: "type_3"},
  /*425 */{dataseat: 425, data_seatno: "s1-y-13-21", customer_id: "1467", name: "山口 凛子", mail: "", phone: "", studio: "新田辺", class: "type_3"},
  /*426 */{dataseat: 426, data_seatno: "s1-y-13-22", customer_id: "1467", name: "山口 凛子", mail: "", phone: "", studio: "新田辺", class: "type_3"},
  /*427 */{dataseat: 427, data_seatno: "s1-y-13-23", customer_id: "1467", name: "山口 凛子", mail: "", phone: "", studio: "新田辺", class: "type_3"},
  /*428 */{dataseat: 428, data_seatno: "s1-y-13-24", customer_id: "1414", name: "吉川 茉実", mail: "", phone: "", studio: "烏丸", class: "type_4"},
  /*429 */{dataseat: 429, data_seatno: "s1-y-13-25", customer_id: "1414", name: "吉川 茉実", mail: "", phone: "", studio: "烏丸", class: "type_4"},
  /*430 */{dataseat: 430, data_seatno: "s1-y-13-26", customer_id: "1414", name: "吉川 茉実", mail: "", phone: "", studio: "烏丸", class: "type_4"},
  /*431 */{dataseat: 431, data_seatno: "s1-y-13-27", customer_id: "1414", name: "吉川 茉実", mail: "", phone: "", studio: "烏丸", class: "type_4"},
  /*432 */{dataseat: 432, data_seatno: "s1-y-13-28", customer_id: "1414", name: "吉川 茉実", mail: "", phone: "", studio: "烏丸", class: "type_4"},
  /*433 */{dataseat: 433, data_seatno: "s1-y-13-29", customer_id: "1068", name: "辻 梨音", mail: "", phone: "", studio: "烏丸", class: "type_4"},
  /*434 */{dataseat: 434, data_seatno: "s1-y-13-30", customer_id: "1068", name: "辻 梨音", mail: "", phone: "", studio: "烏丸", class: "type_4"},
  /*435 */{dataseat: 435, data_seatno: "s1-y-13-32", customer_id: "1934", name: "吉山 つばさ", mail: "", phone: "", studio: "烏丸", class: "type_4"},
  /*436 */{dataseat: 436, data_seatno: "s1-y-13-33", customer_id: "1934", name: "吉山 つばさ", mail: "", phone: "", studio: "烏丸", class: "type_4"},
  /*437 */{dataseat: 437, data_seatno: "s1-y-13-34", customer_id: "1934", name: "吉山 つばさ", mail: "", phone: "", studio: "烏丸", class: "type_4"},
  /*438 */{dataseat: 438, data_seatno: "s1-y-13-35", customer_id: "1838", name: "牧 素花", mail: "", phone: "", studio: "烏丸", class: "type_4"},
  /*439 */{dataseat: 439, data_seatno: "s1-y-13-36", customer_id: "1838", name: "牧 素花", mail: "", phone: "", studio: "烏丸", class: "type_4"},
  /*440 */{dataseat: 440, data_seatno: "s1-y-13-37", customer_id: "", name: "牧典子", mail: "makinon612@gmail.com", phone: "09052579116", studio: "烏丸", class: "type_4"},
  /*441 */{dataseat: 441, data_seatno: "s1-y-13-38", customer_id: "1338", name: "佐藤 姫菜", mail: "", phone: "", studio: "烏丸", class: "type_4"},
  /*442 */{dataseat: 442, data_seatno: "s1-y-13-39", customer_id: "1338", name: "佐藤 姫菜", mail: "", phone: "", studio: "烏丸", class: "type_4"},
  /*443 */{dataseat: 443, data_seatno: "s1-y-13-40", customer_id: "1338", name: "佐藤 姫菜", mail: "", phone: "", studio: "烏丸", class: "type_4"},
  /*444 */{dataseat: 444, data_seatno: "s1-y-13-41", customer_id: "1338", name: "佐藤 姫菜", mail: "", phone: "", studio: "烏丸", class: "type_4"},
  //////////// 14列目右 ////////////
  /*445 */{dataseat: 445, data_seatno: "s1-y-14-45", customer_id: "1988", name: "大西 凛", mail: "", phone: "", studio: "宇治", class: "type_5"},
  /*446 */{dataseat: 446, data_seatno: "s1-y-14-46", customer_id: "1988", name: "大西 凛", mail: "", phone: "", studio: "宇治", class: "type_5"},
  /*447 */{dataseat: 447, data_seatno: "s1-y-14-47", customer_id: "1994", name: "池田 里明", mail: "", phone: "", studio: "宇治", class: "type_5"},
  /*448 */{dataseat: 448, data_seatno: "s1-y-14-48", customer_id: "1994", name: "池田 里明", mail: "", phone: "", studio: "宇治", class: "type_5"},
  //////////// 14列目左 ////////////
  /*449 */{dataseat: 449, data_seatno: "s1-y-14-1", customer_id: "987", name: "二宮 ゆず", mail: "", phone: "", studio: "丹波橋", class: "type_6"},
  /*450 */{dataseat: 450, data_seatno: "s1-y-14-2", customer_id: "987", name: "二宮 ゆず", mail: "", phone: "", studio: "丹波橋", class: "type_6"},
  /*451 */{dataseat: 451, data_seatno: "s1-y-14-3", customer_id: "1349", name: "東 葵", mail: "", phone: "", studio: "丹波橋", class: "type_6"},
  /*452 */{dataseat: 452, data_seatno: "s1-y-14-4", customer_id: "1349", name: "東 葵", mail: "", phone: "", studio: "丹波橋", class: "type_6"},
  //////////// 14列目中央 ////////////
  /*453 */{dataseat: 453, data_seatno: "s1-y-14-8", customer_id: "1647", name: "角屋 杏莉", mail: "", phone: "", studio: "新田辺", class: "type_3"},
  /*454 */{dataseat: 454, data_seatno: "s1-y-14-9", customer_id: "1647", name: "角屋 杏莉", mail: "", phone: "", studio: "新田辺", class: "type_3"},
  /*455 */{dataseat: 455, data_seatno: "s1-y-14-10", customer_id: "", name: "角屋友紀", mail: "anrin0406@icloud.com", phone: "09015829424", studio: "新田辺", class: "type_3"},
  /*456 */{dataseat: 456, data_seatno: "s1-y-14-11", customer_id: "", name: "角屋友紀", mail: "anrin0406@icloud.com", phone: "09015829424", studio: "新田辺", class: "type_3"},
  /*457 */{dataseat: 457, data_seatno: "s1-y-14-12", customer_id: "", name: "角屋友紀", mail: "anrin0406@icloud.com", phone: "09015829424", studio: "新田辺", class: "type_3"},
  /*458 */{dataseat: 458, data_seatno: "s1-y-14-13", customer_id: "", name: "角屋友紀", mail: "anrin0406@icloud.com", phone: "09015829424", studio: "新田辺", class: "type_3"},
  /*459 */{dataseat: 459, data_seatno: "s1-y-14-14", customer_id: "1832", name: "平畠 莉珠", mail: "", phone: "", studio: "烏丸", class: "type_4"},
  /*460 */{dataseat: 460, data_seatno: "s1-y-14-15", customer_id: "1832", name: "平畠 莉珠", mail: "", phone: "", studio: "烏丸", class: "type_4"},
  /*461 */{dataseat: 461, data_seatno: "s1-y-14-16", customer_id: "1834", name: "相川 咲彩", mail: "", phone: "", studio: "烏丸", class: "type_4"},
  /*462 */{dataseat: 462, data_seatno: "s1-y-14-17", customer_id: "1834", name: "相川 咲彩", mail: "", phone: "", studio: "烏丸", class: "type_4"},
  /*463 */{dataseat: 463, data_seatno: "s1-y-14-19", customer_id: "1213", name: "林 亜里珠", mail: "", phone: "", studio: "烏丸", class: "type_4"},
  /*464 */{dataseat: 464, data_seatno: "s1-y-14-20", customer_id: "1213", name: "林 亜里珠", mail: "", phone: "", studio: "烏丸", class: "type_4"},
  /*465 */{dataseat: 465, data_seatno: "s1-y-14-21", customer_id: "774", name: "小林 芽結", mail: "", phone: "", studio: "烏丸", class: "type_4"},
  /*466 */{dataseat: 466, data_seatno: "s1-y-14-22", customer_id: "774", name: "小林 芽結", mail: "", phone: "", studio: "烏丸", class: "type_4"},
  /*467 */{dataseat: 467, data_seatno: "s1-y-14-23", customer_id: "", name: "", mail: "", phone: "", studio: "", class: "type_99"},
  /*468 */{dataseat: 468, data_seatno: "s1-y-14-24", customer_id: "", name: "", mail: "", phone: "", studio: "カメラ", class: "type_98"},
  /*469 */{dataseat: 469, data_seatno: "s1-y-14-25", customer_id: "", name: "", mail: "", phone: "", studio: "カメラ", class: "type_98"},
  /*470 */{dataseat: 470, data_seatno: "s1-y-14-26", customer_id: "814", name: "安永 愛望", mail: "", phone: "", studio: "烏丸", class: "type_4"},
  /*471 */{dataseat: 471, data_seatno: "s1-y-14-27", customer_id: "814", name: "安永 愛望", mail: "", phone: "", studio: "烏丸", class: "type_4"},
  /*472 */{dataseat: 472, data_seatno: "s1-y-14-28", customer_id: "924", name: "橋口 玲仁杏", mail: "", phone: "", studio: "烏丸", class: "type_4"},
  /*473 */{dataseat: 473, data_seatno: "s1-y-14-29", customer_id: "924", name: "橋口 玲仁杏", mail: "", phone: "", studio: "烏丸", class: "type_4"},
  /*474 */{dataseat: 474, data_seatno: "s1-y-14-30", customer_id: "924", name: "橋口 玲仁杏", mail: "", phone: "", studio: "烏丸", class: "type_4"},
  /*475 */{dataseat: 475, data_seatno: "s1-y-14-32", customer_id: "1762", name: "増子 来玲亜", mail: "", phone: "", studio: "宇治", class: "type_5"},
  /*476 */{dataseat: 476, data_seatno: "s1-y-14-33", customer_id: "1762", name: "増子 来玲亜", mail: "", phone: "", studio: "宇治", class: "type_5"},
  /*477 */{dataseat: 477, data_seatno: "s1-y-14-34", customer_id: "1763", name: "青木 真歩", mail: "", phone: "", studio: "宇治", class: "type_5"},
  /*478 */{dataseat: 478, data_seatno: "s1-y-14-35", customer_id: "1763", name: "青木 真歩", mail: "", phone: "", studio: "宇治", class: "type_5"},
  /*479 */{dataseat: 479, data_seatno: "s1-y-14-36", customer_id: "1823", name: "山口 葵葉", mail: "", phone: "", studio: "宇治", class: "type_5"},
  /*480 */{dataseat: 480, data_seatno: "s1-y-14-37", customer_id: "1823", name: "山口 葵葉", mail: "", phone: "", studio: "宇治", class: "type_5"},
  /*481 */{dataseat: 481, data_seatno: "s1-y-14-38", customer_id: "1823", name: "山口 葵葉", mail: "", phone: "", studio: "宇治", class: "type_5"},
  /*482 */{dataseat: 482, data_seatno: "s1-y-14-39", customer_id: "1938", name: "喜賀 羽蘭", mail: "", phone: "", studio: "宇治", class: "type_5"},
  /*483 */{dataseat: 483, data_seatno: "s1-y-14-40", customer_id: "1938", name: "喜賀 羽蘭", mail: "", phone: "", studio: "宇治", class: "type_5"},
  /*484 */{dataseat: 484, data_seatno: "s1-y-14-41", customer_id: "1938", name: "喜賀 羽蘭", mail: "", phone: "", studio: "宇治", class: "type_5"},
  //////////// 15列目右 ////////////
  /*485 */{dataseat: 485, data_seatno: "s1-y-15-45", customer_id: "2018", name: "梅田 せん", mail: "", phone: "", studio: "宇治", class: "type_5"},
  /*486 */{dataseat: 486, data_seatno: "s1-y-15-46", customer_id: "2018", name: "梅田 せん", mail: "", phone: "", studio: "宇治", class: "type_5"},
  /*487 */{dataseat: 487, data_seatno: "s1-y-15-47", customer_id: "1757", name: "小堀 雛", mail: "", phone: "", studio: "宇治", class: "type_5"},
  /*488 */{dataseat: 488, data_seatno: "s1-y-15-48", customer_id: "1757", name: "小堀 雛", mail: "", phone: "", studio: "宇治", class: "type_5"},
  //////////// 15列目左 ////////////
  /*489 */{dataseat: 489, data_seatno: "s1-y-15-1", customer_id: "1659", name: "古川 望緒", mail: "", phone: "", studio: "丹波橋", class: "type_6"},
  /*490 */{dataseat: 490, data_seatno: "s1-y-15-2", customer_id: "1659", name: "古川 望緒", mail: "", phone: "", studio: "丹波橋", class: "type_6"},
  /*491 */{dataseat: 491, data_seatno: "s1-y-15-3", customer_id: "1042", name: "森 月絆", mail: "", phone: "", studio: "丹波橋", class: "type_6"},
  /*492 */{dataseat: 492, data_seatno: "s1-y-15-4", customer_id: "1042", name: "森 月絆", mail: "", phone: "", studio: "丹波橋", class: "type_6"},
  //////////// 16列目 ////////////
  /*493 */{dataseat: 493, data_seatno: "s1-y-16-1", customer_id: "596", name: "上村 海理", mail: "", phone: "", studio: "丹波橋", class: "type_6"},
  /*494 */{dataseat: 494, data_seatno: "s1-y-16-2", customer_id: "596", name: "上村 海理", mail: "", phone: "", studio: "丹波橋", class: "type_6"},
  /*495 */{dataseat: 495, data_seatno: "s1-y-16-3", customer_id: "777", name: "原田 莉子", mail: "", phone: "", studio: "丹波橋", class: "type_6"},
  /*496 */{dataseat: 496, data_seatno: "s1-y-16-4", customer_id: "777", name: "原田 莉子", mail: "", phone: "", studio: "丹波橋", class: "type_6"},
  /*497 */{dataseat: 497, data_seatno: "s1-y-16-10", customer_id: "747", name: "小林 あこ", mail: "", phone: "", studio: "丹波橋", class: "type_6"},
  /*498 */{dataseat: 498, data_seatno: "s1-y-16-11", customer_id: "747", name: "小林 あこ", mail: "", phone: "", studio: "丹波橋", class: "type_6"},
  /*499 */{dataseat: 499, data_seatno: "s1-y-16-12", customer_id: "743", name: "小西 栞寧", mail: "", phone: "", studio: "丹波橋", class: "type_6"},
  /*500 */{dataseat: 500, data_seatno: "s1-y-16-13", customer_id: "743", name: "小西 栞寧", mail: "", phone: "", studio: "丹波橋", class: "type_6"},
  /*501 */{dataseat: 501, data_seatno: "s1-y-16-14", customer_id: "598", name: "結城 叶愛", mail: "", phone: "", studio: "丹波橋", class: "type_6"},
  /*502 */{dataseat: 502, data_seatno: "s1-y-16-15", customer_id: "598", name: "結城 叶愛", mail: "", phone: "", studio: "丹波橋", class: "type_6"},
  /*503 */{dataseat: 503, data_seatno: "s1-y-16-16", customer_id: "598", name: "結城 叶愛", mail: "", phone: "", studio: "丹波橋", class: "type_6"},
  /*504 */{dataseat: 504, data_seatno: "s1-y-16-20", customer_id: "1761", name: "原 百花", mail: "", phone: "", studio: "宇治", class: "type_5"},
  /*505 */{dataseat: 505, data_seatno: "s1-y-16-21", customer_id: "1761", name: "原 百花", mail: "", phone: "", studio: "宇治", class: "type_5"},
  /*506 */{dataseat: 506, data_seatno: "s1-y-16-22", customer_id: "1761", name: "原 百花", mail: "", phone: "", studio: "宇治", class: "type_5"},
  /*507 */{dataseat: 507, data_seatno: "s1-y-16-23", customer_id: "1761", name: "原 百花", mail: "", phone: "", studio: "宇治", class: "type_5"},
  /*508 */{dataseat: 508, data_seatno: "s1-y-16-24", customer_id: "1758", name: "嘉門 柚杏", mail: "", phone: "", studio: "宇治", class: "type_5"},
  /*509 */{dataseat: 509, data_seatno: "s1-y-16-25", customer_id: "1758", name: "嘉門 柚杏", mail: "", phone: "", studio: "宇治", class: "type_5"},
  /*510 */{dataseat: 510, data_seatno: "s1-y-16-26", customer_id: "1758", name: "嘉門 柚杏", mail: "", phone: "", studio: "宇治", class: "type_5"},
  /*511 */{dataseat: 511, data_seatno: "s1-y-16-27", customer_id: "1760", name: "福住 碧心", mail: "", phone: "", studio: "宇治", class: "type_5"},
  /*512 */{dataseat: 512, data_seatno: "s1-y-16-28", customer_id: "1760", name: "福住 碧心", mail: "", phone: "", studio: "宇治", class: "type_5"},
  /*513 */{dataseat: 513, data_seatno: "s1-y-16-33", customer_id: "1188", name: "草下 詩", mail: "", phone: "", studio: "西大路御池", class: "type_7"},
  /*514 */{dataseat: 514, data_seatno: "s1-y-16-34", customer_id: "1188", name: "草下 詩", mail: "", phone: "", studio: "西大路御池", class: "type_7"},
  /*515 */{dataseat: 515, data_seatno: "s1-y-16-35", customer_id: "", name: "草下沙希", mail: "benisaki0610@gmail.com", phone: "08024162000", studio: "西大路御池", class: "type_7"},
  /*516 */{dataseat: 516, data_seatno: "s1-y-16-36", customer_id: "", name: "草下沙希", mail: "benisaki0610@gmail.com", phone: "08024162000", studio: "西大路御池", class: "type_7"},
  /*517 */{dataseat: 517, data_seatno: "s1-y-16-37", customer_id: "", name: "草下沙希", mail: "benisaki0610@gmail.com", phone: "08024162000", studio: "西大路御池", class: "type_7"},
  /*518 */{dataseat: 518, data_seatno: "s1-y-16-38", customer_id: "1669", name: "中居 渚", mail: "", phone: "", studio: "西大路御池", class: "type_7"},
  /*519 */{dataseat: 519, data_seatno: "s1-y-16-39", customer_id: "1669", name: "中居 渚", mail: "", phone: "", studio: "西大路御池", class: "type_7"},
  /*520 */{dataseat: 520, data_seatno: "s1-y-16-45", customer_id: "862", name: "松尾 芽郁", mail: "", phone: "", studio: "西大路御池", class: "type_7"},
  /*521 */{dataseat: 521, data_seatno: "s1-y-16-46", customer_id: "862", name: "松尾 芽郁", mail: "", phone: "", studio: "西大路御池", class: "type_7"},
  /*522 */{dataseat: 522, data_seatno: "s1-y-16-47", customer_id: "862", name: "松尾 芽郁", mail: "", phone: "", studio: "西大路御池", class: "type_7"},
  /*523 */{dataseat: 523, data_seatno: "s1-y-16-48", customer_id: "862", name: "松尾 芽郁", mail: "", phone: "", studio: "西大路御池", class: "type_7"},
  //////////// 17列目 ////////////
  /*524 */{dataseat: 524, data_seatno: "s1-y-17-1", customer_id: "1360", name: "北村 海果", mail: "", phone: "", studio: "丹波橋", class: "type_6"},
  /*525 */{dataseat: 525, data_seatno: "s1-y-17-2", customer_id: "1360", name: "北村 海果", mail: "", phone: "", studio: "丹波橋", class: "type_6"},
  /*526 */{dataseat: 526, data_seatno: "s1-y-17-3", customer_id: "1420", name: "村山 若羽", mail: "", phone: "", studio: "丹波橋", class: "type_6"},
  /*527 */{dataseat: 527, data_seatno: "s1-y-17-4", customer_id: "1420", name: "村山 若羽", mail: "", phone: "", studio: "丹波橋", class: "type_6"},
  /*528 */{dataseat: 528, data_seatno: "s1-y-17-10", customer_id: "1694", name: "堀居 愛可", mail: "", phone: "", studio: "丹波橋", class: "type_6"},
  /*529 */{dataseat: 529, data_seatno: "s1-y-17-11", customer_id: "1694", name: "堀居 愛可", mail: "", phone: "", studio: "丹波橋", class: "type_6"},
  /*530 */{dataseat: 530, data_seatno: "s1-y-17-12", customer_id: "1694", name: "堀居 愛可", mail: "", phone: "", studio: "丹波橋", class: "type_6"},
  /*531 */{dataseat: 531, data_seatno: "s1-y-17-13", customer_id: "1779", name: "小水 瀬梨", mail: "", phone: "", studio: "丹波橋", class: "type_6"},
  /*532 */{dataseat: 532, data_seatno: "s1-y-17-14", customer_id: "1779", name: "小水 瀬梨", mail: "", phone: "", studio: "丹波橋", class: "type_6"},
  /*533 */{dataseat: 533, data_seatno: "s1-y-17-15", customer_id: "", name: "小水綾", mail: "r.i.s.mama.2008-1031@softbank.ne.jp", phone: "08031348323", studio: "丹波橋", class: "type_6"},
  /*534 */{dataseat: 534, data_seatno: "s1-y-17-16", customer_id: "", name: "小水綾", mail: "r.i.s.mama.2008-1031@softbank.ne.jp", phone: "08031348323", studio: "丹波橋", class: "type_6"},
  /*535 */{dataseat: 535, data_seatno: "s1-y-17-20", customer_id: "1770", name: "大西 このは", mail: "", phone: "", studio: "宇治", class: "type_5"},
  /*536 */{dataseat: 536, data_seatno: "s1-y-17-21", customer_id: "1770", name: "大西 このは", mail: "", phone: "", studio: "宇治", class: "type_5"},
  /*537 */{dataseat: 537, data_seatno: "s1-y-17-22", customer_id: "1793", name: "久保 愛茉", mail: "", phone: "", studio: "宇治", class: "type_5"},
  /*538 */{dataseat: 538, data_seatno: "s1-y-17-23", customer_id: "1793", name: "久保 愛茉", mail: "", phone: "", studio: "宇治", class: "type_5"},
  /*539 */{dataseat: 539, data_seatno: "s1-y-17-24", customer_id: "1826", name: "福井 絵真", mail: "", phone: "", studio: "出町柳", class: "type_10"},
  /*540 */{dataseat: 540, data_seatno: "s1-y-17-25", customer_id: "1826", name: "福井 絵真", mail: "", phone: "", studio: "出町柳", class: "type_10"},
  /*541 */{dataseat: 541, data_seatno: "s1-y-17-26", customer_id: "1826", name: "福井 絵真", mail: "", phone: "", studio: "出町柳", class: "type_10"},
  /*542 */{dataseat: 542, data_seatno: "s1-y-17-27", customer_id: "1383", name: "山口 千里", mail: "", phone: "", studio: "出町柳", class: "type_10"},
  /*543 */{dataseat: 543, data_seatno: "s1-y-17-28", customer_id: "1383", name: "山口 千里", mail: "", phone: "", studio: "出町柳", class: "type_10"},
  /*544 */{dataseat: 544, data_seatno: "s1-y-17-33", customer_id: "1065", name: "山田 凛", mail: "", phone: "", studio: "西大路御池", class: "type_7"},
  /*545 */{dataseat: 545, data_seatno: "s1-y-17-34", customer_id: "1065", name: "山田 凛", mail: "", phone: "", studio: "西大路御池", class: "type_7"},
  /*546 */{dataseat: 546, data_seatno: "s1-y-17-35", customer_id: "1065", name: "山田 凛", mail: "", phone: "", studio: "西大路御池", class: "type_7"},
  /*547 */{dataseat: 547, data_seatno: "s1-y-17-36", customer_id: "1065", name: "山田 凛", mail: "", phone: "", studio: "西大路御池", class: "type_7"},
  /*548 */{dataseat: 548, data_seatno: "s1-y-17-37", customer_id: "1065", name: "山田 凛", mail: "", phone: "", studio: "西大路御池", class: "type_7"},
  /*549 */{dataseat: 549, data_seatno: "s1-y-17-38", customer_id: "1058", name: "森 夏蓮", mail: "", phone: "", studio: "西大路御池", class: "type_7"},
  /*550 */{dataseat: 550, data_seatno: "s1-y-17-39", customer_id: "1058", name: "森 夏蓮", mail: "", phone: "", studio: "西大路御池", class: "type_7"},
  /*551 */{dataseat: 551, data_seatno: "s1-y-17-45", customer_id: "866", name: "林 真緒", mail: "", phone: "", studio: "西大路御池", class: "type_7"},
  /*552 */{dataseat: 552, data_seatno: "s1-y-17-46", customer_id: "866", name: "林 真緒", mail: "", phone: "", studio: "西大路御池", class: "type_7"},
  /*553 */{dataseat: 553, data_seatno: "s1-y-17-47", customer_id: "1041", name: "板垣 波音", mail: "", phone: "", studio: "西大路御池", class: "type_7"},
  /*554 */{dataseat: 554, data_seatno: "s1-y-17-48", customer_id: "1041", name: "板垣 波音", mail: "", phone: "", studio: "西大路御池", class: "type_7"},
  //////////// 18列目 ////////////
  /*555 */{dataseat: 555, data_seatno: "s1-y-18-1", customer_id: "760", name: "児玉 結愛", mail: "", phone: "", studio: "丹波橋", class: "type_6"},
  /*556 */{dataseat: 556, data_seatno: "s1-y-18-2", customer_id: "760", name: "児玉 結愛", mail: "", phone: "", studio: "丹波橋", class: "type_6"},
  /*557 */{dataseat: 557, data_seatno: "s1-y-18-3", customer_id: "760", name: "児玉 結愛", mail: "", phone: "", studio: "丹波橋", class: "type_6"},
  /*558 */{dataseat: 558, data_seatno: "s1-y-18-4", customer_id: "760", name: "児玉 結愛", mail: "", phone: "", studio: "丹波橋", class: "type_6"},
  /*559 */{dataseat: 559, data_seatno: "s1-y-18-5", customer_id: "760", name: "児玉 結愛", mail: "", phone: "", studio: "丹波橋", class: "type_6"},
  /*560 */{dataseat: 560, data_seatno: "s1-y-18-9", customer_id: "1668", name: "川本 夢叶", mail: "", phone: "", studio: "丹波橋", class: "type_6"},
  /*561 */{dataseat: 561, data_seatno: "s1-y-18-10", customer_id: "1668", name: "川本 夢叶", mail: "", phone: "", studio: "丹波橋", class: "type_6"},
  /*562 */{dataseat: 562, data_seatno: "s1-y-18-11", customer_id: "1668", name: "川本 夢叶", mail: "", phone: "", studio: "丹波橋", class: "type_6"},
  /*563 */{dataseat: 563, data_seatno: "s1-y-18-12", customer_id: "1668", name: "川本 夢叶", mail: "", phone: "", studio: "丹波橋", class: "type_6"},
  /*564 */{dataseat: 564, data_seatno: "s1-y-18-13", customer_id: "1668", name: "川本 夢叶", mail: "", phone: "", studio: "丹波橋", class: "type_6"},
  /*565 */{dataseat: 565, data_seatno: "s1-y-18-14", customer_id: "1668", name: "川本 夢叶", mail: "", phone: "", studio: "丹波橋", class: "type_6"},
  /*566 */{dataseat: 566, data_seatno: "s1-y-18-15", customer_id: "785", name: "中本 愛美", mail: "", phone: "", studio: "丹波橋", class: "type_6"},
  /*567 */{dataseat: 567, data_seatno: "s1-y-18-16", customer_id: "785", name: "中本 愛美", mail: "", phone: "", studio: "丹波橋", class: "type_6"},
  /*568 */{dataseat: 568, data_seatno: "s1-y-18-20", customer_id: "1927", name: "近藤 希凜", mail: "", phone: "", studio: "宇治", class: "type_5"},
  /*569 */{dataseat: 569, data_seatno: "s1-y-18-21", customer_id: "1927", name: "近藤 希凜", mail: "", phone: "", studio: "宇治", class: "type_5"},
  /*570 */{dataseat: 570, data_seatno: "s1-y-18-22", customer_id: "1928", name: "村田 碧羽", mail: "", phone: "", studio: "宇治", class: "type_5"},
  /*571 */{dataseat: 571, data_seatno: "s1-y-18-23", customer_id: "1928", name: "村田 碧羽", mail: "", phone: "", studio: "宇治", class: "type_5"},
  /*572 */{dataseat: 572, data_seatno: "s1-y-18-24", customer_id: "1393", name: "上杉 優陽", mail: "", phone: "", studio: "出町柳", class: "type_10"},
  /*573 */{dataseat: 573, data_seatno: "s1-y-18-25", customer_id: "1393", name: "上杉 優陽", mail: "", phone: "", studio: "出町柳", class: "type_10"},
  /*574 */{dataseat: 574, data_seatno: "s1-y-18-26", customer_id: "1398", name: "山田 一歌", mail: "", phone: "", studio: "出町柳", class: "type_10"},
  /*575 */{dataseat: 575, data_seatno: "s1-y-18-27", customer_id: "1398", name: "山田 一歌", mail: "", phone: "", studio: "出町柳", class: "type_10"},
  /*576 */{dataseat: 576, data_seatno: "s1-y-18-28", customer_id: "558", name: "三田村 茉優", mail: "", phone: "", studio: "京都選抜", class: "type_13"},
  /*577 */{dataseat: 577, data_seatno: "s1-y-18-29", customer_id: "558", name: "三田村 茉優", mail: "", phone: "", studio: "京都選抜", class: "type_13"},
  /*578 */{dataseat: 578, data_seatno: "s1-y-18-33", customer_id: "1407", name: "原口 穂花", mail: "", phone: "", studio: "西大路御池", class: "type_7"},
  /*579 */{dataseat: 579, data_seatno: "s1-y-18-34", customer_id: "1407", name: "原口 穂花", mail: "", phone: "", studio: "西大路御池", class: "type_7"},
  /*580 */{dataseat: 580, data_seatno: "s1-y-18-35", customer_id: "1407", name: "原口 穂花", mail: "", phone: "", studio: "西大路御池", class: "type_7"},
  /*581 */{dataseat: 581, data_seatno: "s1-y-18-36", customer_id: "855", name: "鳥居 凜", mail: "", phone: "", studio: "西大路御池", class: "type_7"},
  /*582 */{dataseat: 582, data_seatno: "s1-y-18-37", customer_id: "855", name: "鳥居 凜", mail: "", phone: "", studio: "西大路御池", class: "type_7"},
  /*583 */{dataseat: 583, data_seatno: "s1-y-18-38", customer_id: "855", name: "鳥居 凜", mail: "", phone: "", studio: "西大路御池", class: "type_7"},
  /*584 */{dataseat: 584, data_seatno: "s1-y-18-39", customer_id: "855", name: "鳥居 凜", mail: "", phone: "", studio: "西大路御池", class: "type_7"},
  /*585 */{dataseat: 585, data_seatno: "s1-y-18-40", customer_id: "855", name: "鳥居 凜", mail: "", phone: "", studio: "西大路御池", class: "type_7"},
  /*586 */{dataseat: 586, data_seatno: "s1-y-18-44", customer_id: "889", name: "徳山 夢姫", mail: "", phone: "", studio: "西大路御池", class: "type_7"},
  /*587 */{dataseat: 587, data_seatno: "s1-y-18-45", customer_id: "889", name: "徳山 夢姫", mail: "", phone: "", studio: "西大路御池", class: "type_7"},
  /*588 */{dataseat: 588, data_seatno: "s1-y-18-46", customer_id: "889", name: "徳山 夢姫", mail: "", phone: "", studio: "西大路御池", class: "type_7"},
  /*589 */{dataseat: 589, data_seatno: "s1-y-18-47", customer_id: "889", name: "徳山 夢姫", mail: "", phone: "", studio: "西大路御池", class: "type_7"},
  /*590 */{dataseat: 590, data_seatno: "s1-y-18-48", customer_id: "889", name: "徳山 夢姫", mail: "", phone: "", studio: "西大路御池", class: "type_7"},
  //////////// 19列目 ////////////
  /*591 */{dataseat: 591, data_seatno: "s1-y-19-1", customer_id: "976", name: "水島 結乃", mail: "", phone: "", studio: "丹波橋", class: "type_6"},
  /*592 */{dataseat: 592, data_seatno: "s1-y-19-2", customer_id: "976", name: "水島 結乃", mail: "", phone: "", studio: "丹波橋", class: "type_6"},
  /*593 */{dataseat: 593, data_seatno: "s1-y-19-3", customer_id: "976", name: "水島 結乃", mail: "", phone: "", studio: "丹波橋", class: "type_6"},
  /*594 */{dataseat: 594, data_seatno: "s1-y-19-4", customer_id: "976", name: "水島 結乃", mail: "", phone: "", studio: "丹波橋", class: "type_6"},
  /*595 */{dataseat: 595, data_seatno: "s1-y-19-5", customer_id: "976", name: "水島 結乃", mail: "", phone: "", studio: "丹波橋", class: "type_6"},
  /*596 */{dataseat: 596, data_seatno: "s1-y-19-6", customer_id: "976", name: "水島 結乃", mail: "", phone: "", studio: "丹波橋", class: "type_6"},
  /*597 */{dataseat: 597, data_seatno: "s1-y-19-8", customer_id: "773", name: "嵯峨 しまの", mail: "", phone: "", studio: "丹波橋", class: "type_6"},
  /*598 */{dataseat: 598, data_seatno: "s1-y-19-9", customer_id: "773", name: "嵯峨 しまの", mail: "", phone: "", studio: "丹波橋", class: "type_6"},
  /*599 */{dataseat: 599, data_seatno: "s1-y-19-10", customer_id: "1072", name: "田中 心華", mail: "", phone: "", studio: "丹波橋", class: "type_6"},
  /*600 */{dataseat: 600, data_seatno: "s1-y-19-11", customer_id: "1072", name: "田中 心華", mail: "", phone: "", studio: "丹波橋", class: "type_6"},
  /*601 */{dataseat: 601, data_seatno: "s1-y-19-12", customer_id: "1072", name: "田中 心華", mail: "", phone: "", studio: "丹波橋", class: "type_6"},
  /*602 */{dataseat: 602, data_seatno: "s1-y-19-13", customer_id: "1316", name: "藤原 なこ", mail: "", phone: "", studio: "丹波橋", class: "type_6"},
  /*603 */{dataseat: 603, data_seatno: "s1-y-19-14", customer_id: "1316", name: "藤原 なこ", mail: "", phone: "", studio: "丹波橋", class: "type_6"},
  /*604 */{dataseat: 604, data_seatno: "s1-y-19-15", customer_id: "", name: "藤原 なこ", mail: "hiroko.q0.0p@gmail.com", phone: "09090513461", studio: "丹波橋", class: "type_6"},
  /*605 */{dataseat: 605, data_seatno: "s1-y-19-16", customer_id: "1045", name: "北村 優海", mail: "", phone: "", studio: "丹波橋", class: "type_6"},
  /*606 */{dataseat: 606, data_seatno: "s1-y-19-17", customer_id: "1045", name: "北村 優海", mail: "", phone: "", studio: "丹波橋", class: "type_6"},
  /*607 */{dataseat: 607, data_seatno: "s1-y-19-19", customer_id: "", name: "相物 美乃織", mail: "mana.irie.yoshi.0428@gmail.com", phone: "09077543752", studio: "一般1", class: "type_12"},
  /*608 */{dataseat: 608, data_seatno: "s1-y-19-20", customer_id: "", name: "相物 美乃織", mail: "mana.irie.yoshi.0428@gmail.com", phone: "09077543752", studio: "一般1", class: "type_12"},
  /*609 */{dataseat: 609, data_seatno: "s1-y-19-21", customer_id: "", name: "相物 美乃織", mail: "mana.irie.yoshi.0428@gmail.com", phone: "09077543752", studio: "一般1", class: "type_12"},
  /*610 */{dataseat: 610, data_seatno: "s1-y-19-22", customer_id: "", name: "北嶋 ルーナ", mail: "runasari@gmail.com", phone: "09039296863", studio: "一般1", class: "type_12"},
  /*611 */{dataseat: 611, data_seatno: "s1-y-19-23", customer_id: "", name: "北嶋 ルーナ", mail: "runasari@gmail.com", phone: "09039296863", studio: "一般1", class: "type_12"},
  /*612 */{dataseat: 612, data_seatno: "s1-y-19-24", customer_id: "", name: "北嶋 ルーナ", mail: "runasari@gmail.com", phone: "09039296863", studio: "一般1", class: "type_12"},
  /*613 */{dataseat: 613, data_seatno: "s1-y-19-25", customer_id: "1099", name: "今村 優真", mail: "", phone: "", studio: "SLASH選抜1", class: "type_11"},
  /*614 */{dataseat: 614, data_seatno: "s1-y-19-26", customer_id: "1099", name: "今村 優真", mail: "", phone: "", studio: "SLASH選抜1", class: "type_11"},
  /*615 */{dataseat: 615, data_seatno: "s1-y-19-27", customer_id: "748", name: "柳田 陽咲", mail: "", phone: "", studio: "SLASH選抜1", class: "type_11"},
  /*616 */{dataseat: 616, data_seatno: "s1-y-19-28", customer_id: "748", name: "柳田 陽咲", mail: "", phone: "", studio: "SLASH選抜1", class: "type_11"},
  /*617 */{dataseat: 617, data_seatno: "s1-y-19-29", customer_id: "748", name: "柳田 陽咲", mail: "", phone: "", studio: "SLASH選抜1", class: "type_11"},
  /*618 */{dataseat: 618, data_seatno: "s1-y-19-30", customer_id: "748", name: "柳田 陽咲", mail: "", phone: "", studio: "SLASH選抜1", class: "type_11"},
  /*619 */{dataseat: 619, data_seatno: "s1-y-19-32", customer_id: "1081", name: "井堂 みやび", mail: "", phone: "", studio: "西大路御池", class: "type_7"},
  /*620 */{dataseat: 620, data_seatno: "s1-y-19-33", customer_id: "1081", name: "井堂 みやび", mail: "", phone: "", studio: "西大路御池", class: "type_7"},
  /*621 */{dataseat: 621, data_seatno: "s1-y-19-34", customer_id: "33", name: "牧 美佑", mail: "", phone: "", studio: "西大路御池", class: "type_7"},
  /*622 */{dataseat: 622, data_seatno: "s1-y-19-35", customer_id: "33", name: "牧 美佑", mail: "", phone: "", studio: "西大路御池", class: "type_7"},
  /*623 */{dataseat: 623, data_seatno: "s1-y-19-36", customer_id: "851", name: "土手下 杏那", mail: "", phone: "", studio: "西大路御池", class: "type_7"},
  /*624 */{dataseat: 624, data_seatno: "s1-y-19-37", customer_id: "851", name: "土手下 杏那", mail: "", phone: "", studio: "西大路御池", class: "type_7"},
  /*625 */{dataseat: 625, data_seatno: "s1-y-19-38", customer_id: "1061", name: "牧野 莉々可", mail: "", phone: "", studio: "西大路御池", class: "type_7"},
  /*626 */{dataseat: 626, data_seatno: "s1-y-19-39", customer_id: "1061", name: "牧野 莉々可", mail: "", phone: "", studio: "西大路御池", class: "type_7"},
  /*627 */{dataseat: 627, data_seatno: "s1-y-19-40", customer_id: "1638", name: "丸居 雪七子", mail: "", phone: "", studio: "西大路御池", class: "type_7"},
  /*628 */{dataseat: 628, data_seatno: "s1-y-19-41", customer_id: "1638", name: "丸居 雪七子", mail: "", phone: "", studio: "西大路御池", class: "type_7"},
  /*629 */{dataseat: 629, data_seatno: "s1-y-19-43", customer_id: "1643", name: "吉川 采心", mail: "", phone: "", studio: "西大路御池", class: "type_7"},
  /*630 */{dataseat: 630, data_seatno: "s1-y-19-44", customer_id: "1643", name: "吉川 采心", mail: "", phone: "", studio: "西大路御池", class: "type_7"},
  /*631 */{dataseat: 631, data_seatno: "s1-y-19-45", customer_id: "1921", name: "小林 莉緒", mail: "", phone: "", studio: "西大路御池", class: "type_7"},
  /*632 */{dataseat: 632, data_seatno: "s1-y-19-46", customer_id: "1921", name: "小林 莉緒", mail: "", phone: "", studio: "西大路御池", class: "type_7"},
  /*633 */{dataseat: 633, data_seatno: "s1-y-19-47", customer_id: "1044", name: "西川 たから", mail: "", phone: "", studio: "西大路御池", class: "type_7"},
  /*634 */{dataseat: 634, data_seatno: "s1-y-19-48", customer_id: "1044", name: "西川 たから", mail: "", phone: "", studio: "西大路御池", class: "type_7"},
  //////////// 20列目 ////////////
  /*635 */{dataseat: 635, data_seatno: "s1-y-20-1", customer_id: "1126", name: "宮本 果音", mail: "", phone: "", studio: "丹波橋", class: "type_6"},
  /*636 */{dataseat: 636, data_seatno: "s1-y-20-2", customer_id: "1126", name: "宮本 果音", mail: "", phone: "", studio: "丹波橋", class: "type_6"},
  /*637 */{dataseat: 637, data_seatno: "s1-y-20-3", customer_id: "1126", name: "宮本 果音", mail: "", phone: "", studio: "丹波橋", class: "type_6"},
  /*638 */{dataseat: 638, data_seatno: "s1-y-20-4", customer_id: "1126", name: "宮本 果音", mail: "", phone: "", studio: "丹波橋", class: "type_6"},
  /*639 */{dataseat: 639, data_seatno: "s1-y-20-5", customer_id: "1126", name: "宮本 果音", mail: "", phone: "", studio: "丹波橋", class: "type_6"},
  /*640 */{dataseat: 640, data_seatno: "s1-y-20-6", customer_id: "1126", name: "宮本 果音", mail: "", phone: "", studio: "丹波橋", class: "type_6"},
  /*641 */{dataseat: 641, data_seatno: "s1-y-20-8", customer_id: "1143", name: "中西 紗那", mail: "", phone: "", studio: "丹波橋", class: "type_6"},
  /*642 */{dataseat: 642, data_seatno: "s1-y-20-9", customer_id: "1143", name: "中西 紗那", mail: "", phone: "", studio: "丹波橋", class: "type_6"},
  /*643 */{dataseat: 643, data_seatno: "s1-y-20-10", customer_id: "1170", name: "山北 彩夏", mail: "", phone: "", studio: "丹波橋", class: "type_6"},
  /*644 */{dataseat: 644, data_seatno: "s1-y-20-11", customer_id: "1170", name: "山北 彩夏", mail: "", phone: "", studio: "丹波橋", class: "type_6"},
  /*645 */{dataseat: 645, data_seatno: "s1-y-20-12", customer_id: "", name: "山北彩夏", mail: "sayakkolife@gmail.com", phone: "07041116490", studio: "丹波橋", class: "type_6"},
  /*646 */{dataseat: 646, data_seatno: "s1-y-20-13", customer_id: "", name: "山北彩夏", mail: "sayakkolife@gmail.com", phone: "07041116490", studio: "丹波橋", class: "type_6"},
  /*647 */{dataseat: 647, data_seatno: "s1-y-20-14", customer_id: "1579", name: "吉村 心花", mail: "", phone: "", studio: "丹波橋", class: "type_6"},
  /*648 */{dataseat: 648, data_seatno: "s1-y-20-15", customer_id: "1579", name: "吉村 心花", mail: "", phone: "", studio: "丹波橋", class: "type_6"},
  /*649 */{dataseat: 649, data_seatno: "s1-y-20-16", customer_id: "1579", name: "吉村 心花", mail: "", phone: "", studio: "丹波橋", class: "type_6"},
  /*650 */{dataseat: 650, data_seatno: "s1-y-20-17", customer_id: "1579", name: "吉村 心花", mail: "", phone: "", studio: "丹波橋", class: "type_6"},
  /*651 */{dataseat: 651, data_seatno: "s1-y-20-19", customer_id: "783", name: "大橋 百椛", mail: "", phone: "", studio: "SLASH選抜1", class: "type_11"},
  /*652 */{dataseat: 652, data_seatno: "s1-y-20-20", customer_id: "783", name: "大橋 百椛", mail: "", phone: "", studio: "SLASH選抜1", class: "type_11"},
  /*653 */{dataseat: 653, data_seatno: "s1-y-20-21", customer_id: "783", name: "大橋 百椛", mail: "", phone: "", studio: "SLASH選抜1", class: "type_11"},
  /*654 */{dataseat: 654, data_seatno: "s1-y-20-22", customer_id: "783", name: "大橋 百椛", mail: "", phone: "", studio: "SLASH選抜1", class: "type_11"},
  /*655 */{dataseat: 655, data_seatno: "s1-y-20-23", customer_id: "783", name: "大橋 百椛", mail: "", phone: "", studio: "SLASH選抜1", class: "type_11"},
  /*656 */{dataseat: 656, data_seatno: "s1-y-20-24", customer_id: "783", name: "大橋 百椛", mail: "", phone: "", studio: "SLASH選抜1", class: "type_11"},
  /*657 */{dataseat: 657, data_seatno: "s1-y-20-25", customer_id: "1206", name: "山口 華朱", mail: "", phone: "", studio: "SLASH選抜1", class: "type_11"},
  /*658 */{dataseat: 658, data_seatno: "s1-y-20-26", customer_id: "1206", name: "山口 華朱", mail: "", phone: "", studio: "SLASH選抜1", class: "type_11"},
  /*659 */{dataseat: 659, data_seatno: "s1-y-20-27", customer_id: "1206", name: "山口 華朱", mail: "", phone: "", studio: "SLASH選抜1", class: "type_11"},
  /*660 */{dataseat: 660, data_seatno: "s1-y-20-28", customer_id: "1206", name: "山口 華朱", mail: "", phone: "", studio: "SLASH選抜1", class: "type_11"},
  /*661 */{dataseat: 661, data_seatno: "s1-y-20-29", customer_id: "1206", name: "山口 華朱", mail: "", phone: "", studio: "SLASH選抜1", class: "type_11"},
  /*662 */{dataseat: 662, data_seatno: "s1-y-20-30", customer_id: "1206", name: "山口 華朱", mail: "", phone: "", studio: "SLASH選抜1", class: "type_11"},
  /*663 */{dataseat: 663, data_seatno: "s1-y-20-32", customer_id: "1255", name: "亀井 咲幸", mail: "", phone: "", studio: "西大路御池", class: "type_7"},
  /*664 */{dataseat: 664, data_seatno: "s1-y-20-33", customer_id: "1255", name: "亀井 咲幸", mail: "", phone: "", studio: "西大路御池", class: "type_7"},
  /*665 */{dataseat: 665, data_seatno: "s1-y-20-34", customer_id: "1355", name: "福永 美波", mail: "", phone: "", studio: "西大路御池", class: "type_7"},
  /*666 */{dataseat: 666, data_seatno: "s1-y-20-35", customer_id: "1355", name: "福永 美波", mail: "", phone: "", studio: "西大路御池", class: "type_7"},
  /*667 */{dataseat: 667, data_seatno: "s1-y-20-36", customer_id: "1462", name: "長尾 幸和", mail: "", phone: "", studio: "西大路御池", class: "type_7"},
  /*668 */{dataseat: 668, data_seatno: "s1-y-20-37", customer_id: "1462", name: "長尾 幸和", mail: "", phone: "", studio: "西大路御池", class: "type_7"},
  /*669 */{dataseat: 669, data_seatno: "s1-y-20-38", customer_id: "860", name: "塩田 栞奈", mail: "", phone: "", studio: "西大路御池", class: "type_7"},
  /*670 */{dataseat: 670, data_seatno: "s1-y-20-39", customer_id: "860", name: "塩田 栞奈", mail: "", phone: "", studio: "西大路御池", class: "type_7"},
  /*671 */{dataseat: 671, data_seatno: "s1-y-20-40", customer_id: "1140", name: "仁頃 奈子", mail: "", phone: "", studio: "西大路御池", class: "type_7"},
  /*672 */{dataseat: 672, data_seatno: "s1-y-20-41", customer_id: "1140", name: "仁頃 奈子", mail: "", phone: "", studio: "西大路御池", class: "type_7"},
  /*673 */{dataseat: 673, data_seatno: "s1-y-20-43", customer_id: "1204", name: "桶谷 心春", mail: "", phone: "", studio: "西大路御池", class: "type_7"},
  /*674 */{dataseat: 674, data_seatno: "s1-y-20-44", customer_id: "1204", name: "桶谷 心春", mail: "", phone: "", studio: "西大路御池", class: "type_7"},
  /*675 */{dataseat: 675, data_seatno: "s1-y-20-45", customer_id: "1238", name: "高松 春乃", mail: "", phone: "", studio: "西大路御池", class: "type_7"},
  /*676 */{dataseat: 676, data_seatno: "s1-y-20-46", customer_id: "1238", name: "高松 春乃", mail: "", phone: "", studio: "西大路御池", class: "type_7"},
  /*677 */{dataseat: 677, data_seatno: "s1-y-20-47", customer_id: "869", name: "小林 詩", mail: "", phone: "", studio: "西大路御池", class: "type_7"},
  /*678 */{dataseat: 678, data_seatno: "s1-y-20-48", customer_id: "869", name: "小林 詩", mail: "", phone: "", studio: "西大路御池", class: "type_7"},
  //////////// 21列目 ////////////
  /*679 */{dataseat: 679, data_seatno: "s1-y-21-1", customer_id: "623", name: "河野 向日葵", mail: "", phone: "", studio: "丹波橋", class: "type_6"},
  /*680 */{dataseat: 680, data_seatno: "s1-y-21-2", customer_id: "623", name: "河野 向日葵", mail: "", phone: "", studio: "丹波橋", class: "type_6"},
  /*681 */{dataseat: 681, data_seatno: "s1-y-21-3", customer_id: "623", name: "河野 向日葵", mail: "", phone: "", studio: "丹波橋", class: "type_6"},
  /*682 */{dataseat: 682, data_seatno: "s1-y-21-4", customer_id: "623", name: "河野 向日葵", mail: "", phone: "", studio: "丹波橋", class: "type_6"},
  /*683 */{dataseat: 683, data_seatno: "s1-y-21-5", customer_id: "623", name: "河野 向日葵", mail: "", phone: "", studio: "丹波橋", class: "type_6"},
  /*684 */{dataseat: 684, data_seatno: "s1-y-21-6", customer_id: "623", name: "河野 向日葵", mail: "", phone: "", studio: "丹波橋", class: "type_6"},
  /*685 */{dataseat: 685, data_seatno: "s1-y-21-8", customer_id: "1533", name: "長谷部 満咲", mail: "", phone: "", studio: "丹波橋", class: "type_6"},
  /*686 */{dataseat: 686, data_seatno: "s1-y-21-9", customer_id: "1533", name: "長谷部 満咲", mail: "", phone: "", studio: "丹波橋", class: "type_6"},
  /*687 */{dataseat: 687, data_seatno: "s1-y-21-10", customer_id: "1593", name: "幸 あいみ", mail: "", phone: "", studio: "丹波橋", class: "type_6"},
  /*688 */{dataseat: 688, data_seatno: "s1-y-21-11", customer_id: "1593", name: "幸 あいみ", mail: "", phone: "", studio: "丹波橋", class: "type_6"},
  /*689 */{dataseat: 689, data_seatno: "s1-y-21-12", customer_id: "1660", name: "中尾 結菜", mail: "", phone: "", studio: "丹波橋", class: "type_6"},
  /*690 */{dataseat: 690, data_seatno: "s1-y-21-13", customer_id: "1660", name: "中尾 結菜", mail: "", phone: "", studio: "丹波橋", class: "type_6"},
  /*691 */{dataseat: 691, data_seatno: "s1-y-21-14", customer_id: "1734", name: "加賀 莉衣那", mail: "", phone: "", studio: "丹波橋", class: "type_6"},
  /*692 */{dataseat: 692, data_seatno: "s1-y-21-15", customer_id: "1734", name: "加賀 莉衣那", mail: "", phone: "", studio: "丹波橋", class: "type_6"},
  /*693 */{dataseat: 693, data_seatno: "s1-y-21-16", customer_id: "621", name: "三明 真由", mail: "", phone: "", studio: "山科", class: "type_8"},
  /*694 */{dataseat: 694, data_seatno: "s1-y-21-17", customer_id: "621", name: "三明 真由", mail: "", phone: "", studio: "山科", class: "type_8"},
  /*695 */{dataseat: 695, data_seatno: "s1-y-21-19", customer_id: "566", name: "松岡 楼子", mail: "", phone: "", studio: "SLASH選抜1", class: "type_11"},
  /*696 */{dataseat: 696, data_seatno: "s1-y-21-20", customer_id: "566", name: "松岡 楼子", mail: "", phone: "", studio: "SLASH選抜1", class: "type_11"},
  /*697 */{dataseat: 697, data_seatno: "s1-y-21-21", customer_id: "567", name: "川島 心愛", mail: "", phone: "", studio: "SLASH選抜1", class: "type_11"},
  /*698 */{dataseat: 698, data_seatno: "s1-y-21-22", customer_id: "567", name: "川島 心愛", mail: "", phone: "", studio: "SLASH選抜1", class: "type_11"},
  /*699 */{dataseat: 699, data_seatno: "s1-y-21-23", customer_id: "570", name: "鈴木 星菜", mail: "", phone: "", studio: "SLASH選抜1", class: "type_11"},
  /*700 */{dataseat: 700, data_seatno: "s1-y-21-24", customer_id: "570", name: "鈴木 星菜", mail: "", phone: "", studio: "SLASH選抜1", class: "type_11"},
  /*701 */{dataseat: 701, data_seatno: "s1-y-21-25", customer_id: "587", name: "新坂 つむぎ", mail: "", phone: "", studio: "SLASH選抜1", class: "type_11"},
  /*702 */{dataseat: 702, data_seatno: "s1-y-21-26", customer_id: "587", name: "新坂 つむぎ", mail: "", phone: "", studio: "SLASH選抜1", class: "type_11"},
  /*703 */{dataseat: 703, data_seatno: "s1-y-21-27", customer_id: "607", name: "市川 杏莉", mail: "", phone: "", studio: "SLASH選抜1", class: "type_11"},
  /*704 */{dataseat: 704, data_seatno: "s1-y-21-28", customer_id: "607", name: "市川 杏莉", mail: "", phone: "", studio: "SLASH選抜1", class: "type_11"},
  /*705 */{dataseat: 705, data_seatno: "s1-y-21-29", customer_id: "738", name: "藤原 妃菜", mail: "", phone: "", studio: "SLASH選抜1", class: "type_11"},
  /*706 */{dataseat: 706, data_seatno: "s1-y-21-30", customer_id: "738", name: "藤原 妃菜", mail: "", phone: "", studio: "SLASH選抜1", class: "type_11"},
  /*707 */{dataseat: 707, data_seatno: "s1-y-21-32", customer_id: "992", name: "中田 莉瑚", mail: "", phone: "", studio: "西大路御池", class: "type_7"},
  /*708 */{dataseat: 708, data_seatno: "s1-y-21-33", customer_id: "992", name: "中田 莉瑚", mail: "", phone: "", studio: "西大路御池", class: "type_7"},
  /*709 */{dataseat: 709, data_seatno: "s1-y-21-34", customer_id: "1581", name: "新井 りこ", mail: "", phone: "", studio: "西大路御池", class: "type_7"},
  /*710 */{dataseat: 710, data_seatno: "s1-y-21-35", customer_id: "1581", name: "新井 りこ", mail: "", phone: "", studio: "西大路御池", class: "type_7"},
  /*711 */{dataseat: 711, data_seatno: "s1-y-21-36", customer_id: "1581", name: "新井 りこ", mail: "", phone: "", studio: "西大路御池", class: "type_7"},
  /*712 */{dataseat: 712, data_seatno: "s1-y-21-37", customer_id: "1581", name: "新井 りこ", mail: "", phone: "", studio: "西大路御池", class: "type_7"},
  /*713 */{dataseat: 713, data_seatno: "s1-y-21-38", customer_id: "1766", name: "平井 詩己", mail: "", phone: "", studio: "西大路御池", class: "type_7"},
  /*714 */{dataseat: 714, data_seatno: "s1-y-21-39", customer_id: "1766", name: "平井 詩己", mail: "", phone: "", studio: "西大路御池", class: "type_7"},
  /*715 */{dataseat: 715, data_seatno: "s1-y-21-40", customer_id: "1886", name: "音川 七虹", mail: "", phone: "", studio: "西大路御池", class: "type_7"},
  /*716 */{dataseat: 716, data_seatno: "s1-y-21-41", customer_id: "1886", name: "音川 七虹", mail: "", phone: "", studio: "西大路御池", class: "type_7"},
  /*717 */{dataseat: 717, data_seatno: "s1-y-21-43", customer_id: "850", name: "宮村 杏花", mail: "", phone: "", studio: "西大路御池", class: "type_7"},
  /*718 */{dataseat: 718, data_seatno: "s1-y-21-44", customer_id: "850", name: "宮村 杏花", mail: "", phone: "", studio: "西大路御池", class: "type_7"},
  /*719 */{dataseat: 719, data_seatno: "s1-y-21-45", customer_id: "858", name: "藤田 咲菜", mail: "", phone: "", studio: "西大路御池", class: "type_7"},
  /*720 */{dataseat: 720, data_seatno: "s1-y-21-46", customer_id: "858", name: "藤田 咲菜", mail: "", phone: "", studio: "西大路御池", class: "type_7"},
  /*721 */{dataseat: 721, data_seatno: "s1-y-21-47", customer_id: "1750", name: "堀中 めい", mail: "", phone: "", studio: "西大路御池", class: "type_7"},
  /*722 */{dataseat: 722, data_seatno: "s1-y-21-48", customer_id: "1750", name: "堀中 めい", mail: "", phone: "", studio: "西大路御池", class: "type_7"},
  //////////// 22列目 ////////////
  /*723 */{dataseat: 723, data_seatno: "s1-y-22-1", customer_id: "1208", name: "森井 三杏", mail: "", phone: "", studio: "丹波橋", class: "type_6"},
  /*724 */{dataseat: 724, data_seatno: "s1-y-22-2", customer_id: "1208", name: "森井 三杏", mail: "", phone: "", studio: "丹波橋", class: "type_6"},
  /*725 */{dataseat: 725, data_seatno: "s1-y-22-3", customer_id: "1108", name: "水島 沙南", mail: "", phone: "", studio: "丹波橋", class: "type_6"},
  /*726 */{dataseat: 726, data_seatno: "s1-y-22-4", customer_id: "1108", name: "水島 沙南", mail: "", phone: "", studio: "丹波橋", class: "type_6"},
  /*727 */{dataseat: 727, data_seatno: "s1-y-22-5", customer_id: "1594", name: "松尾 杏夏", mail: "", phone: "", studio: "丹波橋", class: "type_6"},
  /*728 */{dataseat: 728, data_seatno: "s1-y-22-6", customer_id: "1594", name: "松尾 杏夏", mail: "", phone: "", studio: "丹波橋", class: "type_6"},
  /*729 */{dataseat: 729, data_seatno: "s1-y-22-8", customer_id: "1026", name: "西岡 和奏", mail: "", phone: "", studio: "山科", class: "type_8"},
  /*730 */{dataseat: 730, data_seatno: "s1-y-22-9", customer_id: "1026", name: "西岡 和奏", mail: "", phone: "", studio: "山科", class: "type_8"},
  /*731 */{dataseat: 731, data_seatno: "s1-y-22-10", customer_id: "1027", name: "山口 來珠", mail: "", phone: "", studio: "山科", class: "type_8"},
  /*732 */{dataseat: 732, data_seatno: "s1-y-22-11", customer_id: "1027", name: "山口 來珠", mail: "", phone: "", studio: "山科", class: "type_8"},
  /*733 */{dataseat: 733, data_seatno: "s1-y-22-12", customer_id: "1095", name: "万代 瑚波", mail: "", phone: "", studio: "山科", class: "type_8"},
  /*734 */{dataseat: 734, data_seatno: "s1-y-22-13", customer_id: "1095", name: "万代 瑚波", mail: "", phone: "", studio: "山科", class: "type_8"},
  /*735 */{dataseat: 735, data_seatno: "s1-y-22-14", customer_id: "1096", name: "松山 愛緒音", mail: "", phone: "", studio: "山科", class: "type_8"},
  /*736 */{dataseat: 736, data_seatno: "s1-y-22-15", customer_id: "1096", name: "松山 愛緒音", mail: "", phone: "", studio: "山科", class: "type_8"},
  /*737 */{dataseat: 737, data_seatno: "s1-y-22-16", customer_id: "1174", name: "萬田 芽唯", mail: "", phone: "", studio: "山科", class: "type_8"},
  /*738 */{dataseat: 738, data_seatno: "s1-y-22-17", customer_id: "1174", name: "萬田 芽唯", mail: "", phone: "", studio: "山科", class: "type_8"},
  /*739 */{dataseat: 739, data_seatno: "s1-y-22-19", customer_id: "", name: "CHIAYA", mail: "", phone: "", studio: "関係者1", class: "type_21"},
  /*740 */{dataseat: 740, data_seatno: "s1-y-22-20", customer_id: "", name: "CHIAYA", mail: "", phone: "", studio: "関係者1", class: "type_21"},
  /*741 */{dataseat: 741, data_seatno: "s1-y-22-21", customer_id: "", name: "YUKA", mail: "", phone: "", studio: "関係者1", class: "type_21"},
  /*742 */{dataseat: 742, data_seatno: "s1-y-22-22", customer_id: "", name: "YUKA_BABY", mail: "", phone: "", studio: "関係者1", class: "type_21"},
  /*743 */{dataseat: 743, data_seatno: "s1-y-22-23", customer_id: "", name: "HIKARI", mail: "", phone: "", studio: "関係者1", class: "type_21"},
  /*744 */{dataseat: 744, data_seatno: "s1-y-22-24", customer_id: "", name: "HIKARI", mail: "", phone: "", studio: "関係者1", class: "type_21"},
  /*745 */{dataseat: 745, data_seatno: "s1-y-22-25", customer_id: "", name: "", mail: "", phone: "", studio: "関係者1", class: "type_21"},
  /*746 */{dataseat: 746, data_seatno: "s1-y-22-26", customer_id: "", name: "", mail: "", phone: "", studio: "関係者1", class: "type_21"},
  /*747 */{dataseat: 747, data_seatno: "s1-y-22-27", customer_id: "", name: "", mail: "", phone: "", studio: "関係者1", class: "type_21"},
  /*748 */{dataseat: 748, data_seatno: "s1-y-22-28", customer_id: "", name: "", mail: "", phone: "", studio: "関係者1", class: "type_21"},
  /*749 */{dataseat: 749, data_seatno: "s1-y-22-29", customer_id: "", name: "", mail: "", phone: "", studio: "関係者1", class: "type_21"},
  /*750 */{dataseat: 750, data_seatno: "s1-y-22-30", customer_id: "", name: "", mail: "", phone: "", studio: "関係者1", class: "type_21"},
  /*751 */{dataseat: 751, data_seatno: "s1-y-22-32", customer_id: "1783", name: "井上 爽和", mail: "", phone: "", studio: "西大路御池", class: "type_7"},
  /*752 */{dataseat: 752, data_seatno: "s1-y-22-33", customer_id: "1783", name: "井上 爽和", mail: "", phone: "", studio: "西大路御池", class: "type_7"},
  /*753 */{dataseat: 753, data_seatno: "s1-y-22-34", customer_id: "1805", name: "松本 心乃", mail: "", phone: "", studio: "西大路御池", class: "type_7"},
  /*754 */{dataseat: 754, data_seatno: "s1-y-22-35", customer_id: "1805", name: "松本 心乃", mail: "", phone: "", studio: "西大路御池", class: "type_7"},
  /*755 */{dataseat: 755, data_seatno: "s1-y-22-36", customer_id: "1966", name: "小田 藍良", mail: "", phone: "", studio: "西大路御池", class: "type_7"},
  /*756 */{dataseat: 756, data_seatno: "s1-y-22-37", customer_id: "1966", name: "小田 藍良", mail: "", phone: "", studio: "西大路御池", class: "type_7"},
  /*757 */{dataseat: 757, data_seatno: "s1-y-22-38", customer_id: "1584", name: "河原 琉暖", mail: "", phone: "", studio: "桂", class: "type_9"},
  /*758 */{dataseat: 758, data_seatno: "s1-y-22-39", customer_id: "1584", name: "河原 琉暖", mail: "", phone: "", studio: "桂", class: "type_9"},
  /*759 */{dataseat: 759, data_seatno: "s1-y-22-40", customer_id: "", name: "河原絢葉", mail: "i.love-rui.runon@docomo.ne.jp", phone: "09054679173", studio: "桂", class: "type_9"},
  /*760 */{dataseat: 760, data_seatno: "s1-y-22-41", customer_id: "", name: "河原絢葉", mail: "i.love-rui.runon@docomo.ne.jp", phone: "09054679173", studio: "桂", class: "type_9"},
  /*761 */{dataseat: 761, data_seatno: "s1-y-22-43", customer_id: "1531", name: "豊岡 華那", mail: "", phone: "", studio: "桂", class: "type_9"},
  /*762 */{dataseat: 762, data_seatno: "s1-y-22-44", customer_id: "1531", name: "豊岡 華那", mail: "", phone: "", studio: "桂", class: "type_9"},
  /*763 */{dataseat: 763, data_seatno: "s1-y-22-45", customer_id: "1531", name: "豊岡 華那", mail: "", phone: "", studio: "桂", class: "type_9"},
  /*764 */{dataseat: 764, data_seatno: "s1-y-22-46", customer_id: "1531", name: "豊岡 華那", mail: "", phone: "", studio: "桂", class: "type_9"},
  /*765 */{dataseat: 765, data_seatno: "s1-y-22-47", customer_id: "1531", name: "豊岡 華那", mail: "", phone: "", studio: "桂", class: "type_9"},
  /*766 */{dataseat: 766, data_seatno: "s1-y-22-48", customer_id: "1531", name: "豊岡 華那", mail: "", phone: "", studio: "桂", class: "type_9"},
  //////////// 23列目 ////////////
  /*767 */{dataseat: 767, data_seatno: "s1-y-23-1", customer_id: "1476", name: "薮下 凛音", mail: "", phone: "", studio: "山科", class: "type_8"},
  /*768 */{dataseat: 768, data_seatno: "s1-y-23-2", customer_id: "1476", name: "薮下 凛音", mail: "", phone: "", studio: "山科", class: "type_8"},
  /*769 */{dataseat: 769, data_seatno: "s1-y-23-3", customer_id: "1476", name: "薮下 凛音", mail: "", phone: "", studio: "山科", class: "type_8"},
  /*770 */{dataseat: 770, data_seatno: "s1-y-23-4", customer_id: "1476", name: "薮下 凛音", mail: "", phone: "", studio: "山科", class: "type_8"},
  /*771 */{dataseat: 771, data_seatno: "s1-y-23-5", customer_id: "1792", name: "桑山 柚", mail: "", phone: "", studio: "山科", class: "type_8"},
  /*772 */{dataseat: 772, data_seatno: "s1-y-23-6", customer_id: "1792", name: "桑山 柚", mail: "", phone: "", studio: "山科", class: "type_8"},
  /*773 */{dataseat: 773, data_seatno: "s1-y-23-8", customer_id: "32", name: "松井 依采", mail: "", phone: "", studio: "山科", class: "type_8"},
  /*774 */{dataseat: 774, data_seatno: "s1-y-23-9", customer_id: "32", name: "松井 依采", mail: "", phone: "", studio: "山科", class: "type_8"},
  /*775 */{dataseat: 775, data_seatno: "s1-y-23-10", customer_id: "", name: "松井美涼", mail: "msm65e@ezweb.ne.jp", phone: "09062360605", studio: "山科", class: "type_8"},
  /*776 */{dataseat: 776, data_seatno: "s1-y-23-11", customer_id: "", name: "松井美涼", mail: "msm65e@ezweb.ne.jp", phone: "09062360605", studio: "山科", class: "type_8"},
  /*777 */{dataseat: 777, data_seatno: "s1-y-23-12", customer_id: "", name: "松井美涼", mail: "msm65e@ezweb.ne.jp", phone: "09062360605", studio: "山科", class: "type_8"},
  /*778 */{dataseat: 778, data_seatno: "s1-y-23-13", customer_id: "", name: "松井美涼", mail: "msm65e@ezweb.ne.jp", phone: "09062360605", studio: "山科", class: "type_8"},
  /*779 */{dataseat: 779, data_seatno: "s1-y-23-14", customer_id: "656", name: "野口 恋百合", mail: "", phone: "", studio: "山科", class: "type_8"},
  /*780 */{dataseat: 780, data_seatno: "s1-y-23-15", customer_id: "656", name: "野口 恋百合", mail: "", phone: "", studio: "山科", class: "type_8"},
  /*781 */{dataseat: 781, data_seatno: "s1-y-23-16", customer_id: "", name: "野口亜紗子", mail: "n_tas1127@softbank.ne.jp", phone: "08061808933", studio: "山科", class: "type_8"},
  /*782 */{dataseat: 782, data_seatno: "s1-y-23-17", customer_id: "", name: "野口亜紗子", mail: "n_tas1127@softbank.ne.jp", phone: "08061808933", studio: "山科", class: "type_8"},
  /*783 */{dataseat: 783, data_seatno: "s1-y-23-19", customer_id: "", name: "", mail: "", phone: "", studio: "関係者1", class: "type_21"},
  /*784 */{dataseat: 784, data_seatno: "s1-y-23-20", customer_id: "", name: "", mail: "", phone: "", studio: "関係者1", class: "type_21"},
  /*785 */{dataseat: 785, data_seatno: "s1-y-23-21", customer_id: "", name: "", mail: "", phone: "", studio: "関係者1", class: "type_21"},
  /*786 */{dataseat: 786, data_seatno: "s1-y-23-22", customer_id: "", name: "", mail: "", phone: "", studio: "関係者1", class: "type_21"},
  /*787 */{dataseat: 787, data_seatno: "s1-y-23-23", customer_id: "", name: "", mail: "", phone: "", studio: "関係者1", class: "type_21"},
  /*788 */{dataseat: 788, data_seatno: "s1-y-23-24", customer_id: "", name: "", mail: "", phone: "", studio: "関係者1", class: "type_21"},
  /*789 */{dataseat: 789, data_seatno: "s1-y-23-25", customer_id: "", name: "", mail: "", phone: "", studio: "関係者1", class: "type_21"},
  /*790 */{dataseat: 790, data_seatno: "s1-y-23-26", customer_id: "", name: "", mail: "", phone: "", studio: "関係者1", class: "type_21"},
  /*791 */{dataseat: 791, data_seatno: "s1-y-23-27", customer_id: "", name: "", mail: "", phone: "", studio: "関係者1", class: "type_21"},
  /*792 */{dataseat: 792, data_seatno: "s1-y-23-28", customer_id: "", name: "", mail: "", phone: "", studio: "関係者1", class: "type_21"},
  /*793 */{dataseat: 793, data_seatno: "s1-y-23-29", customer_id: "", name: "", mail: "", phone: "", studio: "関係者1", class: "type_21"},
  /*794 */{dataseat: 794, data_seatno: "s1-y-23-30", customer_id: "", name: "", mail: "", phone: "", studio: "関係者1", class: "type_21"},
  /*795 */{dataseat: 795, data_seatno: "s1-y-23-32", customer_id: "704", name: "辻 葵衣", mail: "", phone: "", studio: "桂", class: "type_9"},
  /*796 */{dataseat: 796, data_seatno: "s1-y-23-33", customer_id: "704", name: "辻 葵衣", mail: "", phone: "", studio: "桂", class: "type_9"},
  /*797 */{dataseat: 797, data_seatno: "s1-y-23-34", customer_id: "1329", name: "坂尻 颯乃", mail: "", phone: "", studio: "桂", class: "type_9"},
  /*798 */{dataseat: 798, data_seatno: "s1-y-23-35", customer_id: "1329", name: "坂尻 颯乃", mail: "", phone: "", studio: "桂", class: "type_9"},
  /*799 */{dataseat: 799, data_seatno: "s1-y-23-36", customer_id: "1333", name: "服部 新子", mail: "", phone: "", studio: "桂", class: "type_9"},
  /*800 */{dataseat: 800, data_seatno: "s1-y-23-37", customer_id: "1333", name: "服部 新子", mail: "", phone: "", studio: "桂", class: "type_9"},
  /*801 */{dataseat: 801, data_seatno: "s1-y-23-38", customer_id: "1333", name: "服部 新子", mail: "", phone: "", studio: "桂", class: "type_9"},
  /*802 */{dataseat: 802, data_seatno: "s1-y-23-39", customer_id: "1335", name: "檜森 結音", mail: "", phone: "", studio: "桂", class: "type_9"},
  /*803 */{dataseat: 803, data_seatno: "s1-y-23-40", customer_id: "1335", name: "檜森 結音", mail: "", phone: "", studio: "桂", class: "type_9"},
  /*804 */{dataseat: 804, data_seatno: "s1-y-23-41", customer_id: "1335", name: "檜森 結音", mail: "", phone: "", studio: "桂", class: "type_9"},
  /*805 */{dataseat: 805, data_seatno: "s1-y-23-43", customer_id: "1478", name: "妹尾 咲音", mail: "", phone: "", studio: "桂", class: "type_9"},
  /*806 */{dataseat: 806, data_seatno: "s1-y-23-44", customer_id: "1478", name: "妹尾 咲音", mail: "", phone: "", studio: "桂", class: "type_9"},
  /*807 */{dataseat: 807, data_seatno: "s1-y-23-45", customer_id: "1697", name: "佐野 瑚華", mail: "", phone: "", studio: "桂", class: "type_9"},
  /*808 */{dataseat: 808, data_seatno: "s1-y-23-46", customer_id: "1697", name: "佐野 瑚華", mail: "", phone: "", studio: "桂", class: "type_9"},
  /*809 */{dataseat: 809, data_seatno: "s1-y-23-47", customer_id: "1741", name: "小嵜 瑠絆愛", mail: "", phone: "", studio: "桂", class: "type_9"},
  /*810 */{dataseat: 810, data_seatno: "s1-y-23-48", customer_id: "1741", name: "小嵜 瑠絆愛", mail: "", phone: "", studio: "桂", class: "type_9"},
  //////////// 24列目 ////////////
  /*811 */{dataseat: 811, data_seatno: "s1-y-24-1", customer_id: "1319", name: "李 紗綾", mail: "", phone: "", studio: "山科", class: "type_8"},
  /*812 */{dataseat: 812, data_seatno: "s1-y-24-2", customer_id: "1319", name: "李 紗綾", mail: "", phone: "", studio: "山科", class: "type_8"},
  /*813 */{dataseat: 813, data_seatno: "s1-y-24-3", customer_id: "1319", name: "李 紗綾", mail: "", phone: "", studio: "山科", class: "type_8"},
  /*814 */{dataseat: 814, data_seatno: "s1-y-24-4", customer_id: "1319", name: "李 紗綾", mail: "", phone: "", studio: "山科", class: "type_8"},
  /*815 */{dataseat: 815, data_seatno: "s1-y-24-5", customer_id: "603", name: "沼田 美明希", mail: "", phone: "", studio: "山科", class: "type_8"},
  /*816 */{dataseat: 816, data_seatno: "s1-y-24-6", customer_id: "603", name: "沼田 美明希", mail: "", phone: "", studio: "山科", class: "type_8"},
  /*817 */{dataseat: 817, data_seatno: "s1-y-24-8", customer_id: "942", name: "北川 杏", mail: "", phone: "", studio: "山科", class: "type_8"},
  /*818 */{dataseat: 818, data_seatno: "s1-y-24-9", customer_id: "942", name: "北川 杏", mail: "", phone: "", studio: "山科", class: "type_8"},
  /*819 */{dataseat: 819, data_seatno: "s1-y-24-10", customer_id: "950", name: "沼田 彩希", mail: "", phone: "", studio: "山科", class: "type_8"},
  /*820 */{dataseat: 820, data_seatno: "s1-y-24-11", customer_id: "950", name: "沼田 彩希", mail: "", phone: "", studio: "山科", class: "type_8"},
  /*821 */{dataseat: 821, data_seatno: "s1-y-24-12", customer_id: "1074", name: "春田 迪花", mail: "", phone: "", studio: "山科", class: "type_8"},
  /*822 */{dataseat: 822, data_seatno: "s1-y-24-13", customer_id: "1074", name: "春田 迪花", mail: "", phone: "", studio: "山科", class: "type_8"},
  /*823 */{dataseat: 823, data_seatno: "s1-y-24-14", customer_id: "1818", name: "村上 愛海", mail: "", phone: "", studio: "山科", class: "type_8"},
  /*824 */{dataseat: 824, data_seatno: "s1-y-24-15", customer_id: "1818", name: "村上 愛海", mail: "", phone: "", studio: "山科", class: "type_8"},
  /*825 */{dataseat: 825, data_seatno: "s1-y-24-16", customer_id: "1912", name: "八木 寧音", mail: "", phone: "", studio: "山科", class: "type_8"},
  /*826 */{dataseat: 826, data_seatno: "s1-y-24-17", customer_id: "1912", name: "八木 寧音", mail: "", phone: "", studio: "山科", class: "type_8"},
  /*827 */{dataseat: 827, data_seatno: "s1-y-24-19", customer_id: "", name: "", mail: "", phone: "", studio: "PA席", class: "type_1"},
  /*828 */{dataseat: 828, data_seatno: "s1-y-24-20", customer_id: "", name: "", mail: "", phone: "", studio: "PA席", class: "type_1"},
  /*829 */{dataseat: 829, data_seatno: "s1-y-24-21", customer_id: "", name: "", mail: "", phone: "", studio: "PA席", class: "type_1"},
  /*830 */{dataseat: 830, data_seatno: "s1-y-24-22", customer_id: "", name: "", mail: "", phone: "", studio: "PA席", class: "type_1"},
  /*831 */{dataseat: 831, data_seatno: "s1-y-24-23", customer_id: "", name: "", mail: "", phone: "", studio: "PA席", class: "type_1"},
  /*832 */{dataseat: 832, data_seatno: "s1-y-24-24", customer_id: "", name: "", mail: "", phone: "", studio: "PA席", class: "type_1"},
  /*833 */{dataseat: 833, data_seatno: "s1-y-24-25", customer_id: "", name: "", mail: "", phone: "", studio: "PA席", class: "type_1"},
  /*834 */{dataseat: 834, data_seatno: "s1-y-24-26", customer_id: "", name: "", mail: "", phone: "", studio: "PA席", class: "type_1"},
  /*835 */{dataseat: 835, data_seatno: "s1-y-24-27", customer_id: "", name: "", mail: "", phone: "", studio: "PA席", class: "type_1"},
  /*836 */{dataseat: 836, data_seatno: "s1-y-24-28", customer_id: "", name: "", mail: "", phone: "", studio: "PA席", class: "type_1"},
  /*837 */{dataseat: 837, data_seatno: "s1-y-24-29", customer_id: "", name: "", mail: "", phone: "", studio: "PA席", class: "type_1"},
  /*838 */{dataseat: 838, data_seatno: "s1-y-24-30", customer_id: "", name: "", mail: "", phone: "", studio: "PA席", class: "type_1"},
  /*839 */{dataseat: 839, data_seatno: "s1-y-24-32", customer_id: "1341", name: "新井 秋", mail: "", phone: "", studio: "桂", class: "type_9"},
  /*840 */{dataseat: 840, data_seatno: "s1-y-24-33", customer_id: "1341", name: "新井 秋", mail: "", phone: "", studio: "桂", class: "type_9"},
  /*841 */{dataseat: 841, data_seatno: "s1-y-24-34", customer_id: "1341", name: "新井 秋", mail: "", phone: "", studio: "桂", class: "type_9"},
  /*842 */{dataseat: 842, data_seatno: "s1-y-24-35", customer_id: "1341", name: "新井 秋", mail: "", phone: "", studio: "桂", class: "type_9"},
  /*843 */{dataseat: 843, data_seatno: "s1-y-24-36", customer_id: "1358", name: "林 純麗", mail: "", phone: "", studio: "桂", class: "type_9"},
  /*844 */{dataseat: 844, data_seatno: "s1-y-24-37", customer_id: "1358", name: "林 純麗", mail: "", phone: "", studio: "桂", class: "type_9"},
  /*845 */{dataseat: 845, data_seatno: "s1-y-24-38", customer_id: "1358", name: "林 純麗", mail: "", phone: "", studio: "桂", class: "type_9"},
  /*846 */{dataseat: 846, data_seatno: "s1-y-24-39", customer_id: "1358", name: "林 純麗", mail: "", phone: "", studio: "桂", class: "type_9"},
  /*847 */{dataseat: 847, data_seatno: "s1-y-24-40", customer_id: "1347", name: "春山 世吏", mail: "", phone: "", studio: "桂", class: "type_9"},
  /*848 */{dataseat: 848, data_seatno: "s1-y-24-41", customer_id: "1347", name: "春山 世吏", mail: "", phone: "", studio: "桂", class: "type_9"},
  /*849 */{dataseat: 849, data_seatno: "s1-y-24-43", customer_id: "", name: "", mail: "", phone: "", studio: "", class: "type_99"},
  /*850 */{dataseat: 850, data_seatno: "s1-y-24-44", customer_id: "", name: "", mail: "", phone: "", studio: "", class: "type_99"},
  /*851 */{dataseat: 851, data_seatno: "s1-y-24-45", customer_id: "", name: "", mail: "", phone: "", studio: "", class: "type_99"},
  /*852 */{dataseat: 852, data_seatno: "s1-y-24-46", customer_id: "", name: "", mail: "", phone: "", studio: "", class: "type_99"},
  /*853 */{dataseat: 853, data_seatno: "s1-y-24-47", customer_id: "", name: "", mail: "", phone: "", studio: "", class: "type_99"},
  /*854 */{dataseat: 854, data_seatno: "s1-y-24-48", customer_id: "", name: "", mail: "", phone: "", studio: "", class: "type_99"},
  //////////// 25列目 ////////////
  /*855 */{dataseat: 855, data_seatno: "s1-y-25-1", customer_id: "", name: "", mail: "", phone: "", studio: "", class: "type_99"},
  /*856 */{dataseat: 856, data_seatno: "s1-y-25-2", customer_id: "", name: "", mail: "", phone: "", studio: "", class: "type_99"},
  /*857 */{dataseat: 857, data_seatno: "s1-y-25-3", customer_id: "", name: "", mail: "", phone: "", studio: "", class: "type_99"},
  /*858 */{dataseat: 858, data_seatno: "s1-y-25-4", customer_id: "", name: "", mail: "", phone: "", studio: "", class: "type_99"},
  /*859 */{dataseat: 859, data_seatno: "s1-y-25-5", customer_id: "1916", name: "小西 杏奈", mail: "", phone: "", studio: "山科", class: "type_8"},
  /*860 */{dataseat: 860, data_seatno: "s1-y-25-6", customer_id: "1916", name: "小西 杏奈", mail: "", phone: "", studio: "山科", class: "type_8"},
  /*861 */{dataseat: 861, data_seatno: "s1-y-25-8", customer_id: "", name: "", mail: "", phone: "", studio: "", class: "type_99"},
  /*862 */{dataseat: 862, data_seatno: "s1-y-25-9", customer_id: "1636", name: "柴田 栞奈", mail: "", phone: "", studio: "山科", class: "type_8"},
  /*863 */{dataseat: 863, data_seatno: "s1-y-25-10", customer_id: "1636", name: "柴田 栞奈", mail: "", phone: "", studio: "山科", class: "type_8"},
  /*864 */{dataseat: 864, data_seatno: "s1-y-25-11", customer_id: "1636", name: "柴田 栞奈", mail: "", phone: "", studio: "山科", class: "type_8"},
  /*865 */{dataseat: 865, data_seatno: "s1-y-25-12", customer_id: "1254", name: "田中 愛唯", mail: "", phone: "", studio: "山科", class: "type_8"},
  /*866 */{dataseat: 866, data_seatno: "s1-y-25-13", customer_id: "1254", name: "田中 愛唯", mail: "", phone: "", studio: "山科", class: "type_8"},
  /*867 */{dataseat: 867, data_seatno: "s1-y-25-14", customer_id: "1254", name: "田中 愛唯", mail: "", phone: "", studio: "山科", class: "type_8"},
  /*868 */{dataseat: 868, data_seatno: "s1-y-25-15", customer_id: "703", name: "島田 いろは", mail: "", phone: "", studio: "山科", class: "type_8"},
  /*869 */{dataseat: 869, data_seatno: "s1-y-25-16", customer_id: "703", name: "島田 いろは", mail: "", phone: "", studio: "山科", class: "type_8"},
  /*870 */{dataseat: 870, data_seatno: "s1-y-25-17", customer_id: "703", name: "島田 いろは", mail: "", phone: "", studio: "山科", class: "type_8"},
  /*871 */{dataseat: 871, data_seatno: "s1-y-25-19", customer_id: "", name: "", mail: "", phone: "", studio: "PA席", class: "type_1"},
  /*872 */{dataseat: 872, data_seatno: "s1-y-25-20", customer_id: "", name: "", mail: "", phone: "", studio: "PA席", class: "type_1"},
  /*873 */{dataseat: 873, data_seatno: "s1-y-25-21", customer_id: "", name: "", mail: "", phone: "", studio: "PA席", class: "type_1"},
  /*874 */{dataseat: 874, data_seatno: "s1-y-25-22", customer_id: "", name: "", mail: "", phone: "", studio: "PA席", class: "type_1"},
  /*875 */{dataseat: 875, data_seatno: "s1-y-25-23", customer_id: "", name: "", mail: "", phone: "", studio: "PA席", class: "type_1"},
  /*876 */{dataseat: 876, data_seatno: "s1-y-25-24", customer_id: "", name: "", mail: "", phone: "", studio: "PA席", class: "type_1"},
  /*877 */{dataseat: 877, data_seatno: "s1-y-25-25", customer_id: "", name: "", mail: "", phone: "", studio: "PA席", class: "type_1"},
  /*878 */{dataseat: 878, data_seatno: "s1-y-25-26", customer_id: "", name: "", mail: "", phone: "", studio: "PA席", class: "type_1"},
  /*879 */{dataseat: 879, data_seatno: "s1-y-25-27", customer_id: "", name: "", mail: "", phone: "", studio: "PA席", class: "type_1"},
  /*880 */{dataseat: 880, data_seatno: "s1-y-25-28", customer_id: "", name: "", mail: "", phone: "", studio: "PA席", class: "type_1"},
  /*881 */{dataseat: 881, data_seatno: "s1-y-25-29", customer_id: "", name: "", mail: "", phone: "", studio: "PA席", class: "type_1"},
  /*882 */{dataseat: 882, data_seatno: "s1-y-25-30", customer_id: "", name: "", mail: "", phone: "", studio: "PA席", class: "type_1"},
  /*883 */{dataseat: 883, data_seatno: "s1-y-25-32", customer_id: "1769", name: "岡本 侑里香", mail: "", phone: "", studio: "桂", class: "type_9"},
  /*884 */{dataseat: 884, data_seatno: "s1-y-25-33", customer_id: "1769", name: "岡本 侑里香", mail: "", phone: "", studio: "桂", class: "type_9"},
  /*885 */{dataseat: 885, data_seatno: "s1-y-25-34", customer_id: "1330", name: "山本 奈琉", mail: "", phone: "", studio: "桂", class: "type_9"},
  /*886 */{dataseat: 886, data_seatno: "s1-y-25-35", customer_id: "1330", name: "山本 奈琉", mail: "", phone: "", studio: "桂", class: "type_9"},
  /*887 */{dataseat: 887, data_seatno: "s1-y-25-36", customer_id: "1743", name: "村井 美蘭", mail: "", phone: "", studio: "桂", class: "type_9"},
  /*888 */{dataseat: 888, data_seatno: "s1-y-25-37", customer_id: "1743", name: "村井 美蘭", mail: "", phone: "", studio: "桂", class: "type_9"},
  /*889 */{dataseat: 889, data_seatno: "s1-y-25-38", customer_id: "1943", name: "藤原 乃空", mail: "", phone: "", studio: "桂", class: "type_9"},
  /*890 */{dataseat: 890, data_seatno: "s1-y-25-39", customer_id: "1943", name: "藤原 乃空", mail: "", phone: "", studio: "桂", class: "type_9"},
  /*891 */{dataseat: 891, data_seatno: "s1-y-25-40", customer_id: "1979", name: "水山 ヨナ", mail: "", phone: "", studio: "桂", class: "type_9"},
  /*892 */{dataseat: 892, data_seatno: "s1-y-25-41", customer_id: "1979", name: "水山 ヨナ", mail: "", phone: "", studio: "桂", class: "type_9"},
  /*893 */{dataseat: 893, data_seatno: "s1-y-25-43", customer_id: "", name: "", mail: "", phone: "", studio: "", class: "type_99"},
  /*894 */{dataseat: 894, data_seatno: "s1-y-25-44", customer_id: "", name: "", mail: "", phone: "", studio: "", class: "type_99"},
  /*895 */{dataseat: 895, data_seatno: "s1-y-25-45", customer_id: "", name: "", mail: "", phone: "", studio: "", class: "type_99"},
  /*896 */{dataseat: 896, data_seatno: "s1-y-25-46", customer_id: "", name: "", mail: "", phone: "", studio: "", class: "type_99"},
  /*897 */{dataseat: 897, data_seatno: "s1-y-25-47", customer_id: "", name: "", mail: "", phone: "", studio: "", class: "type_99"},
  /*898 */{dataseat: 898, data_seatno: "s1-y-25-48", customer_id: "", name: "", mail: "", phone: "", studio: "", class: "type_99"},
];

export const SECTION2_SEATS = [
  //////////// 1列目 ////////////
  /*899 */{dataseat:899, data_seatno: "s2-y-1-10", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席2", class: "type_1"},
  /*900 */{dataseat:900, data_seatno: "s2-y-1-11", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席2", class: "type_1"},
  /*901 */{dataseat:901, data_seatno: "s2-y-1-12", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席2", class: "type_1"},
  /*902 */{dataseat:902, data_seatno: "s2-y-1-13", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席2", class: "type_1"},
  /*903 */{dataseat:903, data_seatno: "s2-y-1-14", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席2", class: "type_1"},
  /*904 */{dataseat:904, data_seatno: "s2-y-1-15", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席2", class: "type_1"},
  /*905 */{dataseat:905, data_seatno: "s2-y-1-16", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席2", class: "type_1"},
  /*906 */{dataseat:906, data_seatno: "s2-y-1-17", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席2", class: "type_1"},
  /*907 */{dataseat:907, data_seatno: "s2-y-1-19", customer_id: "", name: "", mail: "", phone: "", studio: "カメラ", class: "type_98"},
  /*908 */{dataseat:908, data_seatno: "s2-y-1-20", customer_id: "", name: "", mail: "", phone: "", studio: "カメラ", class: "type_98"},
  /*909 */{dataseat:909, data_seatno: "s2-y-1-21", customer_id: "", name: "", mail: "", phone: "", studio: "カメラ", class: "type_98"},
  /*910 */{dataseat:910, data_seatno: "s2-y-1-22", customer_id: "", name: "", mail: "", phone: "", studio: "カメラ", class: "type_98"},
  /*911 */{dataseat:911, data_seatno: "s2-y-1-23", customer_id: "", name: "", mail: "", phone: "", studio: "カメラ", class: "type_98"},
  /*912 */{dataseat:912, data_seatno: "s2-y-1-24", customer_id: "", name: "", mail: "", phone: "", studio: "カメラ", class: "type_98"},
  /*913 */{dataseat:913, data_seatno: "s2-y-1-25", customer_id: "", name: "", mail: "", phone: "", studio: "カメラ", class: "type_98"},
  /*914 */{dataseat:914, data_seatno: "s2-y-1-26", customer_id: "", name: "", mail: "", phone: "", studio: "カメラ", class: "type_98"},
  /*915 */{dataseat:915, data_seatno: "s2-y-1-27", customer_id: "", name: "", mail: "", phone: "", studio: "カメラ", class: "type_98"},
  /*916 */{dataseat:916, data_seatno: "s2-y-1-28", customer_id: "", name: "", mail: "", phone: "", studio: "カメラ", class: "type_98"},
  /*917 */{dataseat:917, data_seatno: "s2-y-1-29", customer_id: "", name: "", mail: "", phone: "", studio: "カメラ", class: "type_98"},
  /*918 */{dataseat:918, data_seatno: "s2-y-1-30", customer_id: "", name: "", mail: "", phone: "", studio: "カメラ", class: "type_98"},
  /*919 */{dataseat:919, data_seatno: "s2-y-1-32", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席2", class: "type_1"},
  /*920 */{dataseat:920, data_seatno: "s2-y-1-33", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席2", class: "type_1"},
  /*921 */{dataseat:921, data_seatno: "s2-y-1-34", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席2", class: "type_1"},
  /*922 */{dataseat:922, data_seatno: "s2-y-1-35", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席2", class: "type_1"},
  /*923 */{dataseat:923, data_seatno: "s2-y-1-36", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席2", class: "type_1"},
  /*924 */{dataseat:924, data_seatno: "s2-y-1-37", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席2", class: "type_1"},
  /*925 */{dataseat:925, data_seatno: "s2-y-1-38", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席2", class: "type_1"},
  /*926 */{dataseat:926, data_seatno: "s2-y-1-39", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席2", class: "type_1"},
  //////////// 2列目 ////////////
  /*927 */{dataseat:927, data_seatno: "s2-y-2-9", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席2", class: "type_1"},
  /*928 */{dataseat:928, data_seatno: "s2-y-2-10", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席2", class: "type_1"},
  /*929 */{dataseat:929, data_seatno: "s2-y-2-11", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席2", class: "type_1"},
  /*930 */{dataseat:930, data_seatno: "s2-y-2-12", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席2", class: "type_1"},
  /*931 */{dataseat:931, data_seatno: "s2-y-2-13", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席2", class: "type_1"},
  /*932 */{dataseat:932, data_seatno: "s2-y-2-14", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席2", class: "type_1"},
  /*933 */{dataseat:933, data_seatno: "s2-y-2-15", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席2", class: "type_1"},
  /*934 */{dataseat:934, data_seatno: "s2-y-2-16", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席2", class: "type_1"},
  /*935 */{dataseat:935, data_seatno: "s2-y-2-17", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席2", class: "type_1"},
  /*936 */{dataseat:936, data_seatno: "s2-y-2-19", customer_id: "", name: "", mail: "", phone: "", studio: "カメラ", class: "type_98"},
  /*937 */{dataseat:937, data_seatno: "s2-y-2-20", customer_id: "", name: "", mail: "", phone: "", studio: "カメラ", class: "type_98"},
  /*938 */{dataseat:938, data_seatno: "s2-y-2-21", customer_id: "", name: "", mail: "", phone: "", studio: "カメラ", class: "type_98"},
  /*939 */{dataseat:939, data_seatno: "s2-y-2-22", customer_id: "", name: "", mail: "", phone: "", studio: "カメラ", class: "type_98"},
  /*940 */{dataseat:940, data_seatno: "s2-y-2-23", customer_id: "", name: "", mail: "", phone: "", studio: "カメラ", class: "type_98"},
  /*941 */{dataseat:941, data_seatno: "s2-y-2-24", customer_id: "", name: "", mail: "", phone: "", studio: "カメラ", class: "type_98"},
  /*942 */{dataseat:942, data_seatno: "s2-y-2-25", customer_id: "", name: "", mail: "", phone: "", studio: "カメラ", class: "type_98"},
  /*943 */{dataseat:943, data_seatno: "s2-y-2-26", customer_id: "", name: "", mail: "", phone: "", studio: "カメラ", class: "type_98"},
  /*944 */{dataseat:944, data_seatno: "s2-y-2-27", customer_id: "", name: "", mail: "", phone: "", studio: "カメラ", class: "type_98"},
  /*945 */{dataseat:945, data_seatno: "s2-y-2-28", customer_id: "", name: "", mail: "", phone: "", studio: "カメラ", class: "type_98"},
  /*946 */{dataseat:946, data_seatno: "s2-y-2-29", customer_id: "", name: "", mail: "", phone: "", studio: "カメラ", class: "type_98"},
  /*947 */{dataseat:947, data_seatno: "s2-y-2-30", customer_id: "", name: "", mail: "", phone: "", studio: "カメラ", class: "type_98"},
  /*948 */{dataseat:948, data_seatno: "s2-y-2-32", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席2", class: "type_1"},
  /*949 */{dataseat:949, data_seatno: "s2-y-2-33", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席2", class: "type_1"},
  /*950 */{dataseat:950, data_seatno: "s2-y-2-34", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席2", class: "type_1"},
  /*951 */{dataseat:951, data_seatno: "s2-y-2-35", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席2", class: "type_1"},
  /*952 */{dataseat:952, data_seatno: "s2-y-2-36", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席2", class: "type_1"},
  /*953 */{dataseat:953, data_seatno: "s2-y-2-37", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席2", class: "type_1"},
  /*954 */{dataseat:954, data_seatno: "s2-y-2-38", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席2", class: "type_1"},
  /*955 */{dataseat:955, data_seatno: "s2-y-2-39", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席2", class: "type_1"},
  /*956 */{dataseat:956, data_seatno: "s2-y-2-40", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席2", class: "type_1"},
  //////////// 3列目 ////////////
  /*957 */{dataseat:957, data_seatno: "s2-y-3-8", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席2", class: "type_1"},
  /*958 */{dataseat:958, data_seatno: "s2-y-3-9", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席2", class: "type_1"},
  /*959 */{dataseat:959, data_seatno: "s2-y-3-10", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席2", class: "type_1"},
  /*960 */{dataseat:960, data_seatno: "s2-y-3-11", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席2", class: "type_1"},
  /*961 */{dataseat:961, data_seatno: "s2-y-3-12", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席2", class: "type_1"},
  /*962 */{dataseat:962, data_seatno: "s2-y-3-13", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席2", class: "type_1"},
  /*963 */{dataseat:963, data_seatno: "s2-y-3-14", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席2", class: "type_1"},
  /*964 */{dataseat:964, data_seatno: "s2-y-3-15", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席2", class: "type_1"},
  /*965 */{dataseat:965, data_seatno: "s2-y-3-16", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席2", class: "type_1"},
  /*966 */{dataseat:966, data_seatno: "s2-y-3-17", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席2", class: "type_1"},
  /*967 */{dataseat:967, data_seatno: "s2-y-3-19", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席2", class: "type_1"},
  /*968 */{dataseat:968, data_seatno: "s2-y-3-20", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席2", class: "type_1"},
  /*969 */{dataseat:969, data_seatno: "s2-y-3-21", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席2", class: "type_1"},
  /*970 */{dataseat:970, data_seatno: "s2-y-3-22", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席2", class: "type_1"},
  /*971 */{dataseat:971, data_seatno: "s2-y-3-23", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席2", class: "type_1"},
  /*972 */{dataseat:972, data_seatno: "s2-y-3-24", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席2", class: "type_1"},
  /*973 */{dataseat:973, data_seatno: "s2-y-3-25", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席2", class: "type_1"},
  /*974 */{dataseat:974, data_seatno: "s2-y-3-26", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席2", class: "type_1"},
  /*975 */{dataseat:975, data_seatno: "s2-y-3-27", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席2", class: "type_1"},
  /*976 */{dataseat:976, data_seatno: "s2-y-3-28", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席2", class: "type_1"},
  /*977 */{dataseat:977, data_seatno: "s2-y-3-29", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席2", class: "type_1"},
  /*978 */{dataseat:978, data_seatno: "s2-y-3-30", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席2", class: "type_1"},
  /*979 */{dataseat:979, data_seatno: "s2-y-3-32", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席2", class: "type_1"},
  /*980 */{dataseat:980, data_seatno: "s2-y-3-33", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席2", class: "type_1"},
  /*981 */{dataseat:981, data_seatno: "s2-y-3-34", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席2", class: "type_1"},
  /*982 */{dataseat:982, data_seatno: "s2-y-3-35", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席2", class: "type_1"},
  /*983 */{dataseat:983, data_seatno: "s2-y-3-36", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席2", class: "type_1"},
  /*984 */{dataseat:984, data_seatno: "s2-y-3-37", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席2", class: "type_1"},
  /*985 */{dataseat:985, data_seatno: "s2-y-3-38", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席2", class: "type_1"},
  /*986 */{dataseat:986, data_seatno: "s2-y-3-39", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席2", class: "type_1"},
  /*987 */{dataseat:987, data_seatno: "s2-y-3-40", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席2", class: "type_1"},
  /*988 */{dataseat:988, data_seatno: "s2-y-3-41", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席2", class: "type_1"},
  //////////// 4列目 ////////////
  /*989 */{dataseat:989, data_seatno: "s2-y-4-8", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席2", class: "type_1"},
  /*990 */{dataseat:990, data_seatno: "s2-y-4-9", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席2", class: "type_1"},
  /*991 */{dataseat:991, data_seatno: "s2-y-4-10", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席2", class: "type_1"},
  /*992 */{dataseat:992, data_seatno: "s2-y-4-11", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席2", class: "type_1"},
  /*993 */{dataseat:993, data_seatno: "s2-y-4-12", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席2", class: "type_1"},
  /*994 */{dataseat:994, data_seatno: "s2-y-4-13", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席2", class: "type_1"},
  /*995 */{dataseat:995, data_seatno: "s2-y-4-14", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席2", class: "type_1"},
  /*996 */{dataseat:996, data_seatno: "s2-y-4-15", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席2", class: "type_1"},
  /*997 */{dataseat:997, data_seatno: "s2-y-4-16", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席2", class: "type_1"},
  /*998 */{dataseat:998, data_seatno: "s2-y-4-17", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席2", class: "type_1"},
  /*999 */{dataseat:999, data_seatno: "s2-y-4-19", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席2", class: "type_1"},
  /*1000 */{dataseat:1000, data_seatno: "s2-y-4-20", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席2", class: "type_1"},
  /*1001 */{dataseat:1001, data_seatno: "s2-y-4-21", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席2", class: "type_1"},
  /*1002 */{dataseat:1002, data_seatno: "s2-y-4-22", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席2", class: "type_1"},
  /*1003 */{dataseat:1003, data_seatno: "s2-y-4-23", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席2", class: "type_1"},
  /*1004 */{dataseat:1004, data_seatno: "s2-y-4-24", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席2", class: "type_1"},
  /*1005 */{dataseat:1005, data_seatno: "s2-y-4-25", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席2", class: "type_1"},
  /*1006 */{dataseat:1006, data_seatno: "s2-y-4-26", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席2", class: "type_1"},
  /*1007 */{dataseat:1007, data_seatno: "s2-y-4-27", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席2", class: "type_1"},
  /*1008 */{dataseat:1008, data_seatno: "s2-y-4-28", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席2", class: "type_1"},
  /*1009 */{dataseat:1009, data_seatno: "s2-y-4-29", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席2", class: "type_1"},
  /*1010 */{dataseat:1010, data_seatno: "s2-y-4-30", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席2", class: "type_1"},
  /*1011 */{dataseat:1011, data_seatno: "s2-y-4-32", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席2", class: "type_1"},
  /*1012 */{dataseat:1012, data_seatno: "s2-y-4-33", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席2", class: "type_1"},
  /*1013 */{dataseat:1013, data_seatno: "s2-y-4-34", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席2", class: "type_1"},
  /*1014 */{dataseat:1014, data_seatno: "s2-y-4-35", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席2", class: "type_1"},
  /*1015 */{dataseat:1015, data_seatno: "s2-y-4-36", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席2", class: "type_1"},
  /*1016 */{dataseat:1016, data_seatno: "s2-y-4-37", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席2", class: "type_1"},
  /*1017 */{dataseat:1017, data_seatno: "s2-y-4-38", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席2", class: "type_1"},
  /*1018 */{dataseat:1018, data_seatno: "s2-y-4-39", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席2", class: "type_1"},
  /*1019 */{dataseat:1019, data_seatno: "s2-y-4-40", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席2", class: "type_1"},
  /*1020 */{dataseat:1020, data_seatno: "s2-y-4-41", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席2", class: "type_1"},
  //////////// 5列目 ////////////
  /*1021 */{dataseat:1021, data_seatno: "s2-y-5-7", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席2", class: "type_1"},
  /*1022 */{dataseat:1022, data_seatno: "s2-y-5-8", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席2", class: "type_1"},
  /*1023 */{dataseat:1023, data_seatno: "s2-y-5-9", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席2", class: "type_1"},
  /*1024 */{dataseat:1024, data_seatno: "s2-y-5-10", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席2", class: "type_1"},
  /*1025 */{dataseat:1025, data_seatno: "s2-y-5-11", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席2", class: "type_1"},
  /*1026 */{dataseat:1026, data_seatno: "s2-y-5-12", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席2", class: "type_1"},
  /*1027 */{dataseat:1027, data_seatno: "s2-y-5-13", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席2", class: "type_1"},
  /*1028 */{dataseat:1028, data_seatno: "s2-y-5-14", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席2", class: "type_1"},
  /*1029 */{dataseat:1029, data_seatno: "s2-y-5-15", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席2", class: "type_1"},
  /*1030 */{dataseat:1030, data_seatno: "s2-y-5-16", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席2", class: "type_1"},
  /*1031 */{dataseat:1031, data_seatno: "s2-y-5-17", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席2", class: "type_1"},
  /*1032 */{dataseat:1032, data_seatno: "s2-y-5-19", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席2", class: "type_1"},
  /*1033 */{dataseat:1033, data_seatno: "s2-y-5-20", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席2", class: "type_1"},
  /*1034 */{dataseat:1034, data_seatno: "s2-y-5-21", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席2", class: "type_1"},
  /*1035 */{dataseat:1035, data_seatno: "s2-y-5-22", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席2", class: "type_1"},
  /*1036 */{dataseat:1036, data_seatno: "s2-y-5-23", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席2", class: "type_1"},
  /*1037 */{dataseat:1037, data_seatno: "s2-y-5-24", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席2", class: "type_1"},
  /*1038 */{dataseat:1038, data_seatno: "s2-y-5-25", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席2", class: "type_1"},
  /*1039 */{dataseat:1039, data_seatno: "s2-y-5-26", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席2", class: "type_1"},
  /*1040 */{dataseat:1040, data_seatno: "s2-y-5-27", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席2", class: "type_1"},
  /*1041 */{dataseat:1041, data_seatno: "s2-y-5-28", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席2", class: "type_1"},
  /*1042 */{dataseat:1042, data_seatno: "s2-y-5-29", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席2", class: "type_1"},
  /*1043 */{dataseat:1043, data_seatno: "s2-y-5-30", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席2", class: "type_1"},
  /*1044 */{dataseat:1044, data_seatno: "s2-y-5-32", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席2", class: "type_1"},
  /*1045 */{dataseat:1045, data_seatno: "s2-y-5-33", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席2", class: "type_1"},
  /*1046 */{dataseat:1046, data_seatno: "s2-y-5-34", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席2", class: "type_1"},
  /*1047 */{dataseat:1047, data_seatno: "s2-y-5-35", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席2", class: "type_1"},
  /*1048 */{dataseat:1048, data_seatno: "s2-y-5-36", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席2", class: "type_1"},
  /*1049 */{dataseat:1049, data_seatno: "s2-y-5-37", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席2", class: "type_1"},
  /*1050 */{dataseat:1050, data_seatno: "s2-y-5-38", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席2", class: "type_1"},
  /*1051 */{dataseat:1051, data_seatno: "s2-y-5-39", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席2", class: "type_1"},
  /*1052 */{dataseat:1052, data_seatno: "s2-y-5-40", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席2", class: "type_1"},
  /*1053 */{dataseat:1053, data_seatno: "s2-y-5-41", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席2", class: "type_1"},
  /*1054 */{dataseat:1054, data_seatno: "s2-y-5-42", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席2", class: "type_1"},
  //////////// 6列目 ////////////
  /*1055 */{dataseat:1055, data_seatno: "s2-y-6-6", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席2", class: "type_1"},
  /*1056 */{dataseat:1056, data_seatno: "s2-y-6-7", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席2", class: "type_1"},
  /*1057 */{dataseat:1057, data_seatno: "s2-y-6-8", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席2", class: "type_1"},
  /*1058 */{dataseat:1058, data_seatno: "s2-y-6-9", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席2", class: "type_1"},
  /*1059 */{dataseat:1059, data_seatno: "s2-y-6-10", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席2", class: "type_1"},
  /*1060 */{dataseat:1060, data_seatno: "s2-y-6-11", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席2", class: "type_1"},
  /*1061 */{dataseat:1061, data_seatno: "s2-y-6-12", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席2", class: "type_1"},
  /*1062 */{dataseat:1062, data_seatno: "s2-y-6-13", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席2", class: "type_1"},
  /*1063 */{dataseat:1063, data_seatno: "s2-y-6-14", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席2", class: "type_1"},
  /*1064 */{dataseat:1064, data_seatno: "s2-y-6-15", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席2", class: "type_1"},
  /*1065 */{dataseat:1065, data_seatno: "s2-y-6-16", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席2", class: "type_1"},
  /*1066 */{dataseat:1066, data_seatno: "s2-y-6-17", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席2", class: "type_1"},
  /*1067 */{dataseat:1067, data_seatno: "s2-y-6-19", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席2", class: "type_1"},
  /*1068 */{dataseat:1068, data_seatno: "s2-y-6-20", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席2", class: "type_1"},
  /*1069 */{dataseat:1069, data_seatno: "s2-y-6-21", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席2", class: "type_1"},
  /*1070 */{dataseat:1070, data_seatno: "s2-y-6-22", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席2", class: "type_1"},
  /*1071 */{dataseat:1071, data_seatno: "s2-y-6-23", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席2", class: "type_1"},
  /*1072 */{dataseat:1072, data_seatno: "s2-y-6-24", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席2", class: "type_1"},
  /*1073 */{dataseat:1073, data_seatno: "s2-y-6-25", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席2", class: "type_1"},
  /*1074 */{dataseat:1074, data_seatno: "s2-y-6-26", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席2", class: "type_1"},
  /*1075 */{dataseat:1075, data_seatno: "s2-y-6-27", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席2", class: "type_1"},
  /*1076 */{dataseat:1076, data_seatno: "s2-y-6-28", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席2", class: "type_1"},
  /*1077 */{dataseat:1077, data_seatno: "s2-y-6-29", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席2", class: "type_1"},
  /*1078 */{dataseat:1078, data_seatno: "s2-y-6-30", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席2", class: "type_1"},
  /*1079 */{dataseat:1079, data_seatno: "s2-y-6-32", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席2", class: "type_1"},
  /*1080 */{dataseat:1080, data_seatno: "s2-y-6-33", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席2", class: "type_1"},
  /*1081 */{dataseat:1081, data_seatno: "s2-y-6-34", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席2", class: "type_1"},
  /*1082 */{dataseat:1082, data_seatno: "s2-y-6-35", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席2", class: "type_1"},
  /*1083 */{dataseat:1083, data_seatno: "s2-y-6-36", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席2", class: "type_1"},
  /*1084 */{dataseat:1084, data_seatno: "s2-y-6-37", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席2", class: "type_1"},
  /*1085 */{dataseat:1085, data_seatno: "s2-y-6-38", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席2", class: "type_1"},
  /*1086 */{dataseat:1086, data_seatno: "s2-y-6-39", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席2", class: "type_1"},
  /*1087 */{dataseat:1087, data_seatno: "s2-y-6-40", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席2", class: "type_1"},
  /*1088 */{dataseat:1088, data_seatno: "s2-y-6-41", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席2", class: "type_1"},
  /*1089 */{dataseat:1089, data_seatno: "s2-y-6-42", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席2", class: "type_1"},
  /*1090 */{dataseat:1090, data_seatno: "s2-y-6-43", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席2", class: "type_1"},
  //////////// 7列目 ////////////
  /*1091 */{dataseat:1091, data_seatno: "s2-y-7-6", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席2", class: "type_1"},
  /*1092 */{dataseat:1092, data_seatno: "s2-y-7-7", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席2", class: "type_1"},
  /*1093 */{dataseat:1093, data_seatno: "s2-y-7-8", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席2", class: "type_1"},
  /*1094 */{dataseat:1094, data_seatno: "s2-y-7-9", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席2", class: "type_1"},
  /*1095 */{dataseat:1095, data_seatno: "s2-y-7-10", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席2", class: "type_1"},
  /*1096 */{dataseat:1096, data_seatno: "s2-y-7-11", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席2", class: "type_1"},
  /*1097 */{dataseat:1097, data_seatno: "s2-y-7-12", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席2", class: "type_1"},
  /*1098 */{dataseat:1098, data_seatno: "s2-y-7-13", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席2", class: "type_1"},
  /*1099 */{dataseat:1099, data_seatno: "s2-y-7-14", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席2", class: "type_1"},
  /*1100 */{dataseat:1100, data_seatno: "s2-y-7-15", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席2", class: "type_1"},
  /*1101 */{dataseat:1101, data_seatno: "s2-y-7-16", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席2", class: "type_1"},
  /*1102 */{dataseat:1102, data_seatno: "s2-y-7-17", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席2", class: "type_1"},
  /*1103 */{dataseat:1103, data_seatno: "s2-y-7-19", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席2", class: "type_1"},
  /*1104 */{dataseat:1104, data_seatno: "s2-y-7-20", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席2", class: "type_1"},
  /*1105 */{dataseat:1105, data_seatno: "s2-y-7-21", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席2", class: "type_1"},
  /*1106 */{dataseat:1106, data_seatno: "s2-y-7-22", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席2", class: "type_1"},
  /*1107 */{dataseat:1107, data_seatno: "s2-y-7-23", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席2", class: "type_1"},
  /*1108 */{dataseat:1108, data_seatno: "s2-y-7-24", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席2", class: "type_1"},
  /*1109 */{dataseat:1109, data_seatno: "s2-y-7-25", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席2", class: "type_1"},
  /*1110 */{dataseat:1110, data_seatno: "s2-y-7-26", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席2", class: "type_1"},
  /*1111 */{dataseat:1111, data_seatno: "s2-y-7-27", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席2", class: "type_1"},
  /*1112 */{dataseat:1112, data_seatno: "s2-y-7-28", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席2", class: "type_1"},
  /*1113 */{dataseat:1113, data_seatno: "s2-y-7-29", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席2", class: "type_1"},
  /*1114 */{dataseat:1114, data_seatno: "s2-y-7-30", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席2", class: "type_1"},
  /*1115 */{dataseat:1115, data_seatno: "s2-y-7-32", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席2", class: "type_1"},
  /*1116 */{dataseat:1116, data_seatno: "s2-y-7-33", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席2", class: "type_1"},
  /*1117 */{dataseat:1117, data_seatno: "s2-y-7-34", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席2", class: "type_1"},
  /*1118 */{dataseat:1118, data_seatno: "s2-y-7-35", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席2", class: "type_1"},
  /*1119 */{dataseat:1119, data_seatno: "s2-y-7-36", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席2", class: "type_1"},
  /*1120 */{dataseat:1120, data_seatno: "s2-y-7-37", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席2", class: "type_1"},
  /*1121 */{dataseat:1121, data_seatno: "s2-y-7-38", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席2", class: "type_1"},
  /*1122 */{dataseat:1122, data_seatno: "s2-y-7-39", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席2", class: "type_1"},
  /*1123 */{dataseat:1123, data_seatno: "s2-y-7-40", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席2", class: "type_1"},
  /*1124 */{dataseat:1124, data_seatno: "s2-y-7-41", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席2", class: "type_1"},
  /*1125 */{dataseat:1125, data_seatno: "s2-y-7-42", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席2", class: "type_1"},
  /*1126 */{dataseat:1126, data_seatno: "s2-y-7-43", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席2", class: "type_1"},
  //////////// 8列目 ////////////
  /*1127 */{dataseat:1127, data_seatno: "s2-y-8-6", customer_id: "59", name: "長井 梨緒奈", mail: "", phone: "", studio: "天理", class: "type_2"},
  /*1128 */{dataseat:1128, data_seatno: "s2-y-8-7", customer_id: "59", name: "長井 梨緒奈", mail: "", phone: "", studio: "天理", class: "type_2"},
  /*1129 */{dataseat:1129, data_seatno: "s2-y-8-8", customer_id: "59", name: "長井 梨緒奈", mail: "", phone: "", studio: "天理", class: "type_2"},
  /*1130 */{dataseat:1130, data_seatno: "s2-y-8-9", customer_id: "59", name: "長井 梨緒奈", mail: "", phone: "", studio: "天理", class: "type_2"},
  /*1131 */{dataseat:1131, data_seatno: "s2-y-8-10", customer_id: "59", name: "長井 梨緒奈", mail: "", phone: "", studio: "天理", class: "type_2"},
  /*1132 */{dataseat:1132, data_seatno: "s2-y-8-11", customer_id: "59", name: "長井 梨緒奈", mail: "", phone: "", studio: "天理", class: "type_2"},
  /*1133 */{dataseat:1133, data_seatno: "s2-y-8-12", customer_id: "1670", name: "浅野 明来", mail: "", phone: "", studio: "天理", class: "type_2"},
  /*1134 */{dataseat:1134, data_seatno: "s2-y-8-13", customer_id: "1670", name: "浅野 明来", mail: "", phone: "", studio: "天理", class: "type_2"},
  /*1135 */{dataseat:1135, data_seatno: "s2-y-8-14", customer_id: "690", name: "原田 果実", mail: "", phone: "", studio: "天理", class: "type_2"},
  /*1136 */{dataseat:1136, data_seatno: "s2-y-8-15", customer_id: "690", name: "原田 果実", mail: "", phone: "", studio: "天理", class: "type_2"},
  /*1137 */{dataseat:1137, data_seatno: "s2-y-8-16", customer_id: "696", name: "藤田 紗也", mail: "", phone: "", studio: "天理", class: "type_2"},
  /*1138 */{dataseat:1138, data_seatno: "s2-y-8-17", customer_id: "696", name: "藤田 紗也", mail: "", phone: "", studio: "天理", class: "type_2"},
  /*1139 */{dataseat:1139, data_seatno: "s2-y-8-19", customer_id: "1724", name: "瀬戸 杏樹", mail: "", phone: "", studio: "天理", class: "type_2"},
  /*1140 */{dataseat:1140, data_seatno: "s2-y-8-20", customer_id: "1724", name: "瀬戸 杏樹", mail: "", phone: "", studio: "天理", class: "type_2"},
  /*1141 */{dataseat:1141, data_seatno: "s2-y-8-21", customer_id: "1730", name: "田上 いち葉", mail: "", phone: "", studio: "天理", class: "type_2"},
  /*1142 */{dataseat:1142, data_seatno: "s2-y-8-22", customer_id: "1730", name: "田上 いち葉", mail: "", phone: "", studio: "天理", class: "type_2"},
  /*1143 */{dataseat:1143, data_seatno: "s2-y-8-23", customer_id: "1949", name: "有田 利恋", mail: "", phone: "", studio: "天理", class: "type_2"},
  /*1144 */{dataseat:1144, data_seatno: "s2-y-8-24", customer_id: "1949", name: "有田 利恋", mail: "", phone: "", studio: "天理", class: "type_2"},
  /*1145 */{dataseat:1145, data_seatno: "s2-y-8-25", customer_id: "1989", name: "宮本 朋", mail: "", phone: "", studio: "天理", class: "type_2"},
  /*1146 */{dataseat:1146, data_seatno: "s2-y-8-26", customer_id: "1989", name: "宮本 朋", mail: "", phone: "", studio: "天理", class: "type_2"},
  /*1147 */{dataseat:1147, data_seatno: "s2-y-8-27", customer_id: "1628", name: "加藤 朱莉", mail: "", phone: "", studio: "天理", class: "type_2"},
  /*1148 */{dataseat:1148, data_seatno: "s2-y-8-28", customer_id: "1628", name: "加藤 朱莉", mail: "", phone: "", studio: "天理", class: "type_2"},
  /*1149 */{dataseat:1149, data_seatno: "s2-y-8-29", customer_id: "1628", name: "加藤 朱莉", mail: "", phone: "", studio: "天理", class: "type_2"},
  /*1150 */{dataseat:1150, data_seatno: "s2-y-8-30", customer_id: "1628", name: "加藤 朱莉", mail: "", phone: "", studio: "天理", class: "type_2"},
  /*1151 */{dataseat:1151, data_seatno: "s2-y-8-32", customer_id: "1596", name: "椿井 音羽", mail: "", phone: "", studio: "天理", class: "type_2"},
  /*1152 */{dataseat:1152, data_seatno: "s2-y-8-33", customer_id: "1596", name: "椿井 音羽", mail: "", phone: "", studio: "天理", class: "type_2"},
  /*1153 */{dataseat:1153, data_seatno: "s2-y-8-34", customer_id: "1596", name: "椿井 音羽", mail: "", phone: "", studio: "天理", class: "type_2"},
  /*1154 */{dataseat:1154, data_seatno: "s2-y-8-35", customer_id: "1596", name: "椿井 音羽", mail: "", phone: "", studio: "天理", class: "type_2"},
  /*1155 */{dataseat:1155, data_seatno: "s2-y-8-36", customer_id: "1596", name: "椿井 音羽", mail: "", phone: "", studio: "天理", class: "type_2"},
  /*1156 */{dataseat:1156, data_seatno: "s2-y-8-37", customer_id: "1596", name: "椿井 音羽", mail: "", phone: "", studio: "天理", class: "type_2"},
  /*1157 */{dataseat:1157, data_seatno: "s2-y-8-38", customer_id: "1599", name: "竹下 日向美", mail: "", phone: "", studio: "天理", class: "type_2"},
  /*1158 */{dataseat:1158, data_seatno: "s2-y-8-39", customer_id: "1599", name: "竹下 日向美", mail: "", phone: "", studio: "天理", class: "type_2"},
  /*1159 */{dataseat:1159, data_seatno: "s2-y-8-40", customer_id: "1719", name: "天野 千凜", mail: "", phone: "", studio: "天理", class: "type_2"},
  /*1160 */{dataseat:1160, data_seatno: "s2-y-8-41", customer_id: "1719", name: "天野 千凜", mail: "", phone: "", studio: "天理", class: "type_2"},
  /*1161 */{dataseat:1161, data_seatno: "s2-y-8-42", customer_id: "1801", name: "辻川 歩花", mail: "", phone: "", studio: "天理", class: "type_2"},
  /*1162 */{dataseat:1162, data_seatno: "s2-y-8-43", customer_id: "1801", name: "辻川 歩花", mail: "", phone: "", studio: "天理", class: "type_2"},
  //////////// 9列目 ////////////
  /*1163 */{dataseat:1163, data_seatno: "s2-y-9-6", customer_id: "749", name: "野坂 薫花", mail: "", phone: "", studio: "加古川", class: "type_4"},
  /*1164 */{dataseat:1164, data_seatno: "s2-y-9-7", customer_id: "749", name: "野坂 薫花", mail: "", phone: "", studio: "加古川", class: "type_4"},
  /*1165 */{dataseat:1165, data_seatno: "s2-y-9-8", customer_id: "1098", name: "岡本 玲奈", mail: "", phone: "", studio: "加古川", class: "type_4"},
  /*1166 */{dataseat:1166, data_seatno: "s2-y-9-9", customer_id: "1098", name: "岡本 玲奈", mail: "", phone: "", studio: "加古川", class: "type_4"},
  /*1167 */{dataseat:1167, data_seatno: "s2-y-9-10", customer_id: "1127", name: "福島 嘉恩", mail: "", phone: "", studio: "加古川", class: "type_4"},
  /*1168 */{dataseat:1168, data_seatno: "s2-y-9-11", customer_id: "1127", name: "福島 嘉恩", mail: "", phone: "", studio: "加古川", class: "type_4"},
  /*1169 */{dataseat:1169, data_seatno: "s2-y-9-12", customer_id: "", name: "", mail: "", phone: "", studio: "", class: "type_99"},
  /*1170 */{dataseat:1170, data_seatno: "s2-y-9-13", customer_id: "", name: "", mail: "", phone: "", studio: "", class: "type_99"},
  /*1171 */{dataseat:1171, data_seatno: "s2-y-9-14", customer_id: "", name: "", mail: "", phone: "", studio: "", class: "type_99"},
  /*1172 */{dataseat:1172, data_seatno: "s2-y-9-15", customer_id: "", name: "", mail: "", phone: "", studio: "", class: "type_99"},
  /*1173 */{dataseat:1173, data_seatno: "s2-y-9-16", customer_id: "2024", name: "新谷 夢歩", mail: "", phone: "", studio: "", class: "type_18"},
  /*1174 */{dataseat:1174, data_seatno: "s2-y-9-17", customer_id: "2024", name: "新谷 夢歩", mail: "", phone: "", studio: "", class: "type_18"},
  /*1175 */{dataseat:1175, data_seatno: "s2-y-9-19", customer_id: "70", name: "網盛 希乃花", mail: "", phone: "", studio: "加古川", class: "type_4"},
  /*1176 */{dataseat:1176, data_seatno: "s2-y-9-20", customer_id: "70", name: "網盛 希乃花", mail: "", phone: "", studio: "加古川", class: "type_4"},
  /*1177 */{dataseat:1177, data_seatno: "s2-y-9-21", customer_id: "42", name: "武田 乃碧", mail: "", phone: "", studio: "加古川", class: "type_4"},
  /*1178 */{dataseat:1178, data_seatno: "s2-y-9-22", customer_id: "42", name: "武田 乃碧", mail: "", phone: "", studio: "加古川", class: "type_4"},
  /*1179 */{dataseat:1179, data_seatno: "s2-y-9-23", customer_id: "42", name: "武田 乃碧", mail: "", phone: "", studio: "加古川", class: "type_4"},
  /*1180 */{dataseat:1180, data_seatno: "s2-y-9-24", customer_id: "1527", name: "入山 稀乃香", mail: "", phone: "", studio: "大津京", class: "type_3"},
  /*1181 */{dataseat:1181, data_seatno: "s2-y-9-25", customer_id: "1527", name: "入山 稀乃香", mail: "", phone: "", studio: "大津京", class: "type_3"},
  /*1182 */{dataseat:1182, data_seatno: "s2-y-9-26", customer_id: "1602", name: "日花 優来", mail: "", phone: "", studio: "大津京", class: "type_3"},
  /*1183 */{dataseat:1183, data_seatno: "s2-y-9-27", customer_id: "1602", name: "日花 優来", mail: "", phone: "", studio: "大津京", class: "type_3"},
  /*1184 */{dataseat:1184, data_seatno: "s2-y-9-28", customer_id: "1602", name: "日花 優来", mail: "", phone: "", studio: "大津京", class: "type_3"},
  /*1185 */{dataseat:1185, data_seatno: "s2-y-9-29", customer_id: "1608", name: "四方 咲妃", mail: "", phone: "", studio: "大津京", class: "type_3"},
  /*1186 */{dataseat:1186, data_seatno: "s2-y-9-30", customer_id: "1608", name: "四方 咲妃", mail: "", phone: "", studio: "大津京", class: "type_3"},
  /*1187 */{dataseat:1187, data_seatno: "s2-y-9-32", customer_id: "1742", name: "深見 優衣", mail: "", phone: "", studio: "大津京", class: "type_3"},
  /*1188 */{dataseat:1188, data_seatno: "s2-y-9-33", customer_id: "1742", name: "深見 優衣", mail: "", phone: "", studio: "大津京", class: "type_3"},
  /*1189 */{dataseat:1189, data_seatno: "s2-y-9-34", customer_id: "1795", name: "栗林 心陽", mail: "", phone: "", studio: "大津京", class: "type_3"},
  /*1190 */{dataseat:1190, data_seatno: "s2-y-9-35", customer_id: "1795", name: "栗林 心陽", mail: "", phone: "", studio: "大津京", class: "type_3"},
  /*1191 */{dataseat:1191, data_seatno: "s2-y-9-36", customer_id: "1844", name: "金平 愛", mail: "", phone: "", studio: "大津京", class: "type_3"},
  /*1192 */{dataseat:1192, data_seatno: "s2-y-9-37", customer_id: "1844", name: "金平 愛", mail: "", phone: "", studio: "大津京", class: "type_3"},
  /*1193 */{dataseat:1193, data_seatno: "s2-y-9-38", customer_id: "2047", name: "橋本 優那", mail: "", phone: "", studio: "大津京", class: "type_3"},
  /*1194 */{dataseat:1194, data_seatno: "s2-y-9-39", customer_id: "2047", name: "橋本 優那", mail: "", phone: "", studio: "大津京", class: "type_3"},
  /*1195 */{dataseat:1195, data_seatno: "s2-y-9-40", customer_id: "2010", name: "神田 結羽", mail: "", phone: "", studio: "天理", class: "type_2"},
  /*1196 */{dataseat:1196, data_seatno: "s2-y-9-41", customer_id: "2010", name: "神田 結羽", mail: "", phone: "", studio: "天理", class: "type_2"},
  /*1197 */{dataseat:1197, data_seatno: "s2-y-9-42", customer_id: "2010", name: "神田 結羽", mail: "", phone: "", studio: "天理", class: "type_2"},
  /*1198 */{dataseat:1198, data_seatno: "s2-y-9-43", customer_id: "2010", name: "神田 結羽", mail: "", phone: "", studio: "天理", class: "type_2"},
  //////////// 10列目 ////////////
  /*1199 */{dataseat:1199, data_seatno: "s2-y-10-6", customer_id: "1460", name: "中島 凛來", mail: "", phone: "", studio: "加古川", class: "type_4"},
  /*1200 */{dataseat:1200, data_seatno: "s2-y-10-7", customer_id: "1460", name: "中島 凛來", mail: "", phone: "", studio: "加古川", class: "type_4"},
  /*1201 */{dataseat:1201, data_seatno: "s2-y-10-8", customer_id: "1464", name: "大西 ほのか", mail: "", phone: "", studio: "加古川", class: "type_4"},
  /*1202 */{dataseat:1202, data_seatno: "s2-y-10-9", customer_id: "1464", name: "大西 ほのか", mail: "", phone: "", studio: "加古川", class: "type_4"},
  /*1203 */{dataseat:1203, data_seatno: "s2-y-10-10", customer_id: "93", name: "松澤 蒼來", mail: "", phone: "", studio: "加古川", class: "type_4"},
  /*1204 */{dataseat:1204, data_seatno: "s2-y-10-11", customer_id: "93", name: "松澤 蒼來", mail: "", phone: "", studio: "加古川", class: "type_4"},
  /*1205 */{dataseat:1205, data_seatno: "s2-y-10-12", customer_id: "93", name: "松澤 蒼來", mail: "", phone: "", studio: "加古川", class: "type_4"},
  /*1206 */{dataseat:1206, data_seatno: "s2-y-10-13", customer_id: "1672", name: "梅崎 映美", mail: "", phone: "", studio: "加古川", class: "type_4"},
  /*1207 */{dataseat:1207, data_seatno: "s2-y-10-14", customer_id: "1672", name: "梅崎 映美", mail: "", phone: "", studio: "加古川", class: "type_4"},
  /*1208 */{dataseat:1208, data_seatno: "s2-y-10-15", customer_id: "1672", name: "梅崎 映美", mail: "", phone: "", studio: "加古川", class: "type_4"},
  /*1209 */{dataseat:1209, data_seatno: "s2-y-10-16", customer_id: "47", name: "山中 友里花", mail: "", phone: "", studio: "加古川", class: "type_4"},
  /*1210 */{dataseat:1210, data_seatno: "s2-y-10-17", customer_id: "47", name: "山中 友里花", mail: "", phone: "", studio: "加古川", class: "type_4"},
  /*1211 */{dataseat:1211, data_seatno: "s2-y-10-19", customer_id: "92", name: "板倉 菜美香", mail: "", phone: "", studio: "加古川", class: "type_4"},
  /*1212 */{dataseat:1212, data_seatno: "s2-y-10-20", customer_id: "92", name: "板倉 菜美香", mail: "", phone: "", studio: "加古川", class: "type_4"},
  /*1213 */{dataseat:1213, data_seatno: "s2-y-10-21", customer_id: "104", name: "山地 弘子", mail: "", phone: "", studio: "加古川", class: "type_4"},
  /*1214 */{dataseat:1214, data_seatno: "s2-y-10-22", customer_id: "104", name: "山地 弘子", mail: "", phone: "", studio: "加古川", class: "type_4"},
  /*1215 */{dataseat:1215, data_seatno: "s2-y-10-23", customer_id: "699", name: "池本 月菜", mail: "", phone: "", studio: "加古川", class: "type_4"},
  /*1216 */{dataseat:1216, data_seatno: "s2-y-10-24", customer_id: "699", name: "池本 月菜", mail: "", phone: "", studio: "加古川", class: "type_4"},
  /*1217 */{dataseat:1217, data_seatno: "s2-y-10-25", customer_id: "1369", name: "清家 舞菜", mail: "", phone: "", studio: "加古川", class: "type_4"},
  /*1218 */{dataseat:1218, data_seatno: "s2-y-10-26", customer_id: "1369", name: "清家 舞菜", mail: "", phone: "", studio: "加古川", class: "type_4"},
  /*1219 */{dataseat:1219, data_seatno: "s2-y-10-27", customer_id: "1578", name: "川久保 汐莉", mail: "", phone: "", studio: "加古川", class: "type_4"},
  /*1220 */{dataseat:1220, data_seatno: "s2-y-10-28", customer_id: "1578", name: "川久保 汐莉", mail: "", phone: "", studio: "加古川", class: "type_4"},
  /*1221 */{dataseat:1221, data_seatno: "s2-y-10-29", customer_id: "1728", name: "兼田 汐栗", mail: "", phone: "", studio: "加古川", class: "type_4"},
  /*1222 */{dataseat:1222, data_seatno: "s2-y-10-30", customer_id: "1728", name: "兼田 汐栗", mail: "", phone: "", studio: "加古川", class: "type_4"},
  /*1223 */{dataseat:1223, data_seatno: "s2-y-10-32", customer_id: "1738", name: "中渕 早菜", mail: "", phone: "", studio: "加古川", class: "type_4"},
  /*1224 */{dataseat:1224, data_seatno: "s2-y-10-33", customer_id: "1738", name: "中渕 早菜", mail: "", phone: "", studio: "加古川", class: "type_4"},
  /*1225 */{dataseat:1225, data_seatno: "s2-y-10-34", customer_id: "1738", name: "中渕 早菜", mail: "", phone: "", studio: "加古川", class: "type_4"},
  /*1226 */{dataseat:1226, data_seatno: "s2-y-10-35", customer_id: "1738", name: "中渕 早菜", mail: "", phone: "", studio: "加古川", class: "type_4"},
  /*1227 */{dataseat:1227, data_seatno: "s2-y-10-36", customer_id: "1829", name: "下内 彩加", mail: "", phone: "", studio: "加古川", class: "type_4"},
  /*1228 */{dataseat:1228, data_seatno: "s2-y-10-37", customer_id: "1829", name: "下内 彩加", mail: "", phone: "", studio: "加古川", class: "type_4"},
  /*1229 */{dataseat:1229, data_seatno: "s2-y-10-38", customer_id: "1830", name: "岸本 くるみ", mail: "", phone: "", studio: "加古川", class: "type_4"},
  /*1230 */{dataseat:1230, data_seatno: "s2-y-10-39", customer_id: "1830", name: "岸本 くるみ", mail: "", phone: "", studio: "加古川", class: "type_4"},
  /*1231 */{dataseat:1231, data_seatno: "s2-y-10-40", customer_id: "1901", name: "西川 綾花", mail: "", phone: "", studio: "加古川", class: "type_4"},
  /*1232 */{dataseat:1232, data_seatno: "s2-y-10-41", customer_id: "1901", name: "西川 綾花", mail: "", phone: "", studio: "加古川", class: "type_4"},
  /*1233 */{dataseat:1233, data_seatno: "s2-y-10-42", customer_id: "1902", name: "大西 璃央", mail: "", phone: "", studio: "加古川", class: "type_4"},
  /*1234 */{dataseat:1234, data_seatno: "s2-y-10-43", customer_id: "1902", name: "大西 璃央", mail: "", phone: "", studio: "加古川", class: "type_4"},
  //////////// 11列目 ////////////
  /*1235 */{dataseat:1235, data_seatno: "s2-y-11-6", customer_id: "65", name: "大西 翠香", mail: "", phone: "", studio: "西明石", class: "type_5"},
  /*1236 */{dataseat:1236, data_seatno: "s2-y-11-7", customer_id: "65", name: "大西 翠香", mail: "", phone: "", studio: "西明石", class: "type_5"},
  /*1237 */{dataseat:1237, data_seatno: "s2-y-11-8", customer_id: "71", name: "南 心菜", mail: "", phone: "", studio: "西明石", class: "type_5"},
  /*1238 */{dataseat:1238, data_seatno: "s2-y-11-9", customer_id: "71", name: "南 心菜", mail: "", phone: "", studio: "西明石", class: "type_5"},
  /*1239 */{dataseat:1239, data_seatno: "s2-y-11-10", customer_id: "1416", name: "京奥 蒼依", mail: "", phone: "", studio: "西明石", class: "type_5"},
  /*1240 */{dataseat:1240, data_seatno: "s2-y-11-11", customer_id: "1416", name: "京奥 蒼依", mail: "", phone: "", studio: "西明石", class: "type_5"},
  /*1241 */{dataseat:1241, data_seatno: "s2-y-11-12", customer_id: "1852", name: "金本 侑奈", mail: "", phone: "", studio: "西明石", class: "type_5"},
  /*1242 */{dataseat:1242, data_seatno: "s2-y-11-13", customer_id: "1852", name: "金本 侑奈", mail: "", phone: "", studio: "西明石", class: "type_5"},
  /*1243 */{dataseat:1243, data_seatno: "s2-y-11-14", customer_id: "1930", name: "有得 みちる", mail: "", phone: "", studio: "西明石", class: "type_5"},
  /*1244 */{dataseat:1244, data_seatno: "s2-y-11-15", customer_id: "1930", name: "有得 みちる", mail: "", phone: "", studio: "西明石", class: "type_5"},
  /*1245 */{dataseat:1245, data_seatno: "s2-y-11-16", customer_id: "981", name: "吉田 心夏", mail: "", phone: "", studio: "加古川", class: "type_4"},
  /*1246 */{dataseat:1246, data_seatno: "s2-y-11-17", customer_id: "981", name: "吉田 心夏", mail: "", phone: "", studio: "加古川", class: "type_4"},
  /*1247 */{dataseat:1247, data_seatno: "s2-y-11-19", customer_id: "1037", name: "黒地 涼葉", mail: "", phone: "", studio: "加古川", class: "type_4"},
  /*1248 */{dataseat:1248, data_seatno: "s2-y-11-20", customer_id: "1037", name: "黒地 涼葉", mail: "", phone: "", studio: "加古川", class: "type_4"},
  /*1249 */{dataseat:1249, data_seatno: "s2-y-11-21", customer_id: "1224", name: "田中 希空", mail: "", phone: "", studio: "加古川", class: "type_4"},
  /*1250 */{dataseat:1250, data_seatno: "s2-y-11-22", customer_id: "1224", name: "田中 希空", mail: "", phone: "", studio: "加古川", class: "type_4"},
  /*1251 */{dataseat:1251, data_seatno: "s2-y-11-23", customer_id: "1699", name: "清水 梨恋", mail: "", phone: "", studio: "加古川", class: "type_4"},
  /*1252 */{dataseat:1252, data_seatno: "s2-y-11-24", customer_id: "1699", name: "清水 梨恋", mail: "", phone: "", studio: "加古川", class: "type_4"},
  /*1253 */{dataseat:1253, data_seatno: "s2-y-11-25", customer_id: "1717", name: "本間 恵菜", mail: "", phone: "", studio: "加古川", class: "type_4"},
  /*1254 */{dataseat:1254, data_seatno: "s2-y-11-26", customer_id: "1717", name: "本間 恵菜", mail: "", phone: "", studio: "加古川", class: "type_4"},
  /*1255 */{dataseat:1255, data_seatno: "s2-y-11-27", customer_id: "77", name: "扇 梨央奈", mail: "", phone: "", studio: "加古川", class: "type_4"},
  /*1256 */{dataseat:1256, data_seatno: "s2-y-11-28", customer_id: "77", name: "扇 梨央奈", mail: "", phone: "", studio: "加古川", class: "type_4"},
  /*1257 */{dataseat:1257, data_seatno: "s2-y-11-29", customer_id: "82", name: "大川 茉莉愛", mail: "", phone: "", studio: "加古川", class: "type_4"},
  /*1258 */{dataseat:1258, data_seatno: "s2-y-11-30", customer_id: "82", name: "大川 茉莉愛", mail: "", phone: "", studio: "加古川", class: "type_4"},
  /*1259 */{dataseat:1259, data_seatno: "s2-y-11-32", customer_id: "83", name: "渡辺 美桜", mail: "", phone: "", studio: "加古川", class: "type_4"},
  /*1260 */{dataseat:1260, data_seatno: "s2-y-11-33", customer_id: "83", name: "渡辺 美桜", mail: "", phone: "", studio: "加古川", class: "type_4"},
  /*1261 */{dataseat:1261, data_seatno: "s2-y-11-34", customer_id: "86", name: "松田 百花", mail: "", phone: "", studio: "加古川", class: "type_4"},
  /*1262 */{dataseat:1262, data_seatno: "s2-y-11-35", customer_id: "86", name: "松田 百花", mail: "", phone: "", studio: "加古川", class: "type_4"},
  /*1263 */{dataseat:1263, data_seatno: "s2-y-11-36", customer_id: "98", name: "押部 愛叶", mail: "", phone: "", studio: "加古川", class: "type_4"},
  /*1264 */{dataseat:1264, data_seatno: "s2-y-11-37", customer_id: "98", name: "押部 愛叶", mail: "", phone: "", studio: "加古川", class: "type_4"},
  /*1265 */{dataseat:1265, data_seatno: "s2-y-11-38", customer_id: "98", name: "押部 愛叶", mail: "", phone: "", studio: "加古川", class: "type_4"},
  /*1266 */{dataseat:1266, data_seatno: "s2-y-11-39", customer_id: "98", name: "押部 愛叶", mail: "", phone: "", studio: "加古川", class: "type_4"},
  /*1267 */{dataseat:1267, data_seatno: "s2-y-11-40", customer_id: "98", name: "押部 愛叶", mail: "", phone: "", studio: "加古川", class: "type_4"},
  /*1268 */{dataseat:1268, data_seatno: "s2-y-11-41", customer_id: "98", name: "押部 愛叶", mail: "", phone: "", studio: "加古川", class: "type_4"},
  /*1269 */{dataseat:1269, data_seatno: "s2-y-11-42", customer_id: "1903", name: "西川 遙花", mail: "", phone: "", studio: "加古川", class: "type_4"},
  /*1270 */{dataseat:1270, data_seatno: "s2-y-11-43", customer_id: "1903", name: "西川 遙花", mail: "", phone: "", studio: "加古川", class: "type_4"},
  //////////// 12列目 ////////////
  /*1271 */{dataseat:1271, data_seatno: "s2-y-12-8", customer_id: "1345", name: "小林 栞奈", mail: "", phone: "", studio: "西明石", class: "type_5"},
  /*1272 */{dataseat:1272, data_seatno: "s2-y-12-9", customer_id: "1345", name: "小林 栞奈", mail: "", phone: "", studio: "西明石", class: "type_5"},
  /*1273 */{dataseat:1273, data_seatno: "s2-y-12-10", customer_id: "1350", name: "名生 光希", mail: "", phone: "", studio: "西明石", class: "type_5"},
  /*1274 */{dataseat:1274, data_seatno: "s2-y-12-11", customer_id: "1350", name: "名生 光希", mail: "", phone: "", studio: "西明石", class: "type_5"},
  /*1275 */{dataseat:1275, data_seatno: "s2-y-12-12", customer_id: "1351", name: "横山 夢乃", mail: "", phone: "", studio: "西明石", class: "type_5"},
  /*1276 */{dataseat:1276, data_seatno: "s2-y-12-13", customer_id: "1351", name: "横山 夢乃", mail: "", phone: "", studio: "西明石", class: "type_5"},
  /*1277 */{dataseat:1277, data_seatno: "s2-y-12-14", customer_id: "1816", name: "松山 蘭", mail: "", phone: "", studio: "西明石", class: "type_5"},
  /*1278 */{dataseat:1278, data_seatno: "s2-y-12-15", customer_id: "1816", name: "松山 蘭", mail: "", phone: "", studio: "西明石", class: "type_5"},
  /*1279 */{dataseat:1279, data_seatno: "s2-y-12-16", customer_id: "1860", name: "神宮 紗菜", mail: "", phone: "", studio: "草津", class: "type_6"},
  /*1280 */{dataseat:1280, data_seatno: "s2-y-12-17", customer_id: "1860", name: "神宮 紗菜", mail: "", phone: "", studio: "草津", class: "type_6"},
  /*1281 */{dataseat:1281, data_seatno: "s2-y-12-19", customer_id: "1879", name: "山口 愛來", mail: "", phone: "", studio: "草津", class: "type_6"},
  /*1282 */{dataseat:1282, data_seatno: "s2-y-12-20", customer_id: "1879", name: "山口 愛來", mail: "", phone: "", studio: "草津", class: "type_6"},
  /*1283 */{dataseat:1283, data_seatno: "s2-y-12-21", customer_id: "1900", name: "西前 杏", mail: "", phone: "", studio: "草津", class: "type_6"},
  /*1284 */{dataseat:1284, data_seatno: "s2-y-12-22", customer_id: "1900", name: "西前 杏", mail: "", phone: "", studio: "草津", class: "type_6"},
  /*1285 */{dataseat:1285, data_seatno: "s2-y-12-23", customer_id: "1958", name: "矢田 咲愛子", mail: "", phone: "", studio: "草津", class: "type_6"},
  /*1286 */{dataseat:1286, data_seatno: "s2-y-12-24", customer_id: "1958", name: "矢田 咲愛子", mail: "", phone: "", studio: "草津", class: "type_6"},
  /*1287 */{dataseat:1287, data_seatno: "s2-y-12-25", customer_id: "1978", name: "森井 こまち", mail: "", phone: "", studio: "草津", class: "type_6"},
  /*1288 */{dataseat:1288, data_seatno: "s2-y-12-26", customer_id: "1978", name: "森井 こまち", mail: "", phone: "", studio: "草津", class: "type_6"},
  /*1289 */{dataseat:1289, data_seatno: "s2-y-12-27", customer_id: "1864", name: "宮本 莉奈", mail: "", phone: "", studio: "草津", class: "type_6"},
  /*1290 */{dataseat:1290, data_seatno: "s2-y-12-28", customer_id: "1864", name: "宮本 莉奈", mail: "", phone: "", studio: "草津", class: "type_6"},
  /*1291 */{dataseat:1291, data_seatno: "s2-y-12-29", customer_id: "1876", name: "長谷川 朝香", mail: "", phone: "", studio: "草津", class: "type_6"},
  /*1292 */{dataseat:1292, data_seatno: "s2-y-12-30", customer_id: "1876", name: "長谷川 朝香", mail: "", phone: "", studio: "草津", class: "type_6"},
  /*1293 */{dataseat:1293, data_seatno: "s2-y-12-32", customer_id: "1878", name: "寺井 凛", mail: "", phone: "", studio: "草津", class: "type_6"},
  /*1294 */{dataseat:1294, data_seatno: "s2-y-12-33", customer_id: "1878", name: "寺井 凛", mail: "", phone: "", studio: "草津", class: "type_6"},
  /*1295 */{dataseat:1295, data_seatno: "s2-y-12-34", customer_id: "1882", name: "牧野 真央", mail: "", phone: "", studio: "草津", class: "type_6"},
  /*1296 */{dataseat:1296, data_seatno: "s2-y-12-35", customer_id: "1882", name: "牧野 真央", mail: "", phone: "", studio: "草津", class: "type_6"},
  /*1297 */{dataseat:1297, data_seatno: "s2-y-12-36", customer_id: "1889", name: "和田 真梨香", mail: "", phone: "", studio: "草津", class: "type_6"},
  /*1298 */{dataseat:1298, data_seatno: "s2-y-12-37", customer_id: "1889", name: "和田 真梨香", mail: "", phone: "", studio: "草津", class: "type_6"},
  /*1299 */{dataseat:1299, data_seatno: "s2-y-12-38", customer_id: "1892", name: "森下 咲愛", mail: "", phone: "", studio: "草津", class: "type_6"},
  /*1300 */{dataseat:1300, data_seatno: "s2-y-12-39", customer_id: "1892", name: "森下 咲愛", mail: "", phone: "", studio: "草津", class: "type_6"},
  /*1301 */{dataseat:1301, data_seatno: "s2-y-12-40", customer_id: "1893", name: "岩浅 知友", mail: "", phone: "", studio: "草津", class: "type_6"},
  /*1302 */{dataseat:1302, data_seatno: "s2-y-12-41", customer_id: "1893", name: "岩浅 知友", mail: "", phone: "", studio: "草津", class: "type_6"},
  //////////// 13列目左 ////////////
  /*1303 */{dataseat:1303, data_seatno: "s2-y-13-1", customer_id: "1909", name: "橋本 望叶", mail: "", phone: "", studio: "大和八木", class: "type_7"},
  /*1304 */{dataseat:1304, data_seatno: "s2-y-13-2", customer_id: "1909", name: "橋本 望叶", mail: "", phone: "", studio: "大和八木", class: "type_7"},
  /*1305 */{dataseat:1305, data_seatno: "s2-y-13-3", customer_id: "1745", name: "大西 仁瑚", mail: "", phone: "", studio: "大和八木", class: "type_7"},
  /*1306 */{dataseat:1306, data_seatno: "s2-y-13-4", customer_id: "1745", name: "大西 仁瑚", mail: "", phone: "", studio: "大和八木", class: "type_7"},
  //////////// 13列目右 ////////////
  /*1307 */{dataseat:1307, data_seatno: "s2-y-13-45", customer_id: "1631", name: "鈴木 咲希", mail: "", phone: "", studio: "大宮", class: "type_8"},
  /*1308 */{dataseat:1308, data_seatno: "s2-y-13-46", customer_id: "1631", name: "鈴木 咲希", mail: "", phone: "", studio: "大宮", class: "type_8"},
  /*1309 */{dataseat:1309, data_seatno: "s2-y-13-47", customer_id: "1686", name: "松本 梨愛", mail: "", phone: "", studio: "大宮", class: "type_8"},
  /*1310 */{dataseat:1310, data_seatno: "s2-y-13-48", customer_id: "1686", name: "松本 梨愛", mail: "", phone: "", studio: "大宮", class: "type_8"},
  //////////// 13列目中央 ////////////
  /*1311 */{dataseat:1311, data_seatno: "s2-y-13-8", customer_id: "1822", name: "藤井 菜侑", mail: "", phone: "", studio: "大和八木", class: "type_7"},
  /*1312 */{dataseat:1312, data_seatno: "s2-y-13-9", customer_id: "1822", name: "藤井 菜侑", mail: "", phone: "", studio: "大和八木", class: "type_7"},
  /*1313 */{dataseat:1313, data_seatno: "s2-y-13-10", customer_id: "1822", name: "藤井 菜侑", mail: "", phone: "", studio: "大和八木", class: "type_7"},
  /*1314 */{dataseat:1314, data_seatno: "s2-y-13-11", customer_id: "1822", name: "藤井 菜侑", mail: "", phone: "", studio: "大和八木", class: "type_7"},
  /*1315 */{dataseat:1315, data_seatno: "s2-y-13-12", customer_id: "1580", name: "梶本 結愛", mail: "", phone: "", studio: "大和八木", class: "type_7"},
  /*1316 */{dataseat:1316, data_seatno: "s2-y-13-13", customer_id: "1580", name: "梶本 結愛", mail: "", phone: "", studio: "大和八木", class: "type_7"},
  /*1317 */{dataseat:1317, data_seatno: "s2-y-13-14", customer_id: "1580", name: "梶本 結愛", mail: "", phone: "", studio: "大和八木", class: "type_7"},
  /*1318 */{dataseat:1318, data_seatno: "s2-y-13-15", customer_id: "1580", name: "梶本 結愛", mail: "", phone: "", studio: "大和八木", class: "type_7"},
  /*1319 */{dataseat:1319, data_seatno: "s2-y-13-16", customer_id: "1996", name: "稲田 千尋", mail: "", phone: "", studio: "大和八木", class: "type_7"},
  /*1320 */{dataseat:1320, data_seatno: "s2-y-13-17", customer_id: "1996", name: "稲田 千尋", mail: "", phone: "", studio: "大和八木", class: "type_7"},
  /*1321 */{dataseat:1321, data_seatno: "s2-y-13-19", customer_id: "1969", name: "コレイア 百倭", mail: "", phone: "", studio: "大和八木", class: "type_7"},
  /*1322 */{dataseat:1322, data_seatno: "s2-y-13-20", customer_id: "1969", name: "コレイア 百倭", mail: "", phone: "", studio: "大和八木", class: "type_7"},
  /*1323 */{dataseat:1323, data_seatno: "s2-y-13-21", customer_id: "1969", name: "コレイア 百倭", mail: "", phone: "", studio: "大和八木", class: "type_7"},
  /*1324 */{dataseat:1324, data_seatno: "s2-y-13-22", customer_id: "1969", name: "コレイア 百倭", mail: "", phone: "", studio: "大和八木", class: "type_7"},
  /*1325 */{dataseat:1325, data_seatno: "s2-y-13-23", customer_id: "1969", name: "コレイア 百倭", mail: "", phone: "", studio: "大和八木", class: "type_7"},
  /*1326 */{dataseat:1326, data_seatno: "s2-y-13-24", customer_id: "1969", name: "コレイア 百倭", mail: "", phone: "", studio: "大和八木", class: "type_7"},
  /*1327 */{dataseat:1327, data_seatno: "s2-y-13-25", customer_id: "1518", name: "橋口 夏乃", mail: "", phone: "", studio: "大和八木", class: "type_7"},
  /*1328 */{dataseat:1328, data_seatno: "s2-y-13-26", customer_id: "1518", name: "橋口 夏乃", mail: "", phone: "", studio: "大和八木", class: "type_7"},
  /*1329 */{dataseat:1329, data_seatno: "s2-y-13-27", customer_id: "1630", name: "藤田 杏花", mail: "", phone: "", studio: "大和八木", class: "type_7"},
  /*1330 */{dataseat:1330, data_seatno: "s2-y-13-28", customer_id: "1630", name: "藤田 杏花", mail: "", phone: "", studio: "大和八木", class: "type_7"},
  /*1331 */{dataseat:1331, data_seatno: "s2-y-13-29", customer_id: "1685", name: "稲葉 小日向", mail: "", phone: "", studio: "大和八木", class: "type_7"},
  /*1332 */{dataseat:1332, data_seatno: "s2-y-13-30", customer_id: "1685", name: "稲葉 小日向", mail: "", phone: "", studio: "大和八木", class: "type_7"},
  /*1333 */{dataseat:1333, data_seatno: "s2-y-13-32", customer_id: "1904", name: "山田 菜摘", mail: "", phone: "", studio: "草津", class: "type_6"},
  /*1334 */{dataseat:1334, data_seatno: "s2-y-13-33", customer_id: "1904", name: "山田 菜摘", mail: "", phone: "", studio: "草津", class: "type_6"},
  /*1335 */{dataseat:1335, data_seatno: "s2-y-13-34", customer_id: "1922", name: "毛利 恵菜", mail: "", phone: "", studio: "草津", class: "type_6"},
  /*1336 */{dataseat:1336, data_seatno: "s2-y-13-35", customer_id: "1922", name: "毛利 恵菜", mail: "", phone: "", studio: "草津", class: "type_6"},
  /*1337 */{dataseat:1337, data_seatno: "s2-y-13-36", customer_id: "1950", name: "磯崎 みちる", mail: "", phone: "", studio: "草津", class: "type_6"},
  /*1338 */{dataseat:1338, data_seatno: "s2-y-13-37", customer_id: "1950", name: "磯崎 みちる", mail: "", phone: "", studio: "草津", class: "type_6"},
  /*1339 */{dataseat:1339, data_seatno: "s2-y-13-38", customer_id: "1957", name: "大隈 綾乃", mail: "", phone: "", studio: "草津", class: "type_6"},
  /*1340 */{dataseat:1340, data_seatno: "s2-y-13-39", customer_id: "1957", name: "大隈 綾乃", mail: "", phone: "", studio: "草津", class: "type_6"},
  /*1341 */{dataseat:1341, data_seatno: "s2-y-13-40", customer_id: "1968", name: "鶴岡 由理子", mail: "", phone: "", studio: "草津", class: "type_6"},
  /*1342 */{dataseat:1342, data_seatno: "s2-y-13-41", customer_id: "1968", name: "鶴岡 由理子", mail: "", phone: "", studio: "草津", class: "type_6"},
  //////////// 14列目右 ////////////
  /*1343 */{dataseat:1343, data_seatno: "s2-y-14-45", customer_id: "1954", name: "坂本 萌依", mail: "", phone: "", studio: "大宮", class: "type_8"},
  /*1344 */{dataseat:1344, data_seatno: "s2-y-14-46", customer_id: "1954", name: "坂本 萌依", mail: "", phone: "", studio: "大宮", class: "type_8"},
  /*1345 */{dataseat:1345, data_seatno: "s2-y-14-47", customer_id: "1980", name: "石田 亜衣", mail: "", phone: "", studio: "大宮", class: "type_8"},
  /*1346 */{dataseat:1346, data_seatno: "s2-y-14-48", customer_id: "1980", name: "石田 亜衣", mail: "", phone: "", studio: "大宮", class: "type_8"},
  //////////// 14列目左 ////////////
  /*1347 */{dataseat:1347, data_seatno: "s2-y-14-1", customer_id: "1718", name: "橋口 鈴", mail: "", phone: "", studio: "大和八木", class: "type_7"},
  /*1348 */{dataseat:1348, data_seatno: "s2-y-14-2", customer_id: "1718", name: "橋口 鈴", mail: "", phone: "", studio: "大和八木", class: "type_7"},
  /*1349 */{dataseat:1349, data_seatno: "s2-y-14-3", customer_id: "1514", name: "上田 椛愛", mail: "", phone: "", studio: "大和八木", class: "type_7"},
  /*1350 */{dataseat:1350, data_seatno: "s2-y-14-4", customer_id: "1514", name: "上田 椛愛", mail: "", phone: "", studio: "大和八木", class: "type_7"},
  //////////// 14列目中央 ////////////
  /*1351 */{dataseat:1351, data_seatno: "s2-y-14-8", customer_id: "1517", name: "藏光 天音", mail: "", phone: "", studio: "大和八木", class: "type_7"},
  /*1352 */{dataseat:1352, data_seatno: "s2-y-14-9", customer_id: "1517", name: "藏光 天音", mail: "", phone: "", studio: "大和八木", class: "type_7"},
  /*1353 */{dataseat:1353, data_seatno: "s2-y-14-10", customer_id: "", name: "藏光亜紗子", mail: "asa.k331@gmail.com", phone: "09030359632", studio: "大和八木", class: "type_7"},
  /*1354 */{dataseat:1354, data_seatno: "s2-y-14-11", customer_id: "", name: "藏光亜紗子", mail: "asa.k331@gmail.com", phone: "09030359632", studio: "大和八木", class: "type_7"},
  /*1355 */{dataseat:1355, data_seatno: "s2-y-14-12", customer_id: "", name: "藏光亜紗子", mail: "asa.k331@gmail.com", phone: "09030359632", studio: "大和八木", class: "type_7"},
  /*1356 */{dataseat:1356, data_seatno: "s2-y-14-13", customer_id: "1554", name: "神島 心葵", mail: "", phone: "", studio: "大和八木", class: "type_7"},
  /*1357 */{dataseat:1357, data_seatno: "s2-y-14-14", customer_id: "1554", name: "神島 心葵", mail: "", phone: "", studio: "大和八木", class: "type_7"},
  /*1358 */{dataseat:1358, data_seatno: "s2-y-14-15", customer_id: "1554", name: "神島 心葵", mail: "", phone: "", studio: "大和八木", class: "type_7"},
  /*1359 */{dataseat:1359, data_seatno: "s2-y-14-16", customer_id: "10", name: "市原 愛莉", mail: "", phone: "", studio: "大宮", class: "type_8"},
  /*1360 */{dataseat:1360, data_seatno: "s2-y-14-17", customer_id: "10", name: "市原 愛莉", mail: "", phone: "", studio: "大宮", class: "type_8"},
  /*1361 */{dataseat:1361, data_seatno: "s2-y-14-19", customer_id: "1023", name: "服部 友香", mail: "", phone: "", studio: "大宮", class: "type_8"},
  /*1362 */{dataseat:1362, data_seatno: "s2-y-14-20", customer_id: "1023", name: "服部 友香", mail: "", phone: "", studio: "大宮", class: "type_8"},
  /*1363 */{dataseat:1363, data_seatno: "s2-y-14-21", customer_id: "1023", name: "服部 友香", mail: "", phone: "", studio: "大宮", class: "type_8"},
  /*1364 */{dataseat:1364, data_seatno: "s2-y-14-22", customer_id: "1023", name: "服部 友香", mail: "", phone: "", studio: "大宮", class: "type_8"},
  /*1365 */{dataseat:1365, data_seatno: "s2-y-14-23", customer_id: "1023", name: "服部 友香", mail: "", phone: "", studio: "大宮", class: "type_8"},
  /*1366 */{dataseat:1366, data_seatno: "s2-y-14-24", customer_id: "", name: "", mail: "", phone: "", studio: "カメラ", class: "type_98"},
  /*1367 */{dataseat:1367, data_seatno: "s2-y-14-25", customer_id: "", name: "", mail: "", phone: "", studio: "カメラ", class: "type_98"},
  /*1368 */{dataseat:1368, data_seatno: "s2-y-14-26", customer_id: "960", name: "中道 花", mail: "", phone: "", studio: "大宮", class: "type_8"},
  /*1369 */{dataseat:1369, data_seatno: "s2-y-14-27", customer_id: "960", name: "中道 花", mail: "", phone: "", studio: "大宮", class: "type_8"},
  /*1370 */{dataseat:1370, data_seatno: "s2-y-14-28", customer_id: "960", name: "中道 花", mail: "", phone: "", studio: "大宮", class: "type_8"},
  /*1371 */{dataseat:1371, data_seatno: "s2-y-14-29", customer_id: "52", name: "山川 千尋", mail: "", phone: "", studio: "大宮", class: "type_8"},
  /*1372 */{dataseat:1372, data_seatno: "s2-y-14-30", customer_id: "52", name: "山川 千尋", mail: "", phone: "", studio: "大宮", class: "type_8"},
  /*1373 */{dataseat:1373, data_seatno: "s2-y-14-32", customer_id: "58", name: "外山 桃花", mail: "", phone: "", studio: "大宮", class: "type_8"},
  /*1374 */{dataseat:1374, data_seatno: "s2-y-14-33", customer_id: "58", name: "外山 桃花", mail: "", phone: "", studio: "大宮", class: "type_8"},
  /*1375 */{dataseat:1375, data_seatno: "s2-y-14-34", customer_id: "66", name: "井筒 菜々香", mail: "", phone: "", studio: "大宮", class: "type_8"},
  /*1376 */{dataseat:1376, data_seatno: "s2-y-14-35", customer_id: "66", name: "井筒 菜々香", mail: "", phone: "", studio: "大宮", class: "type_8"},
  /*1377 */{dataseat:1377, data_seatno: "s2-y-14-36", customer_id: "101", name: "中島 佳奏", mail: "", phone: "", studio: "大宮", class: "type_8"},
  /*1378 */{dataseat:1378, data_seatno: "s2-y-14-37", customer_id: "101", name: "中島 佳奏", mail: "", phone: "", studio: "大宮", class: "type_8"},
  /*1379 */{dataseat:1379, data_seatno: "s2-y-14-38", customer_id: "1125", name: "岡本 実咲", mail: "", phone: "", studio: "大宮", class: "type_8"},
  /*1380 */{dataseat:1380, data_seatno: "s2-y-14-39", customer_id: "1125", name: "岡本 実咲", mail: "", phone: "", studio: "大宮", class: "type_8"},
  /*1381 */{dataseat:1381, data_seatno: "s2-y-14-40", customer_id: "1311", name: "大森 結心", mail: "", phone: "", studio: "大宮", class: "type_8"},
  /*1382 */{dataseat:1382, data_seatno: "s2-y-14-41", customer_id: "1311", name: "大森 結心", mail: "", phone: "", studio: "大宮", class: "type_8"},
  //////////// 15列目右 ////////////
  /*1383 */{dataseat:1383, data_seatno: "s2-y-15-45", customer_id: "956", name: "清水 莉夏", mail: "", phone: "", studio: "大宮", class: "type_8"},
  /*1384 */{dataseat:1384, data_seatno: "s2-y-15-46", customer_id: "956", name: "清水 莉夏", mail: "", phone: "", studio: "大宮", class: "type_8"},
  /*1385 */{dataseat:1385, data_seatno: "s2-y-15-47", customer_id: "934", name: "堀 陽奈", mail: "", phone: "", studio: "大宮", class: "type_8"},
  /*1386 */{dataseat:1386, data_seatno: "s2-y-15-48", customer_id: "934", name: "堀 陽奈", mail: "", phone: "", studio: "大宮", class: "type_8"},
  //////////// 15列目左 ////////////
  /*1387 */{dataseat:1387, data_seatno: "s2-y-15-1", customer_id: "1905", name: "蓮池 凜咲", mail: "", phone: "", studio: "大和八木", class: "type_7"},
  /*1388 */{dataseat:1388, data_seatno: "s2-y-15-2", customer_id: "1905", name: "蓮池 凜咲", mail: "", phone: "", studio: "大和八木", class: "type_7"},
  /*1389 */{dataseat:1389, data_seatno: "s2-y-15-3", customer_id: "", name: "蓮池佳奈", mail: "misarisa1007.kei1228@gmail.com", phone: "09092165007", studio: "大和八木", class: "type_7"},
  /*1390 */{dataseat:1390, data_seatno: "s2-y-15-4", customer_id: "", name: "蓮池佳奈", mail: "misarisa1007.kei1228@gmail.com", phone: "09092165007", studio: "大和八木", class: "type_7"},
  //////////// 16列目 ////////////
  /*1391 */{dataseat:1391, data_seatno: "s2-y-16-1", customer_id: "1124", name: "廣末 遥妃", mail: "", phone: "", studio: "西神中央", class: "type_14"},
  /*1392 */{dataseat:1392, data_seatno: "s2-y-16-2", customer_id: "1124", name: "廣末 遥妃", mail: "", phone: "", studio: "西神中央", class: "type_14"},
  /*1393 */{dataseat:1393, data_seatno: "s2-y-16-3", customer_id: "1161", name: "小島 玲奈", mail: "", phone: "", studio: "西神中央", class: "type_14"},
  /*1394 */{dataseat:1394, data_seatno: "s2-y-16-4", customer_id: "1161", name: "小島 玲奈", mail: "", phone: "", studio: "西神中央", class: "type_14"},
  /*1395 */{dataseat:1395, data_seatno: "s2-y-16-10", customer_id: "1179", name: "奥野 晴", mail: "", phone: "", studio: "西神中央", class: "type_14"},
  /*1396 */{dataseat:1396, data_seatno: "s2-y-16-11", customer_id: "1179", name: "奥野 晴", mail: "", phone: "", studio: "西神中央", class: "type_14"},
  /*1397 */{dataseat:1397, data_seatno: "s2-y-16-12", customer_id: "1179", name: "奥野 晴", mail: "", phone: "", studio: "西神中央", class: "type_14"},
  /*1398 */{dataseat:1398, data_seatno: "s2-y-16-13", customer_id: "1182", name: "梅田 舞桜", mail: "", phone: "", studio: "西神中央", class: "type_14"},
  /*1399 */{dataseat:1399, data_seatno: "s2-y-16-14", customer_id: "1182", name: "梅田 舞桜", mail: "", phone: "", studio: "西神中央", class: "type_14"},
  /*1400 */{dataseat:1400, data_seatno: "s2-y-16-15", customer_id: "1210", name: "桝谷 さつき", mail: "", phone: "", studio: "西神中央", class: "type_14"},
  /*1401 */{dataseat:1401, data_seatno: "s2-y-16-16", customer_id: "1210", name: "桝谷 さつき", mail: "", phone: "", studio: "西神中央", class: "type_14"},
  /*1402 */{dataseat:1402, data_seatno: "s2-y-16-20", customer_id: "16", name: "柴田 真佑", mail: "", phone: "", studio: "学園前", class: "type_9"},
  /*1403 */{dataseat:1403, data_seatno: "s2-y-16-21", customer_id: "16", name: "柴田 真佑", mail: "", phone: "", studio: "学園前", class: "type_9"},
  /*1404 */{dataseat:1404, data_seatno: "s2-y-16-22", customer_id: "72", name: "浅野 さやか", mail: "", phone: "", studio: "学園前", class: "type_9"},
  /*1405 */{dataseat:1405, data_seatno: "s2-y-16-23", customer_id: "72", name: "浅野 さやか", mail: "", phone: "", studio: "学園前", class: "type_9"},
  /*1406 */{dataseat:1406, data_seatno: "s2-y-16-24", customer_id: "647", name: "友安 詠舞", mail: "", phone: "", studio: "学園前", class: "type_9"},
  /*1407 */{dataseat:1407, data_seatno: "s2-y-16-25", customer_id: "647", name: "友安 詠舞", mail: "", phone: "", studio: "学園前", class: "type_9"},
  /*1408 */{dataseat:1408, data_seatno: "s2-y-16-26", customer_id: "1315", name: "前田 優里", mail: "", phone: "", studio: "学園前", class: "type_9"},
  /*1409 */{dataseat:1409, data_seatno: "s2-y-16-27", customer_id: "1315", name: "前田 優里", mail: "", phone: "", studio: "学園前", class: "type_9"},
  /*1410 */{dataseat:1410, data_seatno: "s2-y-16-28", customer_id: "1519", name: "上村 桃絵", mail: "", phone: "", studio: "学園前", class: "type_9"},
  /*1411 */{dataseat:1411, data_seatno: "s2-y-16-33", customer_id: "1648", name: "井上 綾乃", mail: "", phone: "", studio: "学園前", class: "type_9"},
  /*1412 */{dataseat:1412, data_seatno: "s2-y-16-34", customer_id: "1648", name: "井上 綾乃", mail: "", phone: "", studio: "学園前", class: "type_9"},
  /*1413 */{dataseat:1413, data_seatno: "s2-y-16-35", customer_id: "1648", name: "井上 綾乃", mail: "", phone: "", studio: "学園前", class: "type_9"},
  /*1414 */{dataseat:1414, data_seatno: "s2-y-16-36", customer_id: "923", name: "小池 穂果", mail: "", phone: "", studio: "学園前", class: "type_9"},
  /*1415 */{dataseat:1415, data_seatno: "s2-y-16-37", customer_id: "923", name: "小池 穂果", mail: "", phone: "", studio: "学園前", class: "type_9"},
  /*1416 */{dataseat:1416, data_seatno: "s2-y-16-38", customer_id: "1110", name: "渡辺 世凰", mail: "", phone: "", studio: "学園前", class: "type_9"},
  /*1417 */{dataseat:1417, data_seatno: "s2-y-16-39", customer_id: "1110", name: "渡辺 世凰", mail: "", phone: "", studio: "学園前", class: "type_9"},
  /*1418 */{dataseat:1418, data_seatno: "s2-y-16-45", customer_id: "736", name: "篠田 杏奈", mail: "", phone: "", studio: "大宮", class: "type_8"},
  /*1419 */{dataseat:1419, data_seatno: "s2-y-16-46", customer_id: "736", name: "篠田 杏奈", mail: "", phone: "", studio: "大宮", class: "type_8"},
  /*1420 */{dataseat:1420, data_seatno: "s2-y-16-47", customer_id: "736", name: "篠田 杏奈", mail: "", phone: "", studio: "大宮", class: "type_8"},
  /*1421 */{dataseat:1421, data_seatno: "s2-y-16-48", customer_id: "736", name: "篠田 杏奈", mail: "", phone: "", studio: "大宮", class: "type_8"},
  //////////// 17列目 ////////////
  /*1422 */{dataseat:1422, data_seatno: "s2-y-17-1", customer_id: "1256", name: "", mail: "", phone: "", studio: "", class: "type_99"},
  /*1423 */{dataseat:1423, data_seatno: "s2-y-17-2", customer_id: "1256", name: "", mail: "", phone: "", studio: "", class: "type_99"},
  /*1424 */{dataseat:1424, data_seatno: "s2-y-17-3", customer_id: "1256", name: "", mail: "", phone: "", studio: "", class: "type_99"},
  /*1425 */{dataseat:1425, data_seatno: "s2-y-17-4", customer_id: "1256", name: "", mail: "", phone: "", studio: "", class: "type_99"},
  /*1426 */{dataseat:1426, data_seatno: "s2-y-17-10", customer_id: "1189", name: "久木野 心優", mail: "", phone: "", studio: "西神中央", class: "type_14"},
  /*1427 */{dataseat:1427, data_seatno: "s2-y-17-11", customer_id: "1189", name: "久木野 心優", mail: "", phone: "", studio: "西神中央", class: "type_14"},
  /*1428 */{dataseat:1428, data_seatno: "s2-y-17-12", customer_id: "1189", name: "久木野 心優", mail: "", phone: "", studio: "西神中央", class: "type_14"},
  /*1429 */{dataseat:1429, data_seatno: "s2-y-17-13", customer_id: "1229", name: "白猪 陽暖多", mail: "", phone: "", studio: "西神中央", class: "type_14"},
  /*1430 */{dataseat:1430, data_seatno: "s2-y-17-14", customer_id: "1229", name: "白猪 陽暖多", mail: "", phone: "", studio: "西神中央", class: "type_14"},
  /*1431 */{dataseat:1431, data_seatno: "s2-y-17-15", customer_id: "1261", name: "曾田 星空", mail: "", phone: "", studio: "西神中央", class: "type_14"},
  /*1432 */{dataseat:1432, data_seatno: "s2-y-17-16", customer_id: "1261", name: "曾田 星空", mail: "", phone: "", studio: "西神中央", class: "type_14"},
  /*1433 */{dataseat:1433, data_seatno: "s2-y-17-20", customer_id: "1804", name: "柴田 葵", mail: "", phone: "", studio: "学園前", class: "type_9"},
  /*1434 */{dataseat:1434, data_seatno: "s2-y-17-21", customer_id: "1804", name: "柴田 葵", mail: "", phone: "", studio: "学園前", class: "type_9"},
  /*1435 */{dataseat:1435, data_seatno: "s2-y-17-22", customer_id: "1537", name: "古賀 成美", mail: "", phone: "", studio: "学園前", class: "type_9"},
  /*1436 */{dataseat:1436, data_seatno: "s2-y-17-23", customer_id: "1537", name: "古賀 成美", mail: "", phone: "", studio: "学園前", class: "type_9"},
  /*1437 */{dataseat:1437, data_seatno: "s2-y-17-24", customer_id: "574", name: "伊藤 花恋", mail: "", phone: "", studio: "学園前", class: "type_9"},
  /*1438 */{dataseat:1438, data_seatno: "s2-y-17-25", customer_id: "574", name: "伊藤 花恋", mail: "", phone: "", studio: "学園前", class: "type_9"},
  /*1439 */{dataseat:1439, data_seatno: "s2-y-17-26", customer_id: "631", name: "野口 莉央", mail: "", phone: "", studio: "学園前", class: "type_9"},
  /*1440 */{dataseat:1440, data_seatno: "s2-y-17-27", customer_id: "631", name: "野口 莉央", mail: "", phone: "", studio: "学園前", class: "type_9"},
  /*1441 */{dataseat:1441, data_seatno: "s2-y-17-28", customer_id: "1519", name: "上村 桃絵", mail: "", phone: "", studio: "学園前", class: "type_9"},
  /*1442 */{dataseat:1442, data_seatno: "s2-y-17-33", customer_id: "1648", name: "井上 綾乃", mail: "", phone: "", studio: "学園前", class: "type_9"},
  /*1443 */{dataseat:1443, data_seatno: "s2-y-17-34", customer_id: "1648", name: "井上 綾乃", mail: "", phone: "", studio: "学園前", class: "type_9"},
  /*1444 */{dataseat:1444, data_seatno: "s2-y-17-35", customer_id: "1648", name: "井上 綾乃", mail: "", phone: "", studio: "学園前", class: "type_9"},
  /*1445 */{dataseat:1445, data_seatno: "s2-y-17-36", customer_id: "1243", name: "神吉 咲良", mail: "", phone: "", studio: "学園前", class: "type_9"},
  /*1446 */{dataseat:1446, data_seatno: "s2-y-17-37", customer_id: "1243", name: "神吉 咲良", mail: "", phone: "", studio: "学園前", class: "type_9"},
  /*1447 */{dataseat:1447, data_seatno: "s2-y-17-38", customer_id: "1328", name: "吉永 楓夏", mail: "", phone: "", studio: "学園前", class: "type_9"},
  /*1448 */{dataseat:1448, data_seatno: "s2-y-17-39", customer_id: "1328", name: "吉永 楓夏", mail: "", phone: "", studio: "学園前", class: "type_9"},
  /*1449 */{dataseat:1449, data_seatno: "s2-y-17-45", customer_id: "953", name: "加藤 麗華", mail: "", phone: "", studio: "大宮", class: "type_8"},
  /*1450 */{dataseat:1450, data_seatno: "s2-y-17-46", customer_id: "953", name: "加藤 麗華", mail: "", phone: "", studio: "大宮", class: "type_8"},
  /*1451 */{dataseat:1451, data_seatno: "s2-y-17-47", customer_id: "1051", name: "今井 あさひ", mail: "", phone: "", studio: "大宮", class: "type_8"},
  /*1452 */{dataseat:1452, data_seatno: "s2-y-17-48", customer_id: "1051", name: "今井 あさひ", mail: "", phone: "", studio: "大宮", class: "type_8"},
  //////////// 18列目 ////////////
  /*1453 */{dataseat:1453, data_seatno: "s2-y-18-1", customer_id: "1006", name: "北嶋 ルーナ", mail: "", phone: "", studio: "西神中央", class: "type_14"},
  /*1454 */{dataseat:1454, data_seatno: "s2-y-18-2", customer_id: "1006", name: "北嶋 ルーナ", mail: "", phone: "", studio: "西神中央", class: "type_14"},
  /*1455 */{dataseat:1455, data_seatno: "s2-y-18-3", customer_id: "1006", name: "北嶋 ルーナ", mail: "", phone: "", studio: "西神中央", class: "type_14"},
  /*1456 */{dataseat:1456, data_seatno: "s2-y-18-4", customer_id: "1006", name: "北嶋 ルーナ", mail: "", phone: "", studio: "西神中央", class: "type_14"},
  /*1457 */{dataseat:1457, data_seatno: "s2-y-18-5", customer_id: "1006", name: "北嶋 ルーナ", mail: "", phone: "", studio: "西神中央", class: "type_14"},
  /*1458 */{dataseat:1458, data_seatno: "s2-y-18-9", customer_id: "1375", name: "大江 望来", mail: "", phone: "", studio: "西神中央", class: "type_14"},
  /*1459 */{dataseat:1459, data_seatno: "s2-y-18-10", customer_id: "1375", name: "大江 望来", mail: "", phone: "", studio: "西神中央", class: "type_14"},
  /*1460 */{dataseat:1460, data_seatno: "s2-y-18-11", customer_id: "1396", name: "小林 夕莉菜", mail: "", phone: "", studio: "西神中央", class: "type_14"},
  /*1461 */{dataseat:1461, data_seatno: "s2-y-18-12", customer_id: "1396", name: "小林 夕莉菜", mail: "", phone: "", studio: "西神中央", class: "type_14"},
  /*1462 */{dataseat:1462, data_seatno: "s2-y-18-13", customer_id: "1842", name: "春吉 小羽", mail: "", phone: "", studio: "西神中央", class: "type_14"},
  /*1463 */{dataseat:1463, data_seatno: "s2-y-18-14", customer_id: "1842", name: "春吉 小羽", mail: "", phone: "", studio: "西神中央", class: "type_14"},
  /*1464 */{dataseat:1464, data_seatno: "s2-y-18-15", customer_id: "901", name: "森 いぶき", mail: "", phone: "", studio: "西神中央", class: "type_14"},
  /*1465 */{dataseat:1465, data_seatno: "s2-y-18-16", customer_id: "901", name: "森 いぶき", mail: "", phone: "", studio: "西神中央", class: "type_14"},
  /*1466 */{dataseat:1466, data_seatno: "s2-y-18-20", customer_id: "654", name: "生川 智佳子", mail: "", phone: "", studio: "学園前", class: "type_9"},
  /*1467 */{dataseat:1467, data_seatno: "s2-y-18-21", customer_id: "654", name: "生川 智佳子", mail: "", phone: "", studio: "学園前", class: "type_9"},
  /*1468 */{dataseat:1468, data_seatno: "s2-y-18-22", customer_id: "724", name: "寺西 祐良", mail: "", phone: "", studio: "学園前", class: "type_9"},
  /*1469 */{dataseat:1469, data_seatno: "s2-y-18-23", customer_id: "724", name: "寺西 祐良", mail: "", phone: "", studio: "学園前", class: "type_9"},
  /*1470 */{dataseat:1470, data_seatno: "s2-y-18-24", customer_id: "798", name: "坂口 心晴", mail: "", phone: "", studio: "学園前", class: "type_9"},
  /*1471 */{dataseat:1471, data_seatno: "s2-y-18-25", customer_id: "798", name: "坂口 心晴", mail: "", phone: "", studio: "学園前", class: "type_9"},
  /*1472 */{dataseat:1472, data_seatno: "s2-y-18-26", customer_id: "1001", name: "明浦 光里", mail: "", phone: "", studio: "学園前", class: "type_9"},
  /*1473 */{dataseat:1473, data_seatno: "s2-y-18-27", customer_id: "1001", name: "明浦 光里", mail: "", phone: "", studio: "学園前", class: "type_9"},
  /*1474 */{dataseat:1474, data_seatno: "s2-y-18-28", customer_id: "1390", name: "黒田 奈菜", mail: "", phone: "", studio: "学園前", class: "type_9"},
  /*1475 */{dataseat:1475, data_seatno: "s2-y-18-29", customer_id: "1390", name: "黒田 奈菜", mail: "", phone: "", studio: "学園前", class: "type_9"},
  /*1476 */{dataseat:1476, data_seatno: "s2-y-18-33", customer_id: "1394", name: "小櫻 夏帆", mail: "", phone: "", studio: "学園前", class: "type_9"},
  /*1477 */{dataseat:1477, data_seatno: "s2-y-18-34", customer_id: "1394", name: "小櫻 夏帆", mail: "", phone: "", studio: "学園前", class: "type_9"},
  /*1478 */{dataseat:1478, data_seatno: "s2-y-18-35", customer_id: "1413", name: "大形 七和花", mail: "", phone: "", studio: "学園前", class: "type_9"},
  /*1479 */{dataseat:1479, data_seatno: "s2-y-18-36", customer_id: "1413", name: "大形 七和花", mail: "", phone: "", studio: "学園前", class: "type_9"},
  /*1480 */{dataseat:1480, data_seatno: "s2-y-18-37", customer_id: "1479", name: "水ノ上 栞愛", mail: "", phone: "", studio: "学園前", class: "type_9"},
  /*1481 */{dataseat:1481, data_seatno: "s2-y-18-38", customer_id: "1479", name: "水ノ上 栞愛", mail: "", phone: "", studio: "学園前", class: "type_9"},
  /*1482 */{dataseat:1482, data_seatno: "s2-y-18-39", customer_id: "1535", name: "中野 葵子", mail: "", phone: "", studio: "学園前", class: "type_9"},
  /*1483 */{dataseat:1483, data_seatno: "s2-y-18-40", customer_id: "1535", name: "中野 葵子", mail: "", phone: "", studio: "学園前", class: "type_9"},
  /*1484 */{dataseat:1484, data_seatno: "s2-y-18-44", customer_id: "1151", name: "下浦 杏", mail: "", phone: "", studio: "大宮", class: "type_8"},
  /*1485 */{dataseat:1485, data_seatno: "s2-y-18-45", customer_id: "1151", name: "下浦 杏", mail: "", phone: "", studio: "大宮", class: "type_8"},
  /*1486 */{dataseat:1486, data_seatno: "s2-y-18-46", customer_id: "1151", name: "下浦 杏", mail: "", phone: "", studio: "大宮", class: "type_8"},
  /*1487 */{dataseat:1487, data_seatno: "s2-y-18-47", customer_id: "1091", name: "安原 唯愛", mail: "", phone: "", studio: "大宮", class: "type_8"},
  /*1488 */{dataseat:1488, data_seatno: "s2-y-18-48", customer_id: "1091", name: "安原 唯愛", mail: "", phone: "", studio: "大宮", class: "type_8"},
  //////////// 19列目 ////////////
  /*1489 */{dataseat:1489, data_seatno: "s2-y-19-1", customer_id: "1032", name: "久澄 心音", mail: "", phone: "", studio: "西神中央", class: "type_14"},
  /*1490 */{dataseat:1490, data_seatno: "s2-y-19-2", customer_id: "1032", name: "久澄 心音", mail: "", phone: "", studio: "西神中央", class: "type_14"},
  /*1491 */{dataseat:1491, data_seatno: "s2-y-19-3", customer_id: "1211", name: "川原 一乃", mail: "", phone: "", studio: "西神中央", class: "type_14"},
  /*1492 */{dataseat:1492, data_seatno: "s2-y-19-4", customer_id: "1211", name: "川原 一乃", mail: "", phone: "", studio: "西神中央", class: "type_14"},
  /*1493 */{dataseat:1493, data_seatno: "s2-y-19-5", customer_id: "1652", name: "鈴木 陽葵", mail: "", phone: "", studio: "西神中央", class: "type_14"},
  /*1494 */{dataseat:1494, data_seatno: "s2-y-19-6", customer_id: "1652", name: "鈴木 陽葵", mail: "", phone: "", studio: "西神中央", class: "type_14"},
  /*1495 */{dataseat:1495, data_seatno: "s2-y-19-8", customer_id: "1673", name: "前村 梨音", mail: "", phone: "", studio: "西神中央", class: "type_14"},
  /*1496 */{dataseat:1496, data_seatno: "s2-y-19-9", customer_id: "1673", name: "前村 梨音", mail: "", phone: "", studio: "西神中央", class: "type_14"},
  /*1497 */{dataseat:1497, data_seatno: "s2-y-19-10", customer_id: "1673", name: "前村 梨音", mail: "", phone: "", studio: "西神中央", class: "type_14"},
  /*1498 */{dataseat:1498, data_seatno: "s2-y-19-11", customer_id: "1673", name: "前村 梨音", mail: "", phone: "", studio: "西神中央", class: "type_14"},
  /*1499 */{dataseat:1499, data_seatno: "s2-y-19-12", customer_id: "1673", name: "前村 梨音", mail: "", phone: "", studio: "西神中央", class: "type_14"},
  /*1500 */{dataseat:1500, data_seatno: "s2-y-19-13", customer_id: "938", name: "松井 亜優菜", mail: "", phone: "", studio: "西神中央", class: "type_14"},
  /*1501 */{dataseat:1501, data_seatno: "s2-y-19-14", customer_id: "938", name: "松井 亜優菜", mail: "", phone: "", studio: "西神中央", class: "type_14"},
  /*1502 */{dataseat:1502, data_seatno: "s2-y-19-15", customer_id: "972", name: "平井 乃華", mail: "", phone: "", studio: "西神中央", class: "type_14"},
  /*1503 */{dataseat:1503, data_seatno: "s2-y-19-16", customer_id: "972", name: "平井 乃華", mail: "", phone: "", studio: "西神中央", class: "type_14"},
  /*1504 */{dataseat:1504, data_seatno: "s2-y-19-17", customer_id: "972", name: "平井 乃華", mail: "", phone: "", studio: "西神中央", class: "type_14"},
  /*1505 */{dataseat:1505, data_seatno: "s2-y-19-19", customer_id: "1391", name: "大政 陽奈", mail: "", phone: "", studio: "学園前", class: "type_9"},
  /*1506 */{dataseat:1506, data_seatno: "s2-y-19-20", customer_id: "1391", name: "大政 陽奈", mail: "", phone: "", studio: "学園前", class: "type_9"},
  /*1507 */{dataseat:1507, data_seatno: "s2-y-19-21", customer_id: "1592", name: "山内 咲奈", mail: "", phone: "", studio: "学園前", class: "type_9"},
  /*1508 */{dataseat:1508, data_seatno: "s2-y-19-22", customer_id: "1592", name: "山内 咲奈", mail: "", phone: "", studio: "学園前", class: "type_9"},
  /*1509 */{dataseat:1509, data_seatno: "s2-y-19-23", customer_id: "1620", name: "中井 蒼葉", mail: "", phone: "", studio: "学園前", class: "type_9"},
  /*1510 */{dataseat:1510, data_seatno: "s2-y-19-24", customer_id: "1620", name: "中井 蒼葉", mail: "", phone: "", studio: "学園前", class: "type_9"},
  /*1511 */{dataseat:1511, data_seatno: "s2-y-19-25", customer_id: "1778", name: "井上 柚", mail: "", phone: "", studio: "学園前", class: "type_9"},
  /*1512 */{dataseat:1512, data_seatno: "s2-y-19-26", customer_id: "1778", name: "井上 柚", mail: "", phone: "", studio: "学園前", class: "type_9"},
  /*1513 */{dataseat:1513, data_seatno: "s2-y-19-27", customer_id: "1906", name: "中野 花凛", mail: "", phone: "", studio: "学園前", class: "type_9"},
  /*1514 */{dataseat:1514, data_seatno: "s2-y-19-28", customer_id: "1906", name: "中野 花凛", mail: "", phone: "", studio: "学園前", class: "type_9"},
  /*1515 */{dataseat:1515, data_seatno: "s2-y-19-29", customer_id: "571", name: "石井 琉梛", mail: "", phone: "", studio: "学園前", class: "type_9"},
  /*1516 */{dataseat:1516, data_seatno: "s2-y-19-30", customer_id: "571", name: "石井 琉梛", mail: "", phone: "", studio: "学園前", class: "type_9"},
  /*1517 */{dataseat:1517, data_seatno: "s2-y-19-32", customer_id: "1807", name: "東 栞里", mail: "", phone: "", studio: "学園前", class: "type_9"},
  /*1518 */{dataseat:1518, data_seatno: "s2-y-19-33", customer_id: "1807", name: "東 栞里", mail: "", phone: "", studio: "学園前", class: "type_9"},
  /*1519 */{dataseat:1519, data_seatno: "s2-y-19-34", customer_id: "1877", name: "岡本 志保", mail: "", phone: "", studio: "学園前", class: "type_9"},
  /*1520 */{dataseat:1520, data_seatno: "s2-y-19-35", customer_id: "1877", name: "岡本 志保", mail: "", phone: "", studio: "学園前", class: "type_9"},
  /*1521 */{dataseat:1521, data_seatno: "s2-y-19-36", customer_id: "1885", name: "佐藤 茉紘", mail: "", phone: "", studio: "学園前", class: "type_9"},
  /*1522 */{dataseat:1522, data_seatno: "s2-y-19-37", customer_id: "1885", name: "佐藤 茉紘", mail: "", phone: "", studio: "学園前", class: "type_9"},
  /*1523 */{dataseat:1523, data_seatno: "s2-y-19-38", customer_id: "1976", name: "林田 真歩", mail: "", phone: "", studio: "学園前", class: "type_9"},
  /*1524 */{dataseat:1524, data_seatno: "s2-y-19-39", customer_id: "1976", name: "林田 真歩", mail: "", phone: "", studio: "学園前", class: "type_9"},
  /*1525 */{dataseat:1525, data_seatno: "s2-y-19-40", customer_id: "1731", name: "河野 恵怜", mail: "", phone: "", studio: "学園前", class: "type_9"},
  /*1526 */{dataseat:1526, data_seatno: "s2-y-19-41", customer_id: "1731", name: "河野 恵怜", mail: "", phone: "", studio: "学園前", class: "type_9"},
  /*1527 */{dataseat:1527, data_seatno: "s2-y-19-43", customer_id: "1412", name: "前田 愛華", mail: "", phone: "", studio: "大宮", class: "type_8"},
  /*1528 */{dataseat:1528, data_seatno: "s2-y-19-44", customer_id: "1412", name: "前田 愛華", mail: "", phone: "", studio: "大宮", class: "type_8"},
  /*1529 */{dataseat:1529, data_seatno: "s2-y-19-45", customer_id: "1507", name: "山中 咲季", mail: "", phone: "", studio: "大宮", class: "type_8"},
  /*1530 */{dataseat:1530, data_seatno: "s2-y-19-46", customer_id: "1507", name: "山中 咲季", mail: "", phone: "", studio: "大宮", class: "type_8"},
  /*1531 */{dataseat:1531, data_seatno: "s2-y-19-47", customer_id: "1755", name: "伍賀 帆希", mail: "", phone: "", studio: "大宮", class: "type_8"},
  /*1532 */{dataseat:1532, data_seatno: "s2-y-19-48", customer_id: "1755", name: "伍賀 帆希", mail: "", phone: "", studio: "大宮", class: "type_8"},
  //////////// 20列目 ////////////
  /*1533 */{dataseat:1533, data_seatno: "s2-y-20-1", customer_id: "902", name: "石野 莉子", mail: "", phone: "", studio: "西神中央", class: "type_14"},
  /*1534 */{dataseat:1534, data_seatno: "s2-y-20-2", customer_id: "902", name: "石野 莉子", mail: "", phone: "", studio: "西神中央", class: "type_14"},
  /*1535 */{dataseat:1535, data_seatno: "s2-y-20-3", customer_id: "905", name: "仲井 紫穂", mail: "", phone: "", studio: "西神中央", class: "type_14"},
  /*1536 */{dataseat:1536, data_seatno: "s2-y-20-4", customer_id: "905", name: "仲井 紫穂", mail: "", phone: "", studio: "西神中央", class: "type_14"},
  /*1537 */{dataseat:1537, data_seatno: "s2-y-20-5", customer_id: "907", name: "中村 優花", mail: "", phone: "", studio: "西神中央", class: "type_14"},
  /*1538 */{dataseat:1538, data_seatno: "s2-y-20-6", customer_id: "907", name: "中村 優花", mail: "", phone: "", studio: "西神中央", class: "type_14"},
  /*1539 */{dataseat:1539, data_seatno: "s2-y-20-8", customer_id: "1665", name: "小田 ひまり", mail: "", phone: "", studio: "西神中央", class: "type_14"},
  /*1540 */{dataseat:1540, data_seatno: "s2-y-20-9", customer_id: "1665", name: "小田 ひまり", mail: "", phone: "", studio: "西神中央", class: "type_14"},
  /*1541 */{dataseat:1541, data_seatno: "s2-y-20-10", customer_id: "1666", name: "坂下 仁那", mail: "", phone: "", studio: "西神中央", class: "type_14"},
  /*1542 */{dataseat:1542, data_seatno: "s2-y-20-11", customer_id: "1666", name: "坂下 仁那", mail: "", phone: "", studio: "西神中央", class: "type_14"},
  /*1543 */{dataseat:1543, data_seatno: "s2-y-20-12", customer_id: "1160", name: "川渡 帆高", mail: "", phone: "", studio: "西神中央", class: "type_14"},
  /*1544 */{dataseat:1544, data_seatno: "s2-y-20-13", customer_id: "1160", name: "川渡 帆高", mail: "", phone: "", studio: "西神中央", class: "type_14"},
  /*1545 */{dataseat:1545, data_seatno: "s2-y-20-14", customer_id: "1175", name: "中島 実結", mail: "", phone: "", studio: "西神中央", class: "type_14"},
  /*1546 */{dataseat:1546, data_seatno: "s2-y-20-15", customer_id: "1175", name: "中島 実結", mail: "", phone: "", studio: "西神中央", class: "type_14"},
  /*1547 */{dataseat:1547, data_seatno: "s2-y-20-16", customer_id: "1181", name: "平澤 美織", mail: "", phone: "", studio: "西神中央", class: "type_14"},
  /*1548 */{dataseat:1548, data_seatno: "s2-y-20-17", customer_id: "1181", name: "平澤 美織", mail: "", phone: "", studio: "西神中央", class: "type_14"},
  /*1549 */{dataseat:1549, data_seatno: "s2-y-20-19", customer_id: "748", name: "柳田 陽咲", mail: "", phone: "", studio: "SLASH選抜2", class: "type_11"},
  /*1550 */{dataseat:1550, data_seatno: "s2-y-20-20", customer_id: "748", name: "柳田 陽咲", mail: "", phone: "", studio: "SLASH選抜2", class: "type_11"},
  /*1551 */{dataseat:1551, data_seatno: "s2-y-20-21", customer_id: "783", name: "大橋 百椛", mail: "", phone: "", studio: "SLASH選抜2", class: "type_11"},
  /*1552 */{dataseat:1552, data_seatno: "s2-y-20-22", customer_id: "783", name: "大橋 百椛", mail: "", phone: "", studio: "SLASH選抜2", class: "type_11"},
  /*1553 */{dataseat:1553, data_seatno: "s2-y-20-23", customer_id: "1099", name: "今村 優真", mail: "", phone: "", studio: "SLASH選抜2", class: "type_11"},
  /*1554 */{dataseat:1554, data_seatno: "s2-y-20-24", customer_id: "1099", name: "今村 優真", mail: "", phone: "", studio: "SLASH選抜2", class: "type_11"},
  /*1555 */{dataseat:1555, data_seatno: "s2-y-20-25", customer_id: "1206", name: "山口 華朱", mail: "", phone: "", studio: "SLASH選抜2", class: "type_11"},
  /*1556 */{dataseat:1556, data_seatno: "s2-y-20-26", customer_id: "1206", name: "山口 華朱", mail: "", phone: "", studio: "SLASH選抜2", class: "type_11"},
  /*1557 */{dataseat:1557, data_seatno: "s2-y-20-27", customer_id: "629", name: "畠山 華凛", mail: "", phone: "", studio: "学園前", class: "type_9"},
  /*1558 */{dataseat:1558, data_seatno: "s2-y-20-28", customer_id: "629", name: "畠山 華凛", mail: "", phone: "", studio: "学園前", class: "type_9"},
  /*1559 */{dataseat:1559, data_seatno: "s2-y-20-29", customer_id: "921", name: "東田 結莉", mail: "", phone: "", studio: "学園前", class: "type_9"},
  /*1560 */{dataseat:1560, data_seatno: "s2-y-20-30", customer_id: "921", name: "東田 結莉", mail: "", phone: "", studio: "学園前", class: "type_9"},
  /*1561 */{dataseat:1561, data_seatno: "s2-y-20-32", customer_id: "994", name: "秋山 歩美", mail: "", phone: "", studio: "学園前", class: "type_9"},
  /*1562 */{dataseat:1562, data_seatno: "s2-y-20-33", customer_id: "994", name: "秋山 歩美", mail: "", phone: "", studio: "学園前", class: "type_9"},
  /*1563 */{dataseat:1563, data_seatno: "s2-y-20-34", customer_id: "994", name: "秋山 歩美", mail: "", phone: "", studio: "学園前", class: "type_9"},
  /*1564 */{dataseat:1564, data_seatno: "s2-y-20-35", customer_id: "883", name: "津田 柚来", mail: "", phone: "", studio: "垂水", class: "type_17"},
  /*1565 */{dataseat:1565, data_seatno: "s2-y-20-36", customer_id: "883", name: "津田 柚来", mail: "", phone: "", studio: "垂水", class: "type_17"},
  /*1566 */{dataseat:1566, data_seatno: "s2-y-20-37", customer_id: "", name: "津田愛", mail: "lilaluvm@yahoo.co.jp", phone: "09098870383", studio: "垂水", class: "type_17"},
  /*1567 */{dataseat:1567, data_seatno: "s2-y-20-38", customer_id: "1609", name: "濱野 智美", mail: "", phone: "", studio: "垂水", class: "type_17"},
  /*1568 */{dataseat:1568, data_seatno: "s2-y-20-39", customer_id: "1609", name: "濱野 智美", mail: "", phone: "", studio: "垂水", class: "type_17"},
  /*1569 */{dataseat:1569, data_seatno: "s2-y-20-40", customer_id: "870", name: "安西 美結", mail: "", phone: "", studio: "垂水", class: "type_17"},
  /*1570 */{dataseat:1570, data_seatno: "s2-y-20-41", customer_id: "870", name: "安西 美結", mail: "", phone: "", studio: "垂水", class: "type_17"},
  /*1571 */{dataseat:1571, data_seatno: "s2-y-20-43", customer_id: "1232", name: "酒見 果歩", mail: "", phone: "", studio: "垂水", class: "type_17"},
  /*1572 */{dataseat:1572, data_seatno: "s2-y-20-44", customer_id: "1232", name: "酒見 果歩", mail: "", phone: "", studio: "垂水", class: "type_17"},
  /*1573 */{dataseat:1573, data_seatno: "s2-y-20-45", customer_id: "1308", name: "小山 紗加", mail: "", phone: "", studio: "垂水", class: "type_17"},
  /*1574 */{dataseat:1574, data_seatno: "s2-y-20-46", customer_id: "1308", name: "小山 紗加", mail: "", phone: "", studio: "垂水", class: "type_17"},
  /*1575 */{dataseat:1575, data_seatno: "s2-y-20-47", customer_id: "1458", name: "松浦 莉央", mail: "", phone: "", studio: "垂水", class: "type_17"},
  /*1576 */{dataseat:1576, data_seatno: "s2-y-20-48", customer_id: "1458", name: "松浦 莉央", mail: "", phone: "", studio: "垂水", class: "type_17"},
  //////////// 21列目 ////////////
  /*1577 */{dataseat:1577, data_seatno: "s2-y-21-1", customer_id: "1192", name: "橋本 來春", mail: "", phone: "", studio: "西神中央", class: "type_14"},
  /*1578 */{dataseat:1578, data_seatno: "s2-y-21-2", customer_id: "1192", name: "橋本 來春", mail: "", phone: "", studio: "西神中央", class: "type_14"},
  /*1579 */{dataseat:1579, data_seatno: "s2-y-21-3", customer_id: "1193", name: "鯉田 心菜", mail: "", phone: "", studio: "西神中央", class: "type_14"},
  /*1580 */{dataseat:1580, data_seatno: "s2-y-21-4", customer_id: "1193", name: "鯉田 心菜", mail: "", phone: "", studio: "西神中央", class: "type_14"},
  /*1581 */{dataseat:1581, data_seatno: "s2-y-21-5", customer_id: "1203", name: "羽口 愛姫", mail: "", phone: "", studio: "西神中央", class: "type_14"},
  /*1582 */{dataseat:1582, data_seatno: "s2-y-21-6", customer_id: "1203", name: "羽口 愛姫", mail: "", phone: "", studio: "西神中央", class: "type_14"},
  /*1583 */{dataseat:1583, data_seatno: "s2-y-21-8", customer_id: "1207", name: "土井 望華", mail: "", phone: "", studio: "西神中央", class: "type_14"},
  /*1584 */{dataseat:1584, data_seatno: "s2-y-21-9", customer_id: "1207", name: "土井 望華", mail: "", phone: "", studio: "西神中央", class: "type_14"},
  /*1585 */{dataseat:1585, data_seatno: "s2-y-21-10", customer_id: "1216", name: "分玉 小奈月", mail: "", phone: "", studio: "西神中央", class: "type_14"},
  /*1586 */{dataseat:1586, data_seatno: "s2-y-21-11", customer_id: "1216", name: "分玉 小奈月", mail: "", phone: "", studio: "西神中央", class: "type_14"},
  /*1587 */{dataseat:1587, data_seatno: "s2-y-21-12", customer_id: "1221", name: "山崎 ひかり", mail: "", phone: "", studio: "西神中央", class: "type_14"},
  /*1588 */{dataseat:1588, data_seatno: "s2-y-21-13", customer_id: "1221", name: "山崎 ひかり", mail: "", phone: "", studio: "西神中央", class: "type_14"},
  /*1589 */{dataseat:1589, data_seatno: "s2-y-21-14", customer_id: "1646", name: "大丸 佐矢香", mail: "", phone: "", studio: "西神中央", class: "type_14"},
  /*1590 */{dataseat:1590, data_seatno: "s2-y-21-15", customer_id: "1646", name: "大丸 佐矢香", mail: "", phone: "", studio: "西神中央", class: "type_14"},
  /*1591 */{dataseat:1591, data_seatno: "s2-y-21-16", customer_id: "1655", name: "高嶋 ひより", mail: "", phone: "", studio: "西神中央", class: "type_14"},
  /*1592 */{dataseat:1592, data_seatno: "s2-y-21-17", customer_id: "1655", name: "高嶋 ひより", mail: "", phone: "", studio: "西神中央", class: "type_14"},
  /*1593 */{dataseat:1593, data_seatno: "s2-y-21-19", customer_id: "566", name: "松岡 楼子", mail: "", phone: "", studio: "SLASH選抜2", class: "type_11"},
  /*1594 */{dataseat:1594, data_seatno: "s2-y-21-20", customer_id: "566", name: "松岡 楼子", mail: "", phone: "", studio: "SLASH選抜2", class: "type_11"},
  /*1595 */{dataseat:1595, data_seatno: "s2-y-21-21", customer_id: "567", name: "川島 心愛", mail: "", phone: "", studio: "SLASH選抜2", class: "type_11"},
  /*1596 */{dataseat:1596, data_seatno: "s2-y-21-22", customer_id: "567", name: "川島 心愛", mail: "", phone: "", studio: "SLASH選抜2", class: "type_11"},
  /*1597 */{dataseat:1597, data_seatno: "s2-y-21-23", customer_id: "570", name: "鈴木 星菜", mail: "", phone: "", studio: "SLASH選抜2", class: "type_11"},
  /*1598 */{dataseat:1598, data_seatno: "s2-y-21-24", customer_id: "570", name: "鈴木 星菜", mail: "", phone: "", studio: "SLASH選抜2", class: "type_11"},
  /*1599 */{dataseat:1599, data_seatno: "s2-y-21-25", customer_id: "587", name: "新坂 つむぎ", mail: "", phone: "", studio: "SLASH選抜2", class: "type_11"},
  /*1601 */{dataseat:1601, data_seatno: "s2-y-21-26", customer_id: "587", name: "新坂 つむぎ", mail: "", phone: "", studio: "SLASH選抜2", class: "type_11"},
  /*1600 */{dataseat:1600, data_seatno: "s2-y-21-27", customer_id: "607", name: "市川 杏莉", mail: "", phone: "", studio: "SLASH選抜2", class: "type_11"},
  /*1602 */{dataseat:1602, data_seatno: "s2-y-21-28", customer_id: "607", name: "市川 杏莉", mail: "", phone: "", studio: "SLASH選抜2", class: "type_11"},
  /*1603 */{dataseat:1603, data_seatno: "s2-y-21-29", customer_id: "738", name: "藤原 妃菜", mail: "", phone: "", studio: "SLASH選抜2", class: "type_11"},
  /*1604 */{dataseat:1604, data_seatno: "s2-y-21-30", customer_id: "738", name: "藤原 妃菜", mail: "", phone: "", studio: "SLASH選抜2", class: "type_11"},
  /*1605 */{dataseat:1605, data_seatno: "s2-y-21-32", customer_id: "835", name: "松永 琉花", mail: "", phone: "", studio: "垂水", class: "type_17"},
  /*1606 */{dataseat:1606, data_seatno: "s2-y-21-33", customer_id: "835", name: "松永 琉花", mail: "", phone: "", studio: "垂水", class: "type_17"},
  /*1607 */{dataseat:1607, data_seatno: "s2-y-21-34", customer_id: "839", name: "嘉戸 文音", mail: "", phone: "", studio: "垂水", class: "type_17"},
  /*1608 */{dataseat:1608, data_seatno: "s2-y-21-35", customer_id: "839", name: "嘉戸 文音", mail: "", phone: "", studio: "垂水", class: "type_17"},
  /*1609 */{dataseat:1609, data_seatno: "s2-y-21-36", customer_id: "873", name: "内海 惠里衣", mail: "", phone: "", studio: "垂水", class: "type_17"},
  /*1610 */{dataseat:1610, data_seatno: "s2-y-21-37", customer_id: "873", name: "内海 惠里衣", mail: "", phone: "", studio: "垂水", class: "type_17"},
  /*1611 */{dataseat:1611, data_seatno: "s2-y-21-38", customer_id: "876", name: "小土井 友莉", mail: "", phone: "", studio: "垂水", class: "type_17"},
  /*1612 */{dataseat:1612, data_seatno: "s2-y-21-39", customer_id: "876", name: "小土井 友莉", mail: "", phone: "", studio: "垂水", class: "type_17"},
  /*1613 */{dataseat:1613, data_seatno: "s2-y-21-40", customer_id: "892", name: "田村 理桜", mail: "", phone: "", studio: "垂水", class: "type_17"},
  /*1614 */{dataseat:1614, data_seatno: "s2-y-21-41", customer_id: "892", name: "田村 理桜", mail: "", phone: "", studio: "垂水", class: "type_17"},
  /*1615 */{dataseat:1615, data_seatno: "s2-y-21-43", customer_id: "962", name: "堀部 莉杏", mail: "", phone: "", studio: "垂水", class: "type_17"},
  /*1616 */{dataseat:1616, data_seatno: "s2-y-21-44", customer_id: "962", name: "堀部 莉杏", mail: "", phone: "", studio: "垂水", class: "type_17"},
  /*1617 */{dataseat:1617, data_seatno: "s2-y-21-45", customer_id: "1107", name: "岩城 絆愛", mail: "", phone: "", studio: "垂水", class: "type_17"},
  /*1618 */{dataseat:1618, data_seatno: "s2-y-21-46", customer_id: "1107", name: "岩城 絆愛", mail: "", phone: "", studio: "垂水", class: "type_17"},
  /*1619 */{dataseat:1619, data_seatno: "s2-y-21-47", customer_id: "1164", name: "梶浦 結乃", mail: "", phone: "", studio: "垂水", class: "type_17"},
  /*1620 */{dataseat:1620, data_seatno: "s2-y-21-48", customer_id: "1164", name: "梶浦 結乃", mail: "", phone: "", studio: "垂水", class: "type_17"},
  //////////// 22列目 ////////////
  /*1621 */{dataseat:1621, data_seatno: "s2-y-22-1", customer_id: "1656", name: "石井 彩香", mail: "", phone: "", studio: "西神中央", class: "type_14"},
  /*1622 */{dataseat:1622, data_seatno: "s2-y-22-2", customer_id: "1656", name: "石井 彩香", mail: "", phone: "", studio: "西神中央", class: "type_14"},
  /*1623 */{dataseat:1623, data_seatno: "s2-y-22-3", customer_id: "", name: "石井早紀", mail: "sakyenfleur@gmail.com", phone: "09094579306", studio: "西神中央", class: "type_14"},
  /*1624 */{dataseat:1624, data_seatno: "s2-y-22-4", customer_id: "", name: "石井早紀", mail: "sakyenfleur@gmail.com", phone: "09094579306", studio: "西神中央", class: "type_14"},
  /*1625 */{dataseat:1625, data_seatno: "s2-y-22-5", customer_id: "1662", name: "伊藤 杏", mail: "", phone: "", studio: "西神中央", class: "type_14"},
  /*1626 */{dataseat:1626, data_seatno: "s2-y-22-6", customer_id: "1662", name: "伊藤 杏", mail: "", phone: "", studio: "西神中央", class: "type_14"},
  /*1627 */{dataseat:1627, data_seatno: "s2-y-22-8", customer_id: "1324", name: "小原 凛香", mail: "", phone: "", studio: "西神中央", class: "type_14"},
  /*1628 */{dataseat:1628, data_seatno: "s2-y-22-9", customer_id: "1324", name: "小原 凛香", mail: "", phone: "", studio: "西神中央", class: "type_14"},
  /*1629 */{dataseat:1629, data_seatno: "s2-y-22-10", customer_id: "1591", name: "松村 美咲", mail: "", phone: "", studio: "西神中央", class: "type_14"},
  /*1630 */{dataseat:1630, data_seatno: "s2-y-22-11", customer_id: "1591", name: "松村 美咲", mail: "", phone: "", studio: "西神中央", class: "type_14"},
  /*1631 */{dataseat:1631, data_seatno: "s2-y-22-12", customer_id: "898", name: "西 凛花", mail: "", phone: "", studio: "西神中央", class: "type_14"},
  /*1632 */{dataseat:1632, data_seatno: "s2-y-22-13", customer_id: "898", name: "西 凛花", mail: "", phone: "", studio: "西神中央", class: "type_14"},
  /*1633 */{dataseat:1633, data_seatno: "s2-y-22-14", customer_id: "940", name: "村川 こはる", mail: "", phone: "", studio: "西神中央", class: "type_14"},
  /*1634 */{dataseat:1634, data_seatno: "s2-y-22-15", customer_id: "940", name: "村川 こはる", mail: "", phone: "", studio: "西神中央", class: "type_14"},
  /*1635 */{dataseat:1635, data_seatno: "s2-y-22-16", customer_id: "903", name: "滝本 果音", mail: "", phone: "", studio: "西神中央", class: "type_14"},
  /*1636 */{dataseat:1636, data_seatno: "s2-y-22-17", customer_id: "903", name: "滝本 果音", mail: "", phone: "", studio: "西神中央", class: "type_14"},
  /*1637 */{dataseat:1637, data_seatno: "s2-y-22-19", customer_id: "", name: "AYAKA_SHIGA", mail: "", phone: "", studio: "関係者2", class: "type_21"},
  /*1638 */{dataseat:1638, data_seatno: "s2-y-22-20", customer_id: "", name: "AYAKA_SHIGA", mail: "", phone: "", studio: "関係者2", class: "type_21"},
  /*1639 */{dataseat:1639, data_seatno: "s2-y-22-21", customer_id: "", name: "HINA", mail: "", phone: "", studio: "関係者2", class: "type_21"},
  /*1640 */{dataseat:1640, data_seatno: "s2-y-22-22", customer_id: "", name: "HINA", mail: "", phone: "", studio: "関係者2", class: "type_21"},
  /*1641 */{dataseat:1641, data_seatno: "s2-y-22-23", customer_id: "", name: "JUNI", mail: "", phone: "", studio: "関係者2", class: "type_21"},
  /*1642 */{dataseat:1642, data_seatno: "s2-y-22-24", customer_id: "", name: "JUNI", mail: "", phone: "", studio: "関係者2", class: "type_21"},
  /*1643 */{dataseat:1643, data_seatno: "s2-y-22-25", customer_id: "", name: "NARUMI", mail: "", phone: "", studio: "関係者2", class: "type_21"},
  /*1644 */{dataseat:1644, data_seatno: "s2-y-22-26", customer_id: "", name: "NARUMI", mail: "", phone: "", studio: "関係者2", class: "type_21"},
  /*1645 */{dataseat:1645, data_seatno: "s2-y-22-27", customer_id: "", name: "YUI", mail: "yuiballet@icloud.com", phone: "08053117596", studio: "関係者2", class: "type_21"},
  /*1646 */{dataseat:1646, data_seatno: "s2-y-22-28", customer_id: "", name: "YUI", mail: "yuiballet@icloud.com", phone: "08053117596", studio: "関係者2", class: "type_21"},
  /*1647 */{dataseat:1647, data_seatno: "s2-y-22-29", customer_id: "", name: "YUI", mail: "yuiballet@icloud.com", phone: "08053117596", studio: "関係者2", class: "type_21"},
  /*1648 */{dataseat:1648, data_seatno: "s2-y-22-30", customer_id: "", name: "", mail: "", phone: "", studio: "関係者2", class: "type_21"},
  /*1649 */{dataseat:1649, data_seatno: "s2-y-22-32", customer_id: "1062", name: "河内 かんな", mail: "", phone: "", studio: "垂水", class: "type_17"},
  /*1650 */{dataseat:1650, data_seatno: "s2-y-22-33", customer_id: "1062", name: "河内 かんな", mail: "", phone: "", studio: "垂水", class: "type_17"},
  /*1651 */{dataseat:1651, data_seatno: "s2-y-22-34", customer_id: "1487", name: "武田 彩愛", mail: "", phone: "", studio: "垂水", class: "type_17"},
  /*1652 */{dataseat:1652, data_seatno: "s2-y-22-35", customer_id: "1487", name: "武田 彩愛", mail: "", phone: "", studio: "垂水", class: "type_17"},
  /*1653 */{dataseat:1653, data_seatno: "s2-y-22-36", customer_id: "1684", name: "阿久津 結花", mail: "", phone: "", studio: "垂水", class: "type_17"},
  /*1654 */{dataseat:1654, data_seatno: "s2-y-22-37", customer_id: "1684", name: "阿久津 結花", mail: "", phone: "", studio: "垂水", class: "type_17"},
  /*1655 */{dataseat:1655, data_seatno: "s2-y-22-38", customer_id: "1841", name: "川口 陽真莉", mail: "", phone: "", studio: "垂水", class: "type_17"},
  /*1656 */{dataseat:1656, data_seatno: "s2-y-22-39", customer_id: "1841", name: "川口 陽真莉", mail: "", phone: "", studio: "垂水", class: "type_17"},
  /*1657 */{dataseat:1657, data_seatno: "s2-y-22-40", customer_id: "1880", name: "荻野 真菜", mail: "", phone: "", studio: "垂水", class: "type_17"},
  /*1658 */{dataseat:1658, data_seatno: "s2-y-22-41", customer_id: "1880", name: "荻野 真菜", mail: "", phone: "", studio: "垂水", class: "type_17"},
  /*1659 */{dataseat:1659, data_seatno: "s2-y-22-43", customer_id: "838", name: "小村 茉意", mail: "", phone: "", studio: "垂水", class: "type_17"},
  /*1660 */{dataseat:1660, data_seatno: "s2-y-22-44", customer_id: "838", name: "小村 茉意", mail: "", phone: "", studio: "垂水", class: "type_17"},
  /*1661 */{dataseat:1661, data_seatno: "s2-y-22-45", customer_id: "867", name: "次本 結里優", mail: "", phone: "", studio: "垂水", class: "type_17"},
  /*1662 */{dataseat:1662, data_seatno: "s2-y-22-46", customer_id: "867", name: "次本 結里優", mail: "", phone: "", studio: "垂水", class: "type_17"},
  /*1663 */{dataseat:1663, data_seatno: "s2-y-22-47", customer_id: "879", name: "黒田 紗菜", mail: "", phone: "", studio: "垂水", class: "type_17"},
  /*1664 */{dataseat:1664, data_seatno: "s2-y-22-48", customer_id: "879", name: "黒田 紗菜", mail: "", phone: "", studio: "垂水", class: "type_17"},
  //////////// 23列目 ////////////
  /*1665 */{dataseat:1665, data_seatno: "s2-y-23-1", customer_id: "1721", name: "山本 愛来", mail: "", phone: "", studio: "西神中央", class: "type_14"},
  /*1666 */{dataseat:1666, data_seatno: "s2-y-23-2", customer_id: "1721", name: "山本 愛来", mail: "", phone: "", studio: "西神中央", class: "type_14"},
  /*1667 */{dataseat:1667, data_seatno: "s2-y-23-3", customer_id: "1190", name: "岡田 星奈", mail: "", phone: "", studio: "西神中央", class: "type_14"},
  /*1668 */{dataseat:1668, data_seatno: "s2-y-23-4", customer_id: "1190", name: "岡田 星奈", mail: "", phone: "", studio: "西神中央", class: "type_14"},
  /*1669 */{dataseat:1669, data_seatno: "s2-y-23-5", customer_id: "1195", name: "手島 すず花", mail: "", phone: "", studio: "西神中央", class: "type_14"},
  /*1670 */{dataseat:1670, data_seatno: "s2-y-23-6", customer_id: "1195", name: "手島 すず花", mail: "", phone: "", studio: "西神中央", class: "type_14"},
  /*1671 */{dataseat:1671, data_seatno: "s2-y-23-8", customer_id: "895", name: "幸田 こはる", mail: "", phone: "", studio: "西神中央", class: "type_14"},
  /*1672 */{dataseat:1672, data_seatno: "s2-y-23-9", customer_id: "895", name: "幸田 こはる", mail: "", phone: "", studio: "西神中央", class: "type_14"},
  /*1673 */{dataseat:1673, data_seatno: "s2-y-23-10", customer_id: "1009", name: "氏家 彩菜", mail: "", phone: "", studio: "西神中央", class: "type_14"},
  /*1674 */{dataseat:1674, data_seatno: "s2-y-23-11", customer_id: "1009", name: "氏家 彩菜", mail: "", phone: "", studio: "西神中央", class: "type_14"},
  /*1675 */{dataseat:1675, data_seatno: "s2-y-23-12", customer_id: "1013", name: "三村 夢乃", mail: "", phone: "", studio: "西神中央", class: "type_14"},
  /*1676 */{dataseat:1676, data_seatno: "s2-y-23-13", customer_id: "1013", name: "三村 夢乃", mail: "", phone: "", studio: "西神中央", class: "type_14"},
  /*1677 */{dataseat:1677, data_seatno: "s2-y-23-14", customer_id: "1028", name: "中嶋 心春", mail: "", phone: "", studio: "西神中央", class: "type_14"},
  /*1678 */{dataseat:1678, data_seatno: "s2-y-23-15", customer_id: "1028", name: "中嶋 心春", mail: "", phone: "", studio: "西神中央", class: "type_14"},
  /*1679 */{dataseat:1679, data_seatno: "s2-y-23-16", customer_id: "1034", name: "米澤 薫", mail: "", phone: "", studio: "西神中央", class: "type_14"},
  /*1680 */{dataseat:1680, data_seatno: "s2-y-23-17", customer_id: "1034", name: "米澤 薫", mail: "", phone: "", studio: "西神中央", class: "type_14"},
  /*1681 */{dataseat:1681, data_seatno: "s2-y-23-19", customer_id: "", name: "RINA", mail: "", phone: "", studio: "関係者2", class: "type_21"},
  /*1682 */{dataseat:1682, data_seatno: "s2-y-23-20", customer_id: "", name: "RINA", mail: "", phone: "", studio: "関係者2", class: "type_21"},
  /*1683 */{dataseat:1683, data_seatno: "s2-y-23-21", customer_id: "", name: "YUME", mail: "", phone: "", studio: "関係者2", class: "type_21"},
  /*1684 */{dataseat:1684, data_seatno: "s2-y-23-22", customer_id: "", name: "YUME", mail: "", phone: "", studio: "関係者2", class: "type_21"},
  /*1685 */{dataseat:1685, data_seatno: "s2-y-23-23", customer_id: "", name: "YUME", mail: "", phone: "", studio: "関係者2", class: "type_21"},
  /*1686 */{dataseat:1686, data_seatno: "s2-y-23-24", customer_id: "", name: "YUI", mail: "yuiballet@icloud.com", phone: "08053117596", studio: "関係者2", class: "type_21"},
  /*1687 */{dataseat:1687, data_seatno: "s2-y-23-25", customer_id: "", name: "YUI", mail: "yuiballet@icloud.com", phone: "08053117596", studio: "関係者2", class: "type_21"},
  /*1688 */{dataseat:1688, data_seatno: "s2-y-23-26", customer_id: "", name: "", mail: "", phone: "", studio: "関係者2", class: "type_21"},
  /*1689 */{dataseat:1689, data_seatno: "s2-y-23-27", customer_id: "", name: "", mail: "", phone: "", studio: "関係者2", class: "type_21"},
  /*1690 */{dataseat:1690, data_seatno: "s2-y-23-28", customer_id: "", name: "", mail: "", phone: "", studio: "関係者2", class: "type_21"},
  /*1691 */{dataseat:1691, data_seatno: "s2-y-23-29", customer_id: "", name: "", mail: "", phone: "", studio: "関係者2", class: "type_21"},
  /*1692 */{dataseat:1692, data_seatno: "s2-y-23-30", customer_id: "", name: "", mail: "", phone: "", studio: "関係者2", class: "type_21"},
  /*1693 */{dataseat:1693, data_seatno: "s2-y-23-32", customer_id: "610", name: "井上 愛梨", mail: "", phone: "", studio: "学園前", class: "type_9"},
  /*1694 */{dataseat:1694, data_seatno: "s2-y-23-33", customer_id: "888", name: "谷垣 結菜", mail: "", phone: "", studio: "垂水", class: "type_17"},
  /*1695 */{dataseat:1695, data_seatno: "s2-y-23-34", customer_id: "888", name: "谷垣 結菜", mail: "", phone: "", studio: "垂水", class: "type_17"},
  /*1696 */{dataseat:1696, data_seatno: "s2-y-23-35", customer_id: "926", name: "大西 心桜", mail: "", phone: "", studio: "垂水", class: "type_17"},
  /*1697 */{dataseat:1697, data_seatno: "s2-y-23-36", customer_id: "926", name: "大西 心桜", mail: "", phone: "", studio: "垂水", class: "type_17"},
  /*1698 */{dataseat:1698, data_seatno: "s2-y-23-37", customer_id: "2016", name: "宮﨑 勝羽", mail: "", phone: "", studio: "武庫川 初級", class: "type_18"},
  /*1699 */{dataseat:1699, data_seatno: "s2-y-23-38", customer_id: "2016", name: "宮﨑 勝羽", mail: "", phone: "", studio: "武庫川 初級", class: "type_18"},
  /*1700 */{dataseat:1700, data_seatno: "s2-y-23-39", customer_id: "2016", name: "宮﨑 勝羽", mail: "", phone: "", studio: "武庫川 初級", class: "type_18"},
  /*1701 */{dataseat:1701, data_seatno: "s2-y-23-40", customer_id: "2007", name: "伊丹 榎菜", mail: "", phone: "", studio: "武庫川 初級", class: "type_18"},
  /*1702 */{dataseat:1702, data_seatno: "s2-y-23-41", customer_id: "2007", name: "伊丹 榎菜", mail: "", phone: "", studio: "武庫川 初級", class: "type_18"},
  /*1703 */{dataseat:1703, data_seatno: "s2-y-23-43", customer_id: "2017", name: "野本 結愛", mail: "", phone: "", studio: "武庫川 初級", class: "type_18"},
  /*1704 */{dataseat:1704, data_seatno: "s2-y-23-44", customer_id: "2017", name: "野本 結愛", mail: "", phone: "", studio: "武庫川 初級", class: "type_18"},
  /*1705 */{dataseat:1705, data_seatno: "s2-y-23-45", customer_id: "2017", name: "野本 結愛", mail: "", phone: "", studio: "武庫川 初級", class: "type_18"},
  /*1706 */{dataseat:1706, data_seatno: "s2-y-23-46", customer_id: "2017", name: "野本 結愛", mail: "", phone: "", studio: "武庫川 初級", class: "type_18"},
  /*1707 */{dataseat:1707, data_seatno: "s2-y-23-47", customer_id: "2017", name: "野本 結愛", mail: "", phone: "", studio: "武庫川 初級", class: "type_18"},
  /*1708 */{dataseat:1708, data_seatno: "s2-y-23-48", customer_id: "2017", name: "野本 結愛", mail: "", phone: "", studio: "武庫川 初級", class: "type_18"},
  //////////// 24列目 ////////////
  /*1709 */{dataseat:1709, data_seatno: "s2-y-24-1", customer_id: "1043", name: "田代 月夢", mail: "", phone: "", studio: "西神中央", class: "type_14"},
  /*1710 */{dataseat:1710, data_seatno: "s2-y-24-2", customer_id: "1043", name: "田代 月夢", mail: "", phone: "", studio: "西神中央", class: "type_14"},
  /*1711 */{dataseat:1711, data_seatno: "s2-y-24-3", customer_id: "1054", name: "下田 美優菜", mail: "", phone: "", studio: "西神中央", class: "type_14"},
  /*1712 */{dataseat:1712, data_seatno: "s2-y-24-4", customer_id: "1054", name: "下田 美優菜", mail: "", phone: "", studio: "西神中央", class: "type_14"},
  /*1713 */{dataseat:1713, data_seatno: "s2-y-24-5", customer_id: "1222", name: "榊 寧々", mail: "", phone: "", studio: "西神中央", class: "type_14"},
  /*1714 */{dataseat:1714, data_seatno: "s2-y-24-6", customer_id: "1222", name: "榊 寧々", mail: "", phone: "", studio: "西神中央", class: "type_14"},
  /*1715 */{dataseat:1715, data_seatno: "s2-y-24-8", customer_id: "906", name: "新居 真帆", mail: "", phone: "", studio: "西神中央", class: "type_14"},
  /*1716 */{dataseat:1716, data_seatno: "s2-y-24-9", customer_id: "906", name: "新居 真帆", mail: "", phone: "", studio: "西神中央", class: "type_14"},
  /*1717 */{dataseat:1717, data_seatno: "s2-y-24-10", customer_id: "906", name: "新居 真帆", mail: "", phone: "", studio: "西神中央", class: "type_14"},
  /*1718 */{dataseat:1718, data_seatno: "s2-y-24-11", customer_id: "1663", name: "岡崎 紗和", mail: "", phone: "", studio: "西神中央", class: "type_14"},
  /*1719 */{dataseat:1719, data_seatno: "s2-y-24-12", customer_id: "1663", name: "岡崎 紗和", mail: "", phone: "", studio: "西神中央", class: "type_14"},
  /*1720 */{dataseat:1720, data_seatno: "s2-y-24-13", customer_id: "1709", name: "湯元 花奈", mail: "", phone: "", studio: "西神中央", class: "type_14"},
  /*1721 */{dataseat:1721, data_seatno: "s2-y-24-14", customer_id: "1709", name: "湯元 花奈", mail: "", phone: "", studio: "西神中央", class: "type_14"},
  /*1722 */{dataseat:1722, data_seatno: "s2-y-24-15", customer_id: "", name: "相物 美乃織", mail: "mana.irie.yoshi.0428@gmail.com", phone: "09077543752", studio: "一般2", class: "type_20"},
  /*1723 */{dataseat:1723, data_seatno: "s2-y-24-16", customer_id: "", name: "相物 美乃織", mail: "mana.irie.yoshi.0428@gmail.com", phone: "09077543752", studio: "一般2", class: "type_20"},
  /*1724 */{dataseat:1724, data_seatno: "s2-y-24-17", customer_id: "", name: "相物 美乃織", mail: "mana.irie.yoshi.0428@gmail.com", phone: "09077543752", studio: "一般2", class: "type_20"},
  /*1725 */{dataseat:1725, data_seatno: "s2-y-24-19", customer_id: "", name: "", mail: "", phone: "", studio: "PA席",class: "type_1"},
  /*1726 */{dataseat:1726, data_seatno: "s2-y-24-20", customer_id: "", name: "", mail: "", phone: "", studio: "PA席",class: "type_1"},
  /*1727 */{dataseat:1727, data_seatno: "s2-y-24-21", customer_id: "", name: "", mail: "", phone: "", studio: "PA席",class: "type_1"},
  /*1728 */{dataseat:1728, data_seatno: "s2-y-24-22", customer_id: "", name: "", mail: "", phone: "", studio: "PA席",class: "type_1"},
  /*1729 */{dataseat:1729, data_seatno: "s2-y-24-23", customer_id: "", name: "", mail: "", phone: "", studio: "PA席",class: "type_1"},
  /*1730 */{dataseat:1730, data_seatno: "s2-y-24-24", customer_id: "", name: "", mail: "", phone: "", studio: "PA席",class: "type_1"},
  /*1731 */{dataseat:1731, data_seatno: "s2-y-24-25", customer_id: "", name: "", mail: "", phone: "", studio: "PA席",class: "type_1"},
  /*1732 */{dataseat:1732, data_seatno: "s2-y-24-26", customer_id: "", name: "", mail: "", phone: "", studio: "PA席",class: "type_1"},
  /*1733 */{dataseat:1733, data_seatno: "s2-y-24-27", customer_id: "", name: "", mail: "", phone: "", studio: "PA席",class: "type_1"},
  /*1734 */{dataseat:1734, data_seatno: "s2-y-24-28", customer_id: "", name: "", mail: "", phone: "", studio: "PA席",class: "type_1"},
  /*1735 */{dataseat:1735, data_seatno: "s2-y-24-29", customer_id: "", name: "", mail: "", phone: "", studio: "PA席",class: "type_1"},
  /*1736 */{dataseat:1736, data_seatno: "s2-y-24-30", customer_id: "", name: "", mail: "", phone: "", studio: "PA席",class: "type_1"},
  /*1737 */{dataseat:1737, data_seatno: "s2-y-24-32", customer_id: "1241", name: "澤田 果歩", mail: "", phone: "", studio: "東生駒", class: "type_19"},
  /*1738 */{dataseat:1738, data_seatno: "s2-y-24-33", customer_id: "1241", name: "澤田 果歩", mail: "", phone: "", studio: "東生駒", class: "type_19"},
  /*1739 */{dataseat:1739, data_seatno: "s2-y-24-34", customer_id: "1546", name: "森 裕理", mail: "", phone: "", studio: "東生駒", class: "type_19"},
  /*1740 */{dataseat:1740, data_seatno: "s2-y-24-35", customer_id: "1546", name: "森 裕理", mail: "", phone: "", studio: "東生駒", class: "type_19"},
  /*1741 */{dataseat:1741, data_seatno: "s2-y-24-36", customer_id: "1790", name: "渡邊 心葉", mail: "", phone: "", studio: "東生駒", class: "type_19"},
  /*1742 */{dataseat:1742, data_seatno: "s2-y-24-37", customer_id: "1790", name: "渡邊 心葉", mail: "", phone: "", studio: "東生駒", class: "type_19"},
  /*1743 */{dataseat:1743, data_seatno: "s2-y-24-38", customer_id: "1791", name: "端本 想来", mail: "", phone: "", studio: "東生駒", class: "type_19"},
  /*1744 */{dataseat:1744, data_seatno: "s2-y-24-39", customer_id: "1791", name: "端本 想来", mail: "", phone: "", studio: "東生駒", class: "type_19"},
  /*1745 */{dataseat:1745, data_seatno: "s2-y-24-40", customer_id: "1828", name: "松田 葵", mail: "", phone: "", studio: "東生駒", class: "type_19"},
  /*1746 */{dataseat:1746, data_seatno: "s2-y-24-41", customer_id: "1828", name: "松田 葵", mail: "", phone: "", studio: "東生駒", class: "type_19"},
  /*1747 */{dataseat:1747, data_seatno: "s2-y-24-43", customer_id: "2013", name: "志智 夏鈴", mail: "", phone: "", studio: "武庫川 初級", class: "type_18"},
  /*1748 */{dataseat:1748, data_seatno: "s2-y-24-44", customer_id: "2013", name: "志智 夏鈴", mail: "", phone: "", studio: "武庫川 初級", class: "type_18"},
  /*1749 */{dataseat:1749, data_seatno: "s2-y-24-45", customer_id: "2023", name: "曲馬 空愛", mail: "", phone: "", studio: "武庫川 初級", class: "type_18"},
  /*1750 */{dataseat:1750, data_seatno: "s2-y-24-46", customer_id: "2023", name: "曲馬 空愛", mail: "", phone: "", studio: "武庫川 初級", class: "type_18"},
  /*1751 */{dataseat:1751, data_seatno: "s2-y-24-47", customer_id: "2026", name: "増田 葵", mail: "", phone: "", studio: "武庫川 初級", class: "type_18"},
  /*1752 */{dataseat:1752, data_seatno: "s2-y-24-48", customer_id: "2026", name: "増田 葵", mail: "", phone: "", studio: "武庫川 初級", class: "type_18"},
  //////////// 25列目 ////////////
  /*1753 */{dataseat:1753, data_seatno: "s2-y-25-1", customer_id: "", name: "松原公子", mail: "kimipup221317@gmail.com", phone: "08057060221", studio: "一般2", class: "type_20"},
  /*1754 */{dataseat:1754, data_seatno: "s2-y-25-2", customer_id: "", name: "松原公子", mail: "kimipup221317@gmail.com", phone: "08057060221", studio: "一般2", class: "type_20"},
  /*1755 */{dataseat:1755, data_seatno: "s2-y-25-3", customer_id: "", name: "石崎美偉人", mail: "shiqimeiweiren@gmail.com", phone: "09082702295", studio: "一般2", class: "type_20"},
  /*1756 */{dataseat:1756, data_seatno: "s2-y-25-4", customer_id: "", name: "石崎美偉人", mail: "shiqimeiweiren@gmail.com", phone: "09082702295", studio: "一般2", class: "type_20"},
  /*1757 */{dataseat:1757, data_seatno: "s2-y-25-5", customer_id: "990", name: "佐方 初流乃", mail: "", phone: "", studio: "兵庫選抜", class: "type_13"},
  /*1758 */{dataseat:1758, data_seatno: "s2-y-25-6", customer_id: "990", name: "佐方 初流乃", mail: "", phone: "", studio: "兵庫選抜", class: "type_13"},
  /*1759 */{dataseat:1759, data_seatno: "s2-y-25-8", customer_id: "1242", name: "後藤 望心", mail: "", phone: "", studio: "奈良選抜", class: "type_12"},
  /*1760 */{dataseat:1760, data_seatno: "s2-y-25-9", customer_id: "1242", name: "後藤 望心", mail: "", phone: "", studio: "奈良選抜", class: "type_12"},
  /*1761 */{dataseat:1761, data_seatno: "s2-y-25-10", customer_id: "1312", name: "青木 春来", mail: "", phone: "", studio: "奈良選抜", class: "type_12"},
  /*1762 */{dataseat:1762, data_seatno: "s2-y-25-11", customer_id: "1312", name: "青木 春来", mail: "", phone: "", studio: "奈良選抜", class: "type_12"},
  /*1763 */{dataseat:1763, data_seatno: "s2-y-25-12", customer_id: "761", name: "古野 奈乃芭", mail: "", phone: "", studio: "奈良選抜", class: "type_12"},
  /*1764 */{dataseat:1764, data_seatno: "s2-y-25-13", customer_id: "761", name: "古野 奈乃芭", mail: "", phone: "", studio: "奈良選抜", class: "type_12"},
  /*1765 */{dataseat:1765, data_seatno: "s2-y-25-14", customer_id: "626", name: "松尾 唯花", mail: "", phone: "", studio: "奈良選抜", class: "type_12"},
  /*1766 */{dataseat:1766, data_seatno: "s2-y-25-15", customer_id: "626", name: "松尾 唯花", mail: "", phone: "", studio: "奈良選抜", class: "type_12"},
  /*1767 */{dataseat:1767, data_seatno: "s2-y-25-16", customer_id: "936", name: "今中 虹心", mail: "", phone: "", studio: "奈良選抜", class: "type_12"},
  /*1768 */{dataseat:1768, data_seatno: "s2-y-25-17", customer_id: "936", name: "今中 虹心", mail: "", phone: "", studio: "奈良選抜", class: "type_12"},
  /*1769 */{dataseat:1769, data_seatno: "s2-y-25-19", customer_id: "", name: "", mail: "", phone: "", studio: "PA席",class: "type_1"},
  /*1770 */{dataseat:1770, data_seatno: "s2-y-25-20", customer_id: "", name: "", mail: "", phone: "", studio: "PA席",class: "type_1"},
  /*1771 */{dataseat:1771, data_seatno: "s2-y-25-21", customer_id: "", name: "", mail: "", phone: "", studio: "PA席",class: "type_1"},
  /*1772 */{dataseat:1772, data_seatno: "s2-y-25-22", customer_id: "", name: "", mail: "", phone: "", studio: "PA席",class: "type_1"},
  /*1773 */{dataseat:1773, data_seatno: "s2-y-25-23", customer_id: "", name: "", mail: "", phone: "", studio: "PA席",class: "type_1"},
  /*1774 */{dataseat:1774, data_seatno: "s2-y-25-24", customer_id: "", name: "", mail: "", phone: "", studio: "PA席",class: "type_1"},
  /*1775 */{dataseat:1775, data_seatno: "s2-y-25-25", customer_id: "", name: "", mail: "", phone: "", studio: "PA席",class: "type_1"},
  /*1776 */{dataseat:1776, data_seatno: "s2-y-25-26", customer_id: "", name: "", mail: "", phone: "", studio: "PA席",class: "type_1"},
  /*1777 */{dataseat:1777, data_seatno: "s2-y-25-27", customer_id: "", name: "", mail: "", phone: "", studio: "PA席",class: "type_1"},
  /*1778 */{dataseat:1778, data_seatno: "s2-y-25-28", customer_id: "", name: "", mail: "", phone: "", studio: "PA席",class: "type_1"},
  /*1779 */{dataseat:1779, data_seatno: "s2-y-25-29", customer_id: "", name: "", mail: "", phone: "", studio: "PA席",class: "type_1"},
  /*1780 */{dataseat:1780, data_seatno: "s2-y-25-30", customer_id: "", name: "", mail: "", phone: "", studio: "PA席",class: "type_1"},
  /*1781 */{dataseat:1781, data_seatno: "s2-y-25-32", customer_id: "1947", name: "久保 柚葉", mail: "", phone: "", studio: "東生駒", class: "type_19"},
  /*1782 */{dataseat:1782, data_seatno: "s2-y-25-33", customer_id: "1947", name: "久保 柚葉", mail: "", phone: "", studio: "東生駒", class: "type_19"},
  /*1783 */{dataseat:1783, data_seatno: "s2-y-25-34", customer_id: "1972", name: "鈴木 心絆", mail: "", phone: "", studio: "東生駒", class: "type_19"},
  /*1784 */{dataseat:1784, data_seatno: "s2-y-25-35", customer_id: "1972", name: "鈴木 心絆", mail: "", phone: "", studio: "東生駒", class: "type_19"},
  /*1785 */{dataseat:1785, data_seatno: "s2-y-25-36", customer_id: "1541", name: "井畑 結椛", mail: "", phone: "", studio: "東生駒", class: "type_19"},
  /*1786 */{dataseat:1786, data_seatno: "s2-y-25-37", customer_id: "1541", name: "井畑 結椛", mail: "", phone: "", studio: "東生駒", class: "type_19"},
  /*1787 */{dataseat:1787, data_seatno: "s2-y-25-38", customer_id: "1611", name: "中谷 琉花", mail: "", phone: "", studio: "東生駒", class: "type_19"},
  /*1788 */{dataseat:1788, data_seatno: "s2-y-25-39", customer_id: "1611", name: "中谷 琉花", mail: "", phone: "", studio: "東生駒", class: "type_19"},
  /*1789 */{dataseat:1789, data_seatno: "s2-y-25-40", customer_id: "1888", name: "西 彩羽", mail: "", phone: "", studio: "東生駒", class: "type_19"},
  /*1790 */{dataseat:1790, data_seatno: "s2-y-25-41", customer_id: "1888", name: "西 彩羽", mail: "", phone: "", studio: "東生駒", class: "type_19"},
  /*1791 */{dataseat:1791, data_seatno: "s2-y-25-43", customer_id: "1529", name: "古西 花帆", mail: "", phone: "", studio: "東生駒", class: "type_19"},
  /*1792 */{dataseat:1792, data_seatno: "s2-y-25-44", customer_id: "1529", name: "古西 花帆", mail: "", phone: "", studio: "東生駒", class: "type_19"},
  /*1793 */{dataseat:1793, data_seatno: "s2-y-25-45", customer_id: "", name: "古西直子", mail: "furunishi0202@gaia.eonet.ne.jp", phone: "09014416456", studio: "東生駒", class: "type_19"},
  /*1794 */{dataseat:1794, data_seatno: "s2-y-25-46", customer_id: "1953", name: "嶋本 心咲", mail: "", phone: "", studio: "東生駒", class: "type_19"},
  /*1795 */{dataseat:1795, data_seatno: "s2-y-25-47", customer_id: "1953", name: "嶋本 心咲", mail: "", phone: "", studio: "東生駒", class: "type_19"},
  /*1796 */{dataseat:1796, data_seatno: "s2-y-25-48", customer_id: "1953", name: "嶋本 心咲", mail: "", phone: "", studio: "東生駒", class: "type_19"},
];

export const SECTION3_SEATS = [
  //////////// 1列目 ////////////
  /*1797 */{dataseat:1797, data_seatno: "s3-y-1-10", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席3", class: "type_1"},
  /*1798 */{dataseat:1798, data_seatno: "s3-y-1-11", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席3", class: "type_1"},
  /*1799 */{dataseat:1799, data_seatno: "s3-y-1-12", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席3", class: "type_1"},
  /*1800 */{dataseat:1800, data_seatno: "s3-y-1-13", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席3", class: "type_1"},
  /*1801 */{dataseat:1801, data_seatno: "s3-y-1-14", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席3", class: "type_1"},
  /*1802 */{dataseat:1802, data_seatno: "s3-y-1-15", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席3", class: "type_1"},
  /*1803 */{dataseat:1803, data_seatno: "s3-y-1-16", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席3", class: "type_1"},
  /*1804 */{dataseat:1804, data_seatno: "s3-y-1-17", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席3", class: "type_1"},
  /*1805 */{dataseat:1805, data_seatno: "s3-y-1-19", customer_id: "", name: "", mail: "", phone: "", studio: "カメラ", class: "type_98"},
  /*1806 */{dataseat:1806, data_seatno: "s3-y-1-20", customer_id: "", name: "", mail: "", phone: "", studio: "カメラ", class: "type_98"},
  /*1807 */{dataseat:1807, data_seatno: "s3-y-1-21", customer_id: "", name: "", mail: "", phone: "", studio: "カメラ", class: "type_98"},
  /*1808 */{dataseat:1808, data_seatno: "s3-y-1-22", customer_id: "", name: "", mail: "", phone: "", studio: "カメラ", class: "type_98"},
  /*1809 */{dataseat:1809, data_seatno: "s3-y-1-23", customer_id: "", name: "", mail: "", phone: "", studio: "カメラ", class: "type_98"},
  /*1810 */{dataseat:1810, data_seatno: "s3-y-1-24", customer_id: "", name: "", mail: "", phone: "", studio: "カメラ", class: "type_98"},
  /*1811 */{dataseat:1811, data_seatno: "s3-y-1-25", customer_id: "", name: "", mail: "", phone: "", studio: "カメラ", class: "type_98"},
  /*1812 */{dataseat:1812, data_seatno: "s3-y-1-26", customer_id: "", name: "", mail: "", phone: "", studio: "カメラ", class: "type_98"},
  /*1813 */{dataseat:1813, data_seatno: "s3-y-1-27", customer_id: "", name: "", mail: "", phone: "", studio: "カメラ", class: "type_98"},
  /*1814 */{dataseat:1814, data_seatno: "s3-y-1-28", customer_id: "", name: "", mail: "", phone: "", studio: "カメラ", class: "type_98"},
  /*1815 */{dataseat:1815, data_seatno: "s3-y-1-29", customer_id: "", name: "", mail: "", phone: "", studio: "カメラ", class: "type_98"},
  /*1816 */{dataseat:1816, data_seatno: "s3-y-1-30", customer_id: "", name: "", mail: "", phone: "", studio: "カメラ", class: "type_98"},
  /*1817 */{dataseat:1817, data_seatno: "s3-y-1-32", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席3", class: "type_1"},
  /*1818 */{dataseat:1818, data_seatno: "s3-y-1-33", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席3", class: "type_1"},
  /*1819 */{dataseat:1819, data_seatno: "s3-y-1-34", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席3", class: "type_1"},
  /*1820 */{dataseat:1820, data_seatno: "s3-y-1-35", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席3", class: "type_1"},
  /*1821 */{dataseat:1821, data_seatno: "s3-y-1-36", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席3", class: "type_1"},
  /*1822 */{dataseat:1822, data_seatno: "s3-y-1-37", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席3", class: "type_1"},
  /*1823 */{dataseat:1823, data_seatno: "s3-y-1-38", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席3", class: "type_1"},
  /*1824 */{dataseat:1824, data_seatno: "s3-y-1-39", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席3", class: "type_1"},
  //////////// 2列目 ////////////
  /*1825 */{dataseat:1825, data_seatno: "s3-y-2-9", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席3", class: "type_1"},
  /*1826 */{dataseat:1826, data_seatno: "s3-y-2-10", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席3", class: "type_1"},
  /*1827 */{dataseat:1827, data_seatno: "s3-y-2-11", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席3", class: "type_1"},
  /*1828 */{dataseat:1828, data_seatno: "s3-y-2-12", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席3", class: "type_1"},
  /*1829 */{dataseat:1829, data_seatno: "s3-y-2-13", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席3", class: "type_1"},
  /*1830 */{dataseat:1830, data_seatno: "s3-y-2-14", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席3", class: "type_1"},
  /*1831 */{dataseat:1831, data_seatno: "s3-y-2-15", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席3", class: "type_1"},
  /*1832 */{dataseat:1832, data_seatno: "s3-y-2-16", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席3", class: "type_1"},
  /*1833 */{dataseat:1833, data_seatno: "s3-y-2-17", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席3", class: "type_1"},
  /*1834 */{dataseat:1834, data_seatno: "s3-y-2-19", customer_id: "", name: "", mail: "", phone: "", studio: "カメラ", class: "type_98"},
  /*1835 */{dataseat:1835, data_seatno: "s3-y-2-20", customer_id: "", name: "", mail: "", phone: "", studio: "カメラ", class: "type_98"},
  /*1836 */{dataseat:1836, data_seatno: "s3-y-2-21", customer_id: "", name: "", mail: "", phone: "", studio: "カメラ", class: "type_98"},
  /*1837 */{dataseat:1837, data_seatno: "s3-y-2-22", customer_id: "", name: "", mail: "", phone: "", studio: "カメラ", class: "type_98"},
  /*1838 */{dataseat:1838, data_seatno: "s3-y-2-23", customer_id: "", name: "", mail: "", phone: "", studio: "カメラ", class: "type_98"},
  /*1839 */{dataseat:1839, data_seatno: "s3-y-2-24", customer_id: "", name: "", mail: "", phone: "", studio: "カメラ", class: "type_98"},
  /*1840 */{dataseat:1840, data_seatno: "s3-y-2-25", customer_id: "", name: "", mail: "", phone: "", studio: "カメラ", class: "type_98"},
  /*1841 */{dataseat:1841, data_seatno: "s3-y-2-26", customer_id: "", name: "", mail: "", phone: "", studio: "カメラ", class: "type_98"},
  /*1842 */{dataseat:1842, data_seatno: "s3-y-2-27", customer_id: "", name: "", mail: "", phone: "", studio: "カメラ", class: "type_98"},
  /*1843 */{dataseat:1843, data_seatno: "s3-y-2-28", customer_id: "", name: "", mail: "", phone: "", studio: "カメラ", class: "type_98"},
  /*1844 */{dataseat:1844, data_seatno: "s3-y-2-29", customer_id: "", name: "", mail: "", phone: "", studio: "カメラ", class: "type_98"},
  /*1845 */{dataseat:1845, data_seatno: "s3-y-2-30", customer_id: "", name: "", mail: "", phone: "", studio: "カメラ", class: "type_98"},
  /*1846 */{dataseat:1846, data_seatno: "s3-y-2-32", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席3", class: "type_1"},
  /*1847 */{dataseat:1847, data_seatno: "s3-y-2-33", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席3", class: "type_1"},
  /*1848 */{dataseat:1848, data_seatno: "s3-y-2-34", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席3", class: "type_1"},
  /*1849 */{dataseat:1849, data_seatno: "s3-y-2-35", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席3", class: "type_1"},
  /*1850 */{dataseat:1850, data_seatno: "s3-y-2-36", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席3", class: "type_1"},
  /*1851 */{dataseat:1851, data_seatno: "s3-y-2-37", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席3", class: "type_1"},
  /*1852 */{dataseat:1852, data_seatno: "s3-y-2-38", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席3", class: "type_1"},
  /*1853 */{dataseat:1853, data_seatno: "s3-y-2-39", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席3", class: "type_1"},
  /*1854 */{dataseat:1854, data_seatno: "s3-y-2-40", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席3", class: "type_1"},
  //////////// 3列目 ////////////
  /*1855 */{dataseat:1855, data_seatno: "s3-y-3-8", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席3", class: "type_1"},
  /*1856 */{dataseat:1856, data_seatno: "s3-y-3-9", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席3", class: "type_1"},
  /*1857 */{dataseat:1857, data_seatno: "s3-y-3-10", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席3", class: "type_1"},
  /*1858 */{dataseat:1858, data_seatno: "s3-y-3-11", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席3", class: "type_1"},
  /*1859 */{dataseat:1859, data_seatno: "s3-y-3-12", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席3", class: "type_1"},
  /*1860 */{dataseat:1860, data_seatno: "s3-y-3-13", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席3", class: "type_1"},
  /*1861 */{dataseat:1861, data_seatno: "s3-y-3-14", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席3", class: "type_1"},
  /*1862 */{dataseat:1862, data_seatno: "s3-y-3-15", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席3", class: "type_1"},
  /*1863 */{dataseat:1863, data_seatno: "s3-y-3-16", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席3", class: "type_1"},
  /*1864 */{dataseat:1864, data_seatno: "s3-y-3-17", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席3", class: "type_1"},
  /*1865 */{dataseat:1865, data_seatno: "s3-y-3-19", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席3", class: "type_1"},
  /*1866 */{dataseat:1866, data_seatno: "s3-y-3-20", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席3", class: "type_1"},
  /*1867 */{dataseat:1867, data_seatno: "s3-y-3-21", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席3", class: "type_1"},
  /*1868 */{dataseat:1868, data_seatno: "s3-y-3-22", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席3", class: "type_1"},
  /*1869 */{dataseat:1869, data_seatno: "s3-y-3-23", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席3", class: "type_1"},
  /*1870 */{dataseat:1870, data_seatno: "s3-y-3-24", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席3", class: "type_1"},
  /*1871 */{dataseat:1871, data_seatno: "s3-y-3-25", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席3", class: "type_1"},
  /*1872 */{dataseat:1872, data_seatno: "s3-y-3-26", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席3", class: "type_1"},
  /*1873 */{dataseat:1873, data_seatno: "s3-y-3-27", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席3", class: "type_1"},
  /*1874 */{dataseat:1874, data_seatno: "s3-y-3-28", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席3", class: "type_1"},
  /*1875 */{dataseat:1875, data_seatno: "s3-y-3-29", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席3", class: "type_1"},
  /*1876 */{dataseat:1876, data_seatno: "s3-y-3-30", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席3", class: "type_1"},
  /*1877 */{dataseat:1877, data_seatno: "s3-y-3-32", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席3", class: "type_1"},
  /*1878 */{dataseat:1878, data_seatno: "s3-y-3-33", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席3", class: "type_1"},
  /*1879 */{dataseat:1879, data_seatno: "s3-y-3-34", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席3", class: "type_1"},
  /*1880 */{dataseat:1880, data_seatno: "s3-y-3-35", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席3", class: "type_1"},
  /*1881 */{dataseat:1881, data_seatno: "s3-y-3-36", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席3", class: "type_1"},
  /*1882 */{dataseat:1882, data_seatno: "s3-y-3-37", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席3", class: "type_1"},
  /*1883 */{dataseat:1883, data_seatno: "s3-y-3-38", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席3", class: "type_1"},
  /*1884 */{dataseat:1884, data_seatno: "s3-y-3-39", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席3", class: "type_1"},
  /*1885 */{dataseat:1885, data_seatno: "s3-y-3-40", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席3", class: "type_1"},
  /*1886 */{dataseat:1886, data_seatno: "s3-y-3-41", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席3", class: "type_1"},
  //////////// 4列目 ////////////
  /*1887 */{dataseat:1887, data_seatno: "s3-y-4-8", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席3", class: "type_1"},
  /*1888 */{dataseat:1888, data_seatno: "s3-y-4-9", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席3", class: "type_1"},
  /*1889 */{dataseat:1889, data_seatno: "s3-y-4-10", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席3", class: "type_1"},
  /*1890 */{dataseat:1890, data_seatno: "s3-y-4-11", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席3", class: "type_1"},
  /*1891 */{dataseat:1891, data_seatno: "s3-y-4-12", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席3", class: "type_1"},
  /*1892 */{dataseat:1892, data_seatno: "s3-y-4-13", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席3", class: "type_1"},
  /*1893 */{dataseat:1893, data_seatno: "s3-y-4-14", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席3", class: "type_1"},
  /*1894 */{dataseat:1894, data_seatno: "s3-y-4-15", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席3", class: "type_1"},
  /*1895 */{dataseat:1895, data_seatno: "s3-y-4-16", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席3", class: "type_1"},
  /*1896 */{dataseat:1896, data_seatno: "s3-y-4-17", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席3", class: "type_1"},
  /*1897 */{dataseat:1897, data_seatno: "s3-y-4-19", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席3", class: "type_1"},
  /*1898 */{dataseat:1898, data_seatno: "s3-y-4-20", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席3", class: "type_1"},
  /*1899 */{dataseat:1899, data_seatno: "s3-y-4-21", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席3", class: "type_1"},
  /*1900 */{dataseat:1900, data_seatno: "s3-y-4-22", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席3", class: "type_1"},
  /*1901 */{dataseat:1901, data_seatno: "s3-y-4-23", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席3", class: "type_1"},
  /*1902 */{dataseat:1902, data_seatno: "s3-y-4-24", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席3", class: "type_1"},
  /*1903 */{dataseat:1903, data_seatno: "s3-y-4-25", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席3", class: "type_1"},
  /*1904 */{dataseat:1904, data_seatno: "s3-y-4-26", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席3", class: "type_1"},
  /*1905 */{dataseat:1905, data_seatno: "s3-y-4-27", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席3", class: "type_1"},
  /*1906 */{dataseat:1906, data_seatno: "s3-y-4-28", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席3", class: "type_1"},
  /*1907 */{dataseat:1907, data_seatno: "s3-y-4-29", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席3", class: "type_1"},
  /*1908 */{dataseat:1908, data_seatno: "s3-y-4-30", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席3", class: "type_1"},
  /*1909 */{dataseat:1909, data_seatno: "s3-y-4-32", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席3", class: "type_1"},
  /*1910 */{dataseat:1910, data_seatno: "s3-y-4-33", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席3", class: "type_1"},
  /*1911 */{dataseat:1911, data_seatno: "s3-y-4-34", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席3", class: "type_1"},
  /*1912 */{dataseat:1912, data_seatno: "s3-y-4-35", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席3", class: "type_1"},
  /*1913 */{dataseat:1913, data_seatno: "s3-y-4-36", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席3", class: "type_1"},
  /*1914 */{dataseat:1914, data_seatno: "s3-y-4-37", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席3", class: "type_1"},
  /*1915 */{dataseat:1915, data_seatno: "s3-y-4-38", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席3", class: "type_1"},
  /*1916 */{dataseat:1916, data_seatno: "s3-y-4-39", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席3", class: "type_1"},
  /*1917 */{dataseat:1917, data_seatno: "s3-y-4-40", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席3", class: "type_1"},
  /*1918 */{dataseat:1918, data_seatno: "s3-y-4-41", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席3", class: "type_1"},
  //////////// 5列目 ////////////
  /*1919 */{dataseat:1919, data_seatno: "s3-y-5-7", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席3", class: "type_1"},
  /*1920 */{dataseat:1920, data_seatno: "s3-y-5-8", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席3", class: "type_1"},
  /*1921 */{dataseat:1921, data_seatno: "s3-y-5-9", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席3", class: "type_1"},
  /*1922 */{dataseat:1922, data_seatno: "s3-y-5-10", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席3", class: "type_1"},
  /*1923 */{dataseat:1923, data_seatno: "s3-y-5-11", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席3", class: "type_1"},
  /*1924 */{dataseat:1924, data_seatno: "s3-y-5-12", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席3", class: "type_1"},
  /*1925 */{dataseat:1925, data_seatno: "s3-y-5-13", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席3", class: "type_1"},
  /*1926 */{dataseat:1926, data_seatno: "s3-y-5-14", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席3", class: "type_1"},
  /*1927 */{dataseat:1927, data_seatno: "s3-y-5-15", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席3", class: "type_1"},
  /*1928 */{dataseat:1928, data_seatno: "s3-y-5-16", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席3", class: "type_1"},
  /*1929 */{dataseat:1929, data_seatno: "s3-y-5-17", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席3", class: "type_1"},
  /*1930 */{dataseat:1930, data_seatno: "s3-y-5-19", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席3", class: "type_1"},
  /*1931 */{dataseat:1931, data_seatno: "s3-y-5-20", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席3", class: "type_1"},
  /*1932 */{dataseat:1932, data_seatno: "s3-y-5-21", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席3", class: "type_1"},
  /*1933 */{dataseat:1933, data_seatno: "s3-y-5-22", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席3", class: "type_1"},
  /*1934 */{dataseat:1934, data_seatno: "s3-y-5-23", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席3", class: "type_1"},
  /*1935 */{dataseat:1935, data_seatno: "s3-y-5-24", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席3", class: "type_1"},
  /*1936 */{dataseat:1936, data_seatno: "s3-y-5-25", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席3", class: "type_1"},
  /*1937 */{dataseat:1937, data_seatno: "s3-y-5-26", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席3", class: "type_1"},
  /*1938 */{dataseat:1938, data_seatno: "s3-y-5-27", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席3", class: "type_1"},
  /*1939 */{dataseat:1939, data_seatno: "s3-y-5-28", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席3", class: "type_1"},
  /*1940 */{dataseat:1940, data_seatno: "s3-y-5-29", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席3", class: "type_1"},
  /*1941 */{dataseat:1941, data_seatno: "s3-y-5-30", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席3", class: "type_1"},
  /*1942 */{dataseat:1942, data_seatno: "s3-y-5-32", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席3", class: "type_1"},
  /*1943 */{dataseat:1943, data_seatno: "s3-y-5-33", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席3", class: "type_1"},
  /*1944 */{dataseat:1944, data_seatno: "s3-y-5-34", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席3", class: "type_1"},
  /*1945 */{dataseat:1945, data_seatno: "s3-y-5-35", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席3", class: "type_1"},
  /*1946 */{dataseat:1946, data_seatno: "s3-y-5-36", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席3", class: "type_1"},
  /*1947 */{dataseat:1947, data_seatno: "s3-y-5-37", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席3", class: "type_1"},
  /*1948 */{dataseat:1948, data_seatno: "s3-y-5-38", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席3", class: "type_1"},
  /*1949 */{dataseat:1949, data_seatno: "s3-y-5-39", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席3", class: "type_1"},
  /*1950 */{dataseat:1950, data_seatno: "s3-y-5-40", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席3", class: "type_1"},
  /*1951 */{dataseat:1951, data_seatno: "s3-y-5-41", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席3", class: "type_1"},
  /*1952 */{dataseat:1952, data_seatno: "s3-y-5-42", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席3", class: "type_1"},
  //////////// 6列目 ////////////
  /*1953 */{dataseat:1953, data_seatno: "s3-y-6-6", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席3", class: "type_1"},
  /*1954 */{dataseat:1954, data_seatno: "s3-y-6-7", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席3", class: "type_1"},
  /*1955 */{dataseat:1955, data_seatno: "s3-y-6-8", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席3", class: "type_1"},
  /*1956 */{dataseat:1956, data_seatno: "s3-y-6-9", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席3", class: "type_1"},
  /*1957 */{dataseat:1957, data_seatno: "s3-y-6-10", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席3", class: "type_1"},
  /*1958 */{dataseat:1958, data_seatno: "s3-y-6-11", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席3", class: "type_1"},
  /*1959 */{dataseat:1959, data_seatno: "s3-y-6-12", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席3", class: "type_1"},
  /*1960 */{dataseat:1960, data_seatno: "s3-y-6-13", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席3", class: "type_1"},
  /*1961 */{dataseat:1961, data_seatno: "s3-y-6-14", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席3", class: "type_1"},
  /*1962 */{dataseat:1962, data_seatno: "s3-y-6-15", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席3", class: "type_1"},
  /*1963 */{dataseat:1963, data_seatno: "s3-y-6-16", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席3", class: "type_1"},
  /*1964 */{dataseat:1964, data_seatno: "s3-y-6-17", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席3", class: "type_1"},
  /*1965 */{dataseat:1965, data_seatno: "s3-y-6-19", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席3", class: "type_1"},
  /*1966 */{dataseat:1966, data_seatno: "s3-y-6-20", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席3", class: "type_1"},
  /*1967 */{dataseat:1967, data_seatno: "s3-y-6-21", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席3", class: "type_1"},
  /*1968 */{dataseat:1968, data_seatno: "s3-y-6-22", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席3", class: "type_1"},
  /*1969 */{dataseat:1969, data_seatno: "s3-y-6-23", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席3", class: "type_1"},
  /*1970 */{dataseat:1970, data_seatno: "s3-y-6-24", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席3", class: "type_1"},
  /*1971 */{dataseat:1971, data_seatno: "s3-y-6-25", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席3", class: "type_1"},
  /*1972 */{dataseat:1972, data_seatno: "s3-y-6-26", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席3", class: "type_1"},
  /*1973 */{dataseat:1973, data_seatno: "s3-y-6-27", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席3", class: "type_1"},
  /*1974 */{dataseat:1974, data_seatno: "s3-y-6-28", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席3", class: "type_1"},
  /*1975 */{dataseat:1975, data_seatno: "s3-y-6-29", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席3", class: "type_1"},
  /*1976 */{dataseat:1976, data_seatno: "s3-y-6-30", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席3", class: "type_1"},
  /*1977 */{dataseat:1977, data_seatno: "s3-y-6-32", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席3", class: "type_1"},
  /*1978 */{dataseat:1978, data_seatno: "s3-y-6-33", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席3", class: "type_1"},
  /*1979 */{dataseat:1979, data_seatno: "s3-y-6-34", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席3", class: "type_1"},
  /*1980 */{dataseat:1980, data_seatno: "s3-y-6-35", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席3", class: "type_1"},
  /*1981 */{dataseat:1981, data_seatno: "s3-y-6-36", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席3", class: "type_1"},
  /*1982 */{dataseat:1982, data_seatno: "s3-y-6-37", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席3", class: "type_1"},
  /*1983 */{dataseat:1983, data_seatno: "s3-y-6-38", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席3", class: "type_1"},
  /*1984 */{dataseat:1984, data_seatno: "s3-y-6-39", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席3", class: "type_1"},
  /*1985 */{dataseat:1985, data_seatno: "s3-y-6-40", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席3", class: "type_1"},
  /*1986 */{dataseat:1986, data_seatno: "s3-y-6-41", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席3", class: "type_1"},
  /*1987 */{dataseat:1987, data_seatno: "s3-y-6-42", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席3", class: "type_1"},
  /*1988 */{dataseat:1988, data_seatno: "s3-y-6-43", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席3", class: "type_1"},
  //////////// 7列目 ////////////
  /*1989 */{dataseat:1989, data_seatno: "s3-y-7-6", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席3", class: "type_1"},
  /*1990 */{dataseat:1990, data_seatno: "s3-y-7-7", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席3", class: "type_1"},
  /*1991 */{dataseat:1991, data_seatno: "s3-y-7-8", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席3", class: "type_1"},
  /*1992 */{dataseat:1992, data_seatno: "s3-y-7-9", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席3", class: "type_1"},
  /*1993 */{dataseat:1993, data_seatno: "s3-y-7-10", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席3", class: "type_1"},
  /*1994 */{dataseat:1994, data_seatno: "s3-y-7-11", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席3", class: "type_1"},
  /*1995 */{dataseat:1995, data_seatno: "s3-y-7-12", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席3", class: "type_1"},
  /*1996 */{dataseat:1996, data_seatno: "s3-y-7-13", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席3", class: "type_1"},
  /*1997 */{dataseat:1997, data_seatno: "s3-y-7-14", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席3", class: "type_1"},
  /*1998 */{dataseat:1998, data_seatno: "s3-y-7-15", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席3", class: "type_1"},
  /*1999 */{dataseat:1999, data_seatno: "s3-y-7-16", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席3", class: "type_1"},
  /*2000 */{dataseat:2000, data_seatno: "s3-y-7-17", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席3", class: "type_1"},
  /*2001 */{dataseat:2001, data_seatno: "s3-y-7-19", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席3", class: "type_1"},
  /*2002 */{dataseat:2002, data_seatno: "s3-y-7-20", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席3", class: "type_1"},
  /*2003 */{dataseat:2003, data_seatno: "s3-y-7-21", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席3", class: "type_1"},
  /*2004 */{dataseat:2004, data_seatno: "s3-y-7-22", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席3", class: "type_1"},
  /*2005 */{dataseat:2005, data_seatno: "s3-y-7-23", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席3", class: "type_1"},
  /*2006 */{dataseat:2006, data_seatno: "s3-y-7-24", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席3", class: "type_1"},
  /*2007 */{dataseat:2007, data_seatno: "s3-y-7-25", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席3", class: "type_1"},
  /*2008 */{dataseat:2008, data_seatno: "s3-y-7-26", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席3", class: "type_1"},
  /*2009 */{dataseat:2009, data_seatno: "s3-y-7-27", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席3", class: "type_1"},
  /*2010 */{dataseat:2010, data_seatno: "s3-y-7-28", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席3", class: "type_1"},
  /*2011 */{dataseat:2011, data_seatno: "s3-y-7-29", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席3", class: "type_1"},
  /*2012 */{dataseat:2012, data_seatno: "s3-y-7-30", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席3", class: "type_1"},
  /*2013 */{dataseat:2013, data_seatno: "s3-y-7-32", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席3", class: "type_1"},
  /*2014 */{dataseat:2014, data_seatno: "s3-y-7-33", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席3", class: "type_1"},
  /*2015 */{dataseat:2015, data_seatno: "s3-y-7-34", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席3", class: "type_1"},
  /*2016 */{dataseat:2016, data_seatno: "s3-y-7-35", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席3", class: "type_1"},
  /*2017 */{dataseat:2017, data_seatno: "s3-y-7-36", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席3", class: "type_1"},
  /*2018 */{dataseat:2018, data_seatno: "s3-y-7-37", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席3", class: "type_1"},
  /*2019 */{dataseat:2019, data_seatno: "s3-y-7-38", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席3", class: "type_1"},
  /*2020 */{dataseat:2020, data_seatno: "s3-y-7-39", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席3", class: "type_1"},
  /*2021 */{dataseat:2021, data_seatno: "s3-y-7-40", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席3", class: "type_1"},
  /*2022 */{dataseat:2022, data_seatno: "s3-y-7-41", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席3", class: "type_1"},
  /*2023 */{dataseat:2023, data_seatno: "s3-y-7-42", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席3", class: "type_1"},
  /*2024 */{dataseat:2024, data_seatno: "s3-y-7-43", customer_id: "", name: "", mail: "", phone: "", studio: "生徒席3", class: "type_1"},
  //////////// 8列目 ////////////
  /*2025 */{dataseat:2025, data_seatno: "s3-y-8-6", customer_id: "817", name: "村田 莉夏", mail: "", phone: "", studio: "福島", class: "type_2"},
  /*2026 */{dataseat:2026, data_seatno: "s3-y-8-7", customer_id: "817", name: "村田 莉夏", mail: "", phone: "", studio: "福島", class: "type_2"},
  /*2027 */{dataseat:2027, data_seatno: "s3-y-8-8", customer_id: "1650", name: "福井 栞奈", mail: "", phone: "", studio: "福島", class: "type_2"},
  /*2028 */{dataseat:2028, data_seatno: "s3-y-8-9", customer_id: "1650", name: "福井 栞奈", mail: "", phone: "", studio: "福島", class: "type_2"},
  /*2029 */{dataseat:2029, data_seatno: "s3-y-8-10", customer_id: "1651", name: "坂井 万里子", mail: "", phone: "", studio: "福島", class: "type_2"},
  /*2030 */{dataseat:2030, data_seatno: "s3-y-8-11", customer_id: "1651", name: "坂井 万里子", mail: "", phone: "", studio: "福島", class: "type_2"},
  /*2031 */{dataseat:2031, data_seatno: "s3-y-8-12", customer_id: "1658", name: "畝 みなみ", mail: "", phone: "", studio: "福島", class: "type_2"},
  /*2032 */{dataseat:2032, data_seatno: "s3-y-8-13", customer_id: "1658", name: "畝 みなみ", mail: "", phone: "", studio: "福島", class: "type_2"},
  /*2033 */{dataseat:2033, data_seatno: "s3-y-8-14", customer_id: "1736", name: "木村 水音", mail: "", phone: "", studio: "福島", class: "type_2"},
  /*2034 */{dataseat:2034, data_seatno: "s3-y-8-15", customer_id: "1736", name: "木村 水音", mail: "", phone: "", studio: "福島", class: "type_2"},
  /*2035 */{dataseat:2035, data_seatno: "s3-y-8-16", customer_id: "1798", name: "阪口 実咲", mail: "", phone: "", studio: "福島", class: "type_2"},
  /*2036 */{dataseat:2036, data_seatno: "s3-y-8-17", customer_id: "1798", name: "阪口 実咲", mail: "", phone: "", studio: "福島", class: "type_2"},
  /*2037 */{dataseat:2037, data_seatno: "s3-y-8-19", customer_id: "1538", name: "江口 琴音", mail: "", phone: "", studio: "福島", class: "type_2"},
  /*2038 */{dataseat:2038, data_seatno: "s3-y-8-20", customer_id: "1538", name: "江口 琴音", mail: "", phone: "", studio: "福島", class: "type_2"},
  /*2039 */{dataseat:2039, data_seatno: "s3-y-8-21", customer_id: "1753", name: "巽 彩姫", mail: "", phone: "", studio: "福島", class: "type_2"},
  /*2040 */{dataseat:2040, data_seatno: "s3-y-8-22", customer_id: "1753", name: "巽 彩姫", mail: "", phone: "", studio: "福島", class: "type_2"},
  /*2041 */{dataseat:2041, data_seatno: "s3-y-8-23", customer_id: "1819", name: "竹中 結梨花", mail: "", phone: "", studio: "福島", class: "type_2"},
  /*2042 */{dataseat:2042, data_seatno: "s3-y-8-24", customer_id: "1819", name: "竹中 結梨花", mail: "", phone: "", studio: "福島", class: "type_2"},
  /*2043 */{dataseat:2043, data_seatno: "s3-y-8-25", customer_id: "1981", name: "木村 湊", mail: "", phone: "", studio: "福島", class: "type_2"},
  /*2044 */{dataseat:2044, data_seatno: "s3-y-8-26", customer_id: "1981", name: "木村 湊", mail: "", phone: "", studio: "福島", class: "type_2"},
  /*2045 */{dataseat:2045, data_seatno: "s3-y-8-27", customer_id: "1997", name: "竹本 悠月", mail: "", phone: "", studio: "福島", class: "type_2"},
  /*2046 */{dataseat:2046, data_seatno: "s3-y-8-28", customer_id: "1997", name: "竹本 悠月", mail: "", phone: "", studio: "福島", class: "type_2"},
  /*2047 */{dataseat:2047, data_seatno: "s3-y-8-29", customer_id: "1233", name: "大林 穂希", mail: "", phone: "", studio: "三国ヶ丘", class: "type_3"},
  /*2048 */{dataseat:2048, data_seatno: "s3-y-8-30", customer_id: "1233", name: "大林 穂希", mail: "", phone: "", studio: "三国ヶ丘", class: "type_3"},
  /*2049 */{dataseat:2049, data_seatno: "s3-y-8-32", customer_id: "1237", name: "岩本 樹莉愛", mail: "", phone: "", studio: "三国ヶ丘", class: "type_3"},
  /*2050 */{dataseat:2050, data_seatno: "s3-y-8-33", customer_id: "1237", name: "岩本 樹莉愛", mail: "", phone: "", studio: "三国ヶ丘", class: "type_3"},
  /*2051 */{dataseat:2051, data_seatno: "s3-y-8-34", customer_id: "1239", name: "江良 菫", mail: "", phone: "", studio: "三国ヶ丘", class: "type_3"},
  /*2052 */{dataseat:2052, data_seatno: "s3-y-8-35", customer_id: "1239", name: "江良 菫", mail: "", phone: "", studio: "三国ヶ丘", class: "type_3"},
  /*2053 */{dataseat:2053, data_seatno: "s3-y-8-36", customer_id: "1298", name: "正分 結愛", mail: "", phone: "", studio: "三国ヶ丘", class: "type_3"},
  /*2054 */{dataseat:2054, data_seatno: "s3-y-8-37", customer_id: "1298", name: "正分 結愛", mail: "", phone: "", studio: "三国ヶ丘", class: "type_3"},
  /*2055 */{dataseat:2055, data_seatno: "s3-y-8-38", customer_id: "1789", name: "船塚 彩芭", mail: "", phone: "", studio: "三国ヶ丘", class: "type_3"},
  /*2056 */{dataseat:2056, data_seatno: "s3-y-8-39", customer_id: "1789", name: "船塚 彩芭", mail: "", phone: "", studio: "三国ヶ丘", class: "type_3"},
  /*2057 */{dataseat:2057, data_seatno: "s3-y-8-40", customer_id: "1884", name: "大野 瑛未華", mail: "", phone: "", studio: "三国ヶ丘", class: "type_3"},
  /*2058 */{dataseat:2058, data_seatno: "s3-y-8-41", customer_id: "1884", name: "大野 瑛未華", mail: "", phone: "", studio: "三国ヶ丘", class: "type_3"},
  /*2059 */{dataseat:2059, data_seatno: "s3-y-8-42", customer_id: "1944", name: "小樋井 彩乃", mail: "", phone: "", studio: "三国ヶ丘", class: "type_3"},
  /*2060 */{dataseat:2060, data_seatno: "s3-y-8-43", customer_id: "1944", name: "小樋井 彩乃", mail: "", phone: "", studio: "三国ヶ丘", class: "type_3"},
  //////////// 9列目 ////////////
  /*2061 */{dataseat:2061, data_seatno: "s3-y-9-6", customer_id: "608", name: "西町 香優", mail: "", phone: "", studio: "岸和田", class: "type_6"},
  /*2062 */{dataseat:2062, data_seatno: "s3-y-9-7", customer_id: "608", name: "西町 香優", mail: "", phone: "", studio: "岸和田", class: "type_6"},
  /*2063 */{dataseat:2063, data_seatno: "s3-y-9-8", customer_id: "39", name: "根来 芽依", mail: "", phone: "", studio: "岸和田", class: "type_6"},
  /*2064 */{dataseat:2064, data_seatno: "s3-y-9-9", customer_id: "39", name: "根来 芽依", mail: "", phone: "", studio: "岸和田", class: "type_6"},
  /*2065 */{dataseat:2065, data_seatno: "s3-y-9-10", customer_id: "1033", name: "藤田 心音", mail: "", phone: "", studio: "岸和田", class: "type_6"},
  /*2066 */{dataseat:2066, data_seatno: "s3-y-9-11", customer_id: "1033", name: "藤田 心音", mail: "", phone: "", studio: "岸和田", class: "type_6"},
  /*2067 */{dataseat:2067, data_seatno: "s3-y-9-12", customer_id: "576", name: "南 茉羽", mail: "", phone: "", studio: "岸和田", class: "type_6"},
  /*2068 */{dataseat:2068, data_seatno: "s3-y-9-13", customer_id: "576", name: "南 茉羽", mail: "", phone: "", studio: "岸和田", class: "type_6"},
  /*2069 */{dataseat:2069, data_seatno: "s3-y-9-14", customer_id: "576", name: "南 茉羽", mail: "", phone: "", studio: "岸和田", class: "type_6"},
  /*2070 */{dataseat:2070, data_seatno: "s3-y-9-15", customer_id: "1446", name: "井川 由貴奈", mail: "", phone: "", studio: "三国ヶ丘", class: "type_3"},
  /*2071 */{dataseat:2071, data_seatno: "s3-y-9-16", customer_id: "1446", name: "井川 由貴奈", mail: "", phone: "", studio: "三国ヶ丘", class: "type_3"},
  /*2072 */{dataseat:2072, data_seatno: "s3-y-9-17", customer_id: "1446", name: "井川 由貴奈", mail: "", phone: "", studio: "三国ヶ丘", class: "type_3"},
  /*2073 */{dataseat:2073, data_seatno: "s3-y-9-19", customer_id: "1619", name: "森 愛菜", mail: "", phone: "", studio: "三国ヶ丘", class: "type_3"},
  /*2074 */{dataseat:2074, data_seatno: "s3-y-9-20", customer_id: "1619", name: "森 愛菜", mail: "", phone: "", studio: "三国ヶ丘", class: "type_3"},
  /*2075 */{dataseat:2075, data_seatno: "s3-y-9-21", customer_id: "1744", name: "藤原 えるな", mail: "", phone: "", studio: "三国ヶ丘", class: "type_3"},
  /*2076 */{dataseat:2076, data_seatno: "s3-y-9-22", customer_id: "1744", name: "藤原 えるな", mail: "", phone: "", studio: "三国ヶ丘", class: "type_3"},
  /*2077 */{dataseat:2077, data_seatno: "s3-y-9-23", customer_id: "1746", name: "太田 莉姫", mail: "", phone: "", studio: "三国ヶ丘", class: "type_3"},
  /*2078 */{dataseat:2078, data_seatno: "s3-y-9-24", customer_id: "1746", name: "太田 莉姫", mail: "", phone: "", studio: "三国ヶ丘", class: "type_3"},
  /*2079 */{dataseat:2079, data_seatno: "s3-y-9-25", customer_id: "2034", name: "松浦 美紗希", mail: "", phone: "", studio: "三国ヶ丘", class: "type_3"},
  /*2080 */{dataseat:2080, data_seatno: "s3-y-9-26", customer_id: "2034", name: "松浦 美紗希", mail: "", phone: "", studio: "三国ヶ丘", class: "type_3"},
  /*2081 */{dataseat:2081, data_seatno: "s3-y-9-27", customer_id: "1235", name: "山崎 優杏", mail: "", phone: "", studio: "三国ヶ丘", class: "type_3"},
  /*2082 */{dataseat:2082, data_seatno: "s3-y-9-28", customer_id: "1235", name: "山崎 優杏", mail: "", phone: "", studio: "三国ヶ丘", class: "type_3"},
  /*2083 */{dataseat:2083, data_seatno: "s3-y-9-29", customer_id: "1236", name: "渡辺 愛子", mail: "", phone: "", studio: "三国ヶ丘", class: "type_3"},
  /*2084 */{dataseat:2084, data_seatno: "s3-y-9-30", customer_id: "1236", name: "渡辺 愛子", mail: "", phone: "", studio: "三国ヶ丘", class: "type_3"},
  /*2085 */{dataseat:2085, data_seatno: "s3-y-9-32", customer_id: "1265", name: "大川 紗愛", mail: "", phone: "", studio: "三国ヶ丘", class: "type_3"},
  /*2086 */{dataseat:2086, data_seatno: "s3-y-9-33", customer_id: "1265", name: "大川 紗愛", mail: "", phone: "", studio: "三国ヶ丘", class: "type_3"},
  /*2087 */{dataseat:2087, data_seatno: "s3-y-9-34", customer_id: "1267", name: "下地 真萌", mail: "", phone: "", studio: "三国ヶ丘", class: "type_3"},
  /*2088 */{dataseat:2088, data_seatno: "s3-y-9-35", customer_id: "1267", name: "下地 真萌", mail: "", phone: "", studio: "三国ヶ丘", class: "type_3"},
  /*2089 */{dataseat:2089, data_seatno: "s3-y-9-36", customer_id: "1945", name: "山口 咲良", mail: "", phone: "", studio: "三国ヶ丘", class: "type_3"},
  /*2090 */{dataseat:2090, data_seatno: "s3-y-9-37", customer_id: "1945", name: "山口 咲良", mail: "", phone: "", studio: "三国ヶ丘", class: "type_3"},
  /*2091 */{dataseat:2091, data_seatno: "s3-y-9-38", customer_id: "1946", name: "野口 菜央", mail: "", phone: "", studio: "三国ヶ丘", class: "type_3"},
  /*2092 */{dataseat:2092, data_seatno: "s3-y-9-39", customer_id: "1946", name: "野口 菜央", mail: "", phone: "", studio: "三国ヶ丘", class: "type_3"},
  /*2093 */{dataseat:2093, data_seatno: "s3-y-9-40", customer_id: "1270", name: "市川 愛桜", mail: "", phone: "", studio: "三国ヶ丘", class: "type_3"},
  /*2094 */{dataseat:2094, data_seatno: "s3-y-9-41", customer_id: "1270", name: "市川 愛桜", mail: "", phone: "", studio: "三国ヶ丘", class: "type_3"},
  /*2095 */{dataseat:2095, data_seatno: "s3-y-9-42", customer_id: "1362", name: "鎌田 にこ菜", mail: "", phone: "", studio: "三国ヶ丘", class: "type_3"},
  /*2096 */{dataseat:2096, data_seatno: "s3-y-9-43", customer_id: "1362", name: "鎌田 にこ菜", mail: "", phone: "", studio: "三国ヶ丘", class: "type_3"},
  //////////// 10列目 ////////////
  /*2097 */{dataseat:2097, data_seatno: "s3-y-10-6", customer_id: "634", name: "松本 七椛", mail: "", phone: "", studio: "岸和田", class: "type_6"},
  /*2098 */{dataseat:2098, data_seatno: "s3-y-10-7", customer_id: "634", name: "松本 七椛", mail: "", phone: "", studio: "岸和田", class: "type_6"},
  /*2099 */{dataseat:2099, data_seatno: "s3-y-10-8", customer_id: "640", name: "前田 鈴華", mail: "", phone: "", studio: "岸和田", class: "type_6"},
  /*2100 */{dataseat:2100, data_seatno: "s3-y-10-9", customer_id: "640", name: "前田 鈴華", mail: "", phone: "", studio: "岸和田", class: "type_6"},
  /*2101 */{dataseat:2101, data_seatno: "s3-y-10-10", customer_id: "640", name: "前田 鈴華", mail: "", phone: "", studio: "岸和田", class: "type_6"},
  /*2102 */{dataseat:2102, data_seatno: "s3-y-10-11", customer_id: "640", name: "前田 鈴華", mail: "", phone: "", studio: "岸和田", class: "type_6"},
  /*2103 */{dataseat:2103, data_seatno: "s3-y-10-12", customer_id: "653", name: "井上 優杏", mail: "", phone: "", studio: "岸和田", class: "type_6"},
  /*2104 */{dataseat:2104, data_seatno: "s3-y-10-13", customer_id: "653", name: "井上 優杏", mail: "", phone: "", studio: "岸和田", class: "type_6"},
  /*2105 */{dataseat:2105, data_seatno: "s3-y-10-14", customer_id: "996", name: "羽間 桜愛", mail: "", phone: "", studio: "岸和田", class: "type_6"},
  /*2106 */{dataseat:2106, data_seatno: "s3-y-10-15", customer_id: "996", name: "羽間 桜愛", mail: "", phone: "", studio: "岸和田", class: "type_6"},
  /*2107 */{dataseat:2107, data_seatno: "s3-y-10-16", customer_id: "601", name: "中村 心春", mail: "", phone: "", studio: "岸和田", class: "type_6"},
  /*2108 */{dataseat:2108, data_seatno: "s3-y-10-17", customer_id: "601", name: "中村 心春", mail: "", phone: "", studio: "岸和田", class: "type_6"},
  /*2109 */{dataseat:2109, data_seatno: "s3-y-10-19", customer_id: "837", name: "鮒谷 藍", mail: "", phone: "", studio: "布施", class: "type_4"},
  /*2110 */{dataseat:2110, data_seatno: "s3-y-10-20", customer_id: "837", name: "鮒谷 藍", mail: "", phone: "", studio: "布施", class: "type_4"},
  /*2111 */{dataseat:2111, data_seatno: "s3-y-10-21", customer_id: "853", name: "坂巻 希美", mail: "", phone: "", studio: "布施", class: "type_4"},
  /*2112 */{dataseat:2112, data_seatno: "s3-y-10-22", customer_id: "853", name: "坂巻 希美", mail: "", phone: "", studio: "布施", class: "type_4"},
  /*2113 */{dataseat:2113, data_seatno: "s3-y-10-23", customer_id: "1304", name: "森下 寧々", mail: "", phone: "", studio: "布施", class: "type_4"},
  /*2114 */{dataseat:2114, data_seatno: "s3-y-10-24", customer_id: "1304", name: "森下 寧々", mail: "", phone: "", studio: "布施", class: "type_4"},
  /*2115 */{dataseat:2115, data_seatno: "s3-y-10-25", customer_id: "1304", name: "森下 寧々", mail: "", phone: "", studio: "布施", class: "type_4"},
  /*2116 */{dataseat:2116, data_seatno: "s3-y-10-26", customer_id: "848", name: "奥田 瑠奈", mail: "", phone: "", studio: "布施", class: "type_4"},
  /*2117 */{dataseat:2117, data_seatno: "s3-y-10-27", customer_id: "848", name: "奥田 瑠奈", mail: "", phone: "", studio: "布施", class: "type_4"},
  /*2118 */{dataseat:2118, data_seatno: "s3-y-10-28", customer_id: "848", name: "奥田 瑠奈", mail: "", phone: "", studio: "布施", class: "type_4"},
  /*2119 */{dataseat:2119, data_seatno: "s3-y-10-29", customer_id: "848", name: "奥田 瑠奈", mail: "", phone: "", studio: "布施", class: "type_4"},
  /*2120 */{dataseat:2120, data_seatno: "s3-y-10-30", customer_id: "848", name: "奥田 瑠奈", mail: "", phone: "", studio: "布施", class: "type_4"},
  /*2121 */{dataseat:2121, data_seatno: "s3-y-10-32", customer_id: "1887", name: "八幡 瑠夏", mail: "", phone: "", studio: "布施", class: "type_4"},
  /*2122 */{dataseat:2122, data_seatno: "s3-y-10-33", customer_id: "1887", name: "八幡 瑠夏", mail: "", phone: "", studio: "布施", class: "type_4"},
  /*2123 */{dataseat:2123, data_seatno: "s3-y-10-34", customer_id: "1422", name: "佐藤 湊音", mail: "", phone: "", studio: "布施", class: "type_4"},
  /*2124 */{dataseat:2124, data_seatno: "s3-y-10-35", customer_id: "1422", name: "佐藤 湊音", mail: "", phone: "", studio: "布施", class: "type_4"},
  /*2125 */{dataseat:2125, data_seatno: "s3-y-10-36", customer_id: "1163", name: "佐藤 由美子", mail: "", phone: "", studio: "布施", class: "type_4"},
  /*2126 */{dataseat:2126, data_seatno: "s3-y-10-37", customer_id: "1163", name: "佐藤 由美子", mail: "", phone: "", studio: "布施", class: "type_4"},
  /*2127 */{dataseat:2127, data_seatno: "s3-y-10-38", customer_id: "806", name: "真野 麗羽", mail: "", phone: "", studio: "布施", class: "type_4"},
  /*2128 */{dataseat:2128, data_seatno: "s3-y-10-39", customer_id: "806", name: "真野 麗羽", mail: "", phone: "", studio: "布施", class: "type_4"},
  /*2129 */{dataseat:2129, data_seatno: "s3-y-10-40", customer_id: "810", name: "松本 彩織", mail: "", phone: "", studio: "布施", class: "type_4"},
  /*2130 */{dataseat:2130, data_seatno: "s3-y-10-41", customer_id: "810", name: "松本 彩織", mail: "", phone: "", studio: "布施", class: "type_4"},
  /*2131 */{dataseat:2131, data_seatno: "s3-y-10-42", customer_id: "833", name: "宮本 莉桜", mail: "", phone: "", studio: "布施", class: "type_4"},
  /*2132 */{dataseat:2132, data_seatno: "s3-y-10-43", customer_id: "833", name: "宮本 莉桜", mail: "", phone: "", studio: "布施", class: "type_4"},
  //////////// 11列目 ////////////
  /*2133 */{dataseat:2133, data_seatno: "s3-y-11-6", customer_id: "645", name: "橋本 琉咲", mail: "", phone: "", studio: "岸和田", class: "type_6"},
  /*2134 */{dataseat:2134, data_seatno: "s3-y-11-7", customer_id: "645", name: "橋本 琉咲", mail: "", phone: "", studio: "岸和田", class: "type_6"},
  /*2135 */{dataseat:2135, data_seatno: "s3-y-11-8", customer_id: "645", name: "橋本 琉咲", mail: "", phone: "", studio: "岸和田", class: "type_6"},
  /*2136 */{dataseat:2136, data_seatno: "s3-y-11-9", customer_id: "645", name: "橋本 琉咲", mail: "", phone: "", studio: "岸和田", class: "type_6"},
  /*2137 */{dataseat:2137, data_seatno: "s3-y-11-10", customer_id: "983", name: "藤木 胡春", mail: "", phone: "", studio: "岸和田", class: "type_6"},
  /*2138 */{dataseat:2138, data_seatno: "s3-y-11-11", customer_id: "983", name: "藤木 胡春", mail: "", phone: "", studio: "岸和田", class: "type_6"},
  /*2139 */{dataseat:2139, data_seatno: "s3-y-11-12", customer_id: "1294", name: "津田 梨衣奈", mail: "", phone: "", studio: "岸和田", class: "type_6"},
  /*2140 */{dataseat:2140, data_seatno: "s3-y-11-13", customer_id: "1294", name: "津田 梨衣奈", mail: "", phone: "", studio: "岸和田", class: "type_6"},
  /*2141 */{dataseat:2141, data_seatno: "s3-y-11-14", customer_id: "1423", name: "下江 彩花梨", mail: "", phone: "", studio: "岸和田", class: "type_6"},
  /*2142 */{dataseat:2142, data_seatno: "s3-y-11-15", customer_id: "1423", name: "下江 彩花梨", mail: "", phone: "", studio: "岸和田", class: "type_6"},
  /*2143 */{dataseat:2143, data_seatno: "s3-y-11-16", customer_id: "1553", name: "畑森 愛來", mail: "", phone: "", studio: "岸和田", class: "type_6"},
  /*2144 */{dataseat:2144, data_seatno: "s3-y-11-17", customer_id: "1553", name: "畑森 愛來", mail: "", phone: "", studio: "岸和田", class: "type_6"},
  /*2145 */{dataseat:2145, data_seatno: "s3-y-11-19", customer_id: "911", name: "古川 悠乃", mail: "", phone: "", studio: "布施", class: "type_4"},
  /*2146 */{dataseat:2146, data_seatno: "s3-y-11-20", customer_id: "911", name: "古川 悠乃", mail: "", phone: "", studio: "布施", class: "type_4"},
  /*2147 */{dataseat:2147, data_seatno: "s3-y-11-21", customer_id: "912", name: "原田 京佳", mail: "", phone: "", studio: "布施", class: "type_4"},
  /*2148 */{dataseat:2148, data_seatno: "s3-y-11-22", customer_id: "912", name: "原田 京佳", mail: "", phone: "", studio: "布施", class: "type_4"},
  /*2149 */{dataseat:2149, data_seatno: "s3-y-11-23", customer_id: "984", name: "清水 歩夢", mail: "", phone: "", studio: "布施", class: "type_4"},
  /*2150 */{dataseat:2150, data_seatno: "s3-y-11-24", customer_id: "984", name: "清水 歩夢", mail: "", phone: "", studio: "布施", class: "type_4"},
  /*2151 */{dataseat:2151, data_seatno: "s3-y-11-25", customer_id: "1178", name: "有吉 羽七", mail: "", phone: "", studio: "布施", class: "type_4"},
  /*2152 */{dataseat:2152, data_seatno: "s3-y-11-26", customer_id: "1178", name: "有吉 羽七", mail: "", phone: "", studio: "布施", class: "type_4"},
  /*2153 */{dataseat:2153, data_seatno: "s3-y-11-27", customer_id: "1366", name: "木下 莉杏菜", mail: "", phone: "", studio: "布施", class: "type_4"},
  /*2154 */{dataseat:2154, data_seatno: "s3-y-11-28", customer_id: "1366", name: "木下 莉杏菜", mail: "", phone: "", studio: "布施", class: "type_4"},
  /*2155 */{dataseat:2155, data_seatno: "s3-y-11-29", customer_id: "949", name: "柿本 茉奈", mail: "", phone: "", studio: "豊中", class: "type_5"},
  /*2156 */{dataseat:2156, data_seatno: "s3-y-11-30", customer_id: "949", name: "柿本 茉奈", mail: "", phone: "", studio: "豊中", class: "type_5"},
  /*2157 */{dataseat:2157, data_seatno: "s3-y-11-32", customer_id: "56", name: "井上 七南", mail: "", phone: "", studio: "豊中", class: "type_5"},
  /*2158 */{dataseat:2158, data_seatno: "s3-y-11-33", customer_id: "56", name: "井上 七南", mail: "", phone: "", studio: "豊中", class: "type_5"},
  /*2159 */{dataseat:2159, data_seatno: "s3-y-11-34", customer_id: "1015", name: "松岡 希唯", mail: "", phone: "", studio: "豊中", class: "type_5"},
  /*2160 */{dataseat:2160, data_seatno: "s3-y-11-35", customer_id: "1015", name: "松岡 希唯", mail: "", phone: "", studio: "豊中", class: "type_5"},
  /*2161 */{dataseat:2161, data_seatno: "s3-y-11-36", customer_id: "1017", name: "橋垣 美央", mail: "", phone: "", studio: "豊中", class: "type_5"},
  /*2162 */{dataseat:2162, data_seatno: "s3-y-11-37", customer_id: "1017", name: "橋垣 美央", mail: "", phone: "", studio: "豊中", class: "type_5"},
  /*2163 */{dataseat:2163, data_seatno: "s3-y-11-38", customer_id: "1250", name: "原田 芽依", mail: "", phone: "", studio: "豊中", class: "type_5"},
  /*2164 */{dataseat:2164, data_seatno: "s3-y-11-39", customer_id: "1250", name: "原田 芽依", mail: "", phone: "", studio: "豊中", class: "type_5"},
  /*2165 */{dataseat:2165, data_seatno: "s3-y-11-40", customer_id: "1250", name: "原田 芽依", mail: "", phone: "", studio: "豊中", class: "type_5"},
  /*2166 */{dataseat:2166, data_seatno: "s3-y-11-41", customer_id: "1250", name: "原田 芽依", mail: "", phone: "", studio: "豊中", class: "type_5"},
  /*2167 */{dataseat:2167, data_seatno: "s3-y-11-42", customer_id: "1250", name: "原田 芽依", mail: "", phone: "", studio: "豊中", class: "type_5"},
  /*2168 */{dataseat:2168, data_seatno: "s3-y-11-43", customer_id: "1250", name: "原田 芽依", mail: "", phone: "", studio: "豊中", class: "type_5"},
  //////////// 12列目 ////////////
  /*2169 */{dataseat:2169, data_seatno: "s3-y-12-8", customer_id: "1831", name: "松田 莉那", mail: "", phone: "", studio: "岸和田", class: "type_6"},
  /*2170 */{dataseat:2170, data_seatno: "s3-y-12-9", customer_id: "1831", name: "松田 莉那", mail: "", phone: "", studio: "岸和田", class: "type_6"},
  /*2171 */{dataseat:2171, data_seatno: "s3-y-12-10", customer_id: "581", name: "丸山 咲", mail: "", phone: "", studio: "岸和田", class: "type_6"},
  /*2172 */{dataseat:2172, data_seatno: "s3-y-12-11", customer_id: "581", name: "丸山 咲", mail: "", phone: "", studio: "岸和田", class: "type_6"},
  /*2173 */{dataseat:2173, data_seatno: "s3-y-12-12", customer_id: "1086", name: "細川 七珠", mail: "", phone: "", studio: "岸和田", class: "type_6"},
  /*2174 */{dataseat:2174, data_seatno: "s3-y-12-13", customer_id: "1086", name: "細川 七珠", mail: "", phone: "", studio: "岸和田", class: "type_6"},
  /*2175 */{dataseat:2175, data_seatno: "s3-y-12-14", customer_id: "1395", name: "正岡 つくし", mail: "", phone: "", studio: "岸和田", class: "type_6"},
  /*2176 */{dataseat:2176, data_seatno: "s3-y-12-15", customer_id: "1395", name: "正岡 つくし", mail: "", phone: "", studio: "岸和田", class: "type_6"},
  /*2177 */{dataseat:2177, data_seatno: "s3-y-12-16", customer_id: "1459", name: "中林 夏梨", mail: "", phone: "", studio: "岸和田", class: "type_6"},
  /*2178 */{dataseat:2178, data_seatno: "s3-y-12-17", customer_id: "1459", name: "中林 夏梨", mail: "", phone: "", studio: "岸和田", class: "type_6"},
  /*2179 */{dataseat:2179, data_seatno: "s3-y-12-19", customer_id: "1702", name: "廣瀬 茉陽夏", mail: "", phone: "", studio: "岸和田", class: "type_6"},
  /*2180 */{dataseat:2180, data_seatno: "s3-y-12-20", customer_id: "1702", name: "廣瀬 茉陽夏", mail: "", phone: "", studio: "岸和田", class: "type_6"},
  /*2181 */{dataseat:2181, data_seatno: "s3-y-12-21", customer_id: "1094", name: "野村 都羽", mail: "", phone: "", studio: "岸和田", class: "type_6"},
  /*2182 */{dataseat:2182, data_seatno: "s3-y-12-22", customer_id: "1094", name: "野村 都羽", mail: "", phone: "", studio: "岸和田", class: "type_6"},
  /*2183 */{dataseat:2183, data_seatno: "s3-y-12-23", customer_id: "1547", name: "藤本 結子", mail: "", phone: "", studio: "長岡京", class: "type_7"},
  /*2184 */{dataseat:2184, data_seatno: "s3-y-12-24", customer_id: "1547", name: "藤本 結子", mail: "", phone: "", studio: "長岡京", class: "type_7"},
  /*2185 */{dataseat:2185, data_seatno: "s3-y-12-25", customer_id: "606", name: "西野 瑠色", mail: "", phone: "", studio: "長岡京", class: "type_7"},
  /*2186 */{dataseat:2186, data_seatno: "s3-y-12-26", customer_id: "606", name: "西野 瑠色", mail: "", phone: "", studio: "長岡京", class: "type_7"},
  /*2187 */{dataseat:2187, data_seatno: "s3-y-12-27", customer_id: "605", name: "奥岨 あいか", mail: "", phone: "", studio: "長岡京", class: "type_7"},
  /*2188 */{dataseat:2188, data_seatno: "s3-y-12-28", customer_id: "605", name: "奥岨 あいか", mail: "", phone: "", studio: "長岡京", class: "type_7"},
  /*2189 */{dataseat:2189, data_seatno: "s3-y-12-29", customer_id: "24", name: "合田 彩笑", mail: "", phone: "", studio: "長岡京", class: "type_7"},
  /*2190 */{dataseat:2190, data_seatno: "s3-y-12-30", customer_id: "24", name: "合田 彩笑", mail: "", phone: "", studio: "長岡京", class: "type_7"},
  /*2191 */{dataseat:2191, data_seatno: "s3-y-12-32", customer_id: "43", name: "桶屋 美帆", mail: "", phone: "", studio: "長岡京", class: "type_7"},
  /*2192 */{dataseat:2192, data_seatno: "s3-y-12-33", customer_id: "43", name: "桶屋 美帆", mail: "", phone: "", studio: "長岡京", class: "type_7"},
  /*2193 */{dataseat:2193, data_seatno: "s3-y-12-34", customer_id: "563", name: "塩井 杏夢", mail: "", phone: "", studio: "長岡京", class: "type_7"},
  /*2194 */{dataseat:2194, data_seatno: "s3-y-12-35", customer_id: "563", name: "塩井 杏夢", mail: "", phone: "", studio: "長岡京", class: "type_7"},
  /*2195 */{dataseat:2195, data_seatno: "s3-y-12-36", customer_id: "643", name: "山内 ひまり", mail: "", phone: "", studio: "長岡京", class: "type_7"},
  /*2196 */{dataseat:2196, data_seatno: "s3-y-12-37", customer_id: "643", name: "山内 ひまり", mail: "", phone: "", studio: "長岡京", class: "type_7"},
  /*2197 */{dataseat:2197, data_seatno: "s3-y-12-38", customer_id: "700", name: "松本 こゆみ", mail: "", phone: "", studio: "長岡京", class: "type_7"},
  /*2198 */{dataseat:2198, data_seatno: "s3-y-12-39", customer_id: "700", name: "松本 こゆみ", mail: "", phone: "", studio: "長岡京", class: "type_7"},
  /*2199 */{dataseat:2199, data_seatno: "s3-y-12-40", customer_id: "769", name: "奥居 紅葉", mail: "", phone: "", studio: "長岡京", class: "type_7"},
  /*2200 */{dataseat:2200, data_seatno: "s3-y-12-41", customer_id: "769", name: "奥居 紅葉", mail: "", phone: "", studio: "長岡京", class: "type_7"},
  //////////// 13列目左 ////////////
  /*2201 */{dataseat:2201, data_seatno: "s3-y-13-8", customer_id: "1614", name: "山中 希実", mail: "", phone: "", studio: "高槻", class: "type_8"},
  /*2202 */{dataseat:2202, data_seatno: "s3-y-13-9", customer_id: "1614", name: "山中 希実", mail: "", phone: "", studio: "高槻", class: "type_8"},
  /*2203 */{dataseat:2203, data_seatno: "s3-y-13-10", customer_id: "1645", name: "菊地 夢叶", mail: "", phone: "", studio: "高槻", class: "type_8"},
  /*2204 */{dataseat:2204, data_seatno: "s3-y-13-11", customer_id: "1645", name: "菊地 夢叶", mail: "", phone: "", studio: "高槻", class: "type_8"},
  //////////// 13列目右 ////////////
  /*2205 */{dataseat:2205, data_seatno: "s3-y-13-45", customer_id: "604", name: "粂井 純怜", mail: "", phone: "", studio: "長岡京", class: "type_7"},
  /*2206 */{dataseat:2206, data_seatno: "s3-y-13-46", customer_id: "604", name: "粂井 純怜", mail: "", phone: "", studio: "長岡京", class: "type_7"},
  /*2207 */{dataseat:2207, data_seatno: "s3-y-13-47", customer_id: "630", name: "土肥 晴名", mail: "", phone: "", studio: "長岡京", class: "type_7"},
  /*2208 */{dataseat:2208, data_seatno: "s3-y-13-48", customer_id: "630", name: "土肥 晴名", mail: "", phone: "", studio: "長岡京", class: "type_7"},
  //////////// 13列目中央 ////////////
  /*2209 */{dataseat:2209, data_seatno: "s3-y-13-8", customer_id: "1133", name: "前田 新花", mail: "", phone: "", studio: "高槻", class: "type_8"},
  /*2210 */{dataseat:2210, data_seatno: "s3-y-13-9", customer_id: "1133", name: "前田 新花", mail: "", phone: "", studio: "高槻", class: "type_8"},
  /*2211 */{dataseat:2211, data_seatno: "s3-y-13-10", customer_id: "1136", name: "井上 涼", mail: "", phone: "", studio: "高槻", class: "type_8"},
  /*2212 */{dataseat:2212, data_seatno: "s3-y-13-11", customer_id: "1136", name: "井上 涼", mail: "", phone: "", studio: "高槻", class: "type_8"},
  /*2213 */{dataseat:2213, data_seatno: "s3-y-13-12", customer_id: "1152", name: "山田 胡桃", mail: "", phone: "", studio: "高槻", class: "type_8"},
  /*2214 */{dataseat:2214, data_seatno: "s3-y-13-13", customer_id: "1152", name: "山田 胡桃", mail: "", phone: "", studio: "高槻", class: "type_8"},
  /*2215 */{dataseat:2215, data_seatno: "s3-y-13-14", customer_id: "1153", name: "岡部 ゆきな", mail: "", phone: "", studio: "高槻", class: "type_8"},
  /*2216 */{dataseat:2216, data_seatno: "s3-y-13-15", customer_id: "1153", name: "岡部 ゆきな", mail: "", phone: "", studio: "高槻", class: "type_8"},
  /*2217 */{dataseat:2217, data_seatno: "s3-y-13-16", customer_id: "1173", name: "金本 光莉", mail: "", phone: "", studio: "高槻", class: "type_8"},
  /*2218 */{dataseat:2218, data_seatno: "s3-y-13-17", customer_id: "1173", name: "金本 光莉", mail: "", phone: "", studio: "高槻", class: "type_8"},
  /*2219 */{dataseat:2219, data_seatno: "s3-y-13-19", customer_id: "1155", name: "清水 楓佳", mail: "", phone: "", studio: "高槻", class: "type_8"},
  /*2220 */{dataseat:2220, data_seatno: "s3-y-13-20", customer_id: "1155", name: "清水 楓佳", mail: "", phone: "", studio: "高槻", class: "type_8"},
  /*2221 */{dataseat:2221, data_seatno: "s3-y-13-21", customer_id: "1172", name: "金子 想来璃", mail: "", phone: "", studio: "高槻", class: "type_8"},
  /*2222 */{dataseat:2222, data_seatno: "s3-y-13-22", customer_id: "1172", name: "金子 想来璃", mail: "", phone: "", studio: "高槻", class: "type_8"},
  /*2223 */{dataseat:2223, data_seatno: "s3-y-13-23", customer_id: "1649", name: "田渕 えみ", mail: "", phone: "", studio: "高槻", class: "type_8"},
  /*2224 */{dataseat:2224, data_seatno: "s3-y-13-24", customer_id: "1649", name: "田渕 えみ", mail: "", phone: "", studio: "高槻", class: "type_8"},
  /*2225 */{dataseat:2225, data_seatno: "s3-y-13-25", customer_id: "1021", name: "田中 綾音", mail: "", phone: "", studio: "長岡京", class: "type_7"},
  /*2226 */{dataseat:2226, data_seatno: "s3-y-13-26", customer_id: "1021", name: "田中 綾音", mail: "", phone: "", studio: "長岡京", class: "type_7"},
  /*2227 */{dataseat:2227, data_seatno: "s3-y-13-27", customer_id: "947", name: "吉田 優空", mail: "", phone: "", studio: "長岡京", class: "type_7"},
  /*2228 */{dataseat:2228, data_seatno: "s3-y-13-28", customer_id: "947", name: "吉田 優空", mail: "", phone: "", studio: "長岡京", class: "type_7"},
  /*2229 */{dataseat:2229, data_seatno: "s3-y-13-29", customer_id: "1465", name: "伊藤 愛彩", mail: "", phone: "", studio: "長岡京", class: "type_7"},
  /*2230 */{dataseat:2230, data_seatno: "s3-y-13-30", customer_id: "1465", name: "伊藤 愛彩", mail: "", phone: "", studio: "長岡京", class: "type_7"},
  /*2231 */{dataseat:2231, data_seatno: "s3-y-13-32", customer_id: "1069", name: "濱本 さくら", mail: "", phone: "", studio: "長岡京", class: "type_7"},
  /*2232 */{dataseat:2232, data_seatno: "s3-y-13-33", customer_id: "1069", name: "濱本 さくら", mail: "", phone: "", studio: "長岡京", class: "type_7"},
  /*2233 */{dataseat:2233, data_seatno: "s3-y-13-34", customer_id: "1129", name: "高井 柚花", mail: "", phone: "", studio: "長岡京", class: "type_7"},
  /*2234 */{dataseat:2234, data_seatno: "s3-y-13-35", customer_id: "1129", name: "高井 柚花", mail: "", phone: "", studio: "長岡京", class: "type_7"},
  /*2235 */{dataseat:2235, data_seatno: "s3-y-13-36", customer_id: "1523", name: "川村 瑠恋", mail: "", phone: "", studio: "長岡京", class: "type_7"},
  /*2236 */{dataseat:2236, data_seatno: "s3-y-13-37", customer_id: "1523", name: "川村 瑠恋", mail: "", phone: "", studio: "長岡京", class: "type_7"},
  /*2237 */{dataseat:2237, data_seatno: "s3-y-13-38", customer_id: "1600", name: "宮崎 ひなの", mail: "", phone: "", studio: "長岡京", class: "type_7"},
  /*2238 */{dataseat:2238, data_seatno: "s3-y-13-39", customer_id: "1600", name: "宮崎 ひなの", mail: "", phone: "", studio: "長岡京", class: "type_7"},
  /*2239 */{dataseat:2239, data_seatno: "s3-y-13-40", customer_id: "1708", name: "松ヶ野 楓菜", mail: "", phone: "", studio: "長岡京", class: "type_7"},
  /*2240 */{dataseat:2240, data_seatno: "s3-y-13-41", customer_id: "1708", name: "松ヶ野 楓菜", mail: "", phone: "", studio: "長岡京", class: "type_7"},
  //////////// 14列目右 ////////////
  /*2241 */{dataseat:2241, data_seatno: "s3-y-14-45", customer_id: "1171", name: "北仲 志桜", mail: "", phone: "", studio: "長岡京", class: "type_7"},
  /*2242 */{dataseat:2242, data_seatno: "s3-y-14-46", customer_id: "1171", name: "北仲 志桜", mail: "", phone: "", studio: "長岡京", class: "type_7"},
  /*2243 */{dataseat:2243, data_seatno: "s3-y-14-47", customer_id: "1710", name: "齋藤 柚希", mail: "", phone: "", studio: "長岡京", class: "type_7"},
  /*2244 */{dataseat:2244, data_seatno: "s3-y-14-48", customer_id: "1710", name: "齋藤 柚希", mail: "", phone: "", studio: "長岡京", class: "type_7"},
  //////////// 14列目左 ////////////
  /*2245 */{dataseat:2245, data_seatno: "s3-y-14-1", customer_id: "1698", name: "吉川 美侑", mail: "", phone: "", studio: "高槻", class: "type_8"},
  /*2246 */{dataseat:2246, data_seatno: "s3-y-14-2", customer_id: "1698", name: "吉川 美侑", mail: "", phone: "", studio: "高槻", class: "type_8"},
  /*2247 */{dataseat:2247, data_seatno: "s3-y-14-3", customer_id: "1920", name: "滝口 愛織", mail: "", phone: "", studio: "高槻", class: "type_8"},
  /*2248 */{dataseat:2248, data_seatno: "s3-y-14-4", customer_id: "1920", name: "滝口 愛織", mail: "", phone: "", studio: "高槻", class: "type_8"},
  //////////// 14列目中央 ////////////
  /*2249 */{dataseat:2249, data_seatno: "s3-y-14-8", customer_id: "1583", name: "秦 杏樹", mail: "", phone: "", studio: "高槻", class: "type_8"},
  /*2250 */{dataseat:2250, data_seatno: "s3-y-14-9", customer_id: "1583", name: "秦 杏樹", mail: "", phone: "", studio: "高槻", class: "type_8"},
  /*2251 */{dataseat:2251, data_seatno: "s3-y-14-10", customer_id: "1720", name: "塚田 優愛", mail: "", phone: "", studio: "高槻", class: "type_8"},
  /*2252 */{dataseat:2252, data_seatno: "s3-y-14-11", customer_id: "1720", name: "塚田 優愛", mail: "", phone: "", studio: "高槻", class: "type_8"},
  /*2253 */{dataseat:2253, data_seatno: "s3-y-14-12", customer_id: "1768", name: "本庄 南実", mail: "", phone: "", studio: "高槻", class: "type_8"},
  /*2254 */{dataseat:2254, data_seatno: "s3-y-14-13", customer_id: "1768", name: "本庄 南実", mail: "", phone: "", studio: "高槻", class: "type_8"},
  /*2255 */{dataseat:2255, data_seatno: "s3-y-14-14", customer_id: "1131", name: "林 莉里愛", mail: "", phone: "", studio: "高槻", class: "type_8"},
  /*2256 */{dataseat:2256, data_seatno: "s3-y-14-15", customer_id: "1131", name: "林 莉里愛", mail: "", phone: "", studio: "高槻", class: "type_8"},
  /*2257 */{dataseat:2257, data_seatno: "s3-y-14-16", customer_id: "1138", name: "木村 仁南", mail: "", phone: "", studio: "高槻", class: "type_8"},
  /*2258 */{dataseat:2258, data_seatno: "s3-y-14-17", customer_id: "1138", name: "木村 仁南", mail: "", phone: "", studio: "高槻", class: "type_8"},
  /*2259 */{dataseat:2259, data_seatno: "s3-y-14-19", customer_id: "1149", name: "源田 結愛", mail: "", phone: "", studio: "高槻", class: "type_8"},
  /*2260 */{dataseat:2260, data_seatno: "s3-y-14-20", customer_id: "1149", name: "源田 結愛", mail: "", phone: "", studio: "高槻", class: "type_8"},
  /*2261 */{dataseat:2261, data_seatno: "s3-y-14-21", customer_id: "1154", name: "髙見 柚月", mail: "", phone: "", studio: "高槻", class: "type_8"},
  /*2262 */{dataseat:2262, data_seatno: "s3-y-14-22", customer_id: "1154", name: "髙見 柚月", mail: "", phone: "", studio: "高槻", class: "type_8"},
  /*2263 */{dataseat:2263, data_seatno: "s3-y-14-23", customer_id: "1154", name: "髙見 柚月", mail: "", phone: "", studio: "高槻", class: "type_8"},
  /*2264 */{dataseat:2264, data_seatno: "s3-y-14-24", customer_id: "", name: "", mail: "", phone: "", studio: "カメラ", class: "type_98"},
  /*2265 */{dataseat:2265, data_seatno: "s3-y-14-25", customer_id: "", name: "", mail: "", phone: "", studio: "カメラ", class: "type_98"},
  /*2266 */{dataseat:2266, data_seatno: "s3-y-14-26", customer_id: "1049", name: "木下 琴菜", mail: "", phone: "", studio: "長岡京", class: "type_7"},
  /*2267 */{dataseat:2267, data_seatno: "s3-y-14-27", customer_id: "1049", name: "木下 琴菜", mail: "", phone: "", studio: "長岡京", class: "type_7"},
  /*2268 */{dataseat:2268, data_seatno: "s3-y-14-28", customer_id: "1049", name: "木下 琴菜", mail: "", phone: "", studio: "長岡京", class: "type_7"},
  /*2269 */{dataseat:2269, data_seatno: "s3-y-14-29", customer_id: "1748", name: "堀口 美心", mail: "", phone: "", studio: "長岡京", class: "type_7"},
  /*2270 */{dataseat:2270, data_seatno: "s3-y-14-30", customer_id: "1748", name: "堀口 美心", mail: "", phone: "", studio: "長岡京", class: "type_7"},
  /*2271 */{dataseat:2271, data_seatno: "s3-y-14-32", customer_id: "1813", name: "笹原 美羽", mail: "", phone: "", studio: "長岡京", class: "type_7"},
  /*2272 */{dataseat:2272, data_seatno: "s3-y-14-33", customer_id: "1813", name: "笹原 美羽", mail: "", phone: "", studio: "長岡京", class: "type_7"},
  /*2273 */{dataseat:2273, data_seatno: "s3-y-14-34", customer_id: "1933", name: "森川 芯", mail: "", phone: "", studio: "長岡京", class: "type_7"},
  /*2274 */{dataseat:2274, data_seatno: "s3-y-14-35", customer_id: "1933", name: "森川 芯", mail: "", phone: "", studio: "長岡京", class: "type_7"},
  /*2275 */{dataseat:2275, data_seatno: "s3-y-14-36", customer_id: "1948", name: "黒田 京", mail: "", phone: "", studio: "長岡京", class: "type_7"},
  /*2276 */{dataseat:2276, data_seatno: "s3-y-14-37", customer_id: "1948", name: "黒田 京", mail: "", phone: "", studio: "長岡京", class: "type_7"},
  /*2277 */{dataseat:2277, data_seatno: "s3-y-14-38", customer_id: "1735", name: "加賀 未衣那", mail: "", phone: "", studio: "長岡京", class: "type_7"},
  /*2278 */{dataseat:2278, data_seatno: "s3-y-14-39", customer_id: "1735", name: "加賀 未衣那", mail: "", phone: "", studio: "長岡京", class: "type_7"},
  /*2279 */{dataseat:2279, data_seatno: "s3-y-14-40", customer_id: "558", name: "三田村 茉優", mail: "", phone: "", studio: "長岡京", class: "type_7"},
  /*2280 */{dataseat:2280, data_seatno: "s3-y-14-41", customer_id: "558", name: "三田村 茉優", mail: "", phone: "", studio: "長岡京", class: "type_7"},
  //////////// 15列目右 ////////////
  /*2281 */{dataseat:2281, data_seatno: "s3-y-15-45", customer_id: "1711", name: "吉田 琳", mail: "", phone: "", studio: "長岡京", class: "type_7"},
  /*2282 */{dataseat:2282, data_seatno: "s3-y-15-46", customer_id: "1711", name: "吉田 琳", mail: "", phone: "", studio: "長岡京", class: "type_7"},
  /*2283 */{dataseat:2283, data_seatno: "s3-y-15-47", customer_id: "1729", name: "加藤 由菜", mail: "", phone: "", studio: "長岡京", class: "type_7"},
  /*2284 */{dataseat:2284, data_seatno: "s3-y-15-48", customer_id: "1729", name: "加藤 由菜", mail: "", phone: "", studio: "長岡京", class: "type_7"},
  //////////// 15列目左 ////////////
  /*2285 */{dataseat:2285, data_seatno: "s3-y-15-1", customer_id: "1941", name: "長井 沙英", mail: "", phone: "", studio: "高槻", class: "type_8"},
  /*2286 */{dataseat:2286, data_seatno: "s3-y-15-2", customer_id: "1941", name: "長井 沙英", mail: "", phone: "", studio: "高槻", class: "type_8"},
  /*2287 */{dataseat:2287, data_seatno: "s3-y-15-3", customer_id: "1111", name: "西田 七菜", mail: "", phone: "", studio: "高槻", class: "type_8"},
  /*2288 */{dataseat:2288, data_seatno: "s3-y-15-4", customer_id: "1111", name: "西田 七菜", mail: "", phone: "", studio: "高槻", class: "type_8"},
  //////////// 16列目 ////////////
  /*2289 */{dataseat:2289, data_seatno: "s3-y-16-1", customer_id: "1776", name: "森脇 美珠", mail: "", phone: "", studio: "高槻", class: "type_8"},
  /*2290 */{dataseat:2290, data_seatno: "s3-y-16-2", customer_id: "1776", name: "森脇 美珠", mail: "", phone: "", studio: "高槻", class: "type_8"},
  /*2291 */{dataseat:2291, data_seatno: "s3-y-16-3", customer_id: "1961", name: "川畑 奈緒", mail: "", phone: "", studio: "高槻", class: "type_8"},
  /*2292 */{dataseat:2292, data_seatno: "s3-y-16-4", customer_id: "1961", name: "川畑 奈緒", mail: "", phone: "", studio: "高槻", class: "type_8"},
  /*2293 */{dataseat:2293, data_seatno: "s3-y-16-10", customer_id: "57", name: "古川 叶逢", mail: "", phone: "", studio: "池田", class: "type_9"},
  /*2294 */{dataseat:2294, data_seatno: "s3-y-16-11", customer_id: "57", name: "古川 叶逢", mail: "", phone: "", studio: "池田", class: "type_9"},
  /*2295 */{dataseat:2295, data_seatno: "s3-y-16-12", customer_id: "57", name: "古川 叶逢", mail: "", phone: "", studio: "池田", class: "type_9"},
  /*2296 */{dataseat:2296, data_seatno: "s3-y-16-13", customer_id: "57", name: "古川 叶逢", mail: "", phone: "", studio: "池田", class: "type_9"},
  /*2297 */{dataseat:2297, data_seatno: "s3-y-16-14", customer_id: "1784", name: "横山 愛莉", mail: "", phone: "", studio: "池田", class: "type_9"},
  /*2298 */{dataseat:2298, data_seatno: "s3-y-16-15", customer_id: "1784", name: "横山 愛莉", mail: "", phone: "", studio: "池田", class: "type_9"},
  /*2299 */{dataseat:2299, data_seatno: "s3-y-16-16", customer_id: "", name: "横山 修作", mail: "yokoyama.seikotsuin@icloud.com", phone: "09052474002", studio: "池田", class: "type_9"},
  /*2300 */{dataseat:2300, data_seatno: "s3-y-16-20", customer_id: "64", name: "佐藤 咲来", mail: "", phone: "", studio: "池田", class: "type_9"},
  /*2301 */{dataseat:2301, data_seatno: "s3-y-16-21", customer_id: "64", name: "佐藤 咲来", mail: "", phone: "", studio: "池田", class: "type_9"},
  /*2302 */{dataseat:2302, data_seatno: "s3-y-16-22", customer_id: "638", name: "吉田 芽生", mail: "", phone: "", studio: "池田", class: "type_9"},
  /*2303 */{dataseat:2303, data_seatno: "s3-y-16-23", customer_id: "638", name: "吉田 芽生", mail: "", phone: "", studio: "池田", class: "type_9"},
  /*2304 */{dataseat:2304, data_seatno: "s3-y-16-24", customer_id: "797", name: "名越 詩乃", mail: "", phone: "", studio: "池田", class: "type_9"},
  /*2305 */{dataseat:2305, data_seatno: "s3-y-16-25", customer_id: "797", name: "名越 詩乃", mail: "", phone: "", studio: "池田", class: "type_9"},
  /*2306 */{dataseat:2306, data_seatno: "s3-y-16-26", customer_id: "612", name: "今仲 陽菜", mail: "", phone: "", studio: "池田", class: "type_9"},
  /*2307 */{dataseat:2307, data_seatno: "s3-y-16-27", customer_id: "612", name: "今仲 陽菜", mail: "", phone: "", studio: "池田", class: "type_9"},
  /*2308 */{dataseat:2308, data_seatno: "s3-y-16-28", customer_id: "612", name: "今仲 陽菜", mail: "", phone: "", studio: "池田", class: "type_9"},
  /*2309 */{dataseat:2309, data_seatno: "s3-y-16-33", customer_id: "1376", name: "松倉 花恋", mail: "", phone: "", studio: "栂・美木多", class: "type_13"},
  /*2310 */{dataseat:2310, data_seatno: "s3-y-16-34", customer_id: "1376", name: "松倉 花恋", mail: "", phone: "", studio: "栂・美木多", class: "type_13"},
  /*2311 */{dataseat:2311, data_seatno: "s3-y-16-35", customer_id: "1377", name: "大平 葵", mail: "", phone: "", studio: "栂・美木多", class: "type_13"},
  /*2312 */{dataseat:2312, data_seatno: "s3-y-16-36", customer_id: "1377", name: "大平 葵", mail: "", phone: "", studio: "栂・美木多", class: "type_13"},
  /*2313 */{dataseat:2313, data_seatno: "s3-y-16-37", customer_id: "1429", name: "夘野 ひなの", mail: "", phone: "", studio: "栂・美木多", class: "type_13"},
  /*2314 */{dataseat:2314, data_seatno: "s3-y-16-38", customer_id: "1429", name: "夘野 ひなの", mail: "", phone: "", studio: "栂・美木多", class: "type_13"},
  /*2315 */{dataseat:2315, data_seatno: "s3-y-16-39", customer_id: "1435", name: "山本 結愛", mail: "", phone: "", studio: "栂・美木多", class: "type_13"},
  /*2316 */{dataseat:2316, data_seatno: "s3-y-16-45", customer_id: "26", name: "桑野 莉亜", mail: "", phone: "", studio: "和泉", class: "type_12"},
  /*2317 */{dataseat:2317, data_seatno: "s3-y-16-46", customer_id: "26", name: "桑野 莉亜", mail: "", phone: "", studio: "和泉", class: "type_12"},
  /*2318 */{dataseat:2318, data_seatno: "s3-y-16-47", customer_id: "746", name: "白井 凜", mail: "", phone: "", studio: "和泉", class: "type_12"},
  /*2319 */{dataseat:2319, data_seatno: "s3-y-16-48", customer_id: "746", name: "白井 凜", mail: "", phone: "", studio: "和泉", class: "type_12"},
  //////////// 17列目 ////////////
  /*2320 */{dataseat:2320, data_seatno: "s3-y-17-1", customer_id: "1384", name: "河合 蘭華", mail: "", phone: "", studio: "江坂", class: "type_10"},
  /*2321 */{dataseat:2321, data_seatno: "s3-y-17-2", customer_id: "1384", name: "河合 蘭華", mail: "", phone: "", studio: "江坂", class: "type_10"},
  /*2322 */{dataseat:2322, data_seatno: "s3-y-17-3", customer_id: "1621", name: "奥 陽菜", mail: "", phone: "", studio: "江坂", class: "type_10"},
  /*2323 */{dataseat:2323, data_seatno: "s3-y-17-4", customer_id: "1621", name: "奥 陽菜", mail: "", phone: "", studio: "江坂", class: "type_10"},
  /*2324 */{dataseat:2324, data_seatno: "s3-y-17-10", customer_id: "1752", name: "清水 実美子", mail: "", phone: "", studio: "江坂", class: "type_10"},
  /*2325 */{dataseat:2325, data_seatno: "s3-y-17-11", customer_id: "1752", name: "清水 実美子", mail: "", phone: "", studio: "江坂", class: "type_10"},
  /*2326 */{dataseat:2326, data_seatno: "s3-y-17-12", customer_id: "1995", name: "須見 心温", mail: "", phone: "", studio: "江坂", class: "type_10"},
  /*2327 */{dataseat:2327, data_seatno: "s3-y-17-13", customer_id: "1995", name: "須見 心温", mail: "", phone: "", studio: "江坂", class: "type_10"},
  /*2328 */{dataseat:2328, data_seatno: "s3-y-17-14", customer_id: "1836", name: "原田 陽世", mail: "", phone: "", studio: "池田", class: "type_9"},
  /*2329 */{dataseat:2329, data_seatno: "s3-y-17-15", customer_id: "1836", name: "原田 陽世", mail: "", phone: "", studio: "池田", class: "type_9"},
  /*2330 */{dataseat:2330, data_seatno: "s3-y-17-16", customer_id: "", name: "横山 修作", mail: "yokoyama.seikotsuin@icloud.com", phone: "09052474002", studio: "池田", class: "type_9"},
  /*2331 */{dataseat:2331, data_seatno: "s3-y-17-20", customer_id: "958", name: "間人 友梨奈", mail: "", phone: "", studio: "池田", class: "type_9"},
  /*2332 */{dataseat:2332, data_seatno: "s3-y-17-21", customer_id: "958", name: "間人 友梨奈", mail: "", phone: "", studio: "池田", class: "type_9"},
  /*2333 */{dataseat:2333, data_seatno: "s3-y-17-22", customer_id: "970", name: "西田 芽生", mail: "", phone: "", studio: "池田", class: "type_9"},
  /*2334 */{dataseat:2334, data_seatno: "s3-y-17-23", customer_id: "970", name: "西田 芽生", mail: "", phone: "", studio: "池田", class: "type_9"},
  /*2335 */{dataseat:2335, data_seatno: "s3-y-17-24", customer_id: "1016", name: "渡邊 あかり", mail: "", phone: "", studio: "池田", class: "type_9"},
  /*2336 */{dataseat:2336, data_seatno: "s3-y-17-25", customer_id: "1016", name: "渡邊 あかり", mail: "", phone: "", studio: "池田", class: "type_9"},
  /*2337 */{dataseat:2337, data_seatno: "s3-y-17-26", customer_id: "1073", name: "多田 朱紗", mail: "", phone: "", studio: "池田", class: "type_9"},
  /*2338 */{dataseat:2338, data_seatno: "s3-y-17-27", customer_id: "1073", name: "多田 朱紗", mail: "", phone: "", studio: "池田", class: "type_9"},
  /*2339 */{dataseat:2339, data_seatno: "s3-y-17-28", customer_id: "612", name: "今仲 陽菜", mail: "", phone: "", studio: "池田", class: "type_9"},
  /*2340 */{dataseat:2340, data_seatno: "s3-y-17-33", customer_id: "1433", name: "山口 姫生", mail: "", phone: "", studio: "栂・美木多", class: "type_13"},
  /*2341 */{dataseat:2341, data_seatno: "s3-y-17-34", customer_id: "1433", name: "山口 姫生", mail: "", phone: "", studio: "栂・美木多", class: "type_13"},
  /*2342 */{dataseat:2342, data_seatno: "s3-y-17-35", customer_id: "1525", name: "高橋 優茉", mail: "", phone: "", studio: "栂・美木多", class: "type_13"},
  /*2343 */{dataseat:2343, data_seatno: "s3-y-17-36", customer_id: "1525", name: "高橋 優茉", mail: "", phone: "", studio: "栂・美木多", class: "type_13"},
  /*2344 */{dataseat:2344, data_seatno: "s3-y-17-37", customer_id: "1435", name: "山本 結愛", mail: "", phone: "", studio: "栂・美木多", class: "type_13"},
  /*2345 */{dataseat:2345, data_seatno: "s3-y-17-38", customer_id: "1435", name: "山本 結愛", mail: "", phone: "", studio: "栂・美木多", class: "type_13"},
  /*2346 */{dataseat:2346, data_seatno: "s3-y-17-39", customer_id: "1435", name: "山本 結愛", mail: "", phone: "", studio: "栂・美木多", class: "type_13"},
  /*2347 */{dataseat:2347, data_seatno: "s3-y-17-45", customer_id: "1052", name: "穴瀬 望来", mail: "", phone: "", studio: "和泉", class: "type_12"},
  /*2348 */{dataseat:2348, data_seatno: "s3-y-17-46", customer_id: "1052", name: "穴瀬 望来", mail: "", phone: "", studio: "和泉", class: "type_12"},
  /*2349 */{dataseat:2349, data_seatno: "s3-y-17-47", customer_id: "1907", name: "瀧ヶ平 絢乃", mail: "", phone: "", studio: "和泉", class: "type_12"},
  /*2350 */{dataseat:2350, data_seatno: "s3-y-17-48", customer_id: "1907", name: "瀧ヶ平 絢乃", mail: "", phone: "", studio: "和泉", class: "type_12"},
  //////////// 18列目 ////////////
  /*2351 */{dataseat:2351, data_seatno: "s3-y-18-1", customer_id: "1990", name: "峰松 凜花", mail: "", phone: "", studio: "江坂", class: "type_10"},
  /*2352 */{dataseat:2352, data_seatno: "s3-y-18-2", customer_id: "1990", name: "峰松 凜花", mail: "", phone: "", studio: "江坂", class: "type_10"},
  /*2353 */{dataseat:2353, data_seatno: "s3-y-18-3", customer_id: "1990", name: "峰松 凜花", mail: "", phone: "", studio: "江坂", class: "type_10"},
  /*2354 */{dataseat:2354, data_seatno: "s3-y-18-4", customer_id: "1990", name: "峰松 凜花", mail: "", phone: "", studio: "江坂", class: "type_10"},
  /*2355 */{dataseat:2355, data_seatno: "s3-y-18-5", customer_id: "1990", name: "峰松 凜花", mail: "", phone: "", studio: "江坂", class: "type_10"},
  /*2356 */{dataseat:2356, data_seatno: "s3-y-18-9", customer_id: "1269", name: "新部 栞菜", mail: "", phone: "", studio: "江坂", class: "type_10"},
  /*2357 */{dataseat:2357, data_seatno: "s3-y-18-10", customer_id: "1269", name: "新部 栞菜", mail: "", phone: "", studio: "江坂", class: "type_10"},
  /*2358 */{dataseat:2358, data_seatno: "s3-y-18-11", customer_id: "1274", name: "田中 優衣", mail: "", phone: "", studio: "江坂", class: "type_10"},
  /*2359 */{dataseat:2359, data_seatno: "s3-y-18-12", customer_id: "1274", name: "田中 優衣", mail: "", phone: "", studio: "江坂", class: "type_10"},
  /*2360 */{dataseat:2360, data_seatno: "s3-y-18-13", customer_id: "1278", name: "久龍 咲那", mail: "", phone: "", studio: "江坂", class: "type_10"},
  /*2361 */{dataseat:2361, data_seatno: "s3-y-18-14", customer_id: "1278", name: "久龍 咲那", mail: "", phone: "", studio: "江坂", class: "type_10"},
  /*2362 */{dataseat:2362, data_seatno: "s3-y-18-15", customer_id: "1477", name: "中條 加南子", mail: "", phone: "", studio: "江坂", class: "type_10"},
  /*2363 */{dataseat:2363, data_seatno: "s3-y-18-16", customer_id: "1477", name: "中條 加南子", mail: "", phone: "", studio: "江坂", class: "type_10"},
  /*2364 */{dataseat:2364, data_seatno: "s3-y-18-20", customer_id: "1150", name: "太治 結菜", mail: "", phone: "", studio: "池田", class: "type_9"},
  /*2365 */{dataseat:2365, data_seatno: "s3-y-18-21", customer_id: "1150", name: "太治 結菜", mail: "", phone: "", studio: "池田", class: "type_9"},
  /*2366 */{dataseat:2366, data_seatno: "s3-y-18-22", customer_id: "1725", name: "伊藤 実紅", mail: "", phone: "", studio: "池田", class: "type_9"},
  /*2367 */{dataseat:2367, data_seatno: "s3-y-18-23", customer_id: "1725", name: "伊藤 実紅", mail: "", phone: "", studio: "池田", class: "type_9"},
  /*2368 */{dataseat:2368, data_seatno: "s3-y-18-24", customer_id: "1782", name: "石塚 真子", mail: "", phone: "", studio: "池田", class: "type_9"},
  /*2369 */{dataseat:2369, data_seatno: "s3-y-18-25", customer_id: "1782", name: "石塚 真子", mail: "", phone: "", studio: "池田", class: "type_9"},
  /*2370 */{dataseat:2370, data_seatno: "s3-y-18-26", customer_id: "54", name: "小形 羽美", mail: "", phone: "", studio: "池田", class: "type_9"},
  /*2371 */{dataseat:2371, data_seatno: "s3-y-18-27", customer_id: "54", name: "小形 羽美", mail: "", phone: "", studio: "池田", class: "type_9"},
  /*2372 */{dataseat:2372, data_seatno: "s3-y-18-28", customer_id: "60", name: "北田 慈", mail: "", phone: "", studio: "池田", class: "type_9"},
  /*2373 */{dataseat:2373, data_seatno: "s3-y-18-29", customer_id: "60", name: "北田 慈", mail: "", phone: "", studio: "池田", class: "type_9"},
  /*2374 */{dataseat:2374, data_seatno: "s3-y-18-33", customer_id: "1727", name: "吉留 莉乃", mail: "", phone: "", studio: "栂・美木多", class: "type_13"},
  /*2375 */{dataseat:2375, data_seatno: "s3-y-18-34", customer_id: "1727", name: "吉留 莉乃", mail: "", phone: "", studio: "栂・美木多", class: "type_13"},
  /*2376 */{dataseat:2376, data_seatno: "s3-y-18-35", customer_id: "2000", name: "掛川 美来", mail: "", phone: "", studio: "栂・美木多", class: "type_13"},
  /*2377 */{dataseat:2377, data_seatno: "s3-y-18-36", customer_id: "2000", name: "掛川 美来", mail: "", phone: "", studio: "栂・美木多", class: "type_13"},
  /*2378 */{dataseat:2378, data_seatno: "s3-y-18-37", customer_id: "1439", name: "薮内 音愛", mail: "", phone: "", studio: "栂・美木多", class: "type_13"},
  /*2379 */{dataseat:2379, data_seatno: "s3-y-18-38", customer_id: "1439", name: "薮内 音愛", mail: "", phone: "", studio: "栂・美木多", class: "type_13"},
  /*2380 */{dataseat:2380, data_seatno: "s3-y-18-39", customer_id: "1461", name: "森脇 愛珠", mail: "", phone: "", studio: "栂・美木多", class: "type_13"},
  /*2381 */{dataseat:2381, data_seatno: "s3-y-18-40", customer_id: "1461", name: "森脇 愛珠", mail: "", phone: "", studio: "栂・美木多", class: "type_13"},
  /*2382 */{dataseat:2382, data_seatno: "s3-y-18-44", customer_id: "3", name: "相物 美乃織", mail: "", phone: "", studio: "和泉", class: "type_12"},
  /*2383 */{dataseat:2383, data_seatno: "s3-y-18-45", customer_id: "3", name: "相物 美乃織", mail: "", phone: "", studio: "和泉", class: "type_12"},
  /*2384 */{dataseat:2384, data_seatno: "s3-y-18-46", customer_id: "2009", name: "北野 結愛", mail: "", phone: "", studio: "和泉", class: "type_12"},
  /*2385 */{dataseat:2385, data_seatno: "s3-y-18-47", customer_id: "2009", name: "北野 結愛", mail: "", phone: "", studio: "和泉", class: "type_12"},
  /*2386 */{dataseat:2386, data_seatno: "s3-y-18-48", customer_id: "2009", name: "北野 結愛", mail: "", phone: "", studio: "和泉", class: "type_12"},
  //////////// 19列目 ////////////
  /*2387 */{dataseat:2387, data_seatno: "s3-y-19-1", customer_id: "1263", name: "小野 彩音", mail: "", phone: "", studio: "江坂", class: "type_10"},
  /*2388 */{dataseat:2388, data_seatno: "s3-y-19-2", customer_id: "1263", name: "小野 彩音", mail: "", phone: "", studio: "江坂", class: "type_10"},
  /*2389 */{dataseat:2389, data_seatno: "s3-y-19-3", customer_id: "1268", name: "松本 留美", mail: "", phone: "", studio: "江坂", class: "type_10"},
  /*2390 */{dataseat:2390, data_seatno: "s3-y-19-4", customer_id: "1268", name: "松本 留美", mail: "", phone: "", studio: "江坂", class: "type_10"},
  /*2391 */{dataseat:2391, data_seatno: "s3-y-19-5", customer_id: "1268", name: "松本 留美", mail: "", phone: "", studio: "江坂", class: "type_10"},
  /*2392 */{dataseat:2392, data_seatno: "s3-y-19-6", customer_id: "1268", name: "松本 留美", mail: "", phone: "", studio: "江坂", class: "type_10"},
  /*2393 */{dataseat:2393, data_seatno: "s3-y-19-8", customer_id: "1266", name: "髙橋 愛奈", mail: "", phone: "", studio: "江坂", class: "type_10"},
  /*2394 */{dataseat:2394, data_seatno: "s3-y-19-9", customer_id: "1266", name: "髙橋 愛奈", mail: "", phone: "", studio: "江坂", class: "type_10"},
  /*2395 */{dataseat:2395, data_seatno: "s3-y-19-10", customer_id: "1404", name: "泉 歓奈", mail: "", phone: "", studio: "江坂", class: "type_10"},
  /*2396 */{dataseat:2396, data_seatno: "s3-y-19-11", customer_id: "1404", name: "泉 歓奈", mail: "", phone: "", studio: "江坂", class: "type_10"},
  /*2397 */{dataseat:2397, data_seatno: "s3-y-19-12", customer_id: "1455", name: "坂上 ひまり", mail: "", phone: "", studio: "江坂", class: "type_10"},
  /*2398 */{dataseat:2398, data_seatno: "s3-y-19-13", customer_id: "1455", name: "坂上 ひまり", mail: "", phone: "", studio: "江坂", class: "type_10"},
  /*2399 */{dataseat:2399, data_seatno: "s3-y-19-14", customer_id: "1589", name: "森 夏那", mail: "", phone: "", studio: "江坂", class: "type_10"},
  /*2400 */{dataseat:2400, data_seatno: "s3-y-19-15", customer_id: "1589", name: "森 夏那", mail: "", phone: "", studio: "江坂", class: "type_10"},
  /*2401 */{dataseat:2401, data_seatno: "s3-y-19-16", customer_id: "1983", name: "高橋 夏菜恵", mail: "", phone: "", studio: "江坂", class: "type_10"},
  /*2402 */{dataseat:2402, data_seatno: "s3-y-19-17", customer_id: "1983", name: "高橋 夏菜恵", mail: "", phone: "", studio: "江坂", class: "type_10"},
  /*2403 */{dataseat:2403, data_seatno: "s3-y-19-19", customer_id: "607", name: "市川 杏莉", mail: "", phone: "", studio: "SLASH選抜3", class: "type_11"},
  /*2404 */{dataseat:2404, data_seatno: "s3-y-19-20", customer_id: "607", name: "市川 杏莉", mail: "", phone: "", studio: "SLASH選抜3", class: "type_11"},
  /*2405 */{dataseat:2405, data_seatno: "s3-y-19-21", customer_id: "607", name: "市川 杏莉", mail: "", phone: "", studio: "SLASH選抜3", class: "type_11"},
  /*2406 */{dataseat:2406, data_seatno: "s3-y-19-22", customer_id: "636", name: "山崎 ことは", mail: "", phone: "", studio: "池田", class: "type_9"},
  /*2407 */{dataseat:2407, data_seatno: "s3-y-19-23", customer_id: "636", name: "山崎 ことは", mail: "", phone: "", studio: "池田", class: "type_9"},
  /*2408 */{dataseat:2408, data_seatno: "s3-y-19-24", customer_id: "662", name: "菅野 怜奈", mail: "", phone: "", studio: "池田", class: "type_9"},
  /*2409 */{dataseat:2409, data_seatno: "s3-y-19-25", customer_id: "662", name: "菅野 怜奈", mail: "", phone: "", studio: "池田", class: "type_9"},
  /*2410 */{dataseat:2410, data_seatno: "s3-y-19-26", customer_id: "990", name: "佐方 初流乃", mail: "", phone: "", studio: "池田", class: "type_9"},
  /*2411 */{dataseat:2411, data_seatno: "s3-y-19-27", customer_id: "990", name: "佐方 初流乃", mail: "", phone: "", studio: "池田", class: "type_9"},
  /*2412 */{dataseat:2412, data_seatno: "s3-y-19-28", customer_id: "1025", name: "辻本 珠美", mail: "", phone: "", studio: "池田", class: "type_9"},
  /*2413 */{dataseat:2413, data_seatno: "s3-y-19-29", customer_id: "1025", name: "辻本 珠美", mail: "", phone: "", studio: "池田", class: "type_9"},
  /*2414 */{dataseat:2414, data_seatno: "s3-y-19-30", customer_id: "1025", name: "辻本 珠美", mail: "", phone: "", studio: "池田", class: "type_9"},
  /*2415 */{dataseat:2415, data_seatno: "s3-y-19-32", customer_id: "1428", name: "馬場 日茉莉", mail: "", phone: "", studio: "栂・美木多", class: "type_13"},
  /*2416 */{dataseat:2416, data_seatno: "s3-y-19-33", customer_id: "1428", name: "馬場 日茉莉", mail: "", phone: "", studio: "栂・美木多", class: "type_13"},
  /*2417 */{dataseat:2417, data_seatno: "s3-y-19-34", customer_id: "597", name: "結城 咲来", mail: "", phone: "", studio: "楠葉", class: "type_14"},
  /*2418 */{dataseat:2418, data_seatno: "s3-y-19-35", customer_id: "597", name: "結城 咲来", mail: "", phone: "", studio: "楠葉", class: "type_14"},
  /*2419 */{dataseat:2419, data_seatno: "s3-y-19-36", customer_id: "597", name: "結城 咲来", mail: "", phone: "", studio: "楠葉", class: "type_14"},
  /*2420 */{dataseat:2420, data_seatno: "s3-y-19-37", customer_id: "973", name: "山口 由絆", mail: "", phone: "", studio: "楠葉", class: "type_14"},
  /*2421 */{dataseat:2421, data_seatno: "s3-y-19-38", customer_id: "973", name: "山口 由絆", mail: "", phone: "", studio: "楠葉", class: "type_14"},
  /*2422 */{dataseat:2422, data_seatno: "s3-y-19-39", customer_id: "1292", name: "梅森 麗央", mail: "", phone: "", studio: "楠葉", class: "type_14"},
  /*2423 */{dataseat:2423, data_seatno: "s3-y-19-40", customer_id: "1292", name: "梅森 麗央", mail: "", phone: "", studio: "楠葉", class: "type_14"},
  /*2424 */{dataseat:2424, data_seatno: "s3-y-19-41", customer_id: "1292", name: "梅森 麗央", mail: "", phone: "", studio: "楠葉", class: "type_14"},
  /*2425 */{dataseat:2425, data_seatno: "s3-y-19-43", customer_id: "1964", name: "土居 優奈", mail: "", phone: "", studio: "和泉", class: "type_12"},
  /*2426 */{dataseat:2426, data_seatno: "s3-y-19-44", customer_id: "1964", name: "土居 優奈", mail: "", phone: "", studio: "和泉", class: "type_12"},
  /*2427 */{dataseat:2427, data_seatno: "s3-y-19-45", customer_id: "616", name: "釘町 楓恋", mail: "", phone: "", studio: "和泉", class: "type_12"},
  /*2428 */{dataseat:2428, data_seatno: "s3-y-19-46", customer_id: "616", name: "釘町 楓恋", mail: "", phone: "", studio: "和泉", class: "type_12"},
  /*2429 */{dataseat:2429, data_seatno: "s3-y-19-47", customer_id: "616", name: "釘町 楓恋", mail: "", phone: "", studio: "和泉", class: "type_12"},
  /*2430 */{dataseat:2430, data_seatno: "s3-y-19-48", customer_id: "616", name: "釘町 楓恋", mail: "", phone: "", studio: "和泉", class: "type_12"},
  //////////// 20列目 ////////////
  /*2431 */{dataseat:2431, data_seatno: "s3-y-20-1", customer_id: "808", name: "髙瀬 ひより", mail: "", phone: "", studio: "泉南", class: "type_16"},
  /*2432 */{dataseat:2432, data_seatno: "s3-y-20-2", customer_id: "808", name: "髙瀬 ひより", mail: "", phone: "", studio: "泉南", class: "type_16"},
  /*2433 */{dataseat:2433, data_seatno: "s3-y-20-3", customer_id: "815", name: "大木 遥加", mail: "", phone: "", studio: "泉南", class: "type_16"},
  /*2434 */{dataseat:2434, data_seatno: "s3-y-20-4", customer_id: "815", name: "大木 遥加", mail: "", phone: "", studio: "泉南", class: "type_16"},
  /*2435 */{dataseat:2435, data_seatno: "s3-y-20-5", customer_id: "831", name: "山本 紗希", mail: "", phone: "", studio: "泉南", class: "type_16"},
  /*2436 */{dataseat:2436, data_seatno: "s3-y-20-6", customer_id: "831", name: "山本 紗希", mail: "", phone: "", studio: "泉南", class: "type_16"},
  /*2437 */{dataseat:2437, data_seatno: "s3-y-20-8", customer_id: "826", name: "道川内 夢叶", mail: "", phone: "", studio: "天王寺", class: "type_15"},
  /*2438 */{dataseat:2438, data_seatno: "s3-y-20-9", customer_id: "826", name: "道川内 夢叶", mail: "", phone: "", studio: "天王寺", class: "type_15"},
  /*2439 */{dataseat:2439, data_seatno: "s3-y-20-10", customer_id: "826", name: "道川内 夢叶", mail: "", phone: "", studio: "天王寺", class: "type_15"},
  /*2440 */{dataseat:2440, data_seatno: "s3-y-20-11", customer_id: "826", name: "道川内 夢叶", mail: "", phone: "", studio: "天王寺", class: "type_15"},
  /*2441 */{dataseat:2441, data_seatno: "s3-y-20-12", customer_id: "826", name: "道川内 夢叶", mail: "", phone: "", studio: "天王寺", class: "type_15"},
  /*2442 */{dataseat:2442, data_seatno: "s3-y-20-13", customer_id: "826", name: "道川内 夢叶", mail: "", phone: "", studio: "天王寺", class: "type_15"},
  /*2443 */{dataseat:2443, data_seatno: "s3-y-20-14", customer_id: "2029", name: "野本 望実", mail: "", phone: "", studio: "江坂", class: "type_10"},
  /*2444 */{dataseat:2444, data_seatno: "s3-y-20-15", customer_id: "2029", name: "野本 望実", mail: "", phone: "", studio: "江坂", class: "type_10"},
  /*2445 */{dataseat:2445, data_seatno: "s3-y-20-16", customer_id: "2030", name: "松原 杏奈", mail: "", phone: "", studio: "江坂", class: "type_10"},
  /*2446 */{dataseat:2446, data_seatno: "s3-y-20-17", customer_id: "2030", name: "松原 杏奈", mail: "", phone: "", studio: "江坂", class: "type_10"},
  /*2447 */{dataseat:2447, data_seatno: "s3-y-20-19", customer_id: "738", name: "藤原 妃菜", mail: "", phone: "", studio: "SLASH選抜3", class: "type_11"},
  /*2448 */{dataseat:2448, data_seatno: "s3-y-20-20", customer_id: "738", name: "藤原 妃菜", mail: "", phone: "", studio: "SLASH選抜3", class: "type_11"},
  /*2449 */{dataseat:2449, data_seatno: "s3-y-20-21", customer_id: "748", name: "柳田 陽咲", mail: "", phone: "", studio: "SLASH選抜3", class: "type_11"},
  /*2450 */{dataseat:2450, data_seatno: "s3-y-20-22", customer_id: "748", name: "柳田 陽咲", mail: "", phone: "", studio: "SLASH選抜3", class: "type_11"},
  /*2451 */{dataseat:2451, data_seatno: "s3-y-20-23", customer_id: "748", name: "柳田 陽咲", mail: "", phone: "", studio: "SLASH選抜3", class: "type_11"},
  /*2452 */{dataseat:2452, data_seatno: "s3-y-20-24", customer_id: "748", name: "柳田 陽咲", mail: "", phone: "", studio: "SLASH選抜3", class: "type_11"},
  /*2453 */{dataseat:2453, data_seatno: "s3-y-20-25", customer_id: "783", name: "大橋 百椛", mail: "", phone: "", studio: "SLASH選抜3", class: "type_11"},
  /*2454 */{dataseat:2454, data_seatno: "s3-y-20-26", customer_id: "783", name: "大橋 百椛", mail: "", phone: "", studio: "SLASH選抜3", class: "type_11"},
  /*2455 */{dataseat:2455, data_seatno: "s3-y-20-27", customer_id: "1099", name: "今村 優真", mail: "", phone: "", studio: "SLASH選抜3", class: "type_11"},
  /*2456 */{dataseat:2456, data_seatno: "s3-y-20-28", customer_id: "1099", name: "今村 優真", mail: "", phone: "", studio: "SLASH選抜3", class: "type_11"},
  /*2457 */{dataseat:2457, data_seatno: "s3-y-20-29", customer_id: "1206", name: "山口 華朱", mail: "", phone: "", studio: "SLASH選抜3", class: "type_11"},
  /*2458 */{dataseat:2458, data_seatno: "s3-y-20-30", customer_id: "1206", name: "山口 華朱", mail: "", phone: "", studio: "SLASH選抜3", class: "type_11"},
  /*2459 */{dataseat:2459, data_seatno: "s3-y-20-32", customer_id: "1327", name: "澤村 優美子", mail: "", phone: "", studio: "楠葉", class: "type_14"},
  /*2460 */{dataseat:2460, data_seatno: "s3-y-20-33", customer_id: "1327", name: "澤村 優美子", mail: "", phone: "", studio: "楠葉", class: "type_14"},
  /*2461 */{dataseat:2461, data_seatno: "s3-y-20-34", customer_id: "1327", name: "澤村 優美子", mail: "", phone: "", studio: "楠葉", class: "type_14"},
  /*2462 */{dataseat:2462, data_seatno: "s3-y-20-35", customer_id: "1327", name: "澤村 優美子", mail: "", phone: "", studio: "楠葉", class: "type_14"},
  /*2463 */{dataseat:2463, data_seatno: "s3-y-20-36", customer_id: "1343", name: "松川 望依", mail: "", phone: "", studio: "楠葉", class: "type_14"},
  /*2464 */{dataseat:2464, data_seatno: "s3-y-20-37", customer_id: "1343", name: "松川 望依", mail: "", phone: "", studio: "楠葉", class: "type_14"},
  /*2465 */{dataseat:2465, data_seatno: "s3-y-20-38", customer_id: "1368", name: "齊藤 亜里紗", mail: "", phone: "", studio: "楠葉", class: "type_14"},
  /*2466 */{dataseat:2466, data_seatno: "s3-y-20-39", customer_id: "1368", name: "齊藤 亜里紗", mail: "", phone: "", studio: "楠葉", class: "type_14"},
  /*2467 */{dataseat:2467, data_seatno: "s3-y-20-40", customer_id: "1667", name: "袖岡 椿", mail: "", phone: "", studio: "楠葉", class: "type_14"},
  /*2468 */{dataseat:2468, data_seatno: "s3-y-20-41", customer_id: "1667", name: "袖岡 椿", mail: "", phone: "", studio: "楠葉", class: "type_14"},
  /*2469 */{dataseat:2469, data_seatno: "s3-y-20-43", customer_id: "1548", name: "北川 凜", mail: "", phone: "", studio: "和泉", class: "type_12"},
  /*2470 */{dataseat:2470, data_seatno: "s3-y-20-44", customer_id: "1548", name: "北川 凜", mail: "", phone: "", studio: "和泉", class: "type_12"},
  /*2471 */{dataseat:2471, data_seatno: "s3-y-20-45", customer_id: "1610", name: "森野 夏陽", mail: "", phone: "", studio: "和泉", class: "type_12"},
  /*2472 */{dataseat:2472, data_seatno: "s3-y-20-46", customer_id: "1610", name: "森野 夏陽", mail: "", phone: "", studio: "和泉", class: "type_12"},
  /*2473 */{dataseat:2473, data_seatno: "s3-y-20-47", customer_id: "1681", name: "大浦 蘭", mail: "", phone: "", studio: "和泉", class: "type_12"},
  /*2474 */{dataseat:2474, data_seatno: "s3-y-20-48", customer_id: "1681", name: "大浦 蘭", mail: "", phone: "", studio: "和泉", class: "type_12"},
  //////////// 21列目 ////////////
  /*2475 */{dataseat:2475, data_seatno: "s3-y-21-1", customer_id: "1240", name: "神下 大空", mail: "", phone: "", studio: "泉南", class: "type_16"},
  /*2476 */{dataseat:2476, data_seatno: "s3-y-21-2", customer_id: "1240", name: "神下 大空", mail: "", phone: "", studio: "泉南", class: "type_16"},
  /*2477 */{dataseat:2477, data_seatno: "s3-y-21-3", customer_id: "1242", name: "後藤 望心", mail: "", phone: "", studio: "泉南", class: "type_16"},
  /*2478 */{dataseat:2478, data_seatno: "s3-y-21-4", customer_id: "1242", name: "後藤 望心", mail: "", phone: "", studio: "泉南", class: "type_16"},
  /*2479 */{dataseat:2479, data_seatno: "s3-y-21-5", customer_id: "1772", name: "竹中 彩恵", mail: "", phone: "", studio: "泉南", class: "type_16"},
  /*2480 */{dataseat:2480, data_seatno: "s3-y-21-6", customer_id: "1772", name: "竹中 彩恵", mail: "", phone: "", studio: "泉南", class: "type_16"},
  /*2481 */{dataseat:2481, data_seatno: "s3-y-21-8", customer_id: "891", name: "山本 こころ", mail: "", phone: "", studio: "天王寺", class: "type_15"},
  /*2482 */{dataseat:2482, data_seatno: "s3-y-21-9", customer_id: "891", name: "山本 こころ", mail: "", phone: "", studio: "天王寺", class: "type_15"},
  /*2483 */{dataseat:2483, data_seatno: "s3-y-21-10", customer_id: "1508", name: "平山 愛実", mail: "", phone: "", studio: "天王寺", class: "type_15"},
  /*2484 */{dataseat:2484, data_seatno: "s3-y-21-11", customer_id: "1508", name: "平山 愛実", mail: "", phone: "", studio: "天王寺", class: "type_15"},
  /*2485 */{dataseat:2485, data_seatno: "s3-y-21-12", customer_id: "1810", name: "北野 仁菜", mail: "", phone: "", studio: "天王寺", class: "type_15"},
  /*2486 */{dataseat:2486, data_seatno: "s3-y-21-13", customer_id: "1810", name: "北野 仁菜", mail: "", phone: "", studio: "天王寺", class: "type_15"},
  /*2487 */{dataseat:2487, data_seatno: "s3-y-21-14", customer_id: "1821", name: "牧 ひなた", mail: "", phone: "", studio: "天王寺", class: "type_15"},
  /*2488 */{dataseat:2488, data_seatno: "s3-y-21-15", customer_id: "1821", name: "牧 ひなた", mail: "", phone: "", studio: "天王寺", class: "type_15"},
  /*2489 */{dataseat:2489, data_seatno: "s3-y-21-16", customer_id: "2059", name: "松田 来夢", mail: "", phone: "", studio: "天王寺", class: "type_15"},
  /*2490 */{dataseat:2490, data_seatno: "s3-y-21-17", customer_id: "2059", name: "松田 来夢", mail: "", phone: "", studio: "天王寺", class: "type_15"},
  /*2491 */{dataseat:2491, data_seatno: "s3-y-21-19", customer_id: "566", name: "松岡 楼子", mail: "", phone: "", studio: "SLASH選抜3", class: "type_11"},
  /*2492 */{dataseat:2492, data_seatno: "s3-y-21-20", customer_id: "566", name: "松岡 楼子", mail: "", phone: "", studio: "SLASH選抜3", class: "type_11"},
  /*2493 */{dataseat:2493, data_seatno: "s3-y-21-21", customer_id: "566", name: "松岡 楼子", mail: "", phone: "", studio: "SLASH選抜3", class: "type_11"},
  /*2494 */{dataseat:2494, data_seatno: "s3-y-21-22", customer_id: "566", name: "松岡 楼子", mail: "", phone: "", studio: "SLASH選抜3", class: "type_11"},
  /*2495 */{dataseat:2495, data_seatno: "s3-y-21-23", customer_id: "566", name: "松岡 楼子", mail: "", phone: "", studio: "SLASH選抜3", class: "type_11"},
  /*2496 */{dataseat:2496, data_seatno: "s3-y-21-24", customer_id: "566", name: "松岡 楼子", mail: "", phone: "", studio: "SLASH選抜3", class: "type_11"},
  /*2497 */{dataseat:2497, data_seatno: "s3-y-21-25", customer_id: "567", name: "川島 心愛", mail: "", phone: "", studio: "SLASH選抜3", class: "type_11"},
  /*2498 */{dataseat:2498, data_seatno: "s3-y-21-26", customer_id: "567", name: "川島 心愛", mail: "", phone: "", studio: "SLASH選抜3", class: "type_11"},
  /*2499 */{dataseat:2499, data_seatno: "s3-y-21-27", customer_id: "570", name: "鈴木 星菜", mail: "", phone: "", studio: "SLASH選抜3", class: "type_11"},
  /*2500 */{dataseat:2500, data_seatno: "s3-y-21-28", customer_id: "570", name: "鈴木 星菜", mail: "", phone: "", studio: "SLASH選抜3", class: "type_11"},
  /*2501 */{dataseat:2501, data_seatno: "s3-y-21-29", customer_id: "587", name: "新坂 つむぎ", mail: "", phone: "", studio: "SLASH選抜3", class: "type_11"},
  /*2502 */{dataseat:2502, data_seatno: "s3-y-21-30", customer_id: "587", name: "新坂 つむぎ", mail: "", phone: "", studio: "SLASH選抜3", class: "type_11"},
  /*2503 */{dataseat:2503, data_seatno: "s3-y-21-32", customer_id: "1320", name: "矢谷 凜", mail: "", phone: "", studio: "楠葉", class: "type_14"},
  /*2504 */{dataseat:2504, data_seatno: "s3-y-21-33", customer_id: "1320", name: "矢谷 凜", mail: "", phone: "", studio: "楠葉", class: "type_14"},
  /*2505 */{dataseat:2505, data_seatno: "s3-y-21-34", customer_id: "1320", name: "矢谷 凜", mail: "", phone: "", studio: "楠葉", class: "type_14"},
  /*2506 */{dataseat:2506, data_seatno: "s3-y-21-35", customer_id: "1316", name: "藤原 なこ", mail: "", phone: "", studio: "楠葉", class: "type_14"},
  /*2507 */{dataseat:2507, data_seatno: "s3-y-21-36", customer_id: "1316", name: "藤原 なこ", mail: "", phone: "", studio: "楠葉", class: "type_14"},
  /*2508 */{dataseat:2508, data_seatno: "s3-y-21-37", customer_id: "1316", name: "藤原 なこ", mail: "", phone: "", studio: "楠葉", class: "type_14"},
  /*2509 */{dataseat:2509, data_seatno: "s3-y-21-38", customer_id: "793", name: "前田 來結愛", mail: "", phone: "", studio: "楠葉", class: "type_14"},
  /*2510 */{dataseat:2510, data_seatno: "s3-y-21-39", customer_id: "793", name: "前田 來結愛", mail: "", phone: "", studio: "楠葉", class: "type_14"},
  /*2511 */{dataseat:2511, data_seatno: "s3-y-21-40", customer_id: "1303", name: "中西 咲華", mail: "", phone: "", studio: "楠葉", class: "type_14"},
  /*2512 */{dataseat:2512, data_seatno: "s3-y-21-41", customer_id: "1303", name: "中西 咲華", mail: "", phone: "", studio: "楠葉", class: "type_14"},
  /*2513 */{dataseat:2513, data_seatno: "s3-y-21-43", customer_id: "1683", name: "味谷 実愛", mail: "", phone: "", studio: "和泉", class: "type_12"},
  /*2514 */{dataseat:2514, data_seatno: "s3-y-21-44", customer_id: "1683", name: "味谷 実愛", mail: "", phone: "", studio: "和泉", class: "type_12"},
  /*2515 */{dataseat:2515, data_seatno: "s3-y-21-45", customer_id: "1777", name: "北本 結愛", mail: "", phone: "", studio: "和泉", class: "type_12"},
  /*2516 */{dataseat:2516, data_seatno: "s3-y-21-46", customer_id: "1777", name: "北本 結愛", mail: "", phone: "", studio: "和泉", class: "type_12"},
  /*2517 */{dataseat:2517, data_seatno: "s3-y-21-47", customer_id: "829", name: "金園 柚希", mail: "", phone: "", studio: "八尾", class: "type_17"},
  /*2518 */{dataseat:2518, data_seatno: "s3-y-21-48", customer_id: "829", name: "金園 柚希", mail: "", phone: "", studio: "八尾", class: "type_17"},
  //////////// 22列目 ////////////
  /*2519 */{dataseat:2519, data_seatno: "s3-y-22-1", customer_id: "1011", name: "近藤 永愛", mail: "", phone: "", studio: "泉南", class: "type_16"},
  /*2520 */{dataseat:2520, data_seatno: "s3-y-22-2", customer_id: "1011", name: "近藤 永愛", mail: "", phone: "", studio: "泉南", class: "type_16"},
  /*2521 */{dataseat:2521, data_seatno: "s3-y-22-3", customer_id: "1847", name: "杉本 歩未", mail: "", phone: "", studio: "泉南", class: "type_16"},
  /*2522 */{dataseat:2522, data_seatno: "s3-y-22-4", customer_id: "1847", name: "杉本 歩未", mail: "", phone: "", studio: "泉南", class: "type_16"},
  /*2523 */{dataseat:2523, data_seatno: "s3-y-22-5", customer_id: "1987", name: "酒井 結逢", mail: "", phone: "", studio: "枚方", class: "type_19"},
  /*2524 */{dataseat:2524, data_seatno: "s3-y-22-6", customer_id: "1987", name: "酒井 結逢", mail: "", phone: "", studio: "枚方", class: "type_19"},
  /*2525 */{dataseat:2525, data_seatno: "s3-y-22-8", customer_id: "803", name: "東原 来未", mail: "", phone: "", studio: "天王寺", class: "type_15"},
  /*2526 */{dataseat:2526, data_seatno: "s3-y-22-9", customer_id: "803", name: "東原 来未", mail: "", phone: "", studio: "天王寺", class: "type_15"},
  /*2527 */{dataseat:2527, data_seatno: "s3-y-22-10", customer_id: "821", name: "東野 沙耶", mail: "", phone: "", studio: "天王寺", class: "type_15"},
  /*2528 */{dataseat:2528, data_seatno: "s3-y-22-11", customer_id: "821", name: "東野 沙耶", mail: "", phone: "", studio: "天王寺", class: "type_15"},
  /*2529 */{dataseat:2529, data_seatno: "s3-y-22-12", customer_id: "1447", name: "中納 莉里", mail: "", phone: "", studio: "天王寺", class: "type_15"},
  /*2530 */{dataseat:2530, data_seatno: "s3-y-22-13", customer_id: "1447", name: "中納 莉里", mail: "", phone: "", studio: "天王寺", class: "type_15"},
  /*2531 */{dataseat:2531, data_seatno: "s3-y-22-14", customer_id: "1526", name: "共田 凛菜", mail: "", phone: "", studio: "天王寺", class: "type_15"},
  /*2532 */{dataseat:2532, data_seatno: "s3-y-22-15", customer_id: "1526", name: "共田 凛菜", mail: "", phone: "", studio: "天王寺", class: "type_15"},
  /*2533 */{dataseat:2533, data_seatno: "s3-y-22-16", customer_id: "1526", name: "共田 凛菜", mail: "", phone: "", studio: "天王寺", class: "type_15"},
  /*2534 */{dataseat:2534, data_seatno: "s3-y-22-17", customer_id: "1526", name: "共田 凛菜", mail: "", phone: "", studio: "天王寺", class: "type_15"},
  /*2535 */{dataseat:2535, data_seatno: "s3-y-22-19", customer_id: "", name: "MERU", mail: "", phone: "", studio: "関係者3", class: "type_21"},
  /*2536 */{dataseat:2536, data_seatno: "s3-y-22-20", customer_id: "", name: "MERU", mail: "", phone: "", studio: "関係者3", class: "type_21"},
  /*2537 */{dataseat:2537, data_seatno: "s3-y-22-21", customer_id: "", name: "MIKI", mail: "", phone: "", studio: "関係者3", class: "type_21"},
  /*2538 */{dataseat:2538, data_seatno: "s3-y-22-22", customer_id: "", name: "MIKI", mail: "", phone: "", studio: "関係者3", class: "type_21"},
  /*2539 */{dataseat:2539, data_seatno: "s3-y-22-23", customer_id: "", name: "MIZUKI", mail: "", phone: "", studio: "関係者3", class: "type_21"},
  /*2540 */{dataseat:2540, data_seatno: "s3-y-22-24", customer_id: "", name: "MIZUKI", mail: "", phone: "", studio: "関係者3", class: "type_21"},
  /*2541 */{dataseat:2541, data_seatno: "s3-y-22-25", customer_id: "", name: "NODOKA", mail: "", phone: "", studio: "関係者3", class: "type_21"},
  /*2542 */{dataseat:2542, data_seatno: "s3-y-22-26", customer_id: "", name: "NODOKA", mail: "", phone: "", studio: "関係者3", class: "type_21"},
  /*2543 */{dataseat:2543, data_seatno: "s3-y-22-27", customer_id: "", name: "SAKI", mail: "", phone: "", studio: "関係者3", class: "type_21"},
  /*2544 */{dataseat:2544, data_seatno: "s3-y-22-28", customer_id: "", name: "SAKI", mail: "", phone: "", studio: "関係者3", class: "type_21"},
  /*2545 */{dataseat:2545, data_seatno: "s3-y-22-29", customer_id: "", name: "YUNA", mail: "", phone: "", studio: "関係者3", class: "type_21"},
  /*2546 */{dataseat:2546, data_seatno: "s3-y-22-30", customer_id: "", name: "YUNA", mail: "", phone: "", studio: "関係者3", class: "type_21"},
  /*2547 */{dataseat:2547, data_seatno: "s3-y-22-32", customer_id: "1312", name: "青木 春来", mail: "", phone: "", studio: "楠葉", class: "type_14"},
  /*2548 */{dataseat:2548, data_seatno: "s3-y-22-33", customer_id: "1312", name: "青木 春来", mail: "", phone: "", studio: "楠葉", class: "type_14"},
  /*2549 */{dataseat:2549, data_seatno: "s3-y-22-34", customer_id: "1322", name: "寺山 実音", mail: "", phone: "", studio: "楠葉", class: "type_14"},
  /*2550 */{dataseat:2550, data_seatno: "s3-y-22-35", customer_id: "1322", name: "寺山 実音", mail: "", phone: "", studio: "楠葉", class: "type_14"},
  /*2551 */{dataseat:2551, data_seatno: "s3-y-22-36", customer_id: "1349", name: "東 葵", mail: "", phone: "", studio: "楠葉", class: "type_14"},
  /*2552 */{dataseat:2552, data_seatno: "s3-y-22-37", customer_id: "1349", name: "東 葵", mail: "", phone: "", studio: "楠葉", class: "type_14"},
  /*2553 */{dataseat:2553, data_seatno: "s3-y-22-38", customer_id: "1360", name: "北村 海果", mail: "", phone: "", studio: "楠葉", class: "type_14"},
  /*2554 */{dataseat:2554, data_seatno: "s3-y-22-39", customer_id: "1360", name: "北村 海果", mail: "", phone: "", studio: "楠葉", class: "type_14"},
  /*2555 */{dataseat:2555, data_seatno: "s3-y-22-40", customer_id: "1488", name: "草場 瑠璃華", mail: "", phone: "", studio: "楠葉", class: "type_14"},
  /*2556 */{dataseat:2556, data_seatno: "s3-y-22-41", customer_id: "1488", name: "草場 瑠璃華", mail: "", phone: "", studio: "楠葉", class: "type_14"},
  /*2557 */{dataseat:2557, data_seatno: "s3-y-22-43", customer_id: "830", name: "近藤 優", mail: "", phone: "", studio: "八尾", class: "type_17"},
  /*2558 */{dataseat:2558, data_seatno: "s3-y-22-44", customer_id: "830", name: "近藤 優", mail: "", phone: "", studio: "八尾", class: "type_17"},
  /*2559 */{dataseat:2559, data_seatno: "s3-y-22-45", customer_id: "856", name: "岡上 りいな", mail: "", phone: "", studio: "八尾", class: "type_17"},
  /*2560 */{dataseat:2560, data_seatno: "s3-y-22-46", customer_id: "856", name: "岡上 りいな", mail: "", phone: "", studio: "八尾", class: "type_17"},
  /*2561 */{dataseat:2561, data_seatno: "s3-y-22-47", customer_id: "964", name: "松本 梨花", mail: "", phone: "", studio: "八尾", class: "type_17"},
  /*2562 */{dataseat:2562, data_seatno: "s3-y-22-48", customer_id: "964", name: "松本 梨花", mail: "", phone: "", studio: "八尾", class: "type_17"},
  //////////// 23列目 ////////////
  /*2563 */{dataseat:2563, data_seatno: "s3-y-23-1", customer_id: "2038", name: "森尾 怜華", mail: "", phone: "", studio: "長田", class: "type_19"},
  /*2564 */{dataseat:2564, data_seatno: "s3-y-23-2", customer_id: "2038", name: "森尾 怜華", mail: "", phone: "", studio: "長田", class: "type_19"},
  /*2565 */{dataseat:2565, data_seatno: "s3-y-23-3", customer_id: "2039", name: "古川 蘭", mail: "", phone: "", studio: "長田", class: "type_19"},
  /*2566 */{dataseat:2566, data_seatno: "s3-y-23-4", customer_id: "2039", name: "古川 蘭", mail: "", phone: "", studio: "長田", class: "type_19"},
  /*2567 */{dataseat:2567, data_seatno: "s3-y-23-5", customer_id: "2042", name: "錦織 結菜", mail: "", phone: "", studio: "長田", class: "type_19"},
  /*2568 */{dataseat:2568, data_seatno: "s3-y-23-6", customer_id: "2042", name: "錦織 結菜", mail: "", phone: "", studio: "長田", class: "type_19"},
  /*2569 */{dataseat:2569, data_seatno: "s3-y-23-8", customer_id: "2044", name: "久徳 葵", mail: "", phone: "", studio: "長田", class: "type_19"},
  /*2570 */{dataseat:2570, data_seatno: "s3-y-23-9", customer_id: "2044", name: "久徳 葵", mail: "", phone: "", studio: "長田", class: "type_19"},
  /*2571 */{dataseat:2571, data_seatno: "s3-y-23-10", customer_id: "2045", name: "重松 蒼央", mail: "", phone: "", studio: "長田", class: "type_19"},
  /*2572 */{dataseat:2572, data_seatno: "s3-y-23-11", customer_id: "2045", name: "重松 蒼央", mail: "", phone: "", studio: "長田", class: "type_19"},
  /*2573 */{dataseat:2573, data_seatno: "s3-y-23-12", customer_id: "2057", name: "蓮池 陽莉", mail: "", phone: "", studio: "長田", class: "type_19"},
  /*2574 */{dataseat:2574, data_seatno: "s3-y-23-13", customer_id: "2057", name: "蓮池 陽莉", mail: "", phone: "", studio: "長田", class: "type_19"},
  /*2575 */{dataseat:2575, data_seatno: "s3-y-23-14", customer_id: "2025", name: "河合 秀佳", mail: "", phone: "", studio: "武庫川 小学生以下", class: "type_19"},
  /*2576 */{dataseat:2576, data_seatno: "s3-y-23-15", customer_id: "2025", name: "河合 秀佳", mail: "", phone: "", studio: "武庫川 小学生以下", class: "type_19"},
  /*2577 */{dataseat:2577, data_seatno: "s3-y-23-16", customer_id: "2011", name: "小西 色葉", mail: "", phone: "", studio: "枚方", class: "type_19"},
  /*2578 */{dataseat:2578, data_seatno: "s3-y-23-17", customer_id: "2011", name: "小西 色葉", mail: "", phone: "", studio: "枚方", class: "type_19"},
  /*2579 */{dataseat:2579, data_seatno: "s3-y-23-19", customer_id: "", name: "FANA", mail: "fana20020927@gmail.com", phone: "07017920927", studio: "関係者3", class: "type_21"},
  /*2580 */{dataseat:2580, data_seatno: "s3-y-23-20", customer_id: "", name: "FANA", mail: "fana20020927@gmail.com", phone: "07017920927", studio: "関係者3", class: "type_21"},
  /*2581 */{dataseat:2581, data_seatno: "s3-y-23-21", customer_id: "", name: "FANA", mail: "fana20020927@gmail.com", phone: "07017920927", studio: "関係者3", class: "type_21"},
  /*2582 */{dataseat:2582, data_seatno: "s3-y-23-22", customer_id: "", name: "FANA", mail: "fana20020927@gmail.com", phone: "07017920927", studio: "関係者3", class: "type_21"},
  /*2583 */{dataseat:2583, data_seatno: "s3-y-23-23", customer_id: "", name: "FANA", mail: "fana20020927@gmail.com", phone: "07017920927", studio: "関係者3", class: "type_21"},
  /*2584 */{dataseat:2584, data_seatno: "s3-y-23-24", customer_id: "", name: "FANA", mail: "fana20020927@gmail.com", phone: "07017920927", studio: "関係者3", class: "type_21"},
  /*2585 */{dataseat:2585, data_seatno: "s3-y-23-25", customer_id: "", name: "NANA", mail: "", phone: "", studio: "関係者3", class: "type_21"},
  /*2586 */{dataseat:2586, data_seatno: "s3-y-23-26", customer_id: "", name: "NANA", mail: "", phone: "", studio: "関係者3", class: "type_21"},
  /*2587 */{dataseat:2587, data_seatno: "s3-y-23-27", customer_id: "", name: "", mail: "", phone: "", studio: "関係者3", class: "type_21"},
  /*2588 */{dataseat:2588, data_seatno: "s3-y-23-28", customer_id: "", name: "", mail: "", phone: "", studio: "関係者3", class: "type_21"},
  /*2589 */{dataseat:2589, data_seatno: "s3-y-23-29", customer_id: "", name: "", mail: "", phone: "", studio: "関係者3", class: "type_21"},
  /*2590 */{dataseat:2590, data_seatno: "s3-y-23-30", customer_id: "", name: "", mail: "", phone: "", studio: "関係者3", class: "type_21"},
  /*2591 */{dataseat:2591, data_seatno: "s3-y-23-32", customer_id: "1712", name: "中村 楓梨", mail: "", phone: "", studio: "楠葉", class: "type_14"},
  /*2592 */{dataseat:2592, data_seatno: "s3-y-23-33", customer_id: "1712", name: "中村 楓梨", mail: "", phone: "", studio: "楠葉", class: "type_14"},
  /*2593 */{dataseat:2593, data_seatno: "s3-y-23-34", customer_id: "1713", name: "森下 花梨", mail: "", phone: "", studio: "楠葉", class: "type_14"},
  /*2594 */{dataseat:2594, data_seatno: "s3-y-23-35", customer_id: "1713", name: "森下 花梨", mail: "", phone: "", studio: "楠葉", class: "type_14"},
  /*2595 */{dataseat:2595, data_seatno: "s3-y-23-36", customer_id: "1713", name: "森下 花梨", mail: "", phone: "", studio: "楠葉", class: "type_14"},
  /*2596 */{dataseat:2596, data_seatno: "s3-y-23-37", customer_id: "1713", name: "森下 花梨", mail: "", phone: "", studio: "楠葉", class: "type_14"},
  /*2597 */{dataseat:2597, data_seatno: "s3-y-23-38", customer_id: "1809", name: "清水 陽菜乃", mail: "", phone: "", studio: "楠葉", class: "type_14"},
  /*2598 */{dataseat:2598, data_seatno: "s3-y-23-39", customer_id: "1809", name: "清水 陽菜乃", mail: "", phone: "", studio: "楠葉", class: "type_14"},
  /*2599 */{dataseat:2599, data_seatno: "s3-y-23-40", customer_id: "1965", name: "中島 葵", mail: "", phone: "", studio: "楠葉", class: "type_14"},
  /*2600 */{dataseat:2600, data_seatno: "s3-y-23-41", customer_id: "1965", name: "中島 葵", mail: "", phone: "", studio: "楠葉", class: "type_14"},
  /*2601 */{dataseat:2601, data_seatno: "s3-y-23-43", customer_id: "1115", name: "谷口 麗", mail: "", phone: "", studio: "八尾", class: "type_17"},
  /*2602 */{dataseat:2602, data_seatno: "s3-y-23-44", customer_id: "1115", name: "谷口 麗", mail: "", phone: "", studio: "八尾", class: "type_17"},
  /*2603 */{dataseat:2603, data_seatno: "s3-y-23-45", customer_id: "2021", name: "種村 仁依奈", mail: "", phone: "", studio: "八尾", class: "type_17"},
  /*2604 */{dataseat:2604, data_seatno: "s3-y-23-46", customer_id: "2021", name: "種村 仁依奈", mail: "", phone: "", studio: "八尾", class: "type_17"},
  /*2605 */{dataseat:2605, data_seatno: "s3-y-23-47", customer_id: "", name: "藤本初恵", mail: "ichinatsu.f@icloud.com", phone: "09037209666", studio: "一般3", class: "type_20"},
  /*2606 */{dataseat:2606, data_seatno: "s3-y-23-48", customer_id: "", name: "藤本初恵", mail: "ichinatsu.f@icloud.com", phone: "09037209666", studio: "一般3", class: "type_20"},
  //////////// 24列目 ////////////
  /*2607 */{dataseat:2607, data_seatno: "s3-y-24-1", customer_id: "2041", name: "樽角 澪佳", mail: "", phone: "", studio: "長田", class: "type_19"},
  /*2608 */{dataseat:2608, data_seatno: "s3-y-24-2", customer_id: "2041", name: "樽角 澪佳", mail: "", phone: "", studio: "長田", class: "type_19"},
  /*2609 */{dataseat:2609, data_seatno: "s3-y-24-3", customer_id: "2041", name: "樽角 澪佳", mail: "", phone: "", studio: "長田", class: "type_19"},
  /*2610 */{dataseat:2610, data_seatno: "s3-y-24-4", customer_id: "2036", name: "谷村 一花", mail: "", phone: "", studio: "武庫川 小学生以下", class: "type_19"},
  /*2611 */{dataseat:2611, data_seatno: "s3-y-24-5", customer_id: "2036", name: "谷村 一花", mail: "", phone: "", studio: "武庫川 小学生以下", class: "type_19"},
  /*2612 */{dataseat:2612, data_seatno: "s3-y-24-6", customer_id: "2036", name: "谷村 一花", mail: "", phone: "", studio: "武庫川 小学生以下", class: "type_19"},
  /*2613 */{dataseat:2613, data_seatno: "s3-y-24-8", customer_id: "2008", name: "伊丹 瑠菜", mail: "", phone: "", studio: "武庫川 小学生以下", class: "type_19"},
  /*2614 */{dataseat:2614, data_seatno: "s3-y-24-9", customer_id: "2008", name: "伊丹 瑠菜", mail: "", phone: "", studio: "武庫川 小学生以下", class: "type_19"},
  /*2615 */{dataseat:2615, data_seatno: "s3-y-24-10", customer_id: "2012", name: "志智 柚月", mail: "", phone: "", studio: "武庫川 小学生以下", class: "type_19"},
  /*2616 */{dataseat:2616, data_seatno: "s3-y-24-11", customer_id: "2012", name: "志智 柚月", mail: "", phone: "", studio: "武庫川 小学生以下", class: "type_19"},
  /*2617 */{dataseat:2617, data_seatno: "s3-y-24-12", customer_id: "2019", name: "松本 逢叶", mail: "", phone: "", studio: "武庫川 小学生以下", class: "type_19"},
  /*2618 */{dataseat:2618, data_seatno: "s3-y-24-13", customer_id: "2019", name: "松本 逢叶", mail: "", phone: "", studio: "武庫川 小学生以下", class: "type_19"},
  /*2619 */{dataseat:2619, data_seatno: "s3-y-24-14", customer_id: "2022", name: "古川 櫻子", mail: "", phone: "", studio: "武庫川 小学生以下", class: "type_19"},
  /*2620 */{dataseat:2620, data_seatno: "s3-y-24-15", customer_id: "2022", name: "古川 櫻子", mail: "", phone: "", studio: "武庫川 小学生以下", class: "type_19"},
  /*2621 */{dataseat:2621, data_seatno: "s3-y-24-16", customer_id: "1124", name: "廣末 凛乃香", mail: "", phone: "", studio: "大阪選抜", class: "type_22"},
  /*2622 */{dataseat:2622, data_seatno: "s3-y-24-17", customer_id: "1124", name: "廣末 凛乃香", mail: "", phone: "", studio: "大阪選抜", class: "type_22"},
  /*2623 */{dataseat:2623, data_seatno: "s3-y-24-19", customer_id: "", name: "", mail: "", phone: "", studio: "PA席", class: "type_1"},
  /*2624 */{dataseat:2624, data_seatno: "s3-y-24-20", customer_id: "", name: "", mail: "", phone: "", studio: "PA席", class: "type_1"},
  /*2625 */{dataseat:2625, data_seatno: "s3-y-24-21", customer_id: "", name: "", mail: "", phone: "", studio: "PA席", class: "type_1"},
  /*2626 */{dataseat:2626, data_seatno: "s3-y-24-22", customer_id: "", name: "", mail: "", phone: "", studio: "PA席", class: "type_1"},
  /*2627 */{dataseat:2627, data_seatno: "s3-y-24-23", customer_id: "", name: "", mail: "", phone: "", studio: "PA席", class: "type_1"},
  /*2628 */{dataseat:2628, data_seatno: "s3-y-24-24", customer_id: "", name: "", mail: "", phone: "", studio: "PA席", class: "type_1"},
  /*2629 */{dataseat:2629, data_seatno: "s3-y-24-25", customer_id: "", name: "", mail: "", phone: "", studio: "PA席", class: "type_1"},
  /*2630 */{dataseat:2630, data_seatno: "s3-y-24-26", customer_id: "", name: "", mail: "", phone: "", studio: "PA席", class: "type_1"},
  /*2631 */{dataseat:2631, data_seatno: "s3-y-24-27", customer_id: "", name: "", mail: "", phone: "", studio: "PA席", class: "type_1"},
  /*2632 */{dataseat:2632, data_seatno: "s3-y-24-28", customer_id: "", name: "", mail: "", phone: "", studio: "PA席", class: "type_1"},
  /*2633 */{dataseat:2633, data_seatno: "s3-y-24-29", customer_id: "", name: "", mail: "", phone: "", studio: "PA席", class: "type_1"},
  /*2634 */{dataseat:2634, data_seatno: "s3-y-24-30", customer_id: "", name: "", mail: "", phone: "", studio: "PA席", class: "type_1"},
  /*2635 */{dataseat:2635, data_seatno: "s3-y-24-32", customer_id: "924", name: "橋口 玲仁杏", mail: "", phone: "", studio: "楠葉", class: "type_14"},
  /*2636 */{dataseat:2636, data_seatno: "s3-y-24-33", customer_id: "924", name: "橋口 玲仁杏", mail: "", phone: "", studio: "楠葉", class: "type_14"},
  /*2637 */{dataseat:2637, data_seatno: "s3-y-24-34", customer_id: "924", name: "橋口 玲仁杏", mail: "", phone: "", studio: "楠葉", class: "type_14"},
  /*2638 */{dataseat:2638, data_seatno: "s3-y-24-35", customer_id: "1974", name: "政岡 葉月", mail: "", phone: "", studio: "楠葉", class: "type_14"},
  /*2639 */{dataseat:2639, data_seatno: "s3-y-24-36", customer_id: "1974", name: "政岡 葉月", mail: "", phone: "", studio: "楠葉", class: "type_14"},
  /*2640 */{dataseat:2640, data_seatno: "s3-y-24-37", customer_id: "", name: "北嶋 ルーナ", mail: "runasari@gmail.com", phone: "09039296863", studio: "一般3", class: "type_20"},
  /*2641 */{dataseat:2641, data_seatno: "s3-y-24-38", customer_id: "", name: "北嶋 ルーナ", mail: "runasari@gmail.com", phone: "09039296863", studio: "一般3", class: "type_20"},
  /*2642 */{dataseat:2642, data_seatno: "s3-y-24-39", customer_id: "", name: "北嶋 ルーナ", mail: "runasari@gmail.com", phone: "09039296863", studio: "一般3", class: "type_20"},
  /*2643 */{dataseat:2643, data_seatno: "s3-y-24-40", customer_id: "", name: "酒田 理恵", mail: "mm.yo.ri_21@Softbank.ne.jp", phone: "09017120254", studio: "一般3", class: "type_20"},
  /*2644 */{dataseat:2644, data_seatno: "s3-y-24-41", customer_id: "", name: "酒田 理恵", mail: "mm.yo.ri_21@Softbank.ne.jp", phone: "09017120254", studio: "一般3", class: "type_20"},
  /*2645 */{dataseat:2645, data_seatno: "s3-y-24-43", customer_id: "", name: "難波夏美", mail: "hina-ibu.8@ezweb.ne.jp", phone: "09012460554", studio: "一般3", class: "type_20"},
  /*2646 */{dataseat:2646, data_seatno: "s3-y-24-44", customer_id: "", name: "難波夏美", mail: "hina-ibu.8@ezweb.ne.jp", phone: "09012460554", studio: "一般3", class: "type_20"},
  /*2647 */{dataseat:2647, data_seatno: "s3-y-24-45", customer_id: "", name: "難波夏美", mail: "hina-ibu.8@ezweb.ne.jp", phone: "09012460554", studio: "一般3", class: "type_20"},
  /*2648 */{dataseat:2648, data_seatno: "s3-y-24-46", customer_id: "", name: "難波夏美", mail: "hina-ibu.8@ezweb.ne.jp", phone: "09012460554", studio: "一般3", class: "type_20"},
  /*2649 */{dataseat:2649, data_seatno: "s3-y-24-47", customer_id: "", name: "寺澤明子", mail: "yu041801220718@gmail.com", phone: "09010286837", studio: "一般3", class: "type_20"},
  /*2650 */{dataseat:2650, data_seatno: "s3-y-24-48", customer_id: "", name: "寺澤明子", mail: "yu041801220718@gmail.com", phone: "09010286837", studio: "一般3", class: "type_20"},
  //////////// 25列目 ////////////
  /*2651 */{dataseat:2651, data_seatno: "s3-y-25-1", customer_id: "", name: "矢田恵子", mail: "nagiari-230425@docomo.ne.jp", phone: "09019011619", studio: "一般3", class: "type_20"},
  /*2652 */{dataseat:2652, data_seatno: "s3-y-25-2", customer_id: "", name: "矢田恵子", mail: "nagiari-230425@docomo.ne.jp", phone: "09019011619", studio: "一般3", class: "type_20"},
  /*2653 */{dataseat:2653, data_seatno: "s3-y-25-3", customer_id: "", name: "矢田恵子", mail: "nagiari-230425@docomo.ne.jp", phone: "09019011619", studio: "一般3", class: "type_20"},
  /*2654 */{dataseat:2654, data_seatno: "s3-y-25-4", customer_id: "", name: "高丸 恵美", mail: "a.moon_365@ezweb.ne.jp", phone: "08083057020", studio: "一般3", class: "type_20"},
  /*2655 */{dataseat:2655, data_seatno: "s3-y-25-5", customer_id: "", name: "高丸 恵美", mail: "a.moon_365@ezweb.ne.jp", phone: "08083057020", studio: "一般3", class: "type_20"},
  /*2656 */{dataseat:2656, data_seatno: "s3-y-25-6", customer_id: "", name: "高丸 恵美", mail: "a.moon_365@ezweb.ne.jp", phone: "08083057020", studio: "一般3", class: "type_20"},
  /*2657 */{dataseat:2657, data_seatno: "s3-y-25-8", customer_id: "", name: "笹尾 裕美", mail: "rin0809.s@gmail.com", phone: "09031647875", studio: "一般3", class: "type_20"},
  /*2658 */{dataseat:2658, data_seatno: "s3-y-25-9", customer_id: "", name: "笹尾 裕美", mail: "rin0809.s@gmail.com", phone: "09031647875", studio: "一般3", class: "type_20"},
  /*2659 */{dataseat:2659, data_seatno: "s3-y-25-10", customer_id: "", name: "笹尾 裕美", mail: "rin0809.s@gmail.com", phone: "09031647875", studio: "一般3", class: "type_20"},
  /*2660 */{dataseat:2660, data_seatno: "s3-y-25-11", customer_id: "", name: "笹尾 裕美", mail: "rin0809.s@gmail.com", phone: "09031647875", studio: "一般3", class: "type_20"},
  /*2661 */{dataseat:2661, data_seatno: "s3-y-25-12", customer_id: "1126", name: "宮本 果音", mail: "", phone: "", studio: "大阪選抜", class: "type_22"},
  /*2662 */{dataseat:2662, data_seatno: "s3-y-25-13", customer_id: "1126", name: "宮本 果音", mail: "", phone: "", studio: "大阪選抜", class: "type_22"},
  /*2663 */{dataseat:2663, data_seatno: "s3-y-25-14", customer_id: "1126", name: "宮本 果音", mail: "", phone: "", studio: "大阪選抜", class: "type_22"},
  /*2664 */{dataseat:2664, data_seatno: "s3-y-25-15", customer_id: "1126", name: "宮本 果音", mail: "", phone: "", studio: "大阪選抜", class: "type_22"},
  /*2665 */{dataseat:2665, data_seatno: "s3-y-25-16", customer_id: "1126", name: "宮本 果音", mail: "", phone: "", studio: "大阪選抜", class: "type_22"},
  /*2666 */{dataseat:2666, data_seatno: "s3-y-25-17", customer_id: "1126", name: "宮本 果音", mail: "", phone: "", studio: "大阪選抜", class: "type_22"},
  /*2667 */{dataseat:2667, data_seatno: "s3-y-25-19", customer_id: "", name: "", mail: "", phone: "", studio: "PA席", class: "type_1"},
  /*2668 */{dataseat:2668, data_seatno: "s3-y-25-20", customer_id: "", name: "", mail: "", phone: "", studio: "PA席", class: "type_1"},
  /*2669 */{dataseat:2669, data_seatno: "s3-y-25-21", customer_id: "", name: "", mail: "", phone: "", studio: "PA席", class: "type_1"},
  /*2670 */{dataseat:2670, data_seatno: "s3-y-25-22", customer_id: "", name: "", mail: "", phone: "", studio: "PA席", class: "type_1"},
  /*2671 */{dataseat:2671, data_seatno: "s3-y-25-23", customer_id: "", name: "", mail: "", phone: "", studio: "PA席", class: "type_1"},
  /*2672 */{dataseat:2672, data_seatno: "s3-y-25-24", customer_id: "", name: "", mail: "", phone: "", studio: "PA席", class: "type_1"},
  /*2673 */{dataseat:2673, data_seatno: "s3-y-25-25", customer_id: "", name: "", mail: "", phone: "", studio: "PA席", class: "type_1"},
  /*2674 */{dataseat:2674, data_seatno: "s3-y-25-26", customer_id: "", name: "", mail: "", phone: "", studio: "PA席", class: "type_1"},
  /*2675 */{dataseat:2675, data_seatno: "s3-y-25-27", customer_id: "", name: "", mail: "", phone: "", studio: "PA席", class: "type_1"},
  /*2676 */{dataseat:2676, data_seatno: "s3-y-25-28", customer_id: "", name: "", mail: "", phone: "", studio: "PA席", class: "type_1"},
  /*2677 */{dataseat:2677, data_seatno: "s3-y-25-29", customer_id: "", name: "", mail: "", phone: "", studio: "PA席", class: "type_1"},
  /*2678 */{dataseat:2678, data_seatno: "s3-y-25-30", customer_id: "", name: "", mail: "", phone: "", studio: "PA席", class: "type_1"},
  /*2679 */{dataseat:2679, data_seatno: "s3-y-25-32", customer_id: "1970", name: "牧 結乃愛", mail: "", phone: "", studio: "楠葉", class: "type_14"},
  /*2680 */{dataseat:2680, data_seatno: "s3-y-25-33", customer_id: "1970", name: "牧 結乃愛", mail: "", phone: "", studio: "楠葉", class: "type_14"},
  /*2681 */{dataseat:2681, data_seatno: "s3-y-25-34", customer_id: "1971", name: "小山 壱華", mail: "", phone: "", studio: "楠葉", class: "type_14"},
  /*2682 */{dataseat:2682, data_seatno: "s3-y-25-35", customer_id: "1971", name: "小山 壱華", mail: "", phone: "", studio: "楠葉", class: "type_14"},
  /*2683 */{dataseat:2683, data_seatno: "s3-y-25-36", customer_id: "", name: "徳永理恵", mail: "mukkurutegiara@gmail.com", phone: "09056644684", studio: "一般3", class: "type_20"},
  /*2684 */{dataseat:2684, data_seatno: "s3-y-25-37", customer_id: "", name: "森田絢子", mail: "ayakochan1025@icloud.com", phone: "08014811033", studio: "一般3", class: "type_20"},
  /*2685 */{dataseat:2685, data_seatno: "s3-y-25-38", customer_id: "", name: "山下 唯", mail: "yamashita1988_0104@yahoo.co.jp", phone: "09089316526", studio: "一般3", class: "type_20"},
  /*2686 */{dataseat:2686, data_seatno: "s3-y-25-39", customer_id: "", name: "山下 唯", mail: "yamashita1988_0104@yahoo.co.jp", phone: "09089316526", studio: "一般3", class: "type_20"},
  /*2687 */{dataseat:2687, data_seatno: "s3-y-25-40", customer_id: "", name: "山下 唯", mail: "yamashita1988_0104@yahoo.co.jp", phone: "09089316526", studio: "一般3", class: "type_20"},
  /*2688 */{dataseat:2688, data_seatno: "s3-y-25-41", customer_id: "", name: "山下 唯", mail: "yamashita1988_0104@yahoo.co.jp", phone: "09089316526", studio: "一般3", class: "type_20"},
  /*2689 */{dataseat:2689, data_seatno: "s3-y-25-43", customer_id: "", name: "上田貴美子", mail: "bigbang1225@gmail.com", phone: "09069633781", studio: "一般3", class: "type_20"},
  /*2690 */{dataseat:2690, data_seatno: "s3-y-25-44", customer_id: "", name: "上田貴美子", mail: "bigbang1225@gmail.com", phone: "09069633781", studio: "一般3", class: "type_20"},
  /*2691 */{dataseat:2691, data_seatno: "s3-y-25-45", customer_id: "", name: "西山紀香", mail: "mexico2005cancun@gmail.com", phone: "09073508677", studio: "一般3", class: "type_20"},
  /*2692 */{dataseat:2692, data_seatno: "s3-y-25-46", customer_id: "", name: "農本 りえ", mail: "nomonchuchu@gmail.com", phone: "09091125740", studio: "一般3", class: "type_20"},
  /*2693 */{dataseat:2693, data_seatno: "s3-y-25-47", customer_id: "", name: "農本 りえ", mail: "nomonchuchu@gmail.com", phone: "09091125740", studio: "一般3", class: "type_20"},
  /*2694 */{dataseat:2694, data_seatno: "s3-y-25-48", customer_id: "", name: "農本 りえ", mail: "nomonchuchu@gmail.com", phone: "09091125740", studio: "一般3", class: "type_20"},
]
