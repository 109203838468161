import React, { useState, useEffect, useRef } from "react";
import { Grid, Input, Icon, Button } from "semantic-ui-react";
import "semantic-ui-css/semantic.min.css";
import "react-lazy-load-image-component/src/effects/blur.css";
import "../../../../styles/scss/recital2025DiskOrderList.scss";
import { useLoginCheck } from "../../../../hooks/useLoginCheck";

function DiskOrderListPage() {
  useLoginCheck();
  const [customers, setCustomers] = useState([]); // カスタマー
  const [filteredCustomers, setFilteredCustomers] = useState([]); // 絞り込んだカスタマー
  const [searchKeyword, setSearchKeyword] = useState(""); // 検索ワード
  const [sortConfig, setSortConfig] = useState({
    key: null,
    direction: "ascending",
  }); // ソート
  const [shippedAnimeOn, setShippedAnimeOn] = useState("");

  const searchInputRef = useRef(null);

  // 顧客の取得
  const fetchCustomersData = async () => {
    try {
      const apiUrl = process.env.REACT_APP_API_URL;
      const response = await fetch(
        `${apiUrl}/api/get_recital2025_disk_orders/`
      );
      if (!response.ok) {
        throw new Error("ネットワークレスポンスが異常です。");
      }
      const data = await response.json();
      setCustomers(data);
      setFilteredCustomers(data);
    } catch (error) {
      console.error("データの取得中にエラーが発生しました:", error);
    }
  };

  // 初期表示時に購入者のリストを取得
  useEffect(() => {
    fetchCustomersData();
  }, []);

  // ソート
  const handleSort = (key) => {
    let direction = "ascending";
    if (sortConfig.key === key && sortConfig.direction === "ascending") {
      direction = "descending";
    }
    setSortConfig({ key, direction });
  };

  const sortedCustomers = React.useMemo(() => {
    let sortableCustomers = [...filteredCustomers];
    if (sortConfig.key !== null) {
      sortableCustomers.sort((a, b) => {
        let aValue = a[sortConfig.key];
        let bValue = b[sortConfig.key];

        if (aValue < bValue) {
          return sortConfig.direction === "ascending" ? -1 : 1;
        }
        if (aValue > bValue) {
          return sortConfig.direction === "ascending" ? 1 : -1;
        }
        return 0;
      });
    }
    return sortableCustomers;
  }, [filteredCustomers, sortConfig]);

  // 検索
  const handleSearch = (event) => {
    let keyword = searchKeyword;

    if (
      event.type === "click" ||
      (event.key === "Enter" && event.target.value)
    ) {
      const filtered = customers.filter((customer) => {
        return (
          (customer.id && customer.id.toString().includes(keyword)) ||
          (customer.customer_id &&
            customer.customer_id.toString().includes(keyword)) ||
          (customer.name && customer.name.includes(keyword)) ||
          (customer.mail && customer.mail.includes(keyword)) ||
          (customer.tel && customer.tel.includes(keyword)) ||
          (customer.postcode && customer.postcode.includes(keyword)) ||
          (customer.address && customer.address.includes(keyword)) ||
          (customer.studio && customer.studio.includes(keyword)) ||
          (customer.delivery_type &&
            customer.delivery_type.includes(keyword)) ||
          (customer.payment_type && customer.payment_type.includes(keyword)) ||
          (customer.payment_flg && customer.payment_flg.includes(keyword)) ||
          (customer.dvd_count &&
            String(customer.dvd_count).includes(keyword)) ||
          (customer.bluray_count &&
            String(customer.bluray_count).includes(keyword)) ||
          (customer.download_flg && customer.download_flg.includes(keyword)) ||
          (customer.amount && String(customer.amount).includes(keyword)) ||
          (customer.created_at && customer.created_at.includes(keyword))
        );
      });
      setFilteredCustomers(filtered);
    }
  };

  // 発送済フラグの保存
  const changeDeliveryFlg = async (orderId) => {
    try {
      const apiUrl = process.env.REACT_APP_API_URL;
      const response = await fetch(
        `${apiUrl}/api/change_recital2025_delivery_flg/`,
        {
          method: "POST",
          credentials: "include",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            order_id: orderId,
          }),
        }
      );

      const data = await response.json();
      if (!response.ok) {
        throw new Error(`${data.message} status: ${response.status}`);
      }

      setShippedAnimeOn("on");

      const updatedOrders = customers.map((order) => {
        if (order.id === orderId) {
          return {
            ...order,
            delivery_flg: order.delivery_flg !== true,
          };
        }
        return order;
      });

      setCustomers(updatedOrders);
      setFilteredCustomers(updatedOrders);
    } catch (error) {
      alert(error);
      console.error("There was a problem with the fetch operation:", error);
    }
  };

  return (
    <>
      <p className={`save-anime ${shippedAnimeOn}`}>発送済フラグを保存</p>
      <Grid>
        <h1>DVD購入者一覧</h1>
        <Input
          icon={{
            name: "search",
            circular: true,
            link: true,
            onClick: handleSearch,
          }}
          placeholder="Search..."
          value={searchKeyword}
          ref={searchInputRef}
          onChange={(e) => {
            setSearchKeyword(e.target.value);
          }}
          onKeyDown={handleSearch}
        />
      </Grid>

      <Grid>
        <Grid.Column width={16}>
          <ul className="customer-grid customer-header">
            <li className="cell" onClick={() => handleSort("id")}>
              ID
              {sortConfig.key === "id" &&
                sortConfig.direction === "ascending" && (
                  <Icon name="triangle up" />
                )}
              {sortConfig.key === "id" &&
                sortConfig.direction === "descending" && (
                  <Icon name="triangle down" />
                )}
            </li>
            <li className="cell" onClick={() => handleSort("customer_id")}>
              顧客ID
              {sortConfig.key === "customer_id" &&
                sortConfig.direction === "ascending" && (
                  <Icon name="triangle up" />
                )}
              {sortConfig.key === "customer_id" &&
                sortConfig.direction === "descending" && (
                  <Icon name="triangle down" />
                )}
            </li>
            <li className="cell" onClick={() => handleSort("name")}>
              名前
              {sortConfig.key === "name" &&
                sortConfig.direction === "ascending" && (
                  <Icon name="triangle up" />
                )}
              {sortConfig.key === "name" &&
                sortConfig.direction === "descending" && (
                  <Icon name="triangle down" />
                )}
            </li>
            <li className="cell" onClick={() => handleSort("mail")}>
              メール
              {sortConfig.key === "mail" &&
                sortConfig.direction === "ascending" && (
                  <Icon name="triangle up" />
                )}
              {sortConfig.key === "mail" &&
                sortConfig.direction === "descending" && (
                  <Icon name="triangle down" />
                )}
            </li>
            <li className="cell" onClick={() => handleSort("tel")}>
              電話
              {sortConfig.key === "tel" &&
                sortConfig.direction === "ascending" && (
                  <Icon name="triangle up" />
                )}
              {sortConfig.key === "tel" &&
                sortConfig.direction === "descending" && (
                  <Icon name="triangle down" />
                )}
            </li>
            <li className="cell" onClick={() => handleSort("delivery_type")}>
              受取方法
              {sortConfig.key === "delivery_type" &&
                sortConfig.direction === "ascending" && (
                  <Icon name="triangle up" />
                )}
              {sortConfig.key === "delivery_type" &&
                sortConfig.direction === "descending" && (
                  <Icon name="triangle down" />
                )}
            </li>
            <li className="cell" onClick={() => handleSort("studio")}>
              受取店舗
              {sortConfig.key === "studio" &&
                sortConfig.direction === "ascending" && (
                  <Icon name="triangle up" />
                )}
              {sortConfig.key === "studio" &&
                sortConfig.direction === "descending" && (
                  <Icon name="triangle down" />
                )}
            </li>
            <li className="cell" onClick={() => handleSort("postcode")}>
              郵便番号
              {sortConfig.key === "postcode" &&
                sortConfig.direction === "ascending" && (
                  <Icon name="triangle up" />
                )}
              {sortConfig.key === "postcode" &&
                sortConfig.direction === "descending" && (
                  <Icon name="triangle down" />
                )}
            </li>
            <li className="cell" onClick={() => handleSort("address")}>
              住所
              {sortConfig.key === "address" &&
                sortConfig.direction === "ascending" && (
                  <Icon name="triangle up" />
                )}
              {sortConfig.key === "address" &&
                sortConfig.direction === "descending" && (
                  <Icon name="triangle down" />
                )}
            </li>
            <li className="cell" onClick={() => handleSort("payment_type")}>
              支払方法
              {sortConfig.key === "payment_type" &&
                sortConfig.direction === "ascending" && (
                  <Icon name="triangle up" />
                )}
              {sortConfig.key === "payment_type" &&
                sortConfig.direction === "descending" && (
                  <Icon name="triangle down" />
                )}
            </li>
            <li className="cell" onClick={() => handleSort("payment_flg")}>
              支払済
              {sortConfig.key === "payment_flg" &&
                sortConfig.direction === "ascending" && (
                  <Icon name="triangle up" />
                )}
              {sortConfig.key === "payment_flg" &&
                sortConfig.direction === "descending" && (
                  <Icon name="triangle down" />
                )}
            </li>
            <li className="cell" onClick={() => handleSort("dvd_count")}>
              DVD
              {sortConfig.key === "dvd_count" &&
                sortConfig.direction === "ascending" && (
                  <Icon name="triangle up" />
                )}
              {sortConfig.key === "dvd_count" &&
                sortConfig.direction === "descending" && (
                  <Icon name="triangle down" />
                )}
            </li>
            <li className="cell" onClick={() => handleSort("bluray_count")}>
              Blu-ray
              {sortConfig.key === "bluray_count" &&
                sortConfig.direction === "ascending" && (
                  <Icon name="triangle up" />
                )}
              {sortConfig.key === "bluray_count" &&
                sortConfig.direction === "descending" && (
                  <Icon name="triangle down" />
                )}
            </li>
            <li className="cell" onClick={() => handleSort("download_flg")}>
              ダウンロード
              {sortConfig.key === "download_flg" &&
                sortConfig.direction === "ascending" && (
                  <Icon name="triangle up" />
                )}
              {sortConfig.key === "download_flg" &&
                sortConfig.direction === "descending" && (
                  <Icon name="triangle down" />
                )}
            </li>
            <li className="cell" onClick={() => handleSort("amount")}>
              金額
              {sortConfig.key === "amount" &&
                sortConfig.direction === "ascending" && (
                  <Icon name="triangle up" />
                )}
              {sortConfig.key === "amount" &&
                sortConfig.direction === "descending" && (
                  <Icon name="triangle down" />
                )}
            </li>
            <li className="cell" onClick={() => handleSort("created_at")}>
              購入日
              {sortConfig.key === "created_at" &&
                sortConfig.direction === "ascending" && (
                  <Icon name="triangle up" />
                )}
              {sortConfig.key === "created_at" &&
                sortConfig.direction === "descending" && (
                  <Icon name="triangle down" />
                )}
            </li>
            <li className="cell" onClick={() => handleSort("delivery_flg")}>
              発送済
              {sortConfig.key === "delivery_flg" &&
                sortConfig.direction === "ascending" && (
                  <Icon name="triangle up" />
                )}
              {sortConfig.key === "delivery_flg" &&
                sortConfig.direction === "descending" && (
                  <Icon name="triangle down" />
                )}
            </li>
          </ul>

          {sortedCustomers.map((customer) => (
            <ul key={customer.id} className="customer-grid">
              <li className="cell">{customer.id}</li>
              <li className="cell">{customer.customer_id}</li>
              <li className="cell">{customer.name}</li>
              <li className="cell">{customer.mail}</li>
              <li className="cell">{customer.tel}</li>
              <li className="cell">{customer.delivery_type}</li>
              <li className="cell">{customer.studio}</li>
              <li className="cell">{customer.postcode}</li>
              <li className="cell">{customer.address}</li>
              <li className="cell">{customer.payment_type}</li>
              <li className="cell">{customer.payment_flg}</li>
              <li className="cell">{customer.dvd_count}枚</li>
              <li className="cell">{customer.bluray_count}枚</li>
              <li className="cell">{customer.download_flg}</li>
              <li className="cell">{customer.amount}円</li>
              <li className="cell">{customer.created_at}</li>
              <li className="cell">
                <input
                  type="checkbox"
                  checked={customer.delivery_flg}
                  onChange={() => {
                    setShippedAnimeOn("");
                    changeDeliveryFlg(customer.id);
                  }}
                />
              </li>
            </ul>
          ))}
        </Grid.Column>
      </Grid>
    </>
  );
}

export default DiskOrderListPage;
