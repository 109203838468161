import React, { useState, useEffect } from "react";
import "../../../../styles/scss/recital2025Questionnaire.scss";
import { useLoginCheck } from "../../../../hooks/useLoginCheck";

function QuestionnairePage() {
  useLoginCheck();
  const [questionnaireList, setQuestionnaireList] = useState([]);

  // 音編希望一覧の取得
  const fetchQuestionnaireData = async () => {
    try {
      const apiUrl = process.env.REACT_APP_API_URL;
      const response = await fetch(
        `${apiUrl}/api/get_recital2025_questionnaire/`
      );
      if (!response.ok) {
        throw new Error("ネットワークレスポンスが異常です。");
      }
      const data = await response.json();
      setQuestionnaireList(data);
      console.log(data);
    } catch (error) {
      console.error("データの取得中にエラーが発生しました:", error);
    }
  };

  useEffect(() => {
    fetchQuestionnaireData();
  }, []);

  return (
    <>
      <h1>アンケート</h1>
      <div
        className="graph"
        style={{
          gridTemplateRows: `repeat(${questionnaireList.length + 1}, auto)`,
        }}
      >
        {/* 顧客ID */}
        <ul style={{ gridRow: `span ${questionnaireList.length + 1}` }}>
          <li>ID</li>
          {questionnaireList &&
            questionnaireList.map((questionnaire, index) => {
              return <li key={`customer-id-${index}`}>{questionnaire.id}</li>;
            })}
        </ul>
        {/* 感想 */}
        <ul style={{ gridRow: `span ${questionnaireList.length + 1}` }}>
          <li>感想</li>
          {questionnaireList &&
            questionnaireList.map((questionnaire, index) => {
              return (
                <li key={`impression-${index}`}>{questionnaire.impression}</li>
              );
            })}
        </ul>
        {/* インストラクターへのメッセージ */}
        <ul style={{ gridRow: `span ${questionnaireList.length + 1}` }}>
          <li>インストラクターへのメッセージ</li>
          {questionnaireList &&
            questionnaireList.map((questionnaire, index) => {
              return (
                <li key={`instructor-message-${index}`}>
                  {questionnaire.instructor_message}
                </li>
              );
            })}
        </ul>
        {/* 満足度 */}
        <ul style={{ gridRow: `span ${questionnaireList.length + 1}` }}>
          <li>満足度</li>
          {questionnaireList &&
            questionnaireList.map((questionnaire, index) => {
              return (
                <li key={`satisfaction-${index}`}>
                  {questionnaire.satisfaction}
                </li>
              );
            })}
        </ul>
        {/* 会場の広さ */}
        <ul style={{ gridRow: `span ${questionnaireList.length + 1}` }}>
          <li>会場の広さ</li>
          {questionnaireList &&
            questionnaireList.map((questionnaire, index) => {
              return <li key={`size-${index}`}>{questionnaire.size}</li>;
            })}
        </ul>
        {/* 料金 */}
        <ul style={{ gridRow: `span ${questionnaireList.length + 1}` }}>
          <li>料金</li>
          {questionnaireList &&
            questionnaireList.map((questionnaire, index) => {
              return <li key={`price-${index}`}>{questionnaire.price}</li>;
            })}
        </ul>
        {/* ナンバー */}
        <ul style={{ gridRow: `span ${questionnaireList.length + 1}` }}>
          <li>参加ナンバー</li>
          {questionnaireList.map((questionnaire, index) => (
            <li key={`numbers-${index}`}>
              {questionnaire.numbers.map((number, idx) => (
                <React.Fragment key={`numbers-${index}-${idx}`}>
                  {number.studio_name} / {number.lesson_name}
                  <br />
                </React.Fragment>
              ))}
            </li>
          ))}
        </ul>
        {/* インストラクター */}
        <ul style={{ gridRow: `span ${questionnaireList.length + 1}` }}>
          <li>インストラクター</li>
          {questionnaireList.map((questionnaire, index) => (
            <li key={`numbers-${index}`}>
              {questionnaire.numbers.map((number, idx) => (
                <React.Fragment key={`numbers-${index}-${idx}`}>
                  {number.instructor_name}
                  <br />
                </React.Fragment>
              ))}
            </li>
          ))}
        </ul>
      </div>
    </>
  );
}

export default QuestionnairePage;
