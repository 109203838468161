import React, { useState, useEffect, useRef } from "react";
import "react-lazy-load-image-component/src/effects/blur.css";
import { useLoginCheck } from "../../../../hooks/useLoginCheck";

import { ReactComponent as SeatSection1Svg } from "../../../../assets/recital2025/seatSection1.svg";
import { ReactComponent as SeatSection2Svg } from "../../../../assets/recital2025/seatSection2.svg";
import { ReactComponent as SeatSection3Svg } from "../../../../assets/recital2025/seatSection3.svg";
import "../../../../styles/scss/recital2025StudentSeat.scss";

import {
  SECTION1_SEATS,
  SECTION2_SEATS,
  SECTION3_SEATS,
} from "../../../../constants/recital2025/studentSeatConstants.js";

function SeatPage() {
  useLoginCheck();

  // 1部
  const sec1_seat_data = SECTION1_SEATS;

  // 2部
  const sec2_seat_data = SECTION2_SEATS;

  // 3部
  const sec3_seat_data = SECTION3_SEATS;

  // 1部
  const sec1_number_data = [
    { name: "新田辺/Boys(金) 男性アーティスト専門", count: 12 },
    { name: "出町柳/初級", count: 6 },
    { name: "丹波橋/K-POP 小4~小6", count: 13 },
    { name: "宇治/K-POP 小学生以下", count: 9 },
    { name: "山科/初級", count: 9 },
    { name: "西大路御池/初級", count: 11 },
    { name: "丹波橋/K-POP 小3以下", count: 9 },
    { name: "烏丸/初級", count: 8 },
    { name: "新田辺/小学生以下(月) + 小学生以下(金)", count: 15 },
    { name: "西大路御池/Girls", count: 14 },
    { name: "新田辺/入門(月)", count: 9 },
    { name: "丹波橋/K-POP基礎 小学生以下", count: 7 },
    { name: "西大路御池/小学生以下", count: 10 },
    { name: "丹波橋/K-POP基礎 中学生以上", count: 10 },
    { name: "烏丸/オーディション対策レッスン", count: 7 },
    { name: "宇治/K-POP 初級", count: 10 },
    { name: "木津川/中高生", count: 15 },
    { name: "新田辺/初級(月) + 初級(金)", count: 16 },
    { name: "合同/京都選抜", count: 8 },
  ];

  // 2部
  const sec2_number_data = [
    { name: "加古川/小学生以下", count: 11 },
    { name: "西明石/小学生以下", count: 5 },
    { name: "東生駒/K-POP 初級", count: 9 },
    { name: "垂水/小学生以下", count: 17 },
    { name: "大和八木/K-POP 初級", count: 8 },
    { name: "大津京/K-POP 初級", count: 7 },
    { name: "西神中央/小学生以下(火)", count: 21 },
    { name: "草津/K-POP 小学生以下", count: 5 },
    { name: "天理/K-POP 初級", count: 8 },
    { name: "学園前/K-POP 一般", count: 7 },
    { name: "加古川/一般", count: 14 },
    { name: "西神中央/初級(火)", count: 14 },
    { name: "西明石/初級", count: 5 },
    { name: "垂水/初級", count: 12 },
    { name: "大和八木/K-POP 小学生以下", count: 9 },
    { name: "西神中央/初級(木)", count: 13 },
    { name: "大宮/初級", count: 16 },
    { name: "草津/K-POP 初級", count: 12 },
    { name: "東生駒/K-POP 小学生以下", count: 5 },
    { name: "合同/兵庫選抜", count: 16 },
    { name: "合同/奈良選抜", count: 14 },
  ];

  // 2部
  const sec3_number_data = [
    { name: "楠葉/小学生以下", count: 18 },
    { name: "和泉/小学生以下", count: 9 },
    { name: "江坂/小学生以下", count: 8 },
    { name: "福島/K-POP 小学生以下", count: 6 },
    { name: "栂・美木多/K-POP 入門&初級", count: 10 },
    { name: "長岡京/初級", count: 11 },
    { name: "岸和田/小学生以下(月)", count: 13 },
    { name: "江坂/一般", count: 4 },
    { name: "高槻/一般", count: 3 },
    { name: "福島/K-POP 初級", count: 6 },
    { name: "天王寺/小学生以下", count: 5 },
    { name: "泉南/小学生以下 + 初級", count: 9 },
    { name: "八尾/小学生以下", count: 8 },
    { name: "高槻/中高生", count: 13 },
    { name: "和泉/初級", count: 5 },
    { name: "高槻/小学生以下", count: 10 },
    { name: "江坂/初級", count: 7 },
    { name: "三国ヶ丘/小学生以下", count: 12 },
    { name: "八尾/初級", count: 7 },
    { name: "池田/初級", count: 7 },
    { name: "栂・美木多/ダンス 入門(金)", count: 4 },
    { name: "岸和田/初級(金)", count: 7 },
    { name: "天王寺/初級", count: 7 },
    { name: "布施/小学生以下", count: 8 },
    { name: "長岡京/中級", count: 10 },
    { name: "岸和田/入門(金)", count: 5 },
    { name: "NANA + YUNA + MIKI 新店舗クラス", count: 17 },
    { name: "FANA 中学生", count: 24 },
    { name: "合同/大阪選抜", count: 16 },
  ];

  // チェックボックス変更時のハンドラ
  const handleCheckboxChange = (e, numberName) => {
    // チェックされていれば fill: red, そうでなければ元に戻す
    const isChecked = e.target.checked;

    // data-studioname="(numberName)" の <path> をすべて取得
    const targetPaths = document.querySelectorAll(
      `path[data-studioname="${numberName}"]`
    );

    // 取得した全てのパスをループし、fill を変更
    targetPaths.forEach((path) => {
      path.style.fill = isChecked ? "#e6194b" : "";
    });
  };

  useEffect(() => {
    let seatNumber = 1;
    const allPaths = document.querySelectorAll(".seat-item");
    allPaths.forEach((path) => {
      path.setAttribute("data-seatno", seatNumber++);
    });

    // 1部
    // ①出演生徒座席
    allPaths.forEach((path) => {
      if (path.getAttribute("data-seatno") <= 156) {
        path.classList.add("type_1");
      }
    });

    // 出力
    for (let i = 0; i < sec1_seat_data.length; i++) {
      // sec1_seat_dataの処理をここに書く
      const seatElement = document.querySelector(
        `[data-seatno="${sec1_seat_data[i].dataseat}"]`
      );
      if (seatElement) {
        seatElement.classList.add(sec1_seat_data[i].class);
        seatElement.dataset.studioname = sec1_seat_data[i].studio;
        seatElement.dataset.name = sec1_seat_data[i].name;
      }
    }
    for (let i = 0; i < sec2_seat_data.length; i++) {
      // sec2_seat_dataの処理をここに書く
      const seatElement = document.querySelector(
        `[data-seatno="${sec2_seat_data[i].dataseat}"]`
      );
      if (seatElement) {
        seatElement.classList.add(sec2_seat_data[i].class);
        seatElement.dataset.studioname = sec2_seat_data[i].studio;
        seatElement.dataset.name = sec2_seat_data[i].name;
      }
    }
    for (let i = 0; i < sec3_seat_data.length; i++) {
      // sec3_seat_dataの処理をここに書く
      const seatElement = document.querySelector(
        `[data-seatno="${sec3_seat_data[i].dataseat}"]`
      );
      if (seatElement) {
        seatElement.classList.add(sec3_seat_data[i].class);
        seatElement.dataset.studioname = sec3_seat_data[i].studio;
        seatElement.dataset.name = sec3_seat_data[i].name;
      }
    }
  }, []);

  const [info, setInfo] = useState({
    seatNo: "-",
    studioName: "-",
    name: "-",
  });
  const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });
  const [scale, setScale] = useState(1);
  const [isSvgHovered, setIsSvgHovered] = useState(false);

  useEffect(() => {
    // マウスの移動を追跡
    const handleMouseMove = (e) => {
      setMousePosition({ x: e.pageX, y: e.pageY }); // 修正点：clientX/YからpageX/Yに変更
    };
    window.addEventListener("mousemove", handleMouseMove);

    // SVGのパス要素にイベントリスナーを追加
    const paths = document.querySelectorAll("path");
    const handlePathMouseOver = (e) => {
      const path = e.currentTarget;
      const seatNo = path.getAttribute("data-seatno") || "-";
      const studioName = path.getAttribute("data-studioname") || "-";
      const name = path.getAttribute("data-name") || "-";
      setInfo({ seatNo, studioName, name });
    };
    const handlePathMouseOut = () => {
      setInfo({
        seatNo: "-",
        studioName: "-",
        name: "-",
      });
    };
    paths.forEach((path) => {
      path.addEventListener("mouseover", handlePathMouseOver);
      path.addEventListener("mouseout", handlePathMouseOut);
    });

    // すべてのSVG要素にイベントリスナーを追加
    const svgElements = document.querySelectorAll("svg");
    const handleSvgMouseOver = () => {
      setIsSvgHovered(true);
    };
    const handleSvgMouseOut = () => {
      setIsSvgHovered(false);
    };
    svgElements.forEach((svgElement) => {
      svgElement.addEventListener("mouseover", handleSvgMouseOver);
      svgElement.addEventListener("mouseout", handleSvgMouseOut);
    });

    // マウスストーカーのターゲット要素にイベントリスナーを追加
    const targets = document.querySelectorAll(".stkr-target");
    const handleTargetMouseOver = () => {
      setScale(2);
    };
    const handleTargetMouseOut = () => {
      setScale(1);
    };
    targets.forEach((target) => {
      target.addEventListener("mouseover", handleTargetMouseOver);
      target.addEventListener("mouseout", handleTargetMouseOut);
    });

    // クリーンアップ
    return () => {
      window.removeEventListener("mousemove", handleMouseMove);
      paths.forEach((path) => {
        path.removeEventListener("mouseover", handlePathMouseOver);
        path.removeEventListener("mouseout", handlePathMouseOut);
      });
      svgElements.forEach((svgElement) => {
        svgElement.removeEventListener("mouseover", handleSvgMouseOver);
        svgElement.removeEventListener("mouseout", handleSvgMouseOut);
      });
      targets.forEach((target) => {
        target.removeEventListener("mouseover", handleTargetMouseOver);
        target.removeEventListener("mouseout", handleTargetMouseOut);
      });
    };
  }, []);

  const offsetX = 10; // マウスカーソルの右に10px
  const offsetY = -10; // マウスカーソルの上に10px

  return (
    <>
      {/* <ul className="studio_seat_count_list">
        <li className="full">
          <p>1部</p>
        </li>
        <li>
          <p className="studio_name">新田辺/Boys(金) 男性アーティスト専門</p>
          <span>12</span>
        </li>
        <li>
          <p className="studio_name">出町柳/初級</p>
          <span>6</span>
        </li>
        <li>
          <p className="studio_name">丹波橋/K-POP 小4~小6</p>
          <span>13</span>
        </li>
        <li>
          <p className="studio_name">宇治/K-POP 小学生以下</p>
          <span>9</span>
        </li>
        <li>
          <p className="studio_name">山科/初級</p>
          <span>9</span>
        </li>
        <li>
          <p className="studio_name">西大路御池/初級</p>
          <span>11</span>
        </li>
        <li>
          <p className="studio_name">丹波橋/K-POP 小3以下</p>
          <span>9</span>
        </li>
        <li>
          <p className="studio_name">烏丸/初級</p>
          <span>8</span>
        </li>
        <li>
          <p className="studio_name">新田辺/小学生以下(月) + 小学生以下(金)</p>
          <span>15</span>
        </li>
        <li>
          <p className="studio_name">西大路御池/Girls</p>
          <span>14</span>
        </li>
        <li>
          <p className="studio_name">新田辺/入門(月)</p>
          <span>9</span>
        </li>
        <li>
          <p className="studio_name">丹波橋/K-POP基礎 小学生以下</p>
          <span>7</span>
        </li>
        <li>
          <p className="studio_name">西大路御池/小学生以下</p>
          <span>10</span>
        </li>
        <li>
          <p className="studio_name">丹波橋/K-POP基礎 中学生以上</p>
          <span>10</span>
        </li>
        <li>
          <p className="studio_name">烏丸/オーディション対策レッスン</p>
          <span>7</span>
        </li>
        <li>
          <p className="studio_name">宇治/K-POP 初級</p>
          <span>10</span>
        </li>
        <li>
          <p className="studio_name">木津川/中高生</p>
          <span>15</span>
        </li>
        <li>
          <p className="studio_name">新田辺/初級(月) + 初級(金)</p>
          <span>16</span>
        </li>
        <li>
          <p className="studio_name">合同/京都選抜</p>
          <span>8</span>
        </li>

        <li className="full">
          <p>2部</p>
        </li>
        <li>
          <p className="studio_name">加古川/小学生以下</p>
          <span>11</span>
        </li>
        <li>
          <p className="studio_name">西明石/小学生以下</p>
          <span>5</span>
        </li>
        <li>
          <p className="studio_name">東生駒/K-POP 初級</p>
          <span>9</span>
        </li>
        <li>
          <p className="studio_name">垂水/小学生以下</p>
          <span>17</span>
        </li>
        <li>
          <p className="studio_name">大和八木/K-POP 初級</p>
          <span>8</span>
        </li>
        <li>
          <p className="studio_name">大津京/K-POP 初級</p>
          <span>7</span>
        </li>
        <li>
          <p className="studio_name">西神中央/小学生以下(火)</p>
          <span>21</span>
        </li>
        <li>
          <p className="studio_name">草津/K-POP 小学生以下</p>
          <span>5</span>
        </li>
        <li>
          <p className="studio_name">天理/K-POP 初級</p>
          <span>8</span>
        </li>
        <li>
          <p className="studio_name">学園前/K-POP 一般</p>
          <span>7</span>
        </li>
        <li>
          <p className="studio_name">加古川/一般</p>
          <span>14</span>
        </li>
        <li>
          <p className="studio_name">西神中央/初級(火)</p>
          <span>14</span>
        </li>
        <li>
          <p className="studio_name">西明石/初級</p>
          <span>5</span>
        </li>
        <li>
          <p className="studio_name">垂水/初級</p>
          <span>12</span>
        </li>
        <li>
          <p className="studio_name">大和八木/K-POP 小学生以下</p>
          <span>9</span>
        </li>
        <li>
          <p className="studio_name">西神中央/初級(木)</p>
          <span>13</span>
        </li>
        <li>
          <p className="studio_name">大宮/初級</p>
          <span>16</span>
        </li>
        <li>
          <p className="studio_name">草津/K-POP 初級</p>
          <span>12</span>
        </li>
        <li>
          <p className="studio_name">東生駒/K-POP 小学生以下</p>
          <span>5</span>
        </li>
        <li>
          <p className="studio_name">合同/兵庫選抜</p>
          <span>16</span>
        </li>
        <li>
          <p className="studio_name">合同/奈良選抜</p>
          <span>14</span>
        </li>

        <li className="full">
          <p>3部</p>
        </li>
        <li>
          <p className="studio_name">楠葉/小学生以下</p>
          <span>18</span>
        </li>
        <li>
          <p className="studio_name">和泉/小学生以下</p>
          <span>9</span>
        </li>
        <li>
          <p className="studio_name">江坂/小学生以下</p>
          <span>8</span>
        </li>
        <li>
          <p className="studio_name">福島/K-POP 小学生以下</p>
          <span>6</span>
        </li>
        <li>
          <p className="studio_name">栂・美木多/K-POP 入門&初級</p>
          <span>10</span>
        </li>
        <li>
          <p className="studio_name">長岡京/初級</p>
          <span>11</span>
        </li>
        <li>
          <p className="studio_name">岸和田/小学生以下(月)</p>
          <span>13</span>
        </li>
        <li>
          <p className="studio_name">江坂/一般</p>
          <span>4</span>
        </li>
        <li>
          <p className="studio_name">高槻/一般</p>
          <span>3</span>
        </li>
        <li>
          <p className="studio_name">福島/K-POP 初級</p>
          <span>6</span>
        </li>
        <li>
          <p className="studio_name">天王寺/小学生以下</p>
          <span>5</span>
        </li>
        <li>
          <p className="studio_name">泉南/小学生以下 + 初級</p>
          <span>9</span>
        </li>
        <li>
          <p className="studio_name">八尾/小学生以下</p>
          <span>8</span>
        </li>
        <li>
          <p className="studio_name">高槻/中高生</p>
          <span>13</span>
        </li>
        <li>
          <p className="studio_name">和泉/初級</p>
          <span>5</span>
        </li>
        <li>
          <p className="studio_name">高槻/小学生以下</p>
          <span>10</span>
        </li>
        <li>
          <p className="studio_name">江坂/初級</p>
          <span>7</span>
        </li>
        <li>
          <p className="studio_name">三国ヶ丘/小学生以下</p>
          <span>12</span>
        </li>
        <li>
          <p className="studio_name">八尾/初級</p>
          <span>7</span>
        </li>
        <li>
          <p className="studio_name">池田/初級</p>
          <span>7</span>
        </li>
        <li>
          <p className="studio_name">栂・美木多/ダンス 入門(金)</p>
          <span>4</span>
        </li>
        <li>
          <p className="studio_name">岸和田/初級(金)</p>
          <span>7</span>
        </li>
        <li>
          <p className="studio_name">天王寺/初級</p>
          <span>7</span>
        </li>
        <li>
          <p className="studio_name">布施/小学生以下</p>
          <span>8</span>
        </li>
        <li>
          <p className="studio_name">長岡京/中級</p>
          <span>10</span>
        </li>
        <li>
          <p className="studio_name">岸和田/入門(金)</p>
          <span>5</span>
        </li>
        <li>
          <p className="studio_name">NANA + YUNA + MIKI 新店舗クラス</p>
          <span>17</span>
        </li>
        <li>
          <p className="studio_name">FANA 中学生</p>
          <span>24</span>
        </li>
        <li>
          <p className="studio_name">合同/大阪選抜</p>
          <span>16</span>
        </li>
      </ul> */}

      <div
        id="stkr"
        style={{
          position: "absolute",
          left: mousePosition.x + offsetX,
          top: mousePosition.y + offsetY,
          transform: `scale(${scale})`,
          visibility: isSvgHovered ? "visible" : "hidden",
          pointerEvents: "none",
          backgroundColor: "rgba(255, 255, 255, 0.8)",
          padding: "10px",
          borderRadius: "5px",
        }}
      >
        <span>席番 </span>
        {info.seatNo}
        <br />
        <span>スタジオ </span>
        {info.studioName}
        <br />
        <span>名前 </span>
        {info.name}
      </div>

      {/* 1部 */}
      <div className="section">
        <h2>1部</h2>
        {/* 左カラム */}
        <div className="seat">
          <div className="DIV_IMG">
            <SeatSection1Svg />
          </div>

          {/* 右カラム */}
          <div className="seatlistArea">
            <div className="seatList">
              <ul className="seatlist_section_1">
                <li>id</li>
                <li>ナンバー名</li>
                <li>人数</li>
                <li>表示</li>
                {/*  */}
                {sec1_number_data.map((number, index) => (
                  <React.Fragment key={index}>
                    <li>{index + 1}</li>
                    <li>{number.name}</li>
                    <li>{number.count}</li>
                    <li>
                      <input
                        type="checkbox"
                        onChange={(e) => handleCheckboxChange(e, number.name)}
                      />
                    </li>
                  </React.Fragment>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </div>

      {/* 2部 */}
      <div className="section">
        <h2>2部</h2>
        {/* 左カラム */}
        <div className="seat">
          <div className="DIV_IMG">
            <SeatSection2Svg />
          </div>

          {/* 右カラム */}
          <div className="seatlistArea">
            <div className="seatList">
              <ul className="seatlist_section_2">
                <li>id</li>
                <li>ナンバー名</li>
                <li>人数</li>
                <li>表示</li>
                {/*  */}
                {sec2_number_data.map((number, index) => (
                  <React.Fragment key={index}>
                    <li>{index + 1}</li>
                    <li>{number.name}</li>
                    <li>{number.count}</li>
                    <li>
                      <input
                        type="checkbox"
                        onChange={(e) => handleCheckboxChange(e, number.name)}
                      />
                    </li>
                  </React.Fragment>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </div>

      {/* 3部 */}
      <div className="section">
        <h2>3部</h2>
        {/* 左カラム */}
        <div className="seat">
          <div className="DIV_IMG">
            <SeatSection3Svg />
          </div>

          {/* 右カラム */}
          <div className="seatlistArea">
            <div className="seatList">
              <ul className="seatlist_section_3">
                <li>id</li>
                <li>ナンバー名</li>
                <li>人数</li>
                <li>表示</li>
                {/*  */}
                {sec3_number_data.map((number, index) => (
                  <React.Fragment key={index}>
                    <li>{index + 1}</li>
                    <li>{number.name}</li>
                    <li>{number.count}</li>
                    <li>
                      <input
                        type="checkbox"
                        onChange={(e) => handleCheckboxChange(e, number.name)}
                      />
                    </li>
                  </React.Fragment>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default SeatPage;
